import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmation-change-plan',
  templateUrl: './confirmation-change-plan.component.html',
  styleUrls: ['./confirmation-change-plan.component.css']
})
export class ConfirmationChangePlanComponent implements OnInit {
  closeResult = '';
  @ViewChild('content') content: ElementRef;
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public router: Router,
    public ngZone: NgZone
  ) { }

  ngOnInit(): void { }

}
