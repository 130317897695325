import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { EstablishmentService } from '../../../../../shared/services/establishment.service';
import { StructureFormComponent } from '../../../shared/structure-form.component';
import { Photo, Structure } from '../../../../../shared/models/menu.model';
import { CategoryDish } from '../../../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../../../shared/models/unit-mesure.model';
import { Dish } from '../../../../../shared/models/dish.model';
import { Allergen } from '../../../../../shared/models/allergen.model';
import { AngularFireStorage } from '@angular/fire/storage';
import { FileFirebaseUpload } from '../../../../../shared/models/fileFirebaseUpload.model';
import { tap, finalize } from 'rxjs/operators';

@Component({
  selector: "app-image-structure-form",
  templateUrl: "./image-structure-form.component.html",
  styleUrls: ["./image-structure-form.component.css"],
})
export class ImageStructureFormComponent extends StructureFormComponent<Photo> {
  @Input() contents: Photo;
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() dishes: Dish[];
  @Input() allergens: Allergen[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @Output() delete = new EventEmitter();
  @Output() edit = new EventEmitter<Structure>();
  @Output() indexChange = new EventEmitter();
  constructor(public estService: EstablishmentService) {
    super(estService);
  }
  del() {
    this.delete.emit();
  }
  imageChange($event) {
    this.contents.src = $event;
  }
  indexed() {
    this.indexChange.emit();
  }
}
