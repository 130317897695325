import { Injectable } from '@angular/core';
import { ICategoryDishCRUDService } from '../interfaces/iCategoryDishCRUDService';
import { CategoryDishAdminService } from './category-dish-admin.service';
import { CategoryDishUserService } from './category-dish-user.service';

@Injectable({
  providedIn: "root",
})
export class CategoryDishService {

  public isAdminData = false;

  constructor (
    private _categoryDishUserService: CategoryDishUserService,
    private _categoryDishAdminService: CategoryDishAdminService
  ) {}

  public get instance(): ICategoryDishCRUDService {
    return this.isAdminData ? this._categoryDishAdminService : this._categoryDishUserService;
  }
}
