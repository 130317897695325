import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { SortEvent, NgbdSortableHeader } from '../../shared/directives/sortable.directive';
import { faSearch, faPlus, faEye, faTrash, faEdit, faSpinner, faEyeSlash, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../shared/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../shared/services/helper.service';
import { DecimalPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { User } from 'src/app/shared/services/user';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  faPlus = faPlus;
  faSearch = faSearch;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faTrash = faTrash;
  faEdit = faEdit;
  faSpinner = faSpinner;
  faThumbsUp = faThumbsUp;
  faThumbsDown = faThumbsDown;
  users$: Observable<User[]>;
  total$: Observable<number>;
  selectedLang: string;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(
    public service: UserService,
    private tService: TranslateService,
    private numberPipe: DecimalPipe,
    private helper: HelperService
  ) {
    this.tService.onLangChange.subscribe((data) => {
      this.selectedLang = data.lang.toUpperCase();
      this.service.selectedLang = data.lang.toUpperCase();
    });
    this.service.getAllDataTable();
    this.users$ = service.users$;
    this.total$ = service.total$;
  }

  ngOnInit(): void {}

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  show(user) {

  }
  setAdministrator(user: User) {
    const isAdmin = (user.isAdmin) ? false : true;
    this.service.setAdministrator(user.uid, !user.isAdmin);
  }
}
