<nav class="navbar bg-dark flex-md-nowrap p-0 font background">
    <a class="navbar-brand col-sm-3 col-md-2 mr-0 navbarcolor-brand navbrand" href="http://fuddie.es">
        <img class="brand-logo logo" src="assets/Logo_Blanc_SP.png" alt="Fuudie">
    </a>
    <div class="menuicons">
        <fa-icon class="fas fa-reply iconsnorm" (click)="back()"></fa-icon>
        <!-- <fa-icon class="fas fa-language iconsnorm"></fa-icon> -->
    </div>
</nav>
<div class="fomrnorm">
    <p style="text-align: center;"><strong>Aviso Legal y Condiciones de Uso</strong></p>
    <p>En el presente documento se regula el aviso legal y las condiciones de uso del sitio web www.fuudie.es (en adelante, Sitio Web)<br />Le informamos de que, en virtud de lo dispuesto en el art&iacute;culo 10 de la Ley 34/2002, de 11 de julio, de Servicios
        de la Sociedad de la Informaci&oacute;n y de Comercio Electr&oacute;nico (&ldquo;LSSI&rdquo;), el Sitio Web es operado por MIQUEL VERGÉS VILARRUBIA (en adelante, RESPONSABLE) con C.I.F. (MIQUEL VERGÉS VILARRUBIA 47851672B).<br />El acceso y utilizaci&oacute;n
        del Sitio Web por usted (el &ldquo;Usuario&rdquo;) supone que acepta en su totalidad y se obliga a cumplir por completo los t&eacute;rminos y condiciones recogidos en el presente Aviso Legal, as&iacute; como por las disposiciones contenidas en
        la&nbsp;Pol&iacute;tica de Privacidad, la&nbsp;Pol&iacute;tica de cookies&nbsp;y las&nbsp;Condiciones Generales de Contrataci&oacute;n; los cuales se configuran en su conjunto como los textos legales rectores del Sitio Web.</p>
    <p><br /><strong>CONCIONES DE USO DEL SITIO WEB</strong></p>
    <p><br />RESPONSABLE pone a disposici&oacute;n de los Usuarios el Sitio Web con el fin de promocionar su servicio de gesti&oacute;n de la carta virtual para restauradores.<br />El Usuario se compromete a no usar el Sitio Web con fines fraudulentos, as&iacute;
        como a no llevar a cabo conducta alguna que pudiera da&ntilde;ar la imagen, los intereses y los derechos de RESPONSABLE o de terceros dentro o fuera de Espa&ntilde;a. Asimismo, el Usuario se compromete a no realizar acto alguno con objeto de da&ntilde;ar,
        inutilizar o sobrecargar el Sitio Web, o que impidiera, de cualquier forma, la normal utilizaci&oacute;n y funcionamiento del mismo.<br />Asimismo, el Usuario se abstendr&aacute; de introducir o difundir en la red virus inform&aacute;ticos o cualesquiera
        otros sistemas f&iacute;sicos o l&oacute;gicos que sean susceptibles de provocar los da&ntilde;os anteriormente mencionados; intentar acceder y, en su caso, utilizar las cuentas de correo electr&oacute;nico de otros usuarios y modificar o manipular
        sus mensajes. El Usuario no transmitir&aacute; a terceros no autorizados su nombre de usuario ni su contrase&ntilde;a, debiendo comunicar inmediatamente a RESPONSABLE el acceso por parte de un usuario no autorizado a dicha informaci&oacute;n.<br
        />El acceso al Sitio Web es gratuito salvo en lo relativo al coste de la conexi&oacute;n a trav&eacute;s de la red de telecomunicaciones suministrada por el proveedor de acceso contratado por el Usuario. <br />Determinados servicios son exclusivos
        para nuestros clientes y su acceso se encuentra restringido. La utilizaci&oacute;n del Sitio Web atribuye la condici&oacute;n de Usuario del mismo e implica la aceptaci&oacute;n de todas las condiciones incluidas en los textos legales.<br />La
        prestaci&oacute;n del servicio relativo al Sitio Web tiene una duraci&oacute;n limitada al momento en el que el Usuario se encuentre conectado al Sitio Web o a alguno de los servicios que a trav&eacute;s del mismo se prestan. Por tanto, el Usuario
        debe leer atentamente el presente Aviso Legal en cada una de las ocasiones en que se proponga utilizar Sitio Web, ya que &eacute;ste y sus textos legales pueden sufrir modificaciones en cualquier momento. Algunos servicios del Sitio Web accesibles
        para los Usuarios o exclusivos para los clientes de RESPONSABLE, pueden estar sometidos a condiciones particulares, que, en su caso, pueden completar los textos legales y que deber&aacute;n ser aceptadas por el Usuario antes de iniciarse la prestaci&oacute;n
        del servicio correspondiente.<br />RESPONSABLE se reserva el derecho de suspender el acceso a su Sitio Web, sin previo aviso, de forma discrecional y temporal, por razones t&eacute;cnicas o de cualquier otra &iacute;ndole, pudiendo asimismo modificar
        unilateralmente tanto las condiciones de acceso, como la totalidad o parte de los contenidos en el incluidos. En todo caso, RESPONSABLE podr&aacute; interrumpir el servicio a cualquier Usuario que haga una utilizaci&oacute;n no &eacute;tica, ofensiva,
        ilegal, incorrecta de los contenidos o servicios que ofrece o contraria a los textos legales.</p>
    <p><br /><strong>RESPONSABILIDAD</strong></p>
    <p><br />El Usuario debe saber que las comunicaciones a trav&eacute;s de redes abiertas est&aacute;n expuestas a una pluralidad de amenazas que hacen que no sean seguras. Es responsabilidad del Usuario adoptar todas las medidas t&eacute;cnicas adecuadas
        para controlar razonablemente estas amenazas y, entre ellas, el tener sistemas actualizados de detecci&oacute;n de software malicioso, tales como virus, troyanos, etc., as&iacute; como tener actualizados los parches de seguridad de los correspondientes
        navegadores. En este orden de ideas, RESPONSABLE no se responsabiliza de los da&ntilde;os y perjuicios causados al Usuario como consecuencia de riesgos inherentes al medio empleado, ni de los ocasionados por las vulnerabilidades en sus sistemas
        y herramientas. RESPONSABLE no garantiza tampoco la total seguridad de sus sistemas y aunque tiene adoptadas medidas de seguridad adecuadas, no puede descartarse totalmente la existencia de vulnerabilidades y, en consecuencia, el Usuario debe
        ser precavido en la interactuaci&oacute;n con el Sitio Web.<br />En particular, RESPONSABLE no ser&aacute; responsable de:</p>
    <ul>
        <li>Los da&ntilde;os y perjuicios de cualquier tipo causados en los equipos inform&aacute;ticos del Usuario por virus, gusanos, troyanos o cualquier otro elemento da&ntilde;ino.</li>
        <li>Los da&ntilde;os y perjuicios de cualquier tipo producidos en el Usuario que traigan causa en fallos o desconexiones en las redes de telecomunicaciones que produzcan la suspensi&oacute;n, cancelaci&oacute;n o interrupci&oacute;n del servicio del
            Sitio Web durante la prestaci&oacute;n del mismo. A este respecto, el Usuario reconoce que el acceso al Sitio Web requiere de servicios suministrados por terceros ajenos al control de RESPONSABLE (p.e. operadores de redes de telecomunicaciones)
            cuya fiabilidad, calidad, continuidad y funcionamiento no corresponde a RESPONSABLE, ni forma parte de su responsabilidad garantizar la disponibilidad del servicio.</li>
        <li>De la informaci&oacute;n de terceros en los casos en los que act&uacute;e como prestador de servicios de intermediaci&oacute;n en el sentido dado por la LSSI, salvo cuando concurra conocimiento efectivo y no se haya retirado la informaci&oacute;n
            correspondiente.
        </li>
        <li>De la utilizaci&oacute;n que los Usuarios puedan hacer de los materiales de este Sitio Web o webs de enlace, ya sean prohibidos o permitidos, en infracci&oacute;n de los derechos de propiedad intelectual y/o industrial de contenidos del Sitio
            Web o de terceros.</li>
        <li>Del acceso de menores de edad a los contenidos incluidos en el Sitio Web, siendo responsabilidad de sus progenitores o tutores ejercer un control adecuado sobre la actividad de los hijos o menores a su cargo.</li>
        <li>De las comunicaciones, di&aacute;logos o elementos creados por el usuario en su carta virtual que se organicen a trav&eacute;s de o entorno al Sitio Web y/o sus enlaces, ni responder&aacute;, por tanto, de los eventuales da&ntilde;os y prejuicios
            que sufran los Usuarios particulares y/o colectivos a consecuencia de dichas comunicaciones y/o di&aacute;logos que: (i) sean il&iacute;citos, nocivos o contrarios a la moral y a las buenas costumbres (pornogr&aacute;ficos, violentos, racistas,
            etc.); (ii) induzcan o puedan inducir en el Usuario la falsa concepci&oacute;n de que RESPONSABLE suscribe, respalda, se adhiere o de cualquier manera apoya, las ideas, manifestaciones o expresiones, l&iacute;citas o il&iacute;citas del remitente;
            y (iii) resulten inapropiados o no pertinentes en relaci&oacute;n con la actividad de RESPONSABLE.</li>
    </ul>
    <p><strong>PROPIEDAD INTELECTUAL E INDUSTRIAL</strong></p>
    <p><br />Todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en la P&aacute;gina son propiedad de&nbsp;RESPONSABLE&nbsp;o, en su caso, de terceros que han autorizado su uso, sin que pueda entenderse que el uso o
        acceso al Portal y/o a los Contenidos atribuya al Usuario derecho alguno sobre las citadas marcas, nombres comerciales y/o signos distintivos, y sin que puedan entenderse cedidos al Usuario, ninguno de los derechos de explotaci&oacute;n que existen
        o puedan existir sobre dichos Contenidos.<br />De igual modo los Contenidos son propiedad intelectual de&nbsp;RESPONSABLE, o de terceros en su caso, por tanto, los derechos de Propiedad Intelectual son titularidad de&nbsp;RESPONSABLE&nbsp;o de
        terceros que han autorizado su uso, a quienes corresponde el ejercicio exclusivo de los derechos de explotaci&oacute;n de los mismos en cualquier forma y, en especial, los derechos de reproducci&oacute;n, distribuci&oacute;n, comunicaci&oacute;n
        p&uacute;blica y transformaci&oacute;n.<br />La utilizaci&oacute;n no autorizada de la informaci&oacute;n contenida en esta Web, as&iacute; como la lesi&oacute;n de los derechos de Propiedad Intelectual o Industrial de&nbsp;RESPONSABLE&nbsp;o
        de terceros incluidos en la P&aacute;gina que hayan cedido contenidos dar&aacute; lugar a las responsabilidades legalmente establecidas.<br />El uso del Sitio Web por el Usuario no supone la cesi&oacute;n de derecho alguno de propiedad intelectual
        y/o industrial sobre el Sitio Web, los contenidos y/o los signos distintivos de RESPONSABLE. A tal efecto, mediante el presente Aviso Legal, salvo en aquellos supuestos en los que est&eacute; legalmente permitido o medie previa autorizaci&oacute;n
        de RESPONSABLE, queda expresamente prohibido al Usuario la reproducci&oacute;n, transformaci&oacute;n, distribuci&oacute;n, comunicaci&oacute;n p&uacute;blica, puesta a disposici&oacute;n, extracci&oacute;n y/o reutilizaci&oacute;n del Sitio Web,
        sus contenidos y/o los signos distintivos de RESPONSABLE.</p>
    <p><br /><strong>POLITICA DE ENLACES</strong></p>
    <p><br />El establecimiento de enlaces con destino al Sitio Web, salvo indicaci&oacute;n en contrario por RESPONSABLE, deber&aacute; establecerse en los siguientes t&eacute;rminos:</p>
    <ul>
        <li>El enlace &uacute;nicamente vincular&aacute; a la p&aacute;gina principal de acceso del Sitio Web.</li>
        <li>El enlace no podr&aacute; consistir en frames o marcos que permitan la visualizaci&oacute;n del Sitio Web a trav&eacute;s de direcciones de Internet distintas a las del Sitio Web o que de cualquier otra forma muestren conjuntamente la informaci&oacute;n
            del Sitio Web con la incluida en otras p&aacute;ginas web.</li>
        <li>No se realizar&aacute;n desde la p&aacute;gina web que introduce el enlace (&ldquo;Sitio Enlazante&rdquo;) ning&uacute;n tipo de manifestaci&oacute;n falsa, inexacta o incorrecta sobre el Sitio Web y/o RESPONSABLE.<br />En ning&uacute;n caso la
            autorizaci&oacute;n otorgada por RESPONSABLE significa que: (i) patrocine, colabore, verifique o supervise el contenido y/o los servicios que se prestan a trav&eacute;s del Sitio Enlazante; ni (ii) que sea responsable del contenido del Sitio
            Enlazante.
        </li>
        <li>El Sitio Enlazante deber&aacute; cumplir fielmente con la Ley y no podr&aacute; en ning&uacute;n caso albergar contenidos, propios o de terceros, que: (i) sean il&iacute;citos, nocivos o contrarios a la moral y a las buenas costumbres (pornogr&aacute;ficos,
            violentos, racistas, etc.); (ii) induzcan o puedan inducir en el Usuario la falsa concepci&oacute;n de que RESPONSABLE suscribe, respalda, se adhiere o de cualquier manera apoya, las ideas, manifestaciones o expresiones, l&iacute;citas o il&iacute;citas
            del remitente; y (iii) resulten inapropiados o no pertinentes en relaci&oacute;n con la actividad de RESPONSABLE.</li>
    </ul>
    <p>En el supuesto de incumplimiento de cualquiera de los t&eacute;rminos antes enunciados, RESPONSABLE proceder&aacute; de forma inmediata y sin previa notificaci&oacute;n a la supresi&oacute;n del enlace.<br />A fin de ayudarle a encontrar informaci&oacute;n
        adicional, le informamos de que podemos incluir diversos dispositivos t&eacute;cnicos de enlaces que permiten al Usuario acceder a otras webs (&ldquo;Sitios Enlazados&rdquo;). De acuerdo con lo previsto en la LSSI, RESPONSABLE no ser&aacute; responsable
        de los servicios y contenidos facilitados a trav&eacute;s de los Sitios Enlazados, salvo que tenga conocimiento efectivo de la ilicitud y no haya desactivado el enlace con la debida diligencia.<br />En ning&uacute;n caso la existencia de Sitios
        Enlazados comporta recomendaci&oacute;n, promoci&oacute;n, identificaci&oacute;n o conformidad de RESPONSABLE con las manifestaciones, contenidos o servicios proporcionados a trav&eacute;s de los Sitios Enlazados. En consecuencia, RESPONSABLE
        no se hace responsable del contenido de los Sitios Enlazados, ni de sus condiciones de uso y pol&iacute;ticas de privacidad, siendo el Usuario el &uacute;nico responsable de comprobarlos y aceptarlos cada vez que acceda y use los mismos.<br />En
        el supuesto de que el Usuario considere que existen en los Sitios Enlazados contenidos il&iacute;citos, nocivos, denigrantes, violentos o inadecuados, podr&aacute; ponerlo en conocimiento de RESPONSABLE a trav&eacute;s de la siguiente direcci&oacute;n
        de correo electr&oacute;nico:
        <a href="mailto:fuudie@fuudie.es">fuudie@fuudie.es</a></p>
    <p><br /><strong>NOTIFICACIONES</strong></p>
    <p><br />Todas las comunicaciones entre RESPONSABLE y el Usuario se realizar&aacute;n por escrito y se dirigir&aacute;n a la direcci&oacute;n indicada en el encabezamiento del presente Aviso Legal.</p>
    <p><br /><strong>PROTECCI&Oacute;N DE DATOS</strong></p>
    <p><br />Los aspectos relativos al tratamiento de los datos personales de los Usuarios con motivo del uso del Sitio Web se hallan regulados en la&nbsp;Pol&iacute;tica de Privacidad.</p>
    <p><br /><strong>POL&Iacute;TICA DE COOKIES</strong></p>
    <p><br />Los aspectos relacionados con la de instalaci&oacute;n, gesti&oacute;n, eliminaci&oacute;n y dem&aacute;s informaci&oacute;n relevante relativa a las cookies que se emplean en el Sitio Web se hallan regulados en la&nbsp;Pol&iacute;tica de Cookies.</p>
    <p><br /><strong>CONDICIONES DE CONTRATACI&Oacute;N</strong></p>
    <p><br />Asimismo, las condiciones generales de contrataci&oacute;n se podr&aacute;n consultar en el documento espec&iacute;fico relativo a las&nbsp;Condiciones Generales de Contrataci&oacute;n.</p>
    <p><br /><strong>FUERO Y LEY APLICABLE</strong></p>
    <p><br />Las presentes Condiciones Generales est&aacute;n sometidas a la legislaci&oacute;n espa&ntilde;ola. Las partes se someten, a su elecci&oacute;n, para la resoluci&oacute;n de los conflictos y con renuncia a cualquier otro fuero, a los juzgados
        y tribunales del domicilio del usuario. <br />Todo lo anterior, complementa a lo establecido en nuestro&nbsp;Aviso Legal,&nbsp;Pol&iacute;tica De Privacidad. En caso de cualquier discrepancia prevalecer&aacute; entre los referidos textos legales
        y estas Condiciones Generales, prevalecer&aacute;n &eacute;stas &uacute;ltimas.</p>
</div>