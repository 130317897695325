import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StructureFormComponent } from '../../../shared/structure-form.component';
import { EstablishmentService } from '../../../../../shared/services/establishment.service';
import { Structure, DishStructure } from '../../../../../shared/models/menu.model';
import { Observable } from 'rxjs';
import { Dish } from '../../../../../shared/models/dish.model';
import { DishService } from '../../../../../shared/services/dish.service';
import { CategoryDish } from '../../../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../../../shared/models/unit-mesure.model';
import { Allergen } from '../../../../../shared/models/allergen.model';

@Component({
  selector: 'app-dish-structure-form',
  templateUrl: './dish-structure-form.component.html',
  styleUrls: ['./dish-structure-form.component.css']
})
export class DishStructureFormComponent extends StructureFormComponent<DishStructure> {
  @Input() contents: DishStructure;
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() dishes: Dish[];
  @Input() allergens: Allergen[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @Output() delete = new EventEmitter();
  @Output() edit = new EventEmitter<Structure>();
  constructor(public estService: EstablishmentService) {
    super(estService);
  }
  del() {
    this.delete.emit();
  }
  createData() {

  }
  setDish($event) {
    this.contents.id = $event.map(ci => ci.id);
  }
}
