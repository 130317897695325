import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as _ from 'lodash';
import { AngularFireStorage } from '@angular/fire/storage';
import { FileFirebaseUpload } from '../../../../shared/models/fileFirebaseUpload.model';
import { tap, finalize } from 'rxjs/operators';
import { FileService } from '../../../../shared/services/file.service';

@Component({
  selector: "app-media-add",
  templateUrl: "./media-add.component.html",
  styleUrls: ["./media-add.component.css"],
})
export class MediaAddComponent implements OnInit {
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  @Output() valueChange = new EventEmitter<string>();
  @Input() uploadPath: string = null;
  @Input() src: string = null;
  @Input() showRemove = true;
  @Input() rounded = false;
  @Input() location: string;
  constructor(private fService: FileService) {}

  ngOnInit() {}

  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      // const max_size = 20971520;
      const max_size = 100000;
      const allowed_types = ["image/png", "image/jpeg"];
      const max_height = 15200;
      const max_width = 25600;
      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          "Maximum size allowed is " + max_size / 1000000 + "Mb";

        return false;
      }

      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.imageError = "Only Images are allowed ( JPG | PNG )";
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const img_height = rs.currentTarget["height"];
          const img_width = rs.currentTarget["width"];

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              "Maximum dimentions allowed " +
              max_height +
              "*" +
              max_width +
              "px";
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.startUpload(fileInput.target.files[0], true);
            // this.valueChange.emit(imgBase64Path);
            // this.previewImagePath = imgBase64Path;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    this.valueChange.emit(this.cardImageBase64);
  }

  startUpload(fileToUpload: any, onlyImages: boolean) {
    const file = new FileFirebaseUpload();
    // Client-side validation example
    if (onlyImages && fileToUpload.type.split("/")[0] !== "image") {
      return;
    }
    // The main task
    const user = JSON.parse(localStorage.getItem("user"));
    const establishment = JSON.parse(localStorage.getItem("establishment"));
    const id = `${new Date().getTime()}_${fileToUpload.name}`;
    let path = `users/${user.uid}/Establishments/${establishment.id}/${id}`;
    if (this.uploadPath) {
      path = `users/${user.uid}/${this.uploadPath}`;
    }
    if (this.location) {
      path = `${this.location}/${new Date().getTime()}_${fileToUpload.name}`;
    }
    file.task = this.fService.uploadFile(fileToUpload, path);
    file.task.then(
      () => {
        // console.log("getting URL");
        this.fService.getDownloadURL(path).subscribe((data) => {
          // console.log("URL: ", data);
          this.valueChange.emit(data);
          this.fService.addFile({
            f: id,
            s: fileToUpload.size,
            t: fileToUpload.type,
          });
        });
      },
      (error) => {
        alert("Error uploading image: " + error);
      }
    );
    // Progress monitoring
    // file.percentage = file.task.percentageChanges();
    // file.snapshot = file.task.snapshotChanges().pipe(
    //   tap(snap => {
    //     if (snap.bytesTransferred === snap.totalBytes) {
    //       // Update firestore on completion
    //       // this.db.collection(collection).add({ path, size: snap.totalBytes });
    //     }
    //   }),
    //   finalize(() => {
    //     try {
    //       file.downloadURL = this.fService.getDownloadURL(path);
    //       file.downloadURL.subscribe(data => {
    //         file.url = data;
    //         this.valueChange.emit(data);
    //         this.fService.addFile({
    //           f: id,
    //           u: data,
    //           s: fileToUpload.size,
    //           t: fileToUpload.type
    //         });
    //       });
    //       file.upload = true;
    //     } catch (error) {
    //       alert('Error uploading image: ' + error);
    //       file.upload = false;
    //     }
    //   })
    // );
  }
}
