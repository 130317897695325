<div class="displayTable backgroundsigup font">
    <div class="displayTableCell displaytablesignup">

        <!-- <div class="px-logo">
            <a href="https://fuudie-81c95.web.app/sign-in" target="_blank">
                <img src="assets/Fuudie_Negre.png" alt="Fuudie">
            </a>
        </div> -->

        <div class="authBlock">
            <h3>{{'verify-email.thanks' | translate}}</h3>

            <div class="formGroup" *ngIf="authService.userData as user">
                <p class="text-center">{{'verify-email.confirmation' | translate}}<strong>{{user.email}}</strong>.</p>
                <p class="text-center">{{'verify-email.please' | translate}}</p>
            </div>

            <!-- Calling SendVerificationMail() method using authService Api -->
            <div class="formGroup">
                <button type="button" class="btn btnPrimary" (click)="send()">
          <i class="fas fa-redo-alt"></i>
          {{'verify-email.resend' | translate}}
        </button>
            </div>

            <div class="redirectToLogin redirectstyle">
                <span>{{'verify-email.already' | translate}} <span class="redirect redirectstyle" (click)="signIn()">{{'verify-email.login' | translate}}</span></span>
            </div>
        </div>


    </div>
</div>