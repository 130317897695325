import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { EstablishmentService } from '../../shared/services/establishment.service';
import { Establishment } from 'src/app/shared/models/establishment.model';
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { CategoryDishService } from '../../shared/services/category-dish.service';
import { DishQuantity } from '../../shared/models/quantity.model';
import { CategoryDish } from '../../shared/models/category-dish.model';
import { Router } from '@angular/router';
import { LANGUAGES } from '../../shared/data/data';

@Component({
  selector: 'app-category-dish-form',
  templateUrl: './category-dish-form.component.html',
  styleUrls: ['./category-dish-form.component.css']
})
export class CategoryDishFormComponent implements OnInit {
  public collapseId = 0;
  faPlus = faPlus;
  faTrash = faTrash;
  categoryDishForm: FormGroup;
  editingCategoryDish: CategoryDish;
  establishment: Establishment;
  // quantities: {id}[] = [];
  _elementToEdit: CategoryDish;
  @ViewChild('form') dishRefForm: ElementRef;
  @Input() set elementToEdit(value: CategoryDish) {
    this._elementToEdit = value;
    if (this._elementToEdit) {
      this.editElemet(this._elementToEdit);
    }
  }
  @Output() resetForm = new EventEmitter<CategoryDish>();
  constructor(
    private fb: FormBuilder,
    private categoryDishService: CategoryDishService,
    private estService: EstablishmentService,
    private _router: Router) {
    this.categoryDishService.isAdminData = this._router.url.includes('admin');
    this.establishment = this.estService.isEstablishmentSelected;
    this.categoryDishForm = this.fb.group({
      name: ['', Validators.required],
    });
    if (this.categoryDishService.isAdminData) {
       LANGUAGES.map(li => li.code).forEach((li) => {
        this.categoryDishForm.addControl('name_' + li, new FormControl('', null));
      });
    } else {
      (this.establishment.languages as string[]).forEach((li) => {
        this.categoryDishForm.addControl('name_' + li, new FormControl('', null));
      });
    }
  }
  submit() {
    this.dishRefForm.nativeElement.submit();
  }
  ngOnInit(): void {}
  saveCategoryDish() {
    let cdish = null;
    if (this.categoryDishForm.valid) {
      cdish = this.getCategoryDishFromForm();
      if (this.editingCategoryDish) {
        cdish.id = this.editingCategoryDish.id;
        if (this.editingCategoryDish.a === true || this.editingCategoryDish.a === false) {
          cdish.a = this.editingCategoryDish.a;
        }
        this.categoryDishService.instance.edit(cdish);
        this.editingCategoryDish = null;
      } else {
        cdish = this.categoryDishService.instance.create(cdish);
      }
      this.reset(cdish);
    }
  }
  reset(cdish: CategoryDish) {
    this.editingCategoryDish = null;
    this.categoryDishForm.reset();
    this.resetForm.emit(cdish);
  }
  editElemet(element) {
    this.editingCategoryDish = element;
    this.categoryDishForm.controls['name'].setValue(this.editingCategoryDish.name);
    (this.establishment.languages as string[]).forEach((li) => {
      this.categoryDishForm.controls['name_' + li].setValue(
        (this.editingCategoryDish.name_traductions[li]) ? this.editingCategoryDish.name_traductions[li] : '');
    });
  }
  getCategoryDishFromForm() {
    const obj: CategoryDish = {id: '',
      name: this.categoryDishForm.get('name').value,
      name_traductions: {},
      a: true
    };
    (this.establishment.languages as string[]).forEach((li) => {
      obj.name_traductions[li] = this.categoryDishForm.get('name_' + li).value;
    });
    return obj;
  }
}
