
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { EstablishmentService } from '../../shared/services/establishment.service';
import { Establishment } from 'src/app/shared/models/establishment.model';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Menu, Structure, Text, Container, Table, Page } from '../../shared/models/menu.model';
import { MenuTemplateService } from '../../shared/services/menu-template.service';
import { StructureUtils } from '../../shared/utils/htmlGenerator.util';
import { CategoryDish } from '../../shared/models/category-dish.model';
import { Allergen } from 'src/app/shared/models/allergen.model';
import { UnitMeasure } from '../../shared/models/unit-mesure.model';
import { Dish } from '../../shared/models/dish.model';
import { Subscription } from 'rxjs';
import { AllergenService } from '../../shared/services/allergen.service';
import { CategoryDishService } from '../../shared/services/category-dish.service';
import { UnitMeasureService } from '../../shared/services/unit-measure.service';
import { DishService } from 'src/app/shared/services/dish.service';
import { StyleDrawerComponent } from '../menu/style-drawer/style-drawer.component';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { ResultDrawerComponent } from '../menu/result-drawer/result-drawer.component';
import { DataService } from '../../shared/services/data.service';
import { ConfigFormComponent } from '../menu/config-form/config-form.component';
import { StructureDrawerComponent } from '../menu/forms/structure-drawer/structure-drawer.component';
import { Router } from '@angular/router';
import { LANGUAGES } from '../../shared/data/data';
import html2canvas from 'html2canvas';
import { FileService } from '../../shared/services/file.service';
import { FileFirebaseUpload } from '../../shared/models/fileFirebaseUpload.model';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';

@Component({
  selector: 'app-menu-template-form',
  templateUrl: './menu-template-form.component.html',
  styleUrls: ['./menu-template-form.component.css'],
})
export class MenuTemplateFormComponent implements OnInit, OnDestroy {
  public collapseId = 0;
  faPlus = faPlus;
  faTrash = faTrash;
  menuForm: FormGroup;
  editingMenuTemplate: Menu;
  establishment: Establishment;
  _elementToEdit: Menu;
  _value: Menu;
  resultView = '';
  formView = '';
  active = 3;
  languages: string[];
  _categories: CategoryDish[] = [];
  _allergens: Allergen[] = [];
  _units: UnitMeasure[] = [];
  _dishes: Dish[] = [];
  subc: Subscription;
  suba: Subscription;
  subu: Subscription;
  subd: Subscription;
  subCode: Subscription;
  subDel: Subscription;
  subScroll: Subscription;
  subStyle: Subscription;
  indexes: { id: string, name: string}[] = [];
  scrollId: string;
  @ViewChild('form') dishRefForm: ElementRef;
  @ViewChild(ConfigFormComponent) configForm: ConfigFormComponent;
  @ViewChild(StructureDrawerComponent) codeForm: StructureDrawerComponent;
  @ViewChild(StyleDrawerComponent) styleForm: StyleDrawerComponent;
  @ViewChild(ResultDrawerComponent) drawer: ResultDrawerComponent;
  @ViewChild('screenshot') screenshotRef: ElementRef;
  @ViewChild(NgbNav) nav: NgbNav;
  @Input() set elementToEdit(value: Menu) {
    if (value) {
      this._elementToEdit = value;
      if (this._elementToEdit) {
        this.editElemet(this._elementToEdit);
      }
    }
  }
  @Input() set elementTemplateEdit(value: Menu) {
    if (value) {
      this._elementToEdit = value;
      if (this._elementToEdit) {
        this.templateElement(this._elementToEdit);
      }
    }
  }
  @Output() resetForm = new EventEmitter<Menu>();
  constructor(
    private fb: FormBuilder,
    private menuService: MenuTemplateService,
    private estService: EstablishmentService,
    private aService: AllergenService,
    private cService: CategoryDishService,
    private uService: UnitMeasureService,
    private dService: DishService,
    private data: DataService,
    private _router: Router,
    private fService: FileService) {
    this.menuService.isAdminData = this._router.url.includes('admin');
    this.cService.isAdminData = this._router.url.includes('admin');
    this.uService.isAdminData = this._router.url.includes('admin');
    this.dService.isAdminData = this._router.url.includes('admin');
    this.establishment = this.estService.isEstablishmentSelected;
    const traductions = {};
    this.languages = [];
    if (this.menuService.isAdminData) {
      LANGUAGES.map(lii => lii.code).forEach((li) => {
        traductions[li] = '';
        this.languages.push(li);
      });
    } else {
      this.establishment.languages.forEach((li) => {
        traductions[li] = '';
        this.languages.push(li);
      });
    }
    this._value = {
      id: this.menuService.instance.createId(),
      cnt: [StructureUtils.getPage()],
      n: '',
      ss: '',
      st: traductions,
      nt: traductions,
      l: this.languages,
      v: true,
      al: false,
      bg: '',
      dt: 'Standard',
      eh: false,
      hh: false,
      h: StructureUtils.getContainer(),
      hf: false,
      hi: false,
      i: StructureUtils.getIndex(),
      ls: false,
      lg: '',
      nav: '',
      sh: false,
      t: 'menu'
    };
    this.suba = this.aService.getAll().subscribe(al => this._allergens = al);
    this.subu = this.uService.instance.getAll().subscribe(ul => this._units = ul);
    this.subc = this.cService.instance.getAll().subscribe(cl => this._categories = cl);
    this.subd = this.dService.instance.getAll().subscribe(dl => this._dishes = dl);
    // this.menuForm = this.fb.group({
    //   name: ['', Validators.required],
    // });
    // (this.establishment.languages as string[]).forEach((li) => {
    //   this.menuForm.addControl('name_' + li, new FormControl('', null));
    // });
    this.subStyle = this.data.currentStructureStyle.subscribe(contents => {
      if (contents) {
        this.edit(contents);
        this.data.changeStructureStyle(null);
      }
    });
    this.subCode = this.data.currentStructureCode.subscribe(contents => {
      if (contents) {
        this.code(contents);
        this.data.changeStructureCode(null);
      }
    });
    this.subDel = this.data.currentStructureDel.subscribe(contents => {
      if (contents) {
        this.del(contents);
        this.data.changeStructureDel(null);
      }
    });
  }
  submit() {
    this.dishRefForm.nativeElement.submit();
  }
  ngOnInit(): void {
  }
  saveMenuTemplate() {
    let menu: Menu = null;
    menu = this.getMenuFromForm();
    this.saveMenu(menu);
  }
  saveMenu(menu: Menu) {
    if (this.editingMenuTemplate) {
      menu.id = this.editingMenuTemplate.id;
      this.menuService.instance.edit(menu);
      this.editingMenuTemplate = null;
    } else {
      menu = this.menuService.instance.create(menu);
    }
    this.reset(menu);
  }
  reset(menu: Menu) {
    this.editingMenuTemplate = null;
    // this.menuForm.reset();
    this.resetForm.emit(menu);
  }
  editElemet(element) {
    this.editingMenuTemplate = element;
    this._value = this.editingMenuTemplate;
  }
  templateElement(element) {
    this._value = element;
  }
  getMenuFromForm() {
    return this._value;
  }
  edit($event) {
    if (this.styleForm) {
      this.styleForm.contents = $event;
      if ($event.id) {
        try {
          document.querySelector('#' + $event.id).scrollIntoView({behavior: 'smooth'});
        } catch {}
      }
      this.nav.select(2);
    }
  }
  code($event) {
    const p = this.getPageStructure($event);
    console.log(p);
    if (this.configForm && this.codeForm) {
      if (!this.subScroll) {
        this.subScroll = this.nav.activeIdChange.subscribe(i => {
          console.log('detecting change nav');
          setTimeout(() => {
            if (this.scrollId) {
              try {
                const editor = document.querySelector('#edit_' + $event.id) as HTMLElement;
                editor.scrollIntoView({ behavior: 'smooth' });
                const color = editor.style.backgroundColor;
                editor.style.backgroundColor = '#5d7969';
                setTimeout(() => {
                  editor.style.backgroundColor = color;
                }, 1500);
              } catch {
                console.log('errror on scrolling');
              }
              this.scrollId = null;
            }
          }, 1500);
        });
      }
      let navChange = false;
      if ((p === -2 || p === -1) && this.nav.activeId !== 3) {
        this.nav.select(3);
        this.nav.activeId = 3;
        navChange = true;
      } else if (p >= 0) {
        if (this.nav.activeId !== 1) {
          this.nav.select(1);
          this.nav.activeId = 1;
          navChange = true;
        }
        this.codeForm.pageClicked(p);
      }
      if ($event.id) {
        if (navChange) {
          this.scrollId = '#edit_' + $event.id;
        } else {
          setTimeout(() => {
            try {
              const editor = document.querySelector('#edit_' + $event.id) as HTMLElement;
              editor.scrollIntoView({ behavior: 'smooth' });
              const color = editor.style.backgroundColor;
              editor.style.backgroundColor = '#5d7969';
              setTimeout(() => {
                editor.style.backgroundColor = color;
              }, 1500);
            } catch {
              console.log('errror on scrolling');
            }
          }, 1000);
        }
      }
    }
  }
  del($event) {

  }
  changeStructure($event) {
    this._value = $event;
  }
  ngOnDestroy() {
    this.subc.unsubscribe();
    this.suba.unsubscribe();
    this.subu.unsubscribe();
    this.subd.unsubscribe();
    this.subCode.unsubscribe();
    this.subStyle.unsubscribe();
    this.subDel.unsubscribe();
    if (this.subScroll) {
      this.subScroll.unsubscribe();
    }
  }
  changeIndex() {
    this.drawer.calculateIndex();
    // this.drawer.structure = this._value;
    // this._value = null;
  }
  templateChanged() {
    this.drawer.structure = this._value;
  }
  getPageStructure(contents: Structure) {
    if (!this._elementToEdit) { return -3; }
    if (this.isIn(this._elementToEdit.h, contents.id)) { return -2; }
    if (this._elementToEdit.i.id === contents.id) { return -1; }
    let i = 0;
    while (i < this._elementToEdit.cnt.length) {
      if (this.isIn(this._elementToEdit.cnt[i].cnt, contents.id)) {
        return i;
      }
      ++i;
    }
    return -3;
  }
  isIn(contents: Structure, id: string) {
    if (contents.id === id) {
      return true;
    }
    if (contents.t === 'container') {
      let i = 0;
      while (i < (contents as Container).cnt.length) {
        if (this.isIn((contents as Container).cnt[i], id)) {
          return true;
        }
        ++i;
      }
    } else if (contents.t === 'table') {
      let i = 0;
      let j = 0;
      while (i < (contents as Table).cnt.length) {
        j = 0;
        while (i < (contents as Table).cnt[i].length) {
          if (this.isIn((contents as Container).cnt[i][j], id)) {
            return true;
          }
          ++j;
        }
        ++i;
      }
    }
    return false;
  }
  languageChanged(lang: string) {
    if (this._value && this._value.obn) {
        this._dishes = this._dishes.sort((d1, d2) => d1.nt[lang] > d2.nt[lang] ? 1 : -1);
    }
  }
}
