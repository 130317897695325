import { Component, Input, ɵConsole, Inject, OnDestroy, ContentChildren } from '@angular/core';
import { StructureTemplateComponent, IndexStructureTemplateComponent } from '../../shared/structure-template.component';
import { Menu, Index, Structure, Text, Container, Table, Page } from '../../../../shared/models/menu.model';
import { CategoryDish } from '../../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../../shared/models/unit-mesure.model';
import { Dish } from '../../../../shared/models/dish.model';
import { Allergen } from '../../../../shared/models/allergen.model';
import { DOCUMENT } from '@angular/common';
import { StyleDrawerComponent } from '../../style-drawer/style-drawer.component';
@Component({
    selector: 'app-index-standard-template',
    template: `
    <div *ngIf="_contents" id="index" style="display: block; width: 100%; height: 100%; padding: 20px" [style.text-align]="_contents.s?.hp ? _contents.s.hp : 'center'">
        <button *ngFor="let i of _indexes"
        [style.color]="_contents.s?.c ? _contents.s.c : 'black'"
        [style.font-family]="_contents.s?.f ? _contents.s.f : 'inherit'"
        [style.font-size]="_contents.s?.s ? _contents.s.s : '16px'"
        [style.background-color]="_contents.s?.bc ? _contents.s.bc : 'white'"
        [style.border-color]="_contents.s?.boc ? _contents.s.boc : 'black'"
        [style.border-width]="_contents.s?.bs ? _contents.s.bs : '1px'"
        [style.border-radius]="_contents.s?.br ? _contents.s.br : '5px'"
        [style.font-weight]="(_contents.s?.b ? 'bold' : 'normal')"
        [style.font-style]="(_contents.s?.cu ? 'italic' : 'normal')"
        [style.text-decoration]="(_contents.s?.u ? 'underline' : 'none')"
        [style.margin-top]="(_contents.s?.m ? _contents.s.m : '10px')"
        [style.width]="(_contents.s?.w ? _contents.s.w : '100%')"
        [style.height]="(_contents.s?.h ? _contents.s.h : '40px')"
         style="display: block; width: 100%" (click)="scroll(i)">{{ (i.trds[language_selected] ? i.trds[language_selected] : i.name )}}</button>
         <button *ngIf="_showIndexBtn" type="button" class="btn btn-go-top transparent btn-circle btn-xl sticky toTopButton"
         [style.background-color]="(_contents.s?.c2 ? _contents.s.c2 : '#87af99')" (click)="scroll({id: 'top', name: ''})"><fa-icon class="fas fa-arrow-up"></fa-icon></button>
    </div>
    `,
    styles: [`
    .btn-go-top {
        background-color: 'white';
        border-color: #87af99 !important;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
        border-radius: 0.5rem;
    }
    .btn-go-top.focus,
    .btn-go-top:focus {
        box-shadow: none;
    }
    .btn-go-top.focus {
        background-color: #87af99 !important;
        border-color: #87af99 !important;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
        border-radius: 0.5rem;
    }
    .btn-go-top:hover {
        background-color: #ffffff !important;
        color: #87af99;
        border-color: #ffffff !important;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
        border-radius: 0.5rem;
    }
    .transparent {
        opacity: 0.7;
    }
    .sticky {
        position: fixed;
        bottom: 5px;
        right: 0;
        width: 100%;
        z-index: 1;
      }
    h1 {
        color:green;
    }
    .xyz {
        background-size: auto;
        text-align: center;
        padding-top: 100px;
    }
    .btn-circle.btn-sm {
        width: 30px;
        height: 30px;
        padding: 6px 0px;
        border-radius: 15px;
        font-size: 16px;
        text-align: center;
    }
    .btn-circle.btn-md {
        width: 50px;
        height: 50px;
        padding: 7px 10px;
        border-radius: 25px;
        font-size: 20px;
        text-align: center;
    }
    .btn-circle.btn-xl {
        width: 70px;
        height: 70px;
        padding: 10px 16px;
        border-radius: 35px;
        font-size:24px;
        text-align: center;
    } `]
})
export class IndexStandardTemplateComponent implements IndexStructureTemplateComponent, OnDestroy {
    _menu: Menu;
    _contents: Index;
    _indexes: { id: string, name: string, trds: any}[];
    _showIndexBtn = true;
    @Input() type: string;
    @Input() set showIndexBtn (v: boolean) {
        this._showIndexBtn = v;
    }
    @Input() set menu (v: Menu) {
        this._menu = v;
    }
    @Input() set indexes(v: { id: string, name: string, trds: any}[]) {
        this._indexes = v;
    }
    @Input() set contents (v: Index) {
        this._contents = v;
        if (!this._contents.id) {
          this._contents.id = 'index';
        }
        this._contents.s = {
            hp: v.s?.hp ? v.s.hp : 'center',
            c: v.s?.c ? v.s.c : 'black',
            c2: v.s?.c2 ? v.s.c2 : '#87af99',
            f: v.s?.f ? v.s.f : 'inherit',
            s: v.s?.s ? v.s.s : '16px',
            bc: v.s?.bc ? v.s.bc : 'white',
            boc: v.s?.boc ? v.s.boc : 'black',
            bs: v.s?.bs ? v.s.bs : '1px',
            br: v.s?.br ? v.s.br : '5px',
            b: v.s?.b !== undefined ? v.s.b : false,
            cu: v.s?.cu !== undefined ? v.s.cu : false,
            u: v.s?.u !== undefined ? v.s.u : false,
            m: v.s?.m ? v.s.m : '10px',
            w: v.s?.w ? v.s.w : '100%',
            h: v.s?.h ? v.s.h : '40px'
        };
    }
    @Input() categories: CategoryDish[];
    @Input() units: UnitMeasure[];
    @Input() dishes: Dish[];
    @Input() allergens: Allergen[];
    @Input() languages: string[];
    @Input() language_selected: string;
    @ContentChildren('styleDrawer') styleDrawer: StyleDrawerComponent;
    isTop = true;
    constructor(@Inject(DOCUMENT) public document: Document) {
        // window.addEventListener('scroll', this.scrolling, true); // third parameter
    }

    ngOnDestroy() {
        // window.removeEventListener('scroll', this.scrolling, true);
    }
    scrolling = (event): void => {
        // handle your scroll here
        // notice the 'odd' function assignment to a class field
        // this is used to be able to remove the event listener
        // console.log(event);
        // console.log(this.document.body.scrollTop);
        // const B = this.document.body; // IE 'quirks'
        // let D = this.document.documentElement; // IE with doctype
        // D = (D.clientHeight) ? D : B;
        // if (D.scrollTop === 0) {
        // 		this.isTop = true;
        // } else {
        //     this.isTop = false;
        // }
    }
    scroll(el) {
        document.querySelector('#' + el.id).scrollIntoView({behavior: 'smooth'});
      }
}
