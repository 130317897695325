<div class="displayTable backgroundsigup font">
    <div class="displayTableCell displaytablesignup">

        <!-- <div class="px-logo logo">
        <a href="https://fuudie-81c95.web.app/sign-in" target="_blank">
            <img src="assets/Fuudies_Blanc.png" alt="Fuudie">
        </a>
    </div> -->

        <div class="authBlock menusignup">
            <form [formGroup]="signUpForm">
                <h3 class="signuptext">{{'sign-up.signup' | translate}}</h3>

                <div class="formGroup newformgroup">
                    <input formControlName="firstName" type="text" class="formControl roundedBtn backgroundID" placeholder="{{'sign-up.nameandsur' | translate}}">
                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                        <div *ngIf="f.firstName.errors.required">{{'sign-up.nameandsurrequired' | translate}}</div>
                    </div>
                </div>
                <div class="formGroup newformgroup">
                    <input formControlName="email" type="email" class="formControl roundedBtn backgroundID" placeholder="{{'sign-up.emailaddress' | translate}}">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">{{'sign-up.emailaddressreq' | translate}}</div>
                        <div *ngIf="f.email.errors.email">{{'sign-up.emailaddressvalid' | translate}}</div>
                    </div>
                </div>

                <div class="formGroup newformgroup">
                    <input formControlName="password" type="password" class="formControl roundedBtn backgroundID" placeholder="{{'sign-up.pass' | translate}}">
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">{{'sign-up.passreq' | translate}}</div>
                        <div *ngIf="f.password.errors.pattern">{{'sign-up.passvalid' | translate}}</div>
                    </div>
                </div>

                <div class="formGroup newformgroup">
                    <input formControlName="confirmPassword" type="password" class="formControl roundedBtn backgroundID" placeholder="{{'sign-up.confirmpass' | translate}}">
                    <div *ngIf="submitted && (f.confirmPassword.errors || signUpForm.errors )" class="invalid-feedback">
                        <div *ngIf="f.confirmPassword.errors && f.confirmPassword.errors.required">{{'sign-up.confpassrequired' | translate}}</div>
                        <div *ngIf="signUpForm.errors && signUpForm.errors.notSame">{{'sign-up.confpassequal' | translate}}</div>
                    </div>
                </div>

                <div class="formGroup newformgroup">
                    <input type="submit" class="btn btnPrimary roundedBtn btnsignup" value="{{'sign-up.signup' | translate}}" (click)="onSubmit()">
                </div>
            </form>


            <!-- Continue with Google -->
            <div class="formGroup newformgroup">
                <button type="button" class="btn googleBtn roundedBtn googlebtnstyle" (click)="signInGoogle()">
          <i class="fab fa-google-plus-g"></i>
          {{'sign-up.continuegoogle' | translate}}
        </button>
            </div>
            <div class="redirectToLogin redirectstyle">
                <span>{{'sign-up.alreadyaccount' | translate}} <span class="redirect redirectstyle" (click)="signIn()">{{'sign-up.login' | translate}}</span></span>
            </div>

        </div>

    </div>

</div>