import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { Dish } from '../models/dish.model';
import { MenuTemplateUserService } from './menu-template-user.service';
import { Menu } from '../models/menu.model';
import { CollectionMenuTemplateAdminService } from './collection-menu-template-admin.service';
import { map, tap, debounceTime, switchMap, delay } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class MenuTemplateAdminService extends MenuTemplateUserService {
    constructor(
        public afs: AngularFirestore, // Inject Firestore service
        public afAuth: AngularFireAuth, // Inject Firebase auth service
        public ngZone: NgZone, // NgZone service to remove outside scope warning
        private cService: CollectionMenuTemplateAdminService
    ) {
      super(afs, afAuth, ngZone);
  }
  get ref() {
    return firebase
      .firestore()
      .collection(
        `templates/admin/${this.database}`
      )
      .doc("Menus");
  }
  get refCollection() {
    return firebase
      .firestore()
      .collection(
        `templates/admin/${this.database}`
      );
  }
  get refObs() {
    return this.afs
      .collection<Menu>(
        `templates/admin/${this.database}`
      )
      .doc("Menus");
  }
  get refObsCollection() {
    return this.afs
      .collection<Menu>(
        `templates/admin/${this.database}`
      );
  }
  create(menu: Menu) {
      menu.id = menu.id ? menu.id : this.afs.createId();
      this.refCollection.doc(menu.id).set(menu, { merge: true});
      if (menu.c && menu.c.c) {
        menu.c.c.forEach((ci) => {
          this.cService.addMenu(ci, menu.id);
        });
      }
      return menu;
  }
  edit(menu) {
      return this.refCollection.doc(menu.id).set(menu, { merge: true});
  }
  remove(menu: Menu) {
      return this.refCollection.doc(menu.id).delete();
  }
  editConfig(menu, lastCollections: string[]) {
    const cToRemove = lastCollections.filter(
      (ci) => menu.c.c.findIndex((cii) => cii === ci) === -1
    );
    const cToAdd = menu.c.c.filter(
      (ci) => lastCollections.findIndex((cii) => cii === ci) === -1
    );
    return this.refCollection.doc(menu.id).set(menu, { merge: true }).then(() => {
      cToRemove.forEach((cti) => this.cService.removeMenu(cti, menu.id));
      cToAdd.forEach((cti) => this.cService.addMenu(cti, menu.id));
    });
  }
    getAll() {
        return this.refObsCollection.snapshotChanges().pipe(
            map((actions) => {
                return actions.map(action => action.payload.doc.data() as Menu);
                // return Object.keys(data || {}).map((key) => {
                //     return data[key] as Menu;
                // });
            })
        );
    }
    getAllDataTable() {
        this._search$
            .pipe(
                tap(() => this._loading$.next(true)),
                debounceTime(200),
                switchMap(() => this._search()),
                delay(200),
                tap(() => this._loading$.next(false))
            )
            .subscribe((result) => {
                this._menus$.next(result.menus);
                this._total$.next(result.total);
            });
        return this.refObsCollection.snapshotChanges().pipe(
            map((actions) => {
                this.menus = actions.map(action => action.payload.doc.data() as Menu);
                this._search$.next();
            })
        ).subscribe(data => {
        });
    }
}
