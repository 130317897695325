<form class="container font" [formGroup]="allergenForm" #form>
    <div class="form-group">
        <div class="formGroup">
            <div class="input-group mb-3">
                <input formControlName="icon" type="text" class="formControl form-control newform" placeholder="{{ 'allergen-form.iconname' | translate}}">
            </div>
        </div>
        <div class="formGroup">
            <div class="input-group mb-3">
                <input formControlName="name" type="text" class="formControl form-control newform" placeholder="{{ 'allergen-form.allergenname' | translate}}">
                <div class="input-group-append">
                    <button type="button" class="btn btn-outline-primary btntr" (click)="(collapseId === 1) ? collapseId = 0 : collapseId = 1" [attr.aria-expanded]="collapseId !== 1" aria-controls="nameTraductions">
                        <fa-icon class="fas fa-language"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
        <div id="nameTraductions" [ngbCollapse]="collapseId !== 1">
            <h6>{{ 'common.nametraduction' | translate}}</h6>
            <div class="formGroup" *ngFor="let lan of languages">
                <input formControlName="{{'name_' + lan.code }}" type="text" class="formControl newform" placeholder="{{ ('common.namein' | translate) + lan.code }}">
            </div>
        </div>
        <div class="formGroup">
            <div class="input-group mb-3">
                <input formControlName="description" type="text" class="formControl form-control newform" placeholder="{{ 'allergen-form.allergendesc' | translate}}">
                <div class="input-group-append">
                    <button type="button" class="btn btn-outline-primary btntr" (click)="(collapseId === 2) ? collapseId = 0 : collapseId = 2" [attr.aria-expanded]="collapseId !== 1" aria-controls="descriptionTraductions">
                        <fa-icon class="fas fa-language"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
        <div id="descriptionTraductions" [ngbCollapse]="collapseId !== 2">
            <h6>{{ 'common.desctraduction' | translate}}</h6>
            <div class="formGroup" *ngFor="let lan of languages">
                <input formControlName="{{'description_' + lan.code }}" type="text" class="formControl newform" placeholder="{{ ('common.descin' | translate) + lan.code }}">
            </div>
        </div>
    </div>
</form>