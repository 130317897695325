<div class="inner-adjust font">
    <form>
        <div class="pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2 inlineComp font newtitle">{{'invoices.invoices' | translate}} ({{ (service.money$ | async) / 100 | number }} €)</h1>
            <div class="form-group form-inline rightBtn">
                <fa-icon *ngIf="!(service.loading$ | async)" [icon]="faSearch"></fa-icon>
                <fa-icon class="rotateInfinite" *ngIf=" service.loading$ | async" [icon]="faSpinner"></fa-icon><input class="form-control ml-2 searchitem" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />

            </div>
        </div>
        <table class="table table-striped newtable">
            <thead class="headline">
                <tr>
                    <th scope="col" sortable="n" direction="asc" (sort)="onSort($event)" class="newcolor newborderleft">{{'invoices.client_name' | translate}}</th>
                    <th scope="col" class="newcolor">{{'invoices.invoice_date' | translate}}</th>
                    <th scope="col" class="newcolor">{{'invoices.period' | translate}}</th>
                    <th scope="col" class="newcolor">{{'invoices.cost' | translate}}</th>
                    <th scope="col" class="newcolor">{{'invoices.paid' | translate}}</th>
                    <th class="fit newcolor newborderright" scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let invoice of invoices$ | async" class="contentline">
                    <td class="formatocelda">
                        <ngb-highlight [result]="invoice.customer_name" [term]="service.searchTerm"></ngb-highlight>
                    </td>
                    <td class="formatocelda">
                        {{ invoice.created * 1000 | date }}
                    </td>
                    <td class="formatocelda">
                        {{ ( invoice.period_start * 1000 | date ) + ' - ' + ( invoice.period_end * 1000 | date ) }}
                    </td>
                    <td class="formatocelda">
                        {{ (invoice.total / 100 | number ) + ' €'}}
                    </td>
                    <td>
                        <fa-icon [ngClass]="{'red-font': !invoice.paid, 'green-font': invoice.paid}" [icon]="invoice.paid ? faThumbsUp : faThumbsDown"></fa-icon>
                    </td>
                    <td class="fit" scope="row">
                        <button type="button" (click)="show(invoice)" class="btn btn-primary bmd-btn-fab inlineComp btnTaleRow rightbtn"><fa-icon [icon]="faEye"></fa-icon></button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-between p-2 selectpag">
            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
            </ngb-pagination>

            <select class="custom-select paginas" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                <option [ngValue]="15">15 {{'common.itemsperpage' | translate}}</option>
                <option [ngValue]="30">30 {{'common.itemsperpage' | translate}}</option>
                <option [ngValue]="50">50 {{'common.itemsperpage' | translate}}</option>
            </select>
        </div>
    </form>
</div>