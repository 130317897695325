<div cdkDrag *ngIf="contents" [id]="'edit_'+contents.id" class="formGroup" style="border: #bebebe solid 1px; border-radius: 5px; padding: 5px;  margin-bottom: 2px!important">
    <div>
        <button type=" button " style="display: inline;" class="btn btn-light " (click)="del()"><fa-icon [icon]="faTimes"></fa-icon></button>
        <h6 style="display: inline;">{{'structure-drawer.dishcollection' | translate}}</h6>
        <button type=" button" style="display: inline;" class="btn btn-light" (click)="edit.emit(contents)"><fa-icon [icon]="faEdit"></fa-icon></button>
        <button type=" button" style="display: inline;" class="btn btn-light"><fa-icon class="fa fa-arrows-alt"></fa-icon></button>
    </div>
    <div class="row">
        <div class="col-4">
            <select class="custom-select" (change)="setMode($event)" [value]="contents.m" #mode>
                <option selected value="dish_group">{{'structure-drawer.dishsgroup' | translate}}</option>
                <option value="category_group">{{'structure-drawer.categorygroup' | translate}}</option>
            </select></div>
        <div class="col-8">
            <div *ngIf="mode.value === 'dish_group'">
                <ng-select appendTo="body" (change)="setDishes($event)" class="custom3" [items]="dishes" bindLabel="n" bindValue="id" placeholder="{{'structure-drawer.dishes' | translate}}*" [multiple]="true" [(ngModel)]="contents.d"></ng-select>
            </div>
            <div *ngIf="mode.value === 'category_group'">
                <ng-select appendTo="body" (change)="setCategories($event)" class="custom3" [items]="categories" bindLabel="name" bindValue="id" placeholder="{{'structure-drawer.categories' | translate}}*" [multiple]="true" [(ngModel)]="contents.c"></ng-select>
            </div>
        </div>
    </div>
    <!-- <div class="input-group mb-3">
            <div class="input-group-prepend">
                <select class="custom-select" (change)="setMode($event)" [value]="contents.m" #mode>
                <option selected value="dish_group">Dish's group</option>
                <option value="category_group">Category group</option>
            </select>
            </div>
            <div *ngIf="mode.value === 'dish_group'" class="input-group-append">
                <ng-select appendTo="body" (change)="setDishes($event)" class="custom3" [items]="dishes" bindLabel="n" bindValue="id" placeholder="Dishes*" [multiple]="true" [(ngModel)]="contents.d"></ng-select>
            </div>
            <div *ngIf="mode.value === 'category_group'" class="input-group-append">
                <ng-select appendTo="body" (change)="setCategories($event)" class="custom3" [items]="categories" bindLabel="name" bindValue="id" placeholder="Categories*" [multiple]="true" [(ngModel)]="contents.c"></ng-select>
            </div>
        </div> -->
</div>