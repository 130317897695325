import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-button-add',
  templateUrl: './button-add.component.html',
  styleUrls: ['./button-add.component.css']
})
export class ButtonAddComponent implements OnInit {
  faPlus = faPlus;
  @Output() add = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  addComponent(type: string) {
    this.add.emit(type);
  }
}
