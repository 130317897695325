import { Component, Input, OnInit, ViewChild, ComponentFactoryResolver, SimpleChanges, OnChanges, ContentChildren } from '@angular/core';
import { StructureTemplateComponent } from '../shared/structure-template.component';
import { CategoryDish } from '../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../shared/models/unit-mesure.model';
import { Allergen } from '../../../shared/models/allergen.model';
import { Dish } from '../../../shared/models/dish.model';
import { Container, Menu } from '../../../shared/models/menu.model';
import { ContainerDirective } from '../directives/container.directive';
import { REGISTRY } from '../shared/register.component';
import { StyleDrawerComponent } from '../style-drawer/style-drawer.component';
import { DataService } from '../../../shared/services/data.service';

@Component({
  selector: 'app-container-template',
  template: `
  <!-- <div class="template-container"> -->
    <!-- <div class="editing">
      <button type="button" class="btn btn-light enlinia" (click)="del()"><fa-icon class="fa fa-times"></fa-icon></button>
      <button type="button" class="btn btn-light enlinia" (click)="code()"><fa-icon class="fa fa-code"></fa-icon></button>
      <button type="button" class="btn btn-light enlinia" (click)="edit()"><fa-icon class="fa fa-bold"></fa-icon></button>
    </div> -->
                <ng-template container-host></ng-template>
              <!-- </div> -->
            `
})
export class ContainerTemplateComponent implements OnInit, OnChanges, StructureTemplateComponent<Container> {
  @Input() menu: Menu;
  @Input() contents: Container;
  @Input() type: string;
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() allergens: Allergen[];
  @Input() dishes: Dish[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @Input() showUtils: boolean;
  @ContentChildren('styleDrawer') styleDrawer: StyleDrawerComponent;
  @ViewChild(ContainerDirective, {static: true}) containerHost: ContainerDirective;
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private data: DataService) { }

  ngOnChanges(changes: SimpleChanges): void {
      this.loadComponent();
  }
  ngOnInit() {
    this.loadComponent();
    // this.showUtils = this.containerHost.showUtils;
  }

  loadComponent() {
    if (this.contents && this.languages && this.containerHost) {
        if (!this.type) {
            this.contents.dt = 'Standard';
            this.type = "Standard";
        }
        let t = `Container${this.type}TemplateComponent`;
        let componentFactory;
        try {
          componentFactory = this.componentFactoryResolver.resolveComponentFactory(REGISTRY.get(t));
        } catch {
          t = `ContainerStandardTemplateComponent`;
          componentFactory = this.componentFactoryResolver.resolveComponentFactory(REGISTRY.get(t));
        }

        const viewContainerRef = this.containerHost.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        (<StructureTemplateComponent<Container>>componentRef.instance).menu = this.menu;
        (<StructureTemplateComponent<Container>>componentRef.instance).contents = this.contents;
        (<StructureTemplateComponent<Container>>componentRef.instance).categories = this.categories;
        (<StructureTemplateComponent<Container>>componentRef.instance).units = this.units;
        (<StructureTemplateComponent<Container>>componentRef.instance).allergens = this.allergens;
        (<StructureTemplateComponent<Container>>componentRef.instance).dishes = this.dishes;
        (<StructureTemplateComponent<Container>>componentRef.instance).languages = this.languages;
        (<StructureTemplateComponent<Container>>componentRef.instance).language_selected = this.language_selected;
        (<StructureTemplateComponent<Container>>componentRef.instance).styleDrawer = this.styleDrawer;
    }
  }
  edit() {
    this.data.changeStructureStyle(this.contents);
  }
  del() {

  }
  code() {
    this.data.changeStructureCode(this.contents);
  }
}
