import { Injectable, NgZone, PipeTransform } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { map, tap, debounceTime, switchMap, delay } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../directives/sortable.directive';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { Menu } from '../models/menu.model';
import { IMenuTemplateCRUDService } from '../interfaces/iMenuTemplateCRUDService';

interface SearchResult {
    menus: Menu[];
    total: number;
}

interface State {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: SortColumn;
    sortDirection: SortDirection;
    selectedLang: string;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(menus: Menu[], column: SortColumn, direction: string, selectedLang: string = null): Menu[] {
    if (direction === '' || column === '') {
        return menus;
    } else {
        if (selectedLang && column === "n") {
          return [...menus].sort((a, b) => {
            const an = a.nt[selectedLang] ? a.nt[selectedLang] : a.n;
            const bn = b.nt[selectedLang] ? b.nt[selectedLang] : b.n;
            const res = compare(`${an}`, `${bn}`);
            return direction === "asc" ? res : -res;
          });
        }
        return [...menus].sort((a, b) => {
            const res = compare(`${a[column]}`, `${b[column]}`);
            return direction === 'asc' ? res : -res;
        });
    }
}

function matches(menu: Menu, term: string, selectedLang: string = null, pipe: PipeTransform = null) {
    if (selectedLang && menu.nt[selectedLang]) {
        return menu.nt[selectedLang].toLowerCase().includes(term.toLowerCase());
    }
    return menu.n.toLowerCase().includes(term.toLowerCase());
}

@Injectable({
    providedIn: 'root',
})
export class MenuTemplateUserService implements IMenuTemplateCRUDService {
    database = 'MenusTemplates';
    databaseTrash = 'MenusTemplatesTrash';
    protected _loading$ = new BehaviorSubject<boolean>(true);
    protected _search$ = new Subject<void>();
    protected _menus$ = new BehaviorSubject<Menu[]>([]);
    protected _total$ = new BehaviorSubject<number>(0);
    protected menus: Menu[] = [];
    protected _state: State = {
        page: 1,
        pageSize: 15,
        searchTerm: '',
        sortColumn: '',
        sortDirection: '',
        selectedLang: localStorage.getItem("cLang")
    };
    constructor(
        public afs: AngularFirestore, // Inject Firestore service
        public afAuth: AngularFireAuth, // Inject Firebase auth service
        public ngZone: NgZone // NgZone service to remove outside scope warning
    ) { }
    get timestamp() {
        return firebase.firestore.FieldValue.serverTimestamp();
    }
    get ref() {
        const user = JSON.parse(localStorage.getItem('user'));
        const establishment = JSON.parse(localStorage.getItem('establishment'));
        return firebase
            .firestore()
            .collection(
                `users/${user.uid}/Establishments/${establishment.id}/${this.database}`
            ).doc('Menus');
    }
    get refObs() {
        const user = JSON.parse(localStorage.getItem('user'));
        const establishment = JSON.parse(localStorage.getItem('establishment'));
        return this.afs.collection<Menu>(
            `users/${user.uid}/Establishments/${establishment.id}/${this.database}`
        ).doc('Menus');
    }
    createId() {
        return this.afs.createId();
    }
    create(menu: Menu) {
        menu.id = menu.id ? menu.id : this.afs.createId();
        const value = {};
        value[menu.id] = menu;
        this.ref.set(value, { merge: true });
        return menu;
    }
    edit(menu) {
        const value = {};
        value[menu.id] = menu;
        return this.ref
            .set(value, { merge: true });
    }
    remove(menu: Menu) {
        const user = JSON.parse(localStorage.getItem('user'));
        const establishment = JSON.parse(localStorage.getItem('establishment'));
        const value = {};
        value[menu.id] = menu;
        firebase
            .firestore()
            .doc(
                `users/${user.uid}/EstablishmentsTrash/${establishment.id}/${this.databaseTrash}/Menus`
            )
            .set(value, { merge: true });
        const value2 = {};
        value2[menu.id] = firebase.firestore.FieldValue.delete();
        return this.ref.update(value2);
    }
    getAll() {
        return this.refObs.snapshotChanges().pipe(
            map((actions) => {
                const data = actions.payload.data();
                return Object.keys(data || {}).map((key) => {
                    return data[key] as Menu;
                });
            })
        );
    }
    getAllDataTable() {
        this._search$
            .pipe(
                tap(() => this._loading$.next(true)),
                debounceTime(200),
                switchMap(() => this._search()),
                delay(200),
                tap(() => this._loading$.next(false))
            )
            .subscribe((result) => {
                this._menus$.next(result.menus);
                this._total$.next(result.total);
            });
        return this.refObs.snapshotChanges().pipe(
            map((actions) => {
                const data = actions.payload.data();
                this.menus = Object.keys(data || {}).map((key) => {
                    return data[key] as Menu;
                });
                this._search$.next();
            })
        ).subscribe(data => {
        });
    }
    get data$() { return this._menus$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._state.page; }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }
    get selectedLang() {
      return this._state.selectedLang;
    }

    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize }); }
    set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
    set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }
    set selectedLang(selectedLang: string) {
      this._set({ selectedLang });
    }

    protected _set(patch: Partial<State>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    protected _search(): Observable<SearchResult> {
        const { sortColumn, sortDirection, pageSize, page, searchTerm, selectedLang } = this._state;

        // 1. sort
        let menus = sort(this.menus, sortColumn, sortDirection, selectedLang);

        // 2. filter
        menus = menus.filter((country) => matches(country, searchTerm, selectedLang));
        const total = menus.length;

        // 3. paginate
        menus = menus.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        return of({ menus, total });
    }
}
