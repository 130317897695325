<ng-container *ngIf="structure">
    <div class="row font" style="margin-bottom: 5px;">
        <div class="col-6">
            <h6>{{'config-form.name' | translate}}</h6>
            <div class="input-group">
                <input type="text" class="formControl form-control" placeholder="{{'config-form.name' | translate}}" (change)="setText($event)" [value]="structure.n">
                <div class="input-group-append">
                    <button type="button" class="btn btn-outline-primary" (click)="namecollapsed=!namecollapsed" [attr.aria-expanded]="namecollapsed" aria-controls="textTraductions">
                            <fa-icon class="fa fa-plus"></fa-icon>
                        </button>
                </div>
            </div>
            <div id="textTraductions" [ngbCollapse]="namecollapsed">
                <div class="formGroup" *ngFor="let lang of languages">
                    <input type="text" class="formControl form-control" placeholder="{{ ('config-form.namein' | translate) + lang }}" (change)="setTextTrad(lang, $event)" [value]="structure.nt[lang] || ''">
                </div>
            </div>
        </div>
        <div class="col-6">
            <h6>{{'config-form.defaultlang' | translate}}</h6>
            <ng-select appendTo="body" (change)="structure.dl = $event" class="custom3" [items]="languages" placeholder="{{'config-form.defaultlang' | translate}}" [multiple]="false" [(ngModel)]="structure.dl"></ng-select>
        </div>
    </div>
    <div class="row font" style="margin-bottom: 5px;" *ngIf="isAdminData">
        <div class="col-6">
            <h6>{{'config-form.screenshot' | translate}}</h6>
            <div class="input-group">
                <input type="text" class="formControl form-control" placeholder="{{'config-form.screenshot' | translate}}" (change)="setText($event)" [value]="structure.ss || ''" [disabled]=true>
                <div class="input-group-append">
                    <button type="button" class="btn btn-outline-primary" (click)="takeScreenshot()">
                            <fa-icon class="fa" [ngClass]="{'fa-camera': !loadingScreenshot['default'], 'fa-sync-alt': loadingScreenshot['default'], 'rotate': loadingScreenshot['default']}"></fa-icon>
                    </button>
                    <button type="button" class="btn btn-outline-primary" (click)="screenshotcollapsed=!screenshotcollapsed" [attr.aria-expanded]="screenshotcollapsed" aria-controls="screenshotsTraductions" [disabled]="loadingScreenshot['default']">
                            <fa-icon class="fa fa-plus"></fa-icon>
                        </button>
                </div>
            </div>
            <div id="screenshotsTraductions" [ngbCollapse]="screenshotcollapsed">
                <div class="formGroup" *ngFor="let lang of languages">
                    <div class="input-group">
                        <input type="text" class="formControl form-control" placeholder="{{ ('config-form.screenshotin' | translate) + lang }}" (change)="setScreenshotTrad(lang, $event)" [value]="(structure.st) ? structure.st[lang] || '' : ''" [disabled]=true>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-outline-primary" (click)="takeScreenshot(lang)" [disabled]="loadingScreenshot[lang]"><fa-icon class="fa" [ngClass]="{'fa-camera': !loadingScreenshot[lang], 'fa-sync-alt': loadingScreenshot[lang], 'rotate': loadingScreenshot[lang]}"></fa-icon></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <h6 class="font">{{'config-form.mainoptions' | translate}}</h6>
    <div class="row font" style="margin-bottom: 5px;">
        <div class="col-4">
            <div class="form-check">
                <label class="form-check-label">
                    <input type="checkbox" class="larger" [checked]="structure.ls" (change)="structure.ls = $event.target.checked"> {{'config-form.showlang' | translate}}
                </label>
            </div>
        </div>
        <div class="col-4">
            <div class="form-check">
                <label class="form-check-label">
                    <input type="checkbox" class="larger" [checked]="structure.al" (change)="structure.al = $event.target.checked"> {{'config-form.showallergen' | translate}}
                </label>
            </div>
        </div>
        <div class="col-4">
            <div class="form-check">
                <label class="form-check-label">
                    <input type="checkbox" class="larger" [checked]="structure.sh" (change)="structure.sh = $event.target.checked"> {{'config-form.shareicon' | translate}}
                </label>
            </div>
        </div>
        <div class="col-4 mt-1">
            <div class="form-check">
                <label class="form-check-label">
                    <input type="checkbox" class="larger" [checked]="structure.obn" (change)="structure.obn = $event.target.checked"> {{'config-form.orderbyname' | translate}}
                </label>
            </div>
        </div>
    </div>
    <h6 class="font">{{'config-form.index' | translate}}</h6>
    <div class="formGroup font">
        <div class="row">
            <div class="col-6">
                <div class="input-group">
                    <ng-select appendTo="body" (change)="changeIndex($event.id)" class="custom3" [items]="templateTypes" bindLabel="name" bindValue="id" placeholder="{{'config-form.templates' | translate}}" [multiple]="false" [(ngModel)]="indexSelected"></ng-select>
                    <div class="input-group-append">
                        <button *ngIf="structure.hi" type=" button" style="display: inline;" class="btn btn-light" (click)="edit.emit(structure.i)"><fa-icon class="fa fa-edit"></fa-icon></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <h6 class="font">{{'config-form.background' | translate}}</h6>
            <div class="form-group">
                <ng-select appendTo="body" (change)="structure.tbg = $event.id" class="custom3" [items]="backs" bindLabel="name" bindValue="id" placeholder="{{'config-form.background' | translate}}" [multiple]="false" [(ngModel)]="backSelected"></ng-select>
                <div *ngIf="structure.tbg === 'c'" class="formGroup">
                    <input [(colorPicker)]="structure.bg" class="formControl form-control" cpAlphaChannel="disabled" [style.background]="structure.bg" />
                </div>
                <div *ngIf="structure.tbg === 'i'" class="form-check">
                    <label class="form-check-label" style="width: 50%; margin: auto;">
                        <app-media-add (valueChange)="backImageChange($event)" [src]="structure.bgi"></app-media-add>
                    </label>
                </div>
            </div>
        </div>
        <div class="col-6">
            <h6 class="font">{{'config-form.header' | translate}}</h6>
            <div class="row">
                <div class="col-4">
                    <div class="form-check">
                        <label class="form-check-label">
                      <input type="checkbox" [checked]="structure.hh" (change)="structure.hh = $event.target.checked"> {{'config-form.header' | translate}}
                    </label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input type="checkbox" [checked]="structure.hf" (change)="structure.hf = $event.target.checked"> {{'config-form.headerfixed' | translate}}
                        </label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-check" *ngIf="structure.hf === false">
                        <label class="form-check-label">
                        <input type="checkbox"  [checked]="structure.eh" (change)="structure.eh = $event.target.checked"> {{'config-form.headereverypage' | translate}}
                    </label>
                    </div>
                </div>
            </div>
            <div *ngIf="structure.hh">
                <app-container-structure-form [allowDrag]="false" [contents]="structure.h" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected" (delete)="deleteElement()" (edit)="edit.emit($event)"></app-container-structure-form>
            </div>
        </div>
    </div>


</ng-container>

<!-- 
    nav?: string;
    logo?: string;
    back?: string;
    header?: Container;
    headfix?: boolean;
} -->