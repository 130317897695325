<form class="container font" [formGroup]="establishmentForm" #form>
    <div class="form-group">
        <div class="formGroup">
            <div class="input-group mb-3">
                <input formControlName="name" type="text" class="formControl" placeholder="{{'establishment-form.name' | translate}}">
            </div>
            <div class="input-group mb-3">
                <ng-select appendTo="body" [items]="languages" bindLabel="name" bindValue="code" formControlName="languages" placeholder="{{'establishment-form.languages' | translate}}" [multiple]="true"></ng-select>
            </div>
        </div>
    </div>
</form>