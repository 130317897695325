
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { EstablishmentService } from '../../shared/services/establishment.service';
import { Establishment } from 'src/app/shared/models/establishment.model';
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { UnitMeasure } from '../../shared/models/unit-mesure.model';
import { UnitMeasureService } from '../../shared/services/unit-measure.service';
import { Router } from '@angular/router';
import { LANGUAGES } from '../../shared/data/data';

@Component({
  selector: 'app-units-measures-form',
  templateUrl: './units-measures-form.component.html',
  styleUrls: ['./units-measures-form.component.css']
})
export class UnitsMeasuresFormComponent implements OnInit {
  public collapseId = 0;
  faPlus = faPlus;
  faTrash = faTrash;
  unitMeasureForm: FormGroup;
  editingUnitMeasure: UnitMeasure;
  establishment: Establishment;
  _elementToEdit: UnitMeasure;
  @ViewChild('form') dishRefForm: ElementRef;
  @Input() set elementToEdit(value: UnitMeasure) {
    this._elementToEdit = value;
    if (this._elementToEdit) {
      this.editElemet(this._elementToEdit);
    }
  }
  @Output() resetForm = new EventEmitter<UnitMeasure>();
  constructor(
    private fb: FormBuilder,
    private unitMeasureService: UnitMeasureService,
    private estService: EstablishmentService, private _router: Router) {
    this.unitMeasureService.isAdminData = this._router.url.includes('admin');
    this.establishment = this.estService.isEstablishmentSelected;
    this.unitMeasureForm = this.fb.group({
      name: ['', Validators.required],
    });
    if (this.unitMeasureService.isAdminData) {
      LANGUAGES.map(li => li.code).forEach((li) => {
      this.unitMeasureForm.addControl('name_' + li, new FormControl('', null));
      });
    } else {
      (this.establishment.languages as string[]).forEach((li) => {
        this.unitMeasureForm.addControl('name_' + li, new FormControl('', null));
      });
    }
  }
  submit() {
    this.dishRefForm.nativeElement.submit();
  }
  ngOnInit(): void { }
  saveUnitMeasure() {
    let unit = null;
    if (this.unitMeasureForm.valid) {
      unit = this.getUnitMeasureFromForm();
      if (this.editingUnitMeasure) {
        unit.id = this.editingUnitMeasure.id;
        this.unitMeasureService.instance.edit(unit);
        this.editingUnitMeasure = null;
      } else {
        unit = this.unitMeasureService.instance.create(unit);
      }
      this.reset(unit);
    }
  }
  reset(unit: UnitMeasure) {
    this.editingUnitMeasure = null;
    this.unitMeasureForm.reset();
    this.resetForm.emit(unit);
  }
  editElemet(element) {
    this.editingUnitMeasure = element;
    this.unitMeasureForm.controls['name'].setValue(this.editingUnitMeasure.name);
    (this.establishment.languages as string[]).forEach((li) => {
      this.unitMeasureForm.controls['name_' + li].setValue(
        (this.editingUnitMeasure.name_traductions[li]) ? this.editingUnitMeasure.name_traductions[li] : '');
    });
  }
  getUnitMeasureFromForm() {
    const obj: UnitMeasure = {
      id: '',
      name: this.unitMeasureForm.get('name').value,
      name_traductions: {}
    };
    (this.establishment.languages as string[]).forEach((li) => {
      obj.name_traductions[li] = this.unitMeasureForm.get('name_' + li).value;
    });
    return obj;
  }
}
