<div *ngIf="contents" [id]="'edit_'+contents.id" cdkDrag class="formGroup" style="border: #bebebe solid 1px; border-radius: 5px; padding: 5px; margin-bottom: 2px!important">
    <div>
        <button type=" button " style="display: inline;" class="btn btn-light " (click)="del()"><fa-icon [icon]="faTimes"></fa-icon></button>
        <h6 style="display: inline;">{{'structure-drawer.table' | translate}}</h6>
        <button type=" button" style="display: inline;" class="btn btn-light" (click)="edit.emit(contents)"><fa-icon [icon]="faEdit"></fa-icon></button>
        <button type=" button" style="display: inline;" class="btn btn-light"><fa-icon class="fa fa-arrows-alt"></fa-icon></button>
    </div>
    <div class="input-group mb-3">
        <input type="number" step="1" min="1" max="10" class="formControl form-control" placeholder="{{'structure-drawer.numcols' | translate}}" (change)="changeCols($event)" [value]="contents.cn">
        <div class="input-group-append">
            <input type="number" step="1" min="1" class="formControl form-control" placeholder="{{'structure-drawer.numrows' | translate}}" (change)="changeRows($event)" [value]="contents.rn">
        </div>
    </div>
    <ng-container *ngFor=" let ri of contents.cnt; let i = index">
        <div class="row">
            <ng-container *ngFor="let ci of ri; let j = index">
                <div class="col-sm-{{ contents.w[j]}}">
                    <app-container-structure-form [contents]="ci" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected" (delete)="deleteElement(i, j)" (edit)="edit.emit($event)"
                        (indexChange)="indexed()"></app-container-structure-form>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>