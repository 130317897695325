<div cdkDrag class="formGroup" [id]="'edit_'+contents.id" style="border:#bebebe solid 1px; border-radius: 5px; padding: 5px; margin-bottom: 2px!important">
    <div>
        <button type=" button" style="display: inline;" class="btn btn-light" (click)="del()"><fa-icon [icon]="faTimes"></fa-icon></button>
        <h6 style="display: inline;">{{'structure-drawer.image' | translate}}</h6>
        <button type=" button" style="display: inline;" class="btn btn-light" (click)="edit.emit(contents)"><fa-icon [icon]="faEdit"></fa-icon></button>
        <button type=" button" style="display: inline;" class="btn btn-light"><fa-icon class="fa fa-arrows-alt"></fa-icon></button>
    </div>

    <ng-container *ngIf="contents">
        <app-media-add (valueChange)="imageChange($event)" [src]="contents.src"></app-media-add>
    </ng-container>
</div>