
import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { faSearch, faPlus, faEye, faTrash, faEdit, faSpinner, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../shared/directives/sortable.directive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuTemplateService } from '../../shared/services/menu-template.service';
import { MenuTemplateModalComponent } from '../menu-template-modal/menu-template-modal.component';
import { Menu } from '../../shared/models/menu.model';
import { Router } from '@angular/router';
import { QrViewerModalComponent } from '../qr-viewer-modal/qr-viewer-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../shared/services/helper.service';
import { MenuTemplateSelectorModalComponent } from '../menu-template-selector-modal/menu-template-selector-modal.component';
import { MenuViewModalComponent } from '../menu-view-modal/menu-view-modal.component';
import { MenuTemplateConfigModalComponent } from '../menu-template-config-modal/menu-template-config-modal.component';

@Component({
  selector: 'app-menu-templates',
  templateUrl: './menu-templates.component.html',
  styleUrls: ['./menu-templates.component.css']
})
export class MenuTemplatesComponent implements OnInit {
  faPlus = faPlus;
  faSearch = faSearch;
  faEye = faEye;
  faTrash = faTrash;
  faEdit = faEdit;
  faSpinner = faSpinner;
  faQrcode = faQrcode;
  menus$: Observable<Menu[]>;
  total$: Observable<number>;
  selectedLang: string;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(public modalService: NgbModal, public service: MenuTemplateService,
    public router: Router, public tService: TranslateService, private helper: HelperService) {
    this.service.isAdminData = this.router.url.includes('admin');
    this.tService.onLangChange.subscribe((data) => {
      this.selectedLang = data.lang.toUpperCase();
      this.service.instance.selectedLang = data.lang.toUpperCase();
    });
    this.service.instance.getAllDataTable();
    this.menus$ = service.instance.data$;
    this.total$ = service.instance.total$;
  }

  ngOnInit(): void {
  }
  add() {
    let modal = null;
    const isAdminData = this.router.url.includes('admin');
    if (isAdminData) {
      modal = this.modalService
        .open(MenuTemplateModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'modal_class' });
    } else {
      modal = this.modalService
        .open(MenuTemplateSelectorModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'modal_class' });
    }
    if (modal) {
      modal.result.then(
        (result) => {
          if (!isAdminData) {
            const modal2 = this.modalService
              .open(MenuTemplateModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'modal_class'  });
            if (result) {
              modal2.componentInstance.templateMenu = result;
            }
          }
        },
        (reason) => {
          console.log(reason);
        }
      );
    }
  }
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.instance.sortColumn = column;
    this.service.instance.sortDirection = direction;
  }
  getLanguages(menu: Menu) {
    return Object.keys(menu.nt).filter(key => menu.nt[key].name !== '').join(', ');
  }
  show(menu) {
    if (this.service.isAdminData) {
      this.viewMenu(menu);
    } else {
      const user = JSON.parse(localStorage.getItem("user"));
      const establishment = JSON.parse(localStorage.getItem("establishment"));
      this.router.navigate(["menu", user.uid, establishment.id, menu.id]);
    }
  }
  viewMenu(menu: Menu) {
    const modal = this.modalService
        .open(MenuViewModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'modal_class' });
    modal.componentInstance.menu = menu;
    modal.componentInstance.selectedLanguage = this.selectedLang;
  }
  showQR(menu) {
    const user = JSON.parse(localStorage.getItem("user"));
    const establishment = JSON.parse(localStorage.getItem("establishment"));
    const modal = this.modalService
      .open(QrViewerModalComponent, { ariaLabelledBy: 'modal-basic-title'  });
    if (menu) {
      modal.componentInstance.editElement = `https://fuudie-81c95.web.app/menu/${user.uid}/${establishment.id}/${menu.id}`;
    }
    modal.result.then(
      (result) => {
      },
      (reason) => {
      }
    );
  }
  edit(menu) {
    const modal = this.modalService
      .open(MenuTemplateModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'modal_class'  });
    if (menu) {
      modal.componentInstance.editElement = menu;
    }
    modal.result.then(
      (result) => {
      },
      (reason) => {
      }
    );
  }
  delete(menu) {
    this.helper.openConfirmationDeleteData().then(result => {
      if (result) {
        this.service.instance.remove(menu);
      }
    });
  }
  config(menu) {
    const modal = this.modalService
      .open(MenuTemplateConfigModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'modal_class'  });
    if (menu) {
      modal.componentInstance.editElement = menu;
    }
    modal.result.then(
      (result) => {
      },
      (reason) => {
      }
    );
  }
}
