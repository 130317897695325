import { Component, OnInit, NgZone } from '@angular/core';
import { MediaGallery } from '../../shared/models/mediaGallery.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-media-gallery-modal',
  templateUrl: './media-gallery-modal.component.html',
  styleUrls: ['./media-gallery-modal.component.css']
})
export class MediaGalleryModalComponent implements OnInit {
  closeResult = '';
  editElement: MediaGallery;
  constructor(
    public activeModal: NgbActiveModal,
    public router: Router,
    public ngZone: NgZone
  ) {}

  ngOnInit(): void {}

}
