<div cdkDrag class="formGroup" [id]="'edit_'+contents.id" style="border: #bebebe solid 1px; border-radius: 5px; padding: 5px; margin-bottom: 2px!important">
    <div>
        <button type=" button" style="display: inline;" class="btn btn-light" (click)="del()"><fa-icon [icon]="faTimes"></fa-icon></button>
        <h6 style="display: inline;">{{'structure-drawer.text' | translate}}</h6>
        <input type="checkbox" style="display: inline;" [checked]="(contents.i ? contents.i : false)" (change)="changeIndex($event.target.checked)"> {{'structure-drawer.inindex' | translate}}
        <!-- <div class="form-check" style="display: inline;">
            <label class="form-check-label">
                    <input type="checkbox" [checked]="(contents.i ? contents.i : false)" (change)="changeIndex($event.target.checked)"> In index
                </label>
        </div> -->
        <button type=" button" style="display: inline;" class="btn btn-light" (click)="edit.emit(contents)"><fa-icon [icon]="faEdit"></fa-icon></button>
        <button type=" button" style="display: inline;" class="btn btn-light"><fa-icon class="fa fa-arrows-alt"></fa-icon></button>
    </div>

    <ng-container *ngIf="contents">
        <div class="input-group">
            <input type="text" class="formControl form-control" placeholder="{{'structure-drawer.text' | translate}}" (change)="setText($event)" [value]="contents.tx">
            <div class="input-group-append">
                <button type="button" class="btn btn-outline-primary" (click)="collapsed=! collapsed" [attr.aria-expanded]="collapsed" aria-controls="textTraductions">
                        <fa-icon [icon]="faPlus"></fa-icon>
                    </button>
            </div>
        </div>
        <div id="textTraductions" [ngbCollapse]="collapsed">
            <div class="formGroup" *ngFor="let lang of languages">
                <input type="text" class="formControl form-control" placeholder="{{ ('structure-drawer.textin' | translate) + lang }}" (change)="setTextTrad(lang, $event)" [value]="contents.txt[lang] || ''">
            </div>
        </div>
    </ng-container>
</div>