import { Component, Input, ContentChildren } from '@angular/core';
import { StructureTemplateComponent } from '../../shared/structure-template.component';
import { Text, Menu } from '../../../../shared/models/menu.model';
import { CategoryDish } from '../../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../../shared/models/unit-mesure.model';
import { Dish } from '../../../../shared/models/dish.model';
import { Allergen } from '../../../../shared/models/allergen.model';
import { StyleDrawerComponent } from '../../style-drawer/style-drawer.component';
@Component({
  selector: "app-text-standard-template",
  template: `
    <div *ngIf="_contents?.s"  id="{{_contents.id}}" class="border-text"
        [style.text-align]="_contents.s.hp"
        [style.padding]="_contents.s.p"
        [style.margin-top]="_contents.s.m"
        [style.margin-bottom]="_contents.s.m"
        [style.width]="_contents.s.w"
        [style.background-color]="_contents.s.bc"
        [style.border-color]="_contents.s.boc"
        [style.border-width]="_contents.s.bs"
        [style.border-radius]="_contents.s.br"
        >
      <span
        [style.color]="_contents.s.c"
        [style.font-family]="_contents.s.f"
        [style.font-size]="_contents.s.s"
        [style.font-weight]="(_contents.s.b ? 'bold' : 'normal')"
        [style.font-style]="(_contents.s.cu ? 'italic' : 'normal')"
        [style.text-decoration]="(_contents.s.u ? 'underline' : 'none')">
        {{ _contents.txt[language_selected] ? _contents.txt[language_selected] : _contents.tx }}</span
      >
    </div>
  `,
  styles: [`
  .border-text {
    border-style: solid;
  }`],
})
export class TextStandardTemplateComponent
  implements StructureTemplateComponent<Text> {
  _contents: Text;
  @Input() menu: Menu;
  @Input() type: string;
  @Input() set contents (v: Text) {
    this._contents = v;
    if (!this._contents.id) {
      this._contents.id = 'id_' + Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8);
    }
    this._contents.s = {
        hp: v.s?.hp ? v.s.hp : 'center',
        p: v.s?.p ? v.s.p : '5px',
        m: v.s?.m ? v.s.m : '10px',
        bc: v.s?.bc ? v.s.bc : 'transparent',
        c: v.s?.c ? v.s.c : 'black',
        f: v.s?.f ? v.s.f : 'inherit',
        s: v.s?.s ? v.s.s : '16px',
        b: v.s?.b !== undefined ? v.s.b : false,
        cu: v.s?.cu !== undefined ? v.s.cu : false,
        u: v.s?.u !== undefined ? v.s.u : false,
        boc: v.s?.boc ? v.s.boc : 'black',
        bs: v.s?.bs ? v.s.bs : '0px',
        br: v.s?.br ? v.s.br : '5px',
        w: v.s?.w ? v.s.w : '100%'
    };
  }
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() dishes: Dish[];
  @Input() allergens: Allergen[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @ContentChildren('styleDrawer') styleDrawer: StyleDrawerComponent;
  styles: any = {};
  constructor() {}
}
