import { Injectable } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDeleteAccountComponent } from '../../components/confirmation-delete-account/confirmation-delete-account.component';
import { ConfirmationDeleteDataComponent } from '../../components/confirmation-delete-data/confirmation-delete-data.component';
import { ConfirmationChangePlanComponent } from '../../components/confirmation-change-plan/confirmation-change-plan.component';
@Injectable({
  providedIn: "root",
})
export class HelperService {
  constructor(private modalService: NgbModal) {}

  openConfirmationDeleteAccount() {
    const modal = this.modalService
    .open(ConfirmationDeleteAccountComponent, { ariaLabelledBy: 'modal-basic-title' });
    return modal.result.then(
        (result) => {
          return true;
        },
        (reason) => {
        //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          return false;
        }
      );
  }
  openConfirmationDeleteData() {
    const modal = this.modalService
    .open(ConfirmationDeleteDataComponent, { ariaLabelledBy: 'modal-basic-title' });
    return modal.result.then(
        (result) => {
          return true;
        },
        (reason) => {
        //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          return false;
        }
      );
  }
  openConfirmationChangeAccount() {
    const modal = this.modalService
    .open(ConfirmationChangePlanComponent, { ariaLabelledBy: 'modal-basic-title' });
    return modal.result.then(
        (result) => {
          return true;
        },
        (reason) => {
        //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          return false;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
