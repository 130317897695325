import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AccountType } from '../../shared/models/account-type.model';
import { AuthService } from '../../shared/services/auth.service';
import { EstablishmentService } from '../../shared/services/establishment.service';
import { Establishment } from 'src/app/shared/models/establishment.model';

@Component({
  selector: "app-account-type-form",
  templateUrl: "./account-type-form.component.html",
  styleUrls: ["./account-type-form.component.css"],
})
export class AccountTypeFormComponent implements OnInit {
  data_plans: AccountType[] = [
        { id: "price_1Gr26RGtaeKnAwcAobkLnKXt", t: 1, m: false, c: 1, n: "Fuddie Basic", nd: 300, nm: 5 },
        { id: "price_1Gr28sGtaeKnAwcAx9EeNdYy", t: 2, m: true, c: 5, n: "Fuddie Pro", nd: 400, nm: 10 }];
        // { id: "", t: 3, m: true, c: 1000, n: "Fuddie Pro", nd: 400, nm: 10 }];

  selectedElement: number;
  _elementToEdit: AccountType;
  establishments: Establishment[] = [];
  @Input() set elementToEdit(value: AccountType) {
    this._elementToEdit = value;
    if (this._elementToEdit) {
      this.editElemet(this._elementToEdit);
    }
  }
  @Output() closeForm = new EventEmitter<AccountType>();
  @Output() selectedPlan = new EventEmitter<AccountType>();
  constructor(
    private authService: AuthService, private eService: EstablishmentService
  ) {
    this.eService.getAll().subscribe((ests) => {
       this.establishments = ests.docs.map((di) => di.data() as Establishment);
    });
  }
  ngOnInit(): void {}
  saveAccountType() {
    if (this._elementToEdit && this.selectedElement === this._elementToEdit.t) {
      this.closeForm.emit(this._elementToEdit);
      return;
    } else {
      this.authService
        .SetAccountType(
          this.authService.currentUser,
          this.getPlan(this.selectedElement)
        )
        .then(
          () => {
            this.closeForm.emit(this.getPlan(this.selectedElement));
          },
          (reason) => {
            alert(reason);
          }
        );
    }
  }
  selectAccount(type) {
    const plan = this.getPlan(type);
    if (plan.t === 1 && this.establishments.length > 1) {
      alert(`You can't change the plan because you have more than one restaurant in your account`);
      return;
    } if (plan.t === 2 && this.establishments.length > 5) {
      alert(`You can't change the plan because you have more than one restaurant in your account`);
      return;
    }
    this.selectedElement = type;
    this.selectedPlan.emit(plan);
  }
  editElemet(element) {
      this.selectedElement = element.t;
  }
  getPlan(plan) {
    return this.data_plans.find(dp => dp.t === plan);
  }
}
