import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.css"],
})
export class SignInComponent implements OnInit {
  constructor(
    public authService: AuthService,
    private modal: NgbActiveModal,
    private modalService: NgbModal
  ) {}

  ngOnInit() {}
  login(username, password) {
    this.authService.SignIn(username, password).then((result) => {
      if (result) {
        this.modal.close();
      }
    });
  }
  signInGoogle() {
    this.authService.GoogleAuth().then((result) => {
      if (result) {
        this.modal.close();
      }
    });
  }
  signup() {
    this.modal.close();
    const modal = this.modalService.open(SignUpComponent, {
      windowClass: "white-content",
      centered: true,
    });
    modal.result.then(
      (result) => {},
      (reason) => {}
    );
  }
  forgot() {
    this.modal.close();
    const modal = this.modalService.open(ForgotPasswordComponent, {
      windowClass: "white-content",
      centered: true,
    });
    modal.result.then(
      (result) => {},
      (reason) => {}
    );
  }
}
