import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { Menu } from '../models/menu.model';
import { map, tap, debounceTime, switchMap, delay } from 'rxjs/operators';
import { MediaGalleryUserService } from './media-gallery-user.service';
import { MediaGallery } from '../models/mediaGallery.model';

@Injectable({
  providedIn: "root",
})
export class MediaGalleryAdminService extends MediaGalleryUserService {
    constructor(
        public afs: AngularFirestore, // Inject Firestore service
        public afAuth: AngularFireAuth, // Inject Firebase auth service
        public ngZone: NgZone // NgZone service to remove outside scope warning
    ) {
      super(afs, afAuth, ngZone);
    }
    get ref() {
    return firebase
        .firestore()
        .collection(
        `templates/admin/${this.database}`
        )
        .doc("Menus");
    }
    get refCollection() {
    return firebase
        .firestore()
        .collection(
        `templates/admin/${this.database}`
        );
    }
    get refObs() {
    return this.afs
        .collection<MediaGallery>(
        `templates/admin/${this.database}`
        )
        .doc("Menus");
    }
    get refObsCollection() {
    return this.afs
        .collection<MediaGallery>(
        `templates/admin/${this.database}`
        );
    }
    create(media: MediaGallery) {
        media.id = media.id ? media.id : this.afs.createId();
        this.refCollection.doc(media.id).set(media, { merge: true});
        return media;
    }
    edit(media) {
        return this.refCollection.doc(media.id).set(media, { merge: true});
    }
    remove(media: MediaGallery) {
        return this.refCollection.doc(media.id).delete();
    }
    getAll() {
        return this.refObsCollection.snapshotChanges().pipe(
            map((actions) => {
                return actions.map(action => action.payload.doc.data() as MediaGallery);
            })
        );
    }
    setAvailable(id: string, a: boolean) {
        return this.refCollection.doc(id).set({ 'a': a }, { merge: true});
    }
    getAllDataTable() {
        this._search$
            .pipe(
                tap(() => this._loading$.next(true)),
                debounceTime(200),
                switchMap(() => this._search()),
                delay(200),
                tap(() => this._loading$.next(false))
            )
            .subscribe((result) => {
                this._mediaGalleries$.next(result.mediaGalleries);
                this._total$.next(result.total);
            });
        return this.refObsCollection.snapshotChanges().pipe(
            map((actions) => {
                this.mediaGalleries = actions.map(action => action.payload.doc.data() as MediaGallery);
                this._search$.next();
            })
        ).subscribe(data => {
        });
    }
}
