<form class="container font" [formGroup]="dishForm" #form>
    <div class="row">
        <div class="col-sm column1">
            <div class="form-group">
                <div class="pb-2 mb-3">
                    <h1 class="h5">{{'dish-form.maininfo' | translate}}</h1>
                </div>
                <div class="formGroup">
                    <div class="input-group mb-3">
                        <input formControlName="ref" type="text" class="formControl form-control newform" placeholder="{{'dish-form.dishreference' | translate}}">
                    </div>
                </div>
                <div class="formGroup">
                    <div class="input-group mb-3">
                        <input formControlName="name" type="text" class="formControl form-control newform" placeholder="{{'dish-form.dishname' | translate}}">
                        <div class="input-group-append">
                            <button type="button" class="btn btn-outline-primary btntr" (click)="(collapseId === 1) ? collapseId = 0 : collapseId = 1" [attr.aria-expanded]="collapseId !== 1" aria-controls="nameTraductions">
                        <fa-icon class="fas fa-language"></fa-icon>
                    </button>
                        </div>
                    </div>
                </div>
                <div id="nameTraductions" [ngbCollapse]="collapseId !== 1">
                    <h6>{{'common.nametraduction' | translate}}</h6>
                    <div class="formGroup" *ngFor="let lan of establishment.languages">
                        <input formControlName="{{'name_' + lan }}" type="text" class="formControl form-control newform" placeholder="{{ ('common.namein' | translate) + lan }}">
                    </div>
                </div>
                <div class="formGroup">
                    <div class="input-group mb-3">
                        <input formControlName="description" type="text" class="formControl form-control newform" placeholder="{{'dish-form.dishdescription' | translate}}">
                        <div class="input-group-append">
                            <button type="button" class="btn btn-outline-primary btntr" (click)="(collapseId === 2) ? collapseId = 0 : collapseId = 2" [attr.aria-expanded]="collapseId !== 2" aria-controls="descTraductions">
                        <fa-icon class="fas fa-language"></fa-icon>
                    </button>
                        </div>
                    </div>
                </div>
                <div id="descTraductions" [ngbCollapse]="collapseId !== 2">
                    <h6>{{'common.desctraduction' | translate}}</h6>
                    <div class="formGroup" *ngFor="let lan of establishment.languages">
                        <input formControlName="{{'description_' + lan }}" type="text" class="formControl form-control newform" placeholder="{{ ('common.descin' | translate) + lan }}">
                    </div>
                </div>
                <div class="formGroup">
                    <div class="input-group mb-3">
                        <ng-select appendTo="body" class="custom" [items]="categories$ | async" bindLabel="name" bindValue="id" formControlName="categories" placeholder="{{'dish-form.categories' | translate}}" [multiple]="true"></ng-select>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-outline-primary btntr1" (click)="openFormCategory()">
                        <fa-icon class="fas fa-plus"></fa-icon>
                    </button>
                        </div>
                    </div>
                </div>
                <div class="formGroup">
                    <div class="input-group mb-3">
                        <ng-select appendTo="body" class="custom" [items]="allergens$  | async" bindLabel="name" bindValue="id" formControlName="allergens" placeholder="{{'dish-form.allergens' | translate}}" [multiple]="true"></ng-select>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-outline-primary btntr1" (click)="openAllergenInfo()">
                        <fa-icon class="fas fa-plus"></fa-icon>
                    </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm">
            <div class="form-group">
                <div class="pb-2 mb-3">
                    <h1 class="h5 inlineComp">{{'dish-form.prices' | translate}}</h1>
                    <button type="button" (click)="addPrice()" class="btn btn-primary bmd-btn-fab rightBtn inlineComp btntr2"><fa-icon [icon]="faPlus"></fa-icon></button>
                </div>
                <div class="formGroup" *ngFor="let pi of prices">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend" *ngIf="prices.length > 1">
                            <button type="button" class="btn btn-outline-danger" (click)="removePrice(pi)">
                        <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                        </div>
                        <div class="input-group-prepend">
                            <input formControlName="{{ 'price_' + pi.id }}" type="text" class="formControl form-control newform" placeholder="{{'dish-form.price' | translate}}">
                        </div>
                        <ng-select appendTo="body" class="custom3" [items]="units$  | async" bindLabel="name" bindValue="id" formControlName="{{ 'unit_' + pi.id }}" placeholder="{{'dish-form.unit' | translate}}" [multiple]="false"></ng-select>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-outline-primary btntr" (click)="openFormUnits()">
                        <fa-icon [icon]="faPlus"></fa-icon>
                    </button>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="pb-2 mb-3">
                        <h1 class="h5 inlineComp">{{'dish-form.plus' | translate}}</h1>
                        <button type="button" (click)="addPlus()" class="btn btn-primary bmd-btn-fab rightBtn inlineComp btntr2"><fa-icon [icon]="faPlus"></fa-icon></button>
                    </div>
                </div>
                <div class="formGroup" *ngFor="let pl of pluses">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <button type="button" class="btn btn-outline-danger" (click)="removePlus(pl)"><fa-icon [icon]="faTrash"></fa-icon></button>
                        </div>
                        <div class="input-group-prepend">
                            <input formControlName="{{ 'plusp_' + pl.id }}" type="text" class="formControl form-controlnewform newform1" placeholder="{{'dish-form.price' | translate}}">
                        </div>
                        <input formControlName="{{ 'plusc_' + pl.id }}" type="text" class="formControl form-control newform" placeholder="{{'dish-form.concept' | translate}}">
                        <div class="input-group-append">
                            <button type="button" class="btn btn-outline-primary btntr" (click)="(collapseId === pl.id) ? collapseId = 0 : collapseId = pl.id" [attr.aria-expanded]="collapseId !== pl.id" attr.aria-controls="{{ 'plus_traductions' + pl.id }}">
                        <fa-icon [icon]="faPlus"></fa-icon>
                    </button>
                        </div>
                    </div>
                    <div id="{{ 'plus_traductions' + pl.id}}" [ngbCollapse]="collapseId !== pl.id">
                        <h6>{{'dish-form.concepttraduction' | translate}}</h6>
                        <div class="formGroup" *ngFor="let lan of establishment.languages">
                            <input formControlName="{{'plus_traductions_' + lan + '_' + pl.id}}" type="text" class="formControl form-control newform" placeholder="{{ ('dish-form.conceptin' | translate) + lan }}">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="pb-2 mb-3">
                        <h1 class="h5 inlineComp">{{'dish-form.additionalinfo' | translate}}</h1>
                    </div>
                </div>
                <div class="formGroup">
                    <div class="input-group mb-3">
                        <input formControlName="observations" type="text" class="formControl form-control newform" placeholder="{{'dish-form.observationsdish' | translate}}">
                        <div class="input-group-append">
                            <button type="button" class="btn btn-outline-primary btntr" (click)="(collapseId === 3) ? collapseId = 0 : collapseId = 3" [attr.aria-expanded]="collapseId !== 3" aria-controls="obsTraductions">
                        <fa-icon class="fas fa-language"></fa-icon>
                    </button>
                        </div>
                    </div>
                </div>
                <div id="obsTraductions" [ngbCollapse]="collapseId !== 3">
                    <h6>{{'dish-form.observationstraduction' | translate}}</h6>
                    <div class="formGroup" *ngFor="let lan of establishment.languages">
                        <input formControlName="{{'observations_' + lan }}" type="text" class="formControl form-control newform" placeholder="{{ ('dish-form.observationin' | translate) + lan }}">
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>