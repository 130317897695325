import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Text, Container, Structure } from '../../../../../shared/models/menu.model';
import { EstablishmentService } from '../../../../../shared/services/establishment.service';
import { StructureFormComponent } from '../../../shared/structure-form.component';
import { StructureUtils } from '../../../../../shared/utils/htmlGenerator.util';
import { CategoryDish } from '../../../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../../../shared/models/unit-mesure.model';
import { Dish } from '../../../../../shared/models/dish.model';
import { Allergen } from '../../../../../shared/models/allergen.model';

@Component({
  selector: "app-container-structure-form",
  templateUrl: "./container-structure-form.component.html",
  styleUrls: ["./container-structure-form.component.css"],
})
export class ContainerStructureFormComponent extends StructureFormComponent<Container> {
  _contents: Container;
  @Input() set contents(c: Container) {
    if (c) {
      this.id = 'edit_' + c.id;
    }
    this._contents = c;
  }
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() dishes: Dish[];
  @Input() allergens: Allergen[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @Output() delete = new EventEmitter();
  @Output() edit = new EventEmitter<Structure>();
  @Output() indexChange = new EventEmitter();
  id: string;
  @Input() allowDrag = true;
  constructor(public estService: EstablishmentService) {
    super(estService);
    this.id = 'id_' + Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8);
  }

  addComponent($event) {
    switch ($event) {
      case "text":
        this._contents.cnt.push(StructureUtils.getText(this.languages));
        break;
      case "image":
        this._contents.cnt.push(StructureUtils.getImage());
        break;
      case "container":
        this._contents.cnt.push(StructureUtils.getContainer());
        break;
      case "table":
        this._contents.cnt.push(StructureUtils.getTable());
        break;
      case "dishcollection":
        this._contents.cnt.push(StructureUtils.getDishCollection());
        break;
      case "dish":
        this._contents.cnt.push(StructureUtils.getDish());
        break;
    }
  }
  del() {
    this.delete.emit();
  }
  deleteElement(index) {
    this._contents.cnt.splice(index, 1);
  }
  changeElement(index, $event) {
    this._contents.cnt[index] = $event;
  }
  indexed() {
    this.indexChange.emit();
  }
  onTaskDrop(event) {
    this.array_move(this._contents.cnt, event.previousIndex, event.currentIndex);
  }
  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }
}
