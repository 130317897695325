<div class="inner-adjust font">
    <form>
        <div class="pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2 inlineComp font newtitle">{{'dishes.dishes' | translate}}</h1>
            <button type="button" (click)="add();" class="btn btn-primary bmd-btn-fab rightBtn inlineComp plusbtn">
            <fa-icon [icon]="faPlus"></fa-icon>
            <h1 class="h2 inlineComp font Addtext">{{'dishes.adddish' | translate}}</h1>
        </button>
            <div class="form-group form-inline rightBtn">
                <fa-icon *ngIf="!(service.instance.loading$ | async)" [icon]="faSearch"></fa-icon>
                <fa-icon class="rotateInfinite" *ngIf=" service.instance.loading$ | async" [icon]="faSpinner"></fa-icon><input class="form-control ml-2 searchitem" type="text" name="searchTerm" [(ngModel)]="service.instance.searchTerm" />

            </div>
        </div>
        <table class="table table-striped newtable">
            <thead class="headline">
                <tr>
                    <th scope="col" sortable="n" direction="asc" (sort)="onSort($event)" class="newcolor newborderleft">{{'dishes.name' | translate}}</th>
                    <th scope="col" class="newcolor">{{'dishes.categories' | translate}}</th>
                    <th scope="col" class="newcolor">{{'dishes.price' | translate}}</th>
                    <th scope="col" class="newcolor">{{'dishes.allergens' | translate}}</th>
                    <th class="fit newcolor newborderright" scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let dish of dishes$ | async" class="contentline">
                    <td class="formatocelda">
                        <ngb-highlight [result]="(dish.nt[selectedLang])? dish.nt[selectedLang] : dish.n" [term]="service.instance.searchTerm"></ngb-highlight>
                    </td>
                    <td class="formatocelda">
                        {{ getCategories(dish) }}
                    </td>
                    <td class="formatocelda">
                        {{ getPrices(dish) }}
                    </td>
                    <td class="formatocelda" style="text-align: left; vertical-align: middle;">
                        <img *ngFor="let al of getAllergens(dish)" style="height: 30px; width: 30px; display: inline; margin-left: 5px;" [src]="'../../assets/alergens/' + al + '.png'" />
                    </td>
                    <td class="fit" scope="row">
                        <button type="button" (click)="show(dish)" class="btn btn-primary bmd-btn-fab inlineComp btnTaleRow rightbtn"><fa-icon [icon]="(dish.ab)? faEye : faEyeSlash"></fa-icon></button>
                        <button type="button" (click)="edit(dish)" class="btn btn-success bmd-btn-fab inlineComp btnTaleRow rightbtn"><fa-icon [icon]="faEdit"></fa-icon></button>
                        <button type="button" (click)="delete(dish)" class="btn btn-danger bmd-btn-fab inlineComp btnTaleRow rightbtn"><fa-icon [icon]="faTrash"></fa-icon></button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-between p-2 selectpag">
            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.instance.page" [pageSize]="service.instance.pageSize">
            </ngb-pagination>

            <select class="custom-select paginas" style="width: auto" name="pageSize" [(ngModel)]="service.instance.pageSize">
                <option [ngValue]="15">15 {{'common.itemsperpage' | translate}}</option>
                <option [ngValue]="30">30 {{'common.itemsperpage' | translate}}</option>
                <option [ngValue]="50">50 {{'common.itemsperpage' | translate}}</option>
            </select>
        </div>
    </form>
</div>