import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { MediaGalleryModalComponent } from '../media-gallery-modal/media-gallery-modal.component';
import { MediaGallery } from '../../shared/models/mediaGallery.model';
import { SortEvent, NgbdSortableHeader } from '../../shared/directives/sortable.directive';
import { MediaGalleryService } from '../../shared/services/media-gallery.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../shared/services/helper.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { faSpinner, faEdit, faTrash, faEyeSlash, faEye, faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.css']
})
export class MediaGalleryComponent implements OnInit {
  faPlus = faPlus;
  faSearch = faSearch;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faTrash = faTrash;
  faEdit = faEdit;
  faSpinner = faSpinner;
  medias$: Observable<MediaGallery[]>;
  total$: Observable<number>;
  selectedLang: string;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(public service: MediaGalleryService, public modalService: NgbModal, public tService: TranslateService,
    private helper: HelperService, private _router: Router) {
    this.service.isAdminData = this._router.url.includes('admin');
    this.service.instance.getAllDataTable();
    this.medias$ = service.instance.data$;
    this.total$ = service.instance.total$;
    this.tService.onLangChange.subscribe((data) => {
      this.selectedLang = data.lang.toUpperCase();
      this.service.instance.selectedLang = data.lang.toUpperCase();
    });
  }

  ngOnInit(): void {}

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.instance.sortColumn = column;
    this.service.instance.sortDirection = direction;
  }
  getLanguages(media: MediaGallery) {
    return Object.keys(media.nt).filter(key => media.nt[key].name !== '').join(', ');
  }
  add() {
    const modal = this.modalService
      .open(MediaGalleryModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'modal_class' });
  }
  show(media: MediaGallery) {
    media.a = !media.a;
    this.service.instance.setAvailable(media.id, media.a);
  }
  edit(media) {
    const modal = this.modalService
      .open(MediaGalleryModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'modal_class' });
    if (media) {
      modal.componentInstance.editElement = media;
    }
    modal.result.then(
      (result) => {
      },
      (reason) => {
      }
    );
  }
  delete(cat) {
    this.helper.openConfirmationDeleteData().then(result => {
      if (result) {
        this.service.instance.remove(cat);
      }
    });
  }
  getNumberMenus(cd: any) {
    return (cd.menus) ? Object.keys(cd.menus).filter(key => cd.menus[key]).length : 0;
  }

}
