import { Component, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-delete-data',
  templateUrl: './confirmation-delete-data.component.html',
  styleUrls: ['./confirmation-delete-data.component.css']
})
export class ConfirmationDeleteDataComponent implements OnInit {
  closeResult = '';
  @ViewChild('content') content: ElementRef;
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public router: Router,
    public ngZone: NgZone
  ) { }

  ngOnInit(): void { }
}
