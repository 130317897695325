import { Component, Input, ContentChildren } from '@angular/core';
import { StructureTemplateComponent } from '../../shared/structure-template.component';
import { Page, Table, Menu } from '../../../../shared/models/menu.model';
import { CategoryDish } from '../../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../../shared/models/unit-mesure.model';
import { Dish } from '../../../../shared/models/dish.model';
import { Allergen } from '../../../../shared/models/allergen.model';
import { StyleDrawerComponent } from '../../style-drawer/style-drawer.component';
@Component({
  selector: 'app-page-standard-template',
  template: `
          <ng-container *ngIf="_contents">
            <ng-container *ngFor=" let ri of _contents.cnt; let i = index">
                <div class="row" id="{{_contents.id }}">
                    <ng-container *ngFor="let ci of ri; let j = index">
                        <div class="col-sm-{{ _contents.w[j]}}">
                            <app-container-template  [menu]="menu" [contents]="ci" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected"></app-container-template>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
`,
    styles: ['']
})
export class TableStandardTemplateComponent implements StructureTemplateComponent<Table> {
  _contents: Table;
  @Input() menu: Menu;
  @Input() type: string;
  @Input() set contents (v: Table) {
    this._contents = v;
    if (!this._contents.id) {
        this._contents.id = 'id_' + Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8);
    }
  }
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() dishes: Dish[];
  @Input() allergens: Allergen[];
  @Input() languages: string[];
    @Input() language_selected: string;
    @ContentChildren('styleDrawer') styleDrawer: StyleDrawerComponent;
  constructor() { }
}
