import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SignInComponent } from '../sign-in/sign-in.component';

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public modalService: NgbModal,
    public modal: NgbActiveModal
  ) {}

  ngOnInit() {}

  signIn() {
    this.modal.close();
    const modal = this.modalService.open(SignInComponent, {
      windowClass: "white-content",
      centered: true,
    });
    modal.result.then(
      (result) => {},
      (reason) => {}
    );
  }
  reset(email) {
    this.authService.ForgotPassword(email).then((result) => {
      if (result) {
        this.modal.close();
      }
    });
  }
}
