import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Structure } from '../models/menu.model';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    private structureStyle = new BehaviorSubject<Structure>(null);
    currentStructureStyle = this.structureStyle.asObservable();

    private structureCode = new BehaviorSubject<Structure>(null);
    currentStructureCode = this.structureCode.asObservable();

    private structureDel = new BehaviorSubject<Structure>(null);
    currentStructureDel = this.structureCode.asObservable();

    constructor() { }

    changeStructureStyle(contents: Structure) {
        this.structureStyle.next(contents);
    }
    changeStructureCode(contents: Structure) {
        this.structureCode.next(contents);
    }
    changeStructureDel(contents: Structure) {
        this.structureDel.next(contents);
    }
}
