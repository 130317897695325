import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StepperBillingInfoModalComponent } from '../stepper-billing-info-modal/stepper-billing-info-modal.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { switchMap } from 'rxjs/operators';
import { User } from '../../shared/services/user';
import { of } from 'rxjs';

@Component({
  selector: 'app-intranet',
  templateUrl: './intranet.component.html',
  styleUrls: ['./intranet.component.css']
})
export class IntranetComponent implements OnInit {

  constructor(public authService: AuthService, private modalService: NgbModal, public afAuth: AngularFireAuth) { }

  ngOnInit(): void {
    this.authService.GetBillingInfo().then(data => {
      this.afAuth.authState.subscribe(u => {
        if (u) {
          const diff = Math.abs(new Date().getTime() - new Date(u.metadata.creationTime).getTime());
          const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
          if (diffDays > 30 && (!data || !data.name || data.name === '') && !this.authService.isAdmin) {
            const modal = this.modalService.open(StepperBillingInfoModalComponent, {
              ariaLabelledBy: "modal-basic-title",
              size: "xl",
              backdrop: false,
              keyboard: false
            });
            modal.result.then(
              (result) => { },
              (reason) => { }
            );
          }
        }
      });
    });
  }
}
