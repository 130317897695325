import { Component, Input, OnInit, ViewChild, EventEmitter, Output, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { CollectionMenuTemplateService } from '../../shared/services/collection-menu-template.service';
import { TemplateCollection } from '../../shared/models/template-collection.model';
import { LANGUAGES } from '../../shared/data/data';
import { EstablishmentService } from '../../shared/services/establishment.service';
import { Router } from '@angular/router';
import { Establishment } from '../../shared/models/establishment.model';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-collection-menu-template-form',
  templateUrl: './collection-menu-template-form.component.html',
  styleUrls: ['./collection-menu-template-form.component.css']
})
export class CollectionMenuTemplateFormComponent implements OnInit {
  public collapseId = 0;
  faPlus = faPlus;
  faTrash = faTrash;
  templateCollectionForm: FormGroup;
  editingTemplateCollection: TemplateCollection;
  establishment: Establishment;
  // quantities: {id}[] = [];
  _elementToEdit: TemplateCollection;
  currentLanguages: string[] = [];
  @ViewChild('form') dishRefForm: ElementRef;
  @Input() set elementToEdit(value: TemplateCollection) {
    this._elementToEdit = value;
    if (this._elementToEdit) {
      this.editElemet(this._elementToEdit);
    }
  }
  @Output() resetForm = new EventEmitter<TemplateCollection>();
  constructor(
    private fb: FormBuilder,
    private templateCollectionService: CollectionMenuTemplateService,
    private estService: EstablishmentService,
    private _router: Router) {
    this.templateCollectionService.isAdminData = this._router.url.includes('admin');
    this.establishment = this.estService.isEstablishmentSelected;
    this.templateCollectionForm = this.fb.group({
      name: ['', Validators.required],
    });
    if (this.templateCollectionService.isAdminData) {
      this.currentLanguages = LANGUAGES.map(li => li.code);
    } else {
      this.currentLanguages = this.establishment.languages as string[];
    }
    this.currentLanguages.forEach((li) => {
      this.templateCollectionForm.addControl('name_' + li, new FormControl('', null));
    });
  }
  submit() {
    this.dishRefForm.nativeElement.submit();
  }
  ngOnInit(): void {}
  saveTemplateCollection() {
    let cdish = null;
    if (this.templateCollectionForm.valid) {
      cdish = this.getTemplateCollectionFromForm();
      if (this.editingTemplateCollection) {
        cdish.id = this.editingTemplateCollection.id;
        if (this.editingTemplateCollection.a === true || this.editingTemplateCollection.a === false) {
          cdish.a = this.editingTemplateCollection.a;
        }
        this.templateCollectionService.instance.edit(cdish);
        this.editingTemplateCollection = null;
      } else {
        cdish = this.templateCollectionService.instance.create(cdish);
      }
      this.reset(cdish);
    }
  }
  reset(cdish: TemplateCollection) {
    this.editingTemplateCollection = null;
    this.templateCollectionForm.reset();
    this.resetForm.emit(cdish);
  }
  editElemet(element) {
    this.editingTemplateCollection = element;
    this.templateCollectionForm.controls['name'].setValue(this.editingTemplateCollection.name);
    this.currentLanguages.forEach((li) => {
      this.templateCollectionForm.controls['name_' + li].setValue(
        (this.editingTemplateCollection.nt[li]) ? this.editingTemplateCollection.nt[li] : '');
    });
  }
  getTemplateCollectionFromForm() {
    const obj: TemplateCollection = {id: '',
      name: this.templateCollectionForm.get('name').value,
      nt: {},
      a: true
    };
    this.currentLanguages.forEach((li) => {
      obj.nt[li] = this.templateCollectionForm.get('name_' + li).value;
    });
    return obj;
  }

}
