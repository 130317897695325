import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UnitMeasure } from '../../shared/models/unit-mesure.model';

@Component({
  selector: 'app-units-measures-modal',
  templateUrl: './units-measures-modal.component.html',
  styleUrls: ['./units-measures-modal.component.css']
})
export class UnitsMeasuresModalComponent implements OnInit {
  closeResult = '';
  editElement: UnitMeasure;
  @ViewChild('content') content: ElementRef;
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public router: Router,
    public ngZone: NgZone
  ) { }

  ngOnInit(): void { }
  open(editElement = null) {
    const modal = this.modalService
      .open(UnitsMeasuresModalComponent, { ariaLabelledBy: 'modal-basic-title' });
    if (editElement) {
      modal.componentInstance.editElement = editElement;
    }
    modal.result.then(
      (result) => {
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
