import { Injectable, NgZone, PipeTransform } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { TemplateCollection } from '../models/template-collection.model';
import { map, tap, debounceTime, switchMap, delay } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../directives/sortable.directive';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { ITemplateCollectionCRUDService } from '../interfaces/iTemplateCollectionCRUDService';

interface SearchResult {
  collections: TemplateCollection[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
  selectedLang: string;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sortCollections(collections: TemplateCollection[], column: SortColumn, direction: string, selectedLang: string = null): TemplateCollection[] {
  if (direction === '' || column === '') {
    return collections;
  } else {
    if (selectedLang && column === "name") {
      return [...collections].sort((a, b) => {
        const an = a.nt[selectedLang] ? a.nt[selectedLang] : a.name;
        const bn = b.nt[selectedLang] ? b.nt[selectedLang] : b.name;
        const res = compare(`${an}`, `${bn}`);
        return direction === "asc" ? res : -res;
      });
    }
    return [...collections].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(collection: TemplateCollection, term: string, selectedLang: string = null, pipe: PipeTransform = null) {
  if (selectedLang && collection.nt[selectedLang]) {
    return collection.nt[selectedLang].toLowerCase().includes(term.toLowerCase());
  }
  return collection.name.toLowerCase().includes(term.toLowerCase());
    // || collection.quantities.map(qi => qi.name.toLowerCase()).join(', ').includes(term.toLowerCase());
    // || pipe.transform(country.area).includes(term)
    // || pipe.transform(country.population).includes(term);
}

@Injectable({
  providedIn: "root",
})
export class CollectionMenuTemplateUserService implements ITemplateCollectionCRUDService {
  database = "CollectionMenuTemplates";
  databaseTrash = "CollectionMenuTemplatesTrash";
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _collections$ = new BehaviorSubject<TemplateCollection[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private collections: TemplateCollection[] = [];
  private _state: State = {
    page: 1,
    pageSize: 15,
    searchTerm: "",
    sortColumn: "name",
    sortDirection: "asc",
    selectedLang: localStorage.getItem("cLang")
  };
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {}
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
  get ref() {
    const user = JSON.parse(localStorage.getItem("user"));
    const establishment = JSON.parse(localStorage.getItem("establishment"));
    return firebase
      .firestore()
      .collection(
        `users/${user.uid}/Establishments/${establishment.id}/${this.database}`
      )
      .doc("Collections");
  }
  get refObs() {
    const user = JSON.parse(localStorage.getItem("user"));
    const establishment = JSON.parse(localStorage.getItem("establishment"));
    return this.afs
      .collection<TemplateCollection>(
        `users/${user.uid}/Establishments/${establishment.id}/${this.database}`
      )
      .doc("Collections");
  }

  create(cdish: TemplateCollection) {
    cdish.id = this.afs.createId();
    const value = {};
    value[cdish.id] = cdish;
    this.ref.set(value, { merge: true });
    return cdish;
  }
  edit(cdish) {
    const value = {};
    value[cdish.id] = cdish;
    return this.ref.set(value, { merge: true });
  }
  setAvailable(id: string, a: boolean) {
    const value = {};
    value[id] = { a: a };
    return this.ref.set(value, { merge: true });
  }
  remove(cdish: TemplateCollection) {
    const user = JSON.parse(localStorage.getItem("user"));
    const establishment = JSON.parse(localStorage.getItem("establishment"));
    const value = {};
    value[cdish.id] = cdish;
    firebase
      .firestore()
      .doc(
        `users/${user.uid}/EstablishmentsTrash/${establishment.id}/${this.databaseTrash}/Collections`
      )
      .set(value, { merge: true });
    const value2 = {};
    value2[cdish.id] = firebase.firestore.FieldValue.delete();
    return this.ref.update(value2);
  }
  addMenu(cid: string, id: string) {
    const menus = {};
    menus[id] = true;
    const value = {};
    value[cid] = { menus: menus };
    return this.ref.set(value, { merge: true });
  }
  removeMenu(cid: string, id: string) {
    const menus = {};
    menus[id] = false;
    const value = {};
    value[cid] = { menus: menus };
    return this.ref.set(value, { merge: true });
  }
  getAll() {
    return this.refObs.snapshotChanges().pipe(
      map((actions) => {
        const data = actions.payload.data();
        return Object.keys(data || {}).map((key) => {
          return data[key] as TemplateCollection;
        });
      })
    );
  }
  getAllDataTable() {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this._collections$.next(result.collections);
        this._total$.next(result.total);
      });
    return this.refObs
      .snapshotChanges()
      .pipe(
        map((actions) => {
          const data = actions.payload.data();
          this.collections = Object.keys(data || {}).map((key) => {
            return data[key] as TemplateCollection;
          });
          this._search$.next();
        })
      )
      .subscribe((data) => {});
  }
  get data$() {
    return this._collections$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }
  get selectedLang() {
    return this._state.selectedLang;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }
  set selectedLang(selectedLang: string) {
    this._set({ selectedLang });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const {
      sortColumn,
      sortDirection,
      pageSize,
      page,
      searchTerm,
      selectedLang
    } = this._state;

    // 1. sort
    let collections = sortCollections(this.collections, sortColumn, sortDirection, selectedLang);

    // 2. filter
    collections = collections.filter((colls) => matches(colls, searchTerm, selectedLang));
    const total = collections.length;

    // 3. paginate
    collections = collections.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
    return of({ collections, total });
  }
}
