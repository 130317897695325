
import { Injectable, NgZone, PipeTransform } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { UnitMeasure } from '../models/unit-mesure.model';
import { map, tap, debounceTime, switchMap, delay } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../directives/sortable.directive';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { Establishment } from '../models/establishment.model';
import { User } from './user';

interface SearchResult {
  establishments: Establishment[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
  selectedLang: string;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(establishments: Establishment[], column: SortColumn, direction: string, selectedLang: string = null): Establishment[] {
  if (direction === '' || column === '') {
      return establishments;
  } else {
      return [...establishments].sort((a, b) => {
          const res = compare(`${a[column]}`, `${b[column]}`);
          return direction === 'asc' ? res : -res;
      });
  }
}

function matches(establishment: Establishment, term: string, selectedLang: string = null, pipe: PipeTransform = null) {
  return establishment.name.toLowerCase().includes(term.toLowerCase());
}

@Injectable({
  providedIn: 'root',
})
export class EstablishmentService {
  database = 'Establishments';
  databaseTrash = 'EstablishmentsTrash';
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _establishments$ = new BehaviorSubject<Establishment[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private establishments: Establishment[] = [];
  private _state: State = {
    page: 1,
    pageSize: 15,
    searchTerm: "",
    sortColumn: "",
    sortDirection: "",
    selectedLang: localStorage.getItem("cLang"),
  };
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {}
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
  get ref() {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.afs.collection(`users/${user.uid}/${this.database}`);
  }
  get refObs() {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.afs.collection(`users/${user.uid}/${this.database}`);
  }
  create(establishment: Establishment) {
    establishment.id = this.afs.createId();
    this.ref
      .doc(establishment.id)
      .set(Object.assign({}, establishment), {merge: true});
    return establishment;
  }
  edit(establishment) {
    return this.ref
      .doc(establishment.id)
      .set(Object.assign({}, establishment), {merge: true});
  }
  remove(establishment: Establishment) {
    const user = JSON.parse(localStorage.getItem('user'));
    this.afs.doc(`users/${user.uid}/${this.databaseTrash}/${establishment.id}`)
      .set(Object.assign({}, establishment));
    return this.ref.doc(establishment.id).delete();
  }
  get isEstablishmentSelected(): any {
    return JSON.parse(localStorage.getItem('establishment'));
  }
  recoverSelectedEstablishment() {
      const user = JSON.parse(localStorage.getItem('user'));
      return this.afs.doc(`users/${user.uid}`).get().toPromise().then( us => {
            const u = us.data() as User;
            if (u.selectedEstablishment) {
                localStorage.setItem('establishment', JSON.stringify(u.selectedEstablishment));
            }
       });
  }
  selectEstablishment(establishment) {
      const user = JSON.parse(localStorage.getItem('user'));
      localStorage.setItem('establishment', JSON.stringify(
        {id: establishment.id, name: establishment.name, languages: establishment.languages}));
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      return userRef.set({
        selectedEstablishment: { id: establishment.id, name: establishment.name, languages: establishment.languages }
      }, {
        merge: true
      });
  }
  clearEstablishment() {
      localStorage.removeItem('user');
  }
  getAll() {
    return this.ref.get();
  }
  getAllDataTable() {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this._establishments$.next(result.establishments);
        this._total$.next(result.total);
      });
    return this.refObs
      .snapshotChanges()
      .pipe(
        map((actions) => {
          this.establishments = actions.map(a => {
            return a.payload.doc.data() as Establishment;
          });
          this._search$.next();
        })
      )
      .subscribe((data) => {});
  }
  get establishments$() {
    return this._establishments$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }
  get selectedLang() {
    return this._state.selectedLang;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }
  set selectedLang(selectedLang: string) {
    this._set({ selectedLang });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const {
      sortColumn,
      sortDirection,
      pageSize,
      page,
      searchTerm,
      selectedLang
    } = this._state;

    // 1. sort
    let establishments = sort(this.establishments, sortColumn, sortDirection, selectedLang);

    // 2. filter
    establishments = establishments.filter((unit) =>
      matches(unit, searchTerm, selectedLang)
    );
    const total = establishments.length;

    // 3. paginate
    establishments = establishments.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
    return of({ establishments, total });
  }
}

//   getAll() {
//     return this.refObs.snapshotChanges().pipe(
//       map((actions) => {
//         const data = actions.payload.data();
//         return Object.keys(data || {}).map((key) => {
//           return data[key] as UnitMeasure;
//         });
//       })
//     );
//   }

// }
