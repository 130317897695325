import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Menu, ConfigMenu } from '../../shared/models/menu.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MenuTemplateAdminService } from '../../shared/services/menu-template-admin.service';
import { Observable } from 'rxjs';
import { TemplateCollection } from '../../shared/models/template-collection.model';
import { CollectionMenuTemplateAdminService } from '../../shared/services/collection-menu-template-admin.service';
import { CollectionMenuTemplateModalComponent } from '../collection-menu-template-modal/collection-menu-template-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-menu-template-config-form',
  templateUrl: './menu-template-config-form.component.html',
  styleUrls: ['./menu-template-config-form.component.css']
})
export class MenuTemplateConfigFormComponent implements OnInit {
   public collapseId = 0;
  menuTemplateConfigForm: FormGroup;
  editingMenuTemplateConfig: Menu;
  _elementToEdit: Menu;
  words: string[] = [];
  collections$: Observable<TemplateCollection[]>;
  @Input() set elementToEdit(value: Menu) {
    if (value) {
      this._elementToEdit = value;
      if (this._elementToEdit) {
        this.editElemet(this._elementToEdit);
      }
    }
  }
  @Output() resetForm = new EventEmitter<Menu>();
  constructor(private service: MenuTemplateAdminService,
    private fb: FormBuilder,
    private cService: CollectionMenuTemplateAdminService,
    public modalService: NgbModal) {
    this.menuTemplateConfigForm = this.fb.group({
      home: ['', null],
      template: ['', null],
      collections: ['', null],
      words: ['', null]
    });
    this.collections$ = this.cService.getAll();
  }

  ngOnInit(): void {
  }
  editElemet(menu: Menu) {
    this.editingMenuTemplateConfig = menu;
    if (!menu.c) {
      menu.c = {
        c:  [],
        h: false,
        t: false,
        w: []
      };
    }
    this.menuTemplateConfigForm.controls['collections'].setValue(this.editingMenuTemplateConfig.c.c);
    this.menuTemplateConfigForm.controls['template'].setValue(this.editingMenuTemplateConfig.c.t);
    this.menuTemplateConfigForm.controls['home'].setValue(this.editingMenuTemplateConfig.c.h);
    this.words = this.editingMenuTemplateConfig.c.w;
  }
  saveMenuTemplate() {
    let menuTemplate = null;
    if (this.menuTemplateConfigForm.valid) {
      menuTemplate = this.getMenuTemplateConfigFromForm();
      if (this.editingMenuTemplateConfig) {
        menuTemplate.id = this.editingMenuTemplateConfig.id;
        this.service.editConfig(menuTemplate, this.editingMenuTemplateConfig.c.c);
        this.editingMenuTemplateConfig = null;
      }
      this.reset(menuTemplate);
    }
  }
  reset(menu: Menu) {
    this.editingMenuTemplateConfig = null;
    this.menuTemplateConfigForm.reset();
    this.resetForm.emit(menu);
  }
  getMenuTemplateConfigFromForm() {
    const obj: {id: string, c: ConfigMenu} = {
      id: '',
      c: {
        c: (this.menuTemplateConfigForm.get('collections').value === '') ? [] : this.menuTemplateConfigForm.get('collections').value,
        h: this.editingMenuTemplateConfig.c.h,
        t: this.editingMenuTemplateConfig.c.t,
        w: this.words.slice(0)
      }
    };
    return obj;
  }
  keyPressWord(event) {
    if (event.key === 'Enter') {
      const val = this.menuTemplateConfigForm.controls['words'].value;
      this.words.push(val);
      this.menuTemplateConfigForm.controls['words'].setValue('');
      event.preventDefault();
    }
  }
  removeWord(m) {
    const index = this.words.indexOf(m, 0);
    if (index > -1) {
      this.words.splice(index, 1);
   }
  }
  openFormCollections() {
    const modal = this.modalService
      .open(CollectionMenuTemplateModalComponent, { ariaLabelledBy: 'modal-basic-title' });
  }
}
