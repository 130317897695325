import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { CollectionMenuTemplateModalComponent } from '../collection-menu-template-modal/collection-menu-template-modal.component';
import { TemplateCollection } from '../../shared/models/template-collection.model';
import { SortEvent, NgbdSortableHeader } from '../../shared/directives/sortable.directive';
import { HelperService } from '../../shared/services/helper.service';
import { CollectionMenuTemplateService } from '../../shared/services/collection-menu-template.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { faPlus, faSearch, faEye, faEyeSlash, faTrash, faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-collection-menu-templates',
  templateUrl: './collection-menu-templates.component.html',
  styleUrls: ['./collection-menu-templates.component.css']
})
export class CollectionMenuTemplatesComponent implements OnInit {
  faPlus = faPlus;
  faSearch = faSearch;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faTrash = faTrash;
  faEdit = faEdit;
  faSpinner = faSpinner;
  collectiontemplates$: Observable<TemplateCollection[]>;
  total$: Observable<number>;
  selectedLang: string;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(public service: CollectionMenuTemplateService, public modalService: NgbModal, public tService: TranslateService,
    private helper: HelperService, private _router: Router) {
    this.service.isAdminData = this._router.url.includes('admin');
    this.service.instance.getAllDataTable();
    this.collectiontemplates$ = service.instance.data$;
    this.total$ = service.instance.total$;
    this.tService.onLangChange.subscribe((data) => {
      this.selectedLang = data.lang.toUpperCase();
      this.service.instance.selectedLang = data.lang.toUpperCase();
    });
  }

  ngOnInit(): void {}

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.instance.sortColumn = column;
    this.service.instance.sortDirection = direction;
  }
  getLanguages(col: TemplateCollection) {
    return Object.keys(col.nt).filter(key => col.nt[key].name !== '').join(', ');
  }
  add() {
    const modal = this.modalService
      .open(CollectionMenuTemplateModalComponent, { ariaLabelledBy: 'modal-basic-title' });
  }
  show(col: TemplateCollection) {
    col.a = !col.a;
    this.service.instance.setAvailable(col.id, col.a);
  }
  edit(cat) {
    const modal = this.modalService
      .open(CollectionMenuTemplateModalComponent, { ariaLabelledBy: 'modal-basic-title' });
    if (cat) {
      modal.componentInstance.editElement = cat;
    }
    modal.result.then(
      (result) => {
      },
      (reason) => {
      }
    );
  }
  delete(cat) {
    this.helper.openConfirmationDeleteData().then(result => {
      if (result) {
        this.service.instance.remove(cat);
      }
    });
  }
  getNumberMenus(cd: any) {
    return (cd.menus) ? Object.keys(cd.menus).filter(key => cd.menus[key]).length : 0;
  }
}
