import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignInComponent } from '../sign-in/sign-in.component';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from '../../shared/data/data';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../../shared/services/seo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  isCollapsed = true;
  selectedLang: string;
  languages = LANGUAGES;
  constructor(private modalService: NgbModal,
    public tService: TranslateService, private title: Title, private seo: SeoService) {
      this.tService.onLangChange.subscribe(data => {
        this.selectedLang = data.lang.toUpperCase();
        localStorage.setItem("clang", data.lang);
      });
      this.selectedLang = localStorage.getItem("clang").toUpperCase();
    }

  ngOnInit(): void {
    const t = 'Fuudie - Carta Virtual';
    this.title.setTitle(t);
    this.seo.generateTags({
      title: t,
      description: 'Fuudie - Carta Virtual para Restaurantes',
      slug: 'home'
    });
  }
  login() {
        const modal = this.modalService
      .open(SignInComponent, {windowClass: 'white-content', centered: true});
    modal.result.then(
      (result) => {
      },
      (reason) => {
      }
    );
  }
  signup() {
    const modal = this.modalService
  .open(SignUpComponent, {windowClass: 'white-content', centered: true});
modal.result.then(
  (result) => {
  },
  (reason) => {
  }
);
}
  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
    this.isCollapsed = true;
  }
}
