import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { Observable } from 'rxjs';
import { EstablishmentService } from '../services/establishment.service';
import { take, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    public authService: AuthService,
    public estService: EstablishmentService,
    public router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.authService.user.pipe(
        take(1),
        map(user => !!user),
        tap(loggedIn => {
          if (!loggedIn) {
            console.log('access denied');
            this.router.navigate(['/home']);
          }
        })
      );
    // if (this.authService.isLoggedIn !== true) {
    //   this.router.navigate(['home']);
    // } else if (state.url === '/dishes' && !this.estService.isEstablishmentSelected) {
    //   this.authService.SignOut();
    //   // this.router.navigate(['sign-in']);
    // }
    return true;
  }

}
