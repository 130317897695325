import { Component, Input, OnInit, ViewChild, ComponentFactoryResolver, SimpleChanges, OnChanges, ContentChildren } from '@angular/core';
import { StructureTemplateComponent } from '../shared/structure-template.component';
import { CategoryDish } from '../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../shared/models/unit-mesure.model';
import { Allergen } from '../../../shared/models/allergen.model';
import { Dish } from '../../../shared/models/dish.model';
import { Table, Menu } from '../../../shared/models/menu.model';
import { TableDirective } from '../directives/table.directive';
import { REGISTRY } from '../shared/register.component';
import { StyleDrawerComponent } from '../style-drawer/style-drawer.component';
import { DataService } from '../../../shared/services/data.service';

@Component({
  selector: "app-table-template",
  template: `
  <!-- <div class="template-container">
    <div class="editing">
      <button type="button" class="btn btn-light enlinia" (click)="del()"><fa-icon class="fa fa-times"></fa-icon></button>
      <button type="button" class="btn btn-light enlinia" (click)="code()"><fa-icon class="fa fa-code"></fa-icon></button>
      <button type="button" class="btn btn-light enlinia" (click)="edit()"><fa-icon class="fa fa-bold"></fa-icon></button>
    </div> -->
    <ng-template table-host></ng-template>
    <!-- </div> -->
    `
})
export class TableTemplateComponent
  implements OnInit, OnChanges, StructureTemplateComponent<Table> {
  @Input() menu: Menu;
  @Input() contents: Table;
  @Input() type: string;
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() allergens: Allergen[];
  @Input() dishes: Dish[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @Input() showUtils: boolean;
  @ContentChildren('styleDrawer') styleDrawer: StyleDrawerComponent;
  @ViewChild(TableDirective, { static: true }) tableHost: TableDirective;
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private data: DataService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadComponent();
  }
  ngOnInit() {
    this.loadComponent();
    // this.showUtils = this.tableHost.showUtils;
  }

  loadComponent() {
    if (this.contents && this.languages && this.tableHost) {
      if (!this.type) {
        this.contents.dt = "Standard";
        this.type = "Standard";
      }
      let t = `Table${this.type}TemplateComponent`;
      let componentFactory;
      try {
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(REGISTRY.get(t));
      } catch {
        t = `TableStandardTemplateComponent`;
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(REGISTRY.get(t));
      }

      const viewContainerRef = this.tableHost.viewContainerRef;
      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent(componentFactory);
        (<StructureTemplateComponent<Table>>componentRef.instance).menu = this.menu;
        (<StructureTemplateComponent<Table>>(
        componentRef.instance
      )).contents = this.contents;
      (<StructureTemplateComponent<Table>>(
        componentRef.instance
      )).categories = this.categories;
      (<StructureTemplateComponent<Table>>(
        componentRef.instance
      )).units = this.units;
      (<StructureTemplateComponent<Table>>(
        componentRef.instance
      )).allergens = this.allergens;
      (<StructureTemplateComponent<Table>>(
        componentRef.instance
      )).dishes = this.dishes;
      (<StructureTemplateComponent<Table>>(
        componentRef.instance
      )).languages = this.languages;
      (<StructureTemplateComponent<Table>>componentRef.instance).language_selected = this.language_selected;
      (<StructureTemplateComponent<Table>>componentRef.instance).styleDrawer = this.styleDrawer;
    }
  }
  edit() {
    this.data.changeStructureStyle(this.contents);
  }
  del() {

  }
  code() {
    this.data.changeStructureCode(this.contents);
  }
}
