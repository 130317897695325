import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-advice',
  templateUrl: './legal-advice.component.html',
  styleUrls: ['./legal-advice.component.css']
})
export class LegalAdviceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  back() {
    window.history.back();
  }
}
