import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[editable]'
})
export class EditabletDirective {
    @Input('editable') active: boolean;
    innerHtml: string;
    constructor(private el: ElementRef) {
    }

    @HostListener('mouseenter') onMouseEnter() {
        if (!this.innerHtml) {
            this.innerHtml = this.el.nativeElement.innerHTML;
        }
        this.el.nativeElement.innerHTML = this.innerHtml + '<button>hola</button>';
        this.el.nativeElement.style.border = '2px solid green';
        // this.highlight('yellow');
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.el.nativeElement.innerHTML = this.innerHtml;
        this.el.nativeElement.style.border = '0px solid green';
        // this.highlight(null);
    }

    private highlight(color: string) {
        console.log(this.el);
        // this.el.nativeElement.style.backgroundColor = color;
    }
}
