import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MediaGallery } from '../../shared/models/mediaGallery.model';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MediaGalleryAdminService } from '../../shared/services/media-gallery-admin.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LANGUAGES } from '../../shared/data/data';

@Component({
  selector: 'app-media-gallery-form',
  templateUrl: './media-gallery-form.component.html',
  styleUrls: ['./media-gallery-form.component.css']
})
export class MediaGalleryFormComponent implements OnInit {
   public collapseId = 0;
  mediaGalleryForm: FormGroup;
  editingMediaGallery: MediaGallery;
  _elementToEdit: MediaGallery;
  words: string[] = [];
  mediaFile: string;
  LANGUAGES = LANGUAGES.map(li => li.code);
  locationFiles = `/templates/gallery`;
  @Input() set elementToEdit(value: MediaGallery) {
    if (value) {
      this._elementToEdit = value;
      if (this._elementToEdit) {
        this.editElemet(this._elementToEdit);
      }
    }
  }
  @Output() resetForm = new EventEmitter<MediaGallery>();
  constructor(private service: MediaGalleryAdminService,
    private fb: FormBuilder,
    public modalService: NgbModal) {
    this.mediaGalleryForm = this.fb.group({
      name: ['', Validators.required],
      words: ['', null],
    });
      LANGUAGES.map(li => li.code).forEach((li) => {
      this.mediaGalleryForm.addControl('name_' + li, new FormControl('', null));
    });
  }

  ngOnInit(): void {
  }
  editElemet(media: MediaGallery) {
    this.editingMediaGallery = media;
    this.mediaFile = media.url;
    this.mediaGalleryForm.controls['name'].setValue(this.editingMediaGallery.n);
    LANGUAGES.map(li => li.code).forEach((li) => {
      this.mediaGalleryForm.controls['name_' + li].setValue(
        (this.editingMediaGallery.nt[li]) ? this.editingMediaGallery.nt[li] : '');
    });
    this.words = this.editingMediaGallery.w;
  }
  saveMediaGalleryTemplate() {
    let media = null;
    if (this.mediaGalleryForm.valid) {
      media = this.getMediaGalleryFromForm();
      if (this.editingMediaGallery) {
        media.id = this.editingMediaGallery.id;
        this.service.edit(media);
        this.editingMediaGallery = null;
      } else {
        media = this.service.create(media);
      }
      this.reset(media);
    }
  }
  reset(media: MediaGallery) {
    this.editingMediaGallery = null;
    this.mediaGalleryForm.reset();
    this.resetForm.emit(media);
  }
  getMediaGalleryFromForm() {
    const obj: MediaGallery = {
      id: '',
      url: this.mediaFile,
      n: this.mediaGalleryForm.get('name').value,
      nt: {},
      a: (this.editingMediaGallery) ? this.editingMediaGallery.a : true,
      w: this.words.slice(0)
    };
    this.LANGUAGES.forEach((li) => {
      obj.nt[li] = this.mediaGalleryForm.get('name_' + li).value;
    });
    return obj;
  }
  keyPressWord(event) {
    if (event.key === 'Enter') {
      const val = this.mediaGalleryForm.controls['words'].value;
      this.words.push(val);
      this.mediaGalleryForm.controls['words'].setValue('');
      event.preventDefault();
    }
  }
  removeWord(m) {
    const index = this.words.indexOf(m, 0);
    if (index > -1) {
      this.words.splice(index, 1);
   }
  }
  // openFormCollections() {
  //   const modal = this.modalService
  //     .open(CollectionMediaGalleryTemplateModalComponent, { ariaLabelledBy: 'modal-basic-title' });
  // }
}
