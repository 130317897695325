import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CategoryDish } from '../../shared/models/category-dish.model';
import { Allergen } from '../../shared/models/allergen.model';
import { UnitMeasure } from '../../shared/models/unit-mesure.model';
import { Dish } from 'src/app/shared/models/dish.model';
import { Menu } from '../../shared/models/menu.model';
import { DishService } from '../../shared/services/dish.service';
import { UnitMeasureService } from '../../shared/services/unit-measure.service';
import { CategoryDishService } from '../../shared/services/category-dish.service';
import { AllergenService } from '../../shared/services/allergen.service';
import { MenuTemplateService } from '../../shared/services/menu-template.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu-view-form',
  templateUrl: './menu-view-form.component.html',
  styleUrls: ['./menu-view-form.component.css']
})
export class MenuViewFormComponent implements OnInit, OnDestroy {

  _categories: CategoryDish[] = [];
  _allergens: Allergen[] = [];
  _units: UnitMeasure[] = [];
  _dishes: Dish[] = [];
  _value: Menu;
  _language: string;
  subc: Subscription;
  suba: Subscription;
  subu: Subscription;
  subd: Subscription;
  indexes: { id: string, name: string}[] = [];
  @Input() set menu(value: Menu) {
    if (value) {
      this._value = value;
    }
  }
  @Input() set language(value: string) {
    if (value) {
      this._language = value;
    }
  }
  constructor(
    private menuService: MenuTemplateService,
    private aService: AllergenService,
    private cService: CategoryDishService,
    private uService: UnitMeasureService,
    private dService: DishService) {
    this.menuService.isAdminData = true;
    this.cService.isAdminData = true;
    this.uService.isAdminData = true;
    this.dService.isAdminData = true;
    this.suba = this.aService.getAll().subscribe(al => this._allergens = al);
    this.subu = this.uService.instance.getAll().subscribe(ul => this._units = ul);
    this.subc = this.cService.instance.getAll().subscribe(cl => this._categories = cl);
    this.subd = this.dService.instance.getAll().subscribe(dl => this._dishes = dl);
  }

  ngOnInit(): void {
  }
  ngOnDestroy() {
    this.subc.unsubscribe();
    this.suba.unsubscribe();
    this.subu.unsubscribe();
    this.subd.unsubscribe();
  }
}
