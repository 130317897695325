import { Injectable, NgZone } from '@angular/core';
import { UnitMeasureUserService } from './unit-measure-user.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { UnitMeasure } from '../models/unit-mesure.model';

@Injectable({
  providedIn: "root",
})
export class UnitMeasureAdminService extends UnitMeasureUserService {
    constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
      super(afs, afAuth, ngZone);
  }
  get ref() {
    return firebase
      .firestore()
      .collection(
        `templates/admin/${this.database}`
      )
      .doc("Units");
  }
  get refObs() {
    const user = JSON.parse(localStorage.getItem("user"));
    const establishment = JSON.parse(localStorage.getItem("establishment"));
    return this.afs
      .collection<UnitMeasure>(
        `templates/admin/${this.database}`
      )
      .doc("Units");
  }
}
