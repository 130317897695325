<div class="helpform">
    <div clas="font">
        <p class="contacttitle font h2"> {{ 'help.help' | translate }}</p>
    </div>
    <!-- name -->
    <div class="row">
        <div class="col-sm-6">
            <input type="text" name="name nametext" class=" form-control input formcontact nametext" placeholder="{{ 'help.yourname' | translate }}" [(ngModel)]="name" required>
            <input type="email" name="email" class="form-control input formcontact emailtext" placeholder="{{ 'help.youremail' | translate }}" [(ngModel)]="email" required>
            <input type="restaurant" name="email" class="form-control input formcontact emailtext" placeholder="{{ 'help.restaurant' | translate }}" [(ngModel)]="restaurant" required>
            <input type="phone" name="email" class="form-control input formcontact emailtext" placeholder="{{ 'help.phone' | translate }}" [(ngModel)]="phone" required>
        </div>
        <div class="col-sm-6">
            <textarea class="form-control textarea formcontactmessage whatstext" name="message" placeholder="{{ 'help.whats' | translate }}" [(ngModel)]="message" required></textarea>
            <button type="submit" class="btn button is-danger is-large submitbutton" (click)="enviar()">{{ 'help.submit' | translate }}</button>
        </div>
    </div>
</div>