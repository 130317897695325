

import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Dish } from '../../shared/models/dish.model';

@Component({
  selector: 'app-qr-viewer-modal',
  templateUrl: './qr-viewer-modal.component.html',
  styleUrls: ['./qr-viewer-modal.component.css']
})
export class QrViewerModalComponent implements OnInit {
  closeResult = '';
  editElement: string;
  @ViewChild('content') content: ElementRef;
  constructor(
    public activeModal: NgbActiveModal,
    public router: Router,
    public ngZone: NgZone
  ) { }

  ngOnInit(): void { }
}
