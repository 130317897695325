import { Component, OnInit, NgZone } from '@angular/core';
import { Menu } from '../../shared/models/menu.model';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu-view-modal',
  templateUrl: './menu-view-modal.component.html',
  styleUrls: ['./menu-view-modal.component.css']
})
export class MenuViewModalComponent implements OnInit {

  menu: Menu;
  selectedLanguage: string;
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public router: Router,
    public ngZone: NgZone,
    public tService: TranslateService
  ) {
    this.selectedLanguage = this.tService.currentLang;
  }

  ngOnInit(): void {
  }

}
