import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { SortEvent, NgbdSortableHeader } from '../../shared/directives/sortable.directive';
import { faSearch, faPlus, faEye, faTrash, faEdit, faSpinner, faEyeSlash, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { InvoiceAdminService } from '../../shared/services/invoicesadmin.service';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';
import { HelperService } from '../../shared/services/helper.service';
import { InvoiceHelper } from '../../shared/utils/invoice.helper';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {

  faPlus = faPlus;
  faSearch = faSearch;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faTrash = faTrash;
  faEdit = faEdit;
  faSpinner = faSpinner;
  faThumbsUp = faThumbsUp;
  faThumbsDown = faThumbsDown;
  invoices$: Observable<any[]>;
  total$: Observable<number>;
  selectedLang: string;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(
    public service: InvoiceAdminService,
    private tService: TranslateService,
    private numberPipe: DecimalPipe,
    private helper: HelperService,
    private invoiceHelper: InvoiceHelper
  ) {
    this.tService.onLangChange.subscribe((data) => {
      this.selectedLang = data.lang.toUpperCase();
      this.service.selectedLang = data.lang.toUpperCase();
    });
    this.service.getAllDataTable();
    this.invoices$ = service.invoices$;
    this.total$ = service.total$;
  }

  ngOnInit(): void {}

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  show(invoice) {
    this.invoiceHelper.show(invoice);
  }

}
