import { AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
export class FileFirebaseUpload {
    id: string;
    file: File;
    name: string;
    realName: string;
    upload: boolean;
    path: string;
    toSend: boolean;
    url: string;
    type: string;
    task: AngularFireUploadTask;
    percentage: Observable<number>;
    snapshot: Observable<any>;
    downloadURL: Observable<string>;
    idTask?: string;
}

export class FileUpload {
    f: string; // filename
    s: string; // size
    t: string; // type
}
