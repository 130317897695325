import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AccountType } from '../../shared/models/account-type.model';
import { AccountTypeFormComponent } from '../account-type-form/account-type-form.component';
import { HelperService } from '../../shared/services/helper.service';

@Component({
  selector: "app-account-type-modal",
  templateUrl: "./account-type-modal.component.html",
  styleUrls: ["./account-type-modal.component.css"],
})
export class AccountTypeModalComponent implements OnInit {
  closeResult = "";
  editElement: AccountType;
  @ViewChild("content") content: ElementRef;
  @ViewChild(AccountTypeFormComponent) form: AccountTypeFormComponent;
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public router: Router,
    public ngZone: NgZone,
    private helper: HelperService
  ) {}

  ngOnInit(): void {}
  open(editElement = null) {
    const modal = this.modalService.open(AccountTypeModalComponent, {
      ariaLabelledBy: "modal-basic-title",
    });
    if (editElement) {
      modal.componentInstance.editElement = editElement;
    }
    modal.result.then(
      (result) => {},
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  save() {
    this.helper.openConfirmationChangeAccount().then(result => {
      if (result) {
        this.form.saveAccountType();
      }
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
