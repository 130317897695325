import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { faSearch, faPlus, faEye, faTrash, faEdit, faSpinner, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../shared/directives/sortable.directive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Dish } from '../../shared/models/dish.model';
import { DishService } from '../../shared/services/dish.service';
import { DishModalComponent } from '../dish-modal/dish-modal.component';
import { CategoryDish } from '../../shared/models/category-dish.model';
import { CategoryDishService } from '../../shared/services/category-dish.service';
import { UnitMeasure } from '../../shared/models/unit-mesure.model';
import { UnitMeasureService } from '../../shared/services/unit-measure.service';
import { AllergenService } from '../../shared/services/allergen.service';
import { Allergen } from 'src/app/shared/models/allergen.model';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';
import { HelperService } from '../../shared/services/helper.service';
import { Router } from '@angular/router';

@Component({
  selector: "app-dishes",
  templateUrl: "./dishes.component.html",
  styleUrls: ["./dishes.component.css"],
})
export class DishesComponent implements OnInit {
  faPlus = faPlus;
  faSearch = faSearch;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faTrash = faTrash;
  faEdit = faEdit;
  faSpinner = faSpinner;
  dishes$: Observable<Dish[]>;
  total$: Observable<number>;
  categories: CategoryDish[] = [];
  units: UnitMeasure[] = [];
  allergens: Allergen[] = [];
  selectedLang: string;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(
    public service: DishService,
    public modalService: NgbModal,
    public catService: CategoryDishService,
    public unitService: UnitMeasureService,
    public alService: AllergenService,
    private tService: TranslateService,
    private numberPipe: DecimalPipe,
    private helper: HelperService,
    private _router: Router) {
    this.service.isAdminData = this._router.url.includes('admin');
    this.catService.isAdminData = this._router.url.includes('admin');
    this.unitService.isAdminData = this._router.url.includes('admin');
    this.tService.onLangChange.subscribe((data) => {
      this.selectedLang = data.lang.toUpperCase();
      this.service.instance.selectedLang = data.lang.toUpperCase();
    });
    this.service.instance.getAllDataTable();
    this.dishes$ = service.instance.data$;
    this.total$ = service.instance.total$;
    this.catService.instance.getAll().subscribe((cis) => {
      this.categories = cis;
    });
    this.unitService.instance.getAll().subscribe((uis) => {
      this.units = uis;
    });
    this.alService.getAll().subscribe((ais) => {
      this.allergens = ais;
    });
    // this.service.migrate();
  }

  ngOnInit(): void {}

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.service.instance.sortColumn = column;
    this.service.instance.sortDirection = direction;
  }
  getCategories(dish: Dish) {
    if (this.categories.length > 0) {
      return dish.c
        .map((ci) => {
          const c = this.categories.find((cii) => cii.id === ci);
          if (!c) {
            return "";
          }
          return c.name_traductions[this.selectedLang]
            ? c.name_traductions[this.selectedLang]
            : c.name;
        })
        .join(", ");
    }
    return "";
  }
  getPrices(dish: Dish) {
    if (dish.pr && this.units.length > 0) {
      return Object.keys(dish.pr)
        .map((pi) => {
          const u = this.units.find((ui) => ui.id === pi);
          if (!u) {
            return "";
          }
          const p =
            this.numberPipe.transform(dish.pr[pi]["EUR"], "1.2-2") + " € ";
          return p + (u.name_traductions[this.selectedLang]
            ? u.name_traductions[this.selectedLang]
            : u.name);
        })
        .join(" / ");
    }
    return "";
  }
  getAllergens(dish: Dish) {
    if (this.allergens.length > 0) {
      return dish.a.map(
        (ai) => this.allergens.find((aii) => aii.id === ai).icon
      );
    }
    return [];
  }
  add() {
    const modal = this.modalService.open(DishModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });
  }
  show(dish: Dish) {
    dish.ab = !dish.ab;
    this.service.instance.setAvailable(dish.id, dish.ab);
  }
  edit(dish) {
    const modal = this.modalService.open(DishModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });
    if (dish) {
      modal.componentInstance.editElement = dish;
    }
    modal.result.then(
      (result) => {},
      (reason) => {}
    );
  }
  delete(dish) {
    this.helper.openConfirmationDeleteData().then(result => {
      if (result) {
        this.service.instance.remove(dish);
      }
    });
  }
}
