import { Injectable, NgZone, PipeTransform } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { Dish } from '../models/dish.model';
import { map, tap, debounceTime, switchMap, delay } from 'rxjs/operators';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { SortColumn, SortDirection } from '../directives/sortable.directive';
import { CategoryDishService } from './category-dish.service';
import { UnitMeasureService } from './unit-measure.service';
import { TranslateService } from '@ngx-translate/core';
import { IDishCRUDService } from '../interfaces/iDishCRUDService';

interface SearchResult {
  dishes: Dish[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
  selectedLang: string;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(dishes: Dish[], column: SortColumn, direction: string, selectedLang: string = null): Dish[] {
  if (direction === '' || column === '') {
    return dishes;
  } else {
    if (selectedLang && column === 'n') {
      return [...dishes].sort((a, b) => {
        const an = a.nt[selectedLang] ? a.nt[selectedLang] : a.n;
        const bn = b.nt[selectedLang] ? b.nt[selectedLang] : b.n;
        const res = compare(`${an}`, `${bn}`);
        return direction === "asc" ? res : -res;
      });
    }
    return [...dishes].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(dish: Dish, term: string, selectedLang: string = null, pipe: PipeTransform = null) {
  if (selectedLang && dish.nt[selectedLang]) {
    return dish.nt[selectedLang].toLowerCase().includes(term.toLowerCase());
  }
  return dish.n.toLowerCase().includes(term.toLowerCase());
  // || category.quantities.map(qi => qi.name.toLowerCase()).join(', ').includes(term.toLowerCase());
  // || pipe.transform(country.area).includes(term)
  // || pipe.transform(country.population).includes(term);
}

@Injectable({
  providedIn: "root",
})
export class DishUserService implements IDishCRUDService {
  database = "Dishes";
  databaseTrash = "DishesTrash";
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _dishes$ = new BehaviorSubject<Dish[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private dishes: Dish[] = [];
  private _state: State = {
    page: 1,
    pageSize: 15,
    searchTerm: "",
    sortColumn: "n",
    sortDirection: "asc",
    selectedLang: localStorage.getItem("cLang")
  };
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public cService: CategoryDishService,
    public uService: UnitMeasureService,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {}
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
  get ref() {
    const user = JSON.parse(localStorage.getItem("user"));
    const establishment = JSON.parse(localStorage.getItem("establishment"));
    return firebase
      .firestore()
      .collection(
        `users/${user.uid}/Establishments/${establishment.id}/${this.database}`
      )
      .doc("Dishes");
  }
//   get ref2() {
//     const user = JSON.parse(localStorage.getItem("user"));
//     const establishment = JSON.parse(localStorage.getItem("establishment"));
//     return firebase
//       .firestore()
//       .collection(
//         `users/${user.uid}/Establishments/${establishment.id}/${this.database}`
//       )
//       .doc("Dishes2");
//   }
  get refObs() {
    const user = JSON.parse(localStorage.getItem("user"));
    const establishment = JSON.parse(localStorage.getItem("establishment"));
    return this.afs
      .collection<Dish>(
        `users/${user.uid}/Establishments/${establishment.id}/${this.database}`
      )
      .doc("Dishes");
  }
  create(dish: Dish) {
    dish.id = this.afs.createId();
    const value = {};
    value[dish.id] = dish;
    this.ref.set(value, { merge: true }).then(() => {
      dish.c.forEach((ci) => {
        this.cService.instance.addDish(ci, dish.id);
      });
      Object.keys(dish.pr).forEach((key) => {
        this.uService.instance.addDish(key, dish.id);
      });
    });
    return dish;
  }
  edit(dish: Dish, lastCategories: string[], lastUnits: string[]) {
    const cToRemove = lastCategories.filter(
      (ci) => dish.c.findIndex((cii) => cii === ci) === -1
    );
    const uToRemove = lastUnits.filter(
      (ui) => Object.keys(dish.pr).findIndex((uii) => uii === ui) === -1
    );
    const cToAdd = dish.c.filter(
      (ci) => lastCategories.findIndex((cii) => cii === ci) === -1
    );
    const uToAdd = Object.keys(dish.pr).filter(
      (ui) => lastUnits.findIndex((uii) => uii === ui) === -1
    );
    const value = {};
    value[dish.id] = dish;
    return this.ref.set(value, { merge: true }).then(() => {
      cToRemove.forEach((cti) => this.cService.instance.removeDish(cti, dish.id));
      uToRemove.forEach((uti) => this.uService.instance.removeDish(uti, dish.id));
      cToAdd.forEach((cti) => this.cService.instance.addDish(cti, dish.id));
      uToAdd.forEach((uti) => this.uService.instance.addDish(uti, dish.id));
    });
  }
  setAvailable(id: string, ab: boolean) {
    const value = {};
    value[id] = { ab: ab };
    return this.ref.set(value, { merge: true });
  }
  // migrate() {
  //   this.getAll().subscribe(dishes => {
  //     const new_dishes = {};
  //     const old_dishes = {};
  //     dishes.forEach(di => {
  //       old_dishes[di.id] = di;
  //       new_dishes[di.id] = this.toNewDish(di);
  //     });
  //     this.ref2.set(old_dishes).then(() => {
  //       this.ref.set(new_dishes);
  //     });
  //   });
  // }
  // toNewDish(d: Dish) {
  //   return {
  //     id: d.id,
  //     r: (d.ref) ? d.ref : '',
  //     n: d.name,
  //     nt: d.name_traductions,
  //     c: d.categories,
  //     d: d.description,
  //     dt: d.description_traductions,
  //     p: (d.photos) ? d.photos : [],
  //     a: d.allergens,
  //     pr: d.prices,
  //     o: d.observations,
  //     ot: d.observations_traductions,
  //     pl: []
  //   };
  // }
  //   export interface Dish {
  //   id: string;
  //   ref: string;
  //   name: string;
  //   name_traductions: any;
  //   categories: string[];
  //   description: string;
  //   description_traductions: any;
  //   photos?: string[];
  //   allergens: string[];
  //   prices: any;
  //   observations: string;
  //   observations_traductions: string;
  //   pluses: PlusProduct[];
  //   available: boolean;
  // }

  // export interface PlusProduct {
  //   name: string;
  //   name_traductions: any;
  //   price: any;
  // }

  // export interface NewDish {
  //   id: string;
  //   r: string; // ref
  //   n: string; // name
  //   mt: any; // name_traductions
  //   c: string[]; // categories
  //   d: string; // description
  //   dt: any; // descriptions_traductions
  //   p?: string[]; // photos
  //   a: string[]; // allergens
  //   pr: any; // prices
  //   o: string; // observations
  //   ot: string; // observations_traductions
  //   pl: PlusProduct[]; // pluses
  //   ab: boolean; // available
  // }

  // export interface NewPlusProduct {
  //   n: string; // name
  //   nt: any; // name_traductions
  //   p: any; // prices
  // }
  remove(dish: Dish) {
    const user = JSON.parse(localStorage.getItem("user"));
    const establishment = JSON.parse(localStorage.getItem("establishment"));
    const value = {};
    value[dish.id] = dish;
    firebase
      .firestore()
      .doc(
        `users/${user.uid}/EstablishmentsTrash/${establishment.id}/${this.databaseTrash}/Dishes`
      )
      .set(value, { merge: true });
    const value2 = {};
    value2[dish.id] = firebase.firestore.FieldValue.delete();
    return this.ref.update(value2).then(() => {
      dish.c.forEach((ci) => {
        this.cService.instance.removeDish(ci, dish.id);
      });
      Object.keys(dish.pr).forEach((key) => {
        this.uService.instance.removeDish(key, dish.id);
      });
    });
  }
  getAll() {
    return this.refObs.snapshotChanges().pipe(
      map((actions) => {
        const data = actions.payload.data();
        return Object.keys(data || {}).map((key) => {
          return data[key] as Dish;
        });
      })
    );
  }
  getAllDataTable() {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this._dishes$.next(result.dishes);
        this._total$.next(result.total);
      });
    return this.refObs
      .snapshotChanges()
      .pipe(
        map((actions) => {
          const data = actions.payload.data();
          this.dishes = Object.keys(data || {}).map((key) => {
            return data[key] as Dish;
          });
          this._search$.next();
        })
      )
      .subscribe((data) => {});
  }
  get data$() {
    return this._dishes$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }
  get selectedLang() {
    return this._state.selectedLang;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }
  set selectedLang(selectedLang: string) {
    this._set({ selectedLang });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const {
      sortColumn,
      sortDirection,
      pageSize,
      page,
      searchTerm,
      selectedLang,
    } = this._state;

    // console.log(this.dishes);
    // 1. sort
    let dishes = sort(this.dishes, sortColumn, sortDirection, selectedLang);

    // 2. filter
    // console.log(this._state);
    // console.log(dishes);
    dishes = dishes.filter((dish) => matches(dish, searchTerm, selectedLang));
    const total = dishes.length;

    // 3. paginate
    dishes = dishes.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
    return of({ dishes, total });
  }
}
