import { Injectable, NgZone } from '@angular/core';
import { User } from '../services/user';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Email } from '../models/mail.model';
@Injectable({
  providedIn: "root",
})
export class MailService {
  userData: any; // Save logged in user data
  user: Observable<User | null>;
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
  ) {}
  SendEmail(email) {
    email.id = this.afs.createId();
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
        `emails/${email.id}`
      );
    return userRef.set(email, { merge: true });
  }
  SendEmailbyUser(user, email: Email) {
    email.id = this.afs.createId();
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
        `users/${user.uid}/Emails/${email.id}`
      );
    return userRef.set(email, { merge: true });
  }
}
