<form #demoForm="ngForm" [formGroup]="form" (ngSubmit)="onSubmit(demoForm)" novalidate>
    <div class="form-group row">
        <label for="ccnumber" class="col-sm-2 col-form-label">Credit card number*: </label>
        <div class="col-sm-10">
            <input id="ccnumber" class="form-control" placeholder="Credit card number" formControlName="creditCard" type="tel" autocomplete="cc-number" ccNumber>
        </div>
    </div>
    <div class="form-group row">
        <label for="titular" class="col-sm-2 col-form-label">Titular*: </label>
        <div class="col-sm-10">
            <input id="titular" class="form-control" placeholder="Titular" formControlName="titular" type="text" autocomplete="off">
        </div>
    </div>
    <div class="form-group row">
        <label for="expiration" class="col-sm-2 col-form-label">Expiration date*: </label>
        <div class="col-sm-10">
            <input id="expiration" class="form-control" placeholder="Expiration date" formControlName="expirationDate" type="tel" autocomplete="cc-exp" ccExp>
        </div>
    </div>
    <div class="form-group row">
        <label for="cvc" class="col-sm-2 col-form-label">CVC*: </label>
        <div class="col-sm-10">
            <input id="cvc" class="form-control" placeholder="CVC" formControlName="cvc" type="tel" autocomplete="off" ccCvc>
        </div>
    </div>
</form>