import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class MenuUserService {
  constructor(
    public afs: AngularFirestore // Inject Firestore service
  ) {}
  getMenuUser(code1, code2) {
    return this.afs
      .doc(`menus/${code1}/Establishments/${code2}`)
      .snapshotChanges();
  }
}
