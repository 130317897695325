<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'qr-viewer-modal.qr' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <app-qr-viewer #form [elementToEdit]="editElement"></app-qr-viewer>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="form.download()">{{'common.download' | translate}}</button>
    <button type="button" class="btn btn-primary" (click)="activeModal.dismiss('Cancel click')">{{'common.accept' | translate}}</button>
</div>