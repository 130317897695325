<div *ngIf="!allowDrag && _contents" cdkDropList [id]="id" [cdkDropListData]="_contents.cnt" (cdkDropListDropped)="onTaskDrop($event)" cdkDragRootElement=".modal_class" class="formGroup modal_class" style="border: #bebebe solid 1px; border-radius: 5px; padding: 5px;  margin-bottom: 2px!important">
    <div>
        <button type=" button " style="display: inline;" class="btn btn-light " (click)="del()"><fa-icon [icon]="faTimes"></fa-icon></button>
        <h6 style="display: inline;">{{'structure-drawer.container' | translate}}</h6>
        <button type=" button" style="display: inline;" class="btn btn-light" (click)="edit.emit(_contents)"><fa-icon [icon]="faEdit"></fa-icon></button>
    </div>
    <ng-container *ngFor=" let ci of _contents.cnt; let i = index" [ngSwitch]="ci.t">
        <app-text-structure-form *ngSwitchCase="'text'" [contents]="ci" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected" (delete)="deleteElement(i)" (edit)="edit.emit($event)"
            (indexChange)="indexed()"></app-text-structure-form>
        <app-container-structure-form *ngSwitchCase="'container'" [contents]="ci" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected" (delete)="deleteElement(i)" (edit)="edit.emit($event)"
            (indexChange)="indexed()"></app-container-structure-form>
        <app-dish-collection-structure-form *ngSwitchCase="'dishcollection'" [contents]="ci" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected" (delete)="deleteElement(i)"
            (edit)="edit.emit($event)"></app-dish-collection-structure-form>
        <app-image-structure-form *ngSwitchCase="'image'" [contents]="ci" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected" (delete)="deleteElement(i)" (edit)="edit.emit($event)"
            (indexChange)="indexed()"></app-image-structure-form>
        <app-table-structure-form *ngSwitchCase="'table'" [contents]="ci" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected" (delete)="deleteElement(i)" (edit)="edit.emit($event)"
            (indexChange)="indexed()"></app-table-structure-form>
        <app-dish-structure-form *ngSwitchCase="'dish'" [contents]="ci" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected" (delete)="deleteElement(i)" (edit)="edit.emit($event)"></app-dish-structure-form>
    </ng-container>
    <div class="text-center">
        <app-button-add (add)="addComponent($event)"></app-button-add>
    </div>
</div>
<div *ngIf="allowDrag && _contents" cdkDrag cdkDropList [id]="id" [cdkDropListData]="_contents.cnt" (cdkDropListDropped)="onTaskDrop($event)" cdkDragRootElement=".modal_class" class="formGroup modal_class" style="border: #bebebe solid 1px; border-radius: 5px; padding: 5px;  margin-bottom: 2px!important">
    <div>
        <button type=" button " style="display: inline;" class="btn btn-light " (click)="del()"><fa-icon [icon]="faTimes"></fa-icon></button>
        <h6 style="display: inline;">{{'structure-drawer.container' | translate}}</h6>
        <button type=" button" style="display: inline;" class="btn btn-light" (click)="edit.emit(_contents)"><fa-icon [icon]="faEdit"></fa-icon></button>
        <button type=" button" style="display: inline;" class="btn btn-light"><fa-icon class="fa fa-arrows-alt"></fa-icon></button>
    </div>
    <ng-container *ngFor=" let ci of _contents.cnt; let i = index" [ngSwitch]="ci.t">
        <app-text-structure-form *ngSwitchCase="'text'" [contents]="ci" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected" (delete)="deleteElement(i)" (edit)="edit.emit($event)"
            (indexChange)="indexed()"></app-text-structure-form>
        <app-container-structure-form *ngSwitchCase="'container'" [contents]="ci" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected" (delete)="deleteElement(i)" (edit)="edit.emit($event)"
            (indexChange)="indexed()"></app-container-structure-form>
        <app-dish-collection-structure-form *ngSwitchCase="'dishcollection'" [contents]="ci" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected" (delete)="deleteElement(i)"
            (edit)="edit.emit($event)"></app-dish-collection-structure-form>
        <app-image-structure-form *ngSwitchCase="'image'" [contents]="ci" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected" (delete)="deleteElement(i)" (edit)="edit.emit($event)"
            (indexChange)="indexed()"></app-image-structure-form>
        <app-table-structure-form *ngSwitchCase="'table'" [contents]="ci" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected" (delete)="deleteElement(i)" (edit)="edit.emit($event)"
            (indexChange)="indexed()"></app-table-structure-form>
        <app-dish-structure-form *ngSwitchCase="'dish'" [contents]="ci" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected" (delete)="deleteElement(i)" (edit)="edit.emit($event)"></app-dish-structure-form>
    </ng-container>
    <div class="text-center">
        <app-button-add (add)="addComponent($event)"></app-button-add>
    </div>
</div>