<ng-container *ngIf="authService.user">
    <!-- Topbar navigation -->
    <app-top-nav></app-top-nav>
    <!-- Sidebar navigation -->
    <div class="container-fluid background">
        <div class="row">
            <app-main-nav></app-main-nav>
            <!-- Main content -->
            <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
                <router-outlet></router-outlet>
            </main>
        </div>
    </div>
</ng-container>