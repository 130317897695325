import { Component, Input, OnInit, ViewChild, ComponentFactoryResolver, SimpleChanges, OnChanges, ContentChildren } from '@angular/core';
import { StructureTemplateComponent, IndexStructureTemplateComponent } from '../shared/structure-template.component';
import { CategoryDish } from '../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../shared/models/unit-mesure.model';
import { Allergen } from '../../../shared/models/allergen.model';
import { Dish } from '../../../shared/models/dish.model';
import { ImageDirective } from '../directives/image.directive';
import { Photo, Index, Menu } from '../../../shared/models/menu.model';
import { REGISTRY } from '../shared/register.component';
import { IndexDirective } from '../directives/index.directive';
import { StyleDrawerComponent } from '../style-drawer/style-drawer.component';
import { DataService } from '../../../shared/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: "app-index-template",
  template: `
  <div [ngClass]="{'template-container': showUtils}">
    <div class="editing" *ngIf="showUtils">
      <!-- <button type="button" class="btn btn-light enlinia" (click)="del()"><fa-icon class="fa fa-times"></fa-icon></button> -->
      <button type="button" class="btn btn-light enlinia" (click)="code()"><fa-icon class="fa fa-code"></fa-icon></button>
      <button type="button" class="btn btn-light enlinia" (click)="edit()"><fa-icon class="fa fa-bold"></fa-icon></button>
    </div> <ng-template index-host></ng-template></div> `
})
export class IndexTemplateComponent
  implements OnInit, OnChanges, IndexStructureTemplateComponent {
  @Input() showIndexBtn: boolean;
  @Input() menu: Menu;
  @Input() type: string;
  @Input() contents: Index;
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() allergens: Allergen[];
  @Input() dishes: Dish[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @Input() showUtils: boolean;
  @Input() indexes: { id: string, name: string, trds: any }[];
  @ContentChildren('styleDrawer') styleDrawer: StyleDrawerComponent;
  @ViewChild(IndexDirective, { static: true }) indexHost: IndexDirective;
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private data: DataService, private router: Router) {}
    calculateIndex() {
        // throw new Error("Method not implemented.");
    }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadComponent();
  }
  ngOnInit() {
    this.loadComponent();
    this.showUtils = this.router.url.endsWith('menus');
    // this.showUtils = this.indexHost.showUtils;
  }

  loadComponent() {
    if (this.contents && this.languages && this.indexHost) {
      if (!this.type) {
        this.contents.dt = "Standard";
        this.type = "Standard";
      }
      let t = `Index${this.type}TemplateComponent`;
      let componentFactory;
      try {
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(REGISTRY.get(t));
      } catch {
        t = `IndexStandardTemplateComponent`;
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(REGISTRY.get(t));
      }

      const viewContainerRef = this.indexHost.viewContainerRef;
      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent(componentFactory);
        (<StructureTemplateComponent<Index>>componentRef.instance).menu = this.menu;
        (<StructureTemplateComponent<Index>>(
        componentRef.instance
      )).contents = this.contents;
      (<StructureTemplateComponent<Index>>(
        componentRef.instance
      )).languages = this.languages;
      (<StructureTemplateComponent<Index>>(
        componentRef.instance
      )).language_selected = this.language_selected;
      (<StructureTemplateComponent<Index>>(
        componentRef.instance
      )).menu = this.menu;
      (<StructureTemplateComponent<Index>>(
        componentRef.instance
      )).indexes = this.indexes;
      (<IndexStructureTemplateComponent>(
        componentRef.instance
      )).showIndexBtn = this.showIndexBtn;
      (<StructureTemplateComponent<Index>>componentRef.instance).styleDrawer = this.styleDrawer;
    }
  }
  edit() {
    this.data.changeStructureStyle(this.contents);
  }
  del() {

  }
  code() {
    this.data.changeStructureCode(this.contents);
  }
}
