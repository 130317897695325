<div class="landing font">
    <nav id="myheader" class="navbar navbar-expand-lg navbar-light bg-light nav_landing sticky" style="background-color: transparent" role="navigation">
        <a class="navbar-brand" href="/home">
            <img class="logo" src="../../../assets/Logo_Blanc_SP.png" alt="">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"><span class="navbar-toggler-icon"></span></button>
        <div class="collapse navbar-collapse justifyend" id="navbarNavDropdown" [ngbCollapse]="isCollapsed">
            <ul class="nav navbar-nav homenavbar" style="text-align: left;">
                <li class="nav-item active light-btn">
                    <a class="nav-link btnmainnav" (click)="scroll(section2)">{{ 'home.virtualcard' | translate }}</a>
                </li>
                <li class="nav-item light-btn">
                    <a class="nav-link btnmainnav" (click)="scroll(section3)">{{ 'home.howitworks' | translate }}</a>
                </li>
                <li class="nav-item light-btn">
                    <a class="nav-link btnmainnav" (click)="scroll(section4)">{{ 'home.plans' | translate }}</a>
                </li>
                <li class="nav-item light-btn">
                    <a class="nav-link btnmainnav" (click)="scroll(section5)">{{ 'home.contactus' | translate }}</a>
                </li>
                <li class="nav-item light-btn">
                    <a class="nav-link btnmainnav" (click)="login()">{{ 'home.signin' | translate }}</a>
                </li>
            </ul>
        </div>
        <ng-container>
            <div class="d-inline-block profilebtn" ngbDropdown>
                <button class="btn mr-2 accountbtn" id="langDropDown" style="color: white;" ngbDropdownToggle>
                    <ng-container>{{ selectedLang }}</ng-container> 
                </button>
                <div ngbDropdownMenu aria-labelledby="langDropDown" class="menudropdown">
                    <button ngbDropdownItem *ngFor="let l of languages" (click)="tService.use(l.code.toLowerCase())">{{l.code}}</button>
                </div>
            </div>
        </ng-container>
    </nav>

    <div class="title1">
        <div>{{ 'home.subtitle' | translate }}</div>
        <div class="contbtn">
            <a class="nav-link titlebtn" (click)="scroll(section3)">
                <i class="far fa-arrow-alt-circle-down"></i>{{ 'home.video' | translate }}
            </a>
        </div>

    </div>
    <span class="nice-pos stamp is-approved" (click)="signup()">
        <div class="center-text-spam">{{ 'home.freedays30' | translate }}</div>
        <div class="little-text-spam center-text-spam">{{ 'home.registrateslogan' | translate }}</div>
        <div class="little-text-spam center-text-spam">{{ 'home.totallyfree' | translate }}</div>
    </span>
</div>

<div #section2 class="virtualcard font">
    <div class="row newrow">
        <div class="col-sm-4 marginmobile">
            <img class="mobileimg" src="../../../assets/Mockup.png" alt="Fuudie">
        </div>
        <div class="col-sm-8">
            <p class="VCtitle font">{{ 'home.titleVC' | translate }} </p>
            <p class="VCtext font">{{ 'home.textVC' | translate }}
            </p>
            <div class="diviconVC">
                <img class="VCicon" src="../../../assets/QR.png" alt="Fuudie">
                <p style="display:block;">{{ 'home.acces' | translate }}<br>No App</p>
            </div>
            <div class="diviconVC">
                <img class="VCicon" src="../../../assets/time.png" alt="Fuudie">
                <p style="display:block;">{{ 'home.management' | translate }}<br>24/7</p>
            </div>
            <div class="diviconVC">
                <img class="VCicon" src="../../../assets/finger.png" alt="Fuudie">
                <p style="display:block;">{{ 'home.changes' | translate }}<br>{{ 'home.instant' | translate }}</p>
            </div>
            <div class="diviconVC">
                <img class="VCicon" src="../../../assets/manage.png" alt="Fuudie">
                <p style="display:block;">{{ 'home.management2' | translate }}<br>{{ 'home.full' | translate }}</p>
            </div>
            <div class="diviconVC">
                <img class="VCicon" src="../../../assets/devices.png" alt="Fuudie">
                <p style="display:block;">{{ 'home.multiple' | translate }}<br>{{ 'home.platforms' | translate }}</p>
            </div>
            <div class="diviconVC">
                <img class="VCicon" src="../../../assets/screen.png" alt="Fuudie">
                <p style="display:block;">{{ 'home.multiple' | translate }}<br>{{ 'home.templates' | translate }}</p>
            </div>
            <div class="diviconVC">
                <img class="VCicon" src="../../../assets/languages.png" alt="Fuudie">
                <p style="display:block;">{{ 'home.multiple' | translate }}<br>{{ 'home.languages' | translate }}</p>
            </div>

        </div>
    </div>
</div>
<div #section3 class="how font">
    <p class="howtitle font">{{ 'home.howitworks' | translate }}</p>
    <div class="tablet">
        <div class="contentvideo">
            <video src="https://firebasestorage.googleapis.com/v0/b/fuudie-81c95.appspot.com/o/home%2Ffuudie.mp4?alt=media&token=19cd48c2-a044-4547-a36c-59f5b3f31645" style="width:100%;border:none;height:100%" controls></video>
        </div>
    </div>

</div>
<div #section4 class="plans font">
    <div class="row newrow">
        <div class="col-sm-2">

        </div>
        <div class="col-sm-4 responsivemargin">
            <div class="planform" (click)="signup()">
                <p class="plantitle font">{{ 'home.basic' | translate }}</p>
                <p class="pricetext font">12,99 {{ 'home.priceunits' | translate }}</p>
                <ul class="conditionslist">
                    <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>{{ 'home.digitalcard' | translate }}</li>
                    <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>{{ 'home.qr' | translate }}</li>
                    <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>{{ 'home.editeasy' | translate }}</li>
                    <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>{{ 'home.manage' | translate }}</li>
                    <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>{{ 'home.twohundred' | translate }}</li>
                    <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>{{ 'home.fivemenus' | translate }}</li>
                </ul>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="planform" (click)="signup()">
                <p class="plantitle font">Pro</p>
                <p class="pricetext font">24,99 {{ 'home.priceunits' | translate }}</p>
                <ul class="conditionslist">
                    <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>{{ 'home.digitalcard' | translate }}</li>
                    <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>{{ 'home.qr' | translate }}</li>
                    <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>{{ 'home.editeasy' | translate }}</li>
                    <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>{{ 'home.manage' | translate }}</li>
                    <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>{{ 'home.fourhundred' | translate }}</li>
                    <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>{{ 'home.tenmenus' | translate }}</li>
                </ul>
            </div>
        </div>
        <div class="col-sm-2">

        </div>
    </div>
</div>
<div #section5 class="contactus font">
    <div class="contactusform">
        <app-contact></app-contact>
    </div>
</div>
<footer class="page-footer font-small blue pt-4 Fuudiefooter font">

    <!-- Footer Links -->
    <div class="container-fluid text-center text-md-left">

        <!-- Grid row -->
        <div class="row">

            <!-- Grid column -->
            <div class="col-md-3 mt-md-0 mt-3">

                <!-- Content -->
                <img class="brand-logo logo logoresponsive" src="assets/Logo_Blanc_SP.png" alt="Fuudie">

            </div>
            <!-- Grid column -->

            <hr class="clearfix w-100 d-md-none pb-3">

            <!-- Grid column -->
            <div class="col-md-3 mb-md-0 mb-3">

                <!-- Links -->
                <h5 class="text-size font">{{ 'home.company' | translate }}</h5>

                <ul class="list-unstyled font">
                    <li>
                        <span routerLink="/legaladvice" target="_blank" class="text-link">{{ 'home.legaladvice' | translate }}</span>
                    </li>
                    <li>
                        <span routerLink="/privacypolicy" class="text-link">{{ 'home.privacypolicy' | translate }}</span>
                    </li>
                    <li>
                        <span routerLink="/cookiepolicy" class="text-link">{{ 'home.cookiepolicy' | translate }}</span>
                    </li>

                </ul>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-3 mb-md-0 mb-3">

                <!-- Links -->
                <h5 class="text-size font">{{ 'home.help' | translate }}</h5>

                <ul class="list-unstyled font">
                    <li>
                        <span href="#!" class="text-link" (click)="scroll(section5)">{{ 'home.contact' | translate }}</span>
                    </li>
                </ul>

            </div>
            <!-- Grid column -->
            <div class=" col-md-3 mb-md-0 mb-3 ">

                <!-- Links -->
                <h5 class="text-size font ">{{ 'home.followus' | translate }}</h5>

                <a href="https://instagram.com/fuudie.es?igshid=7erfo4uk6daf" class="text-icon ">
                    <fa-icon class="fab fa-instagram "></fa-icon>
                </a>
                <a href="https://m.facebook.com/Fuudiees-112916660448991/?ref=bookmarks" class="text-icon ">
                    <fa-icon class="fab fa-facebook-square "></fa-icon>
                </a>
                <!-- <span href="#! " class="text-icon "><fa-icon class="fab fa-twitter-square "></fa-icon></span> -->


            </div>

        </div>
        <!-- Grid row -->

    </div>
    <!-- Footer Links -->

    <!-- Copyright -->
    <div class="footer-copyright text-center py-3 ">© 2020 Copyright:
        <a href="https://Fuudie.es/ "> Fuudie.es</a>
    </div>
    <!-- Copyright -->

</footer>
<!-- Footer -->