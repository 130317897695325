import { Injectable } from '@angular/core';
import { MediaGalleryUserService } from './media-gallery-user.service';
import { MediaGalleryAdminService } from './media-gallery-admin.service';
import { IMediaGalleryCRUDService } from '../interfaces/iMediaGalleryCRUDService';

@Injectable({
  providedIn: "root",
})
export class MediaGalleryService {

  public isAdminData = false;

  constructor (
    private _mediaGalleryUserService: MediaGalleryUserService,
    private _mediaGalleryAdminService: MediaGalleryAdminService
  ) {}

  public get instance(): IMediaGalleryCRUDService {
    return this.isAdminData ? this._mediaGalleryAdminService : this._mediaGalleryUserService;
  }
}
