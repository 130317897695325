import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CategoryDishFormComponent } from '../category-dish-form/category-dish-form.component';
import { CategoryDish } from '../../shared/models/category-dish.model';

@Component({
  selector: 'app-category-dish-modal',
  templateUrl: './category-dish-modal.component.html',
  styleUrls: ['./category-dish-modal.component.css'],
})
export class CategoryDishModalComponent implements OnInit {
  closeResult = '';
  editElement: CategoryDish;
  @ViewChild('content') content: ElementRef;
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public router: Router,
    public ngZone: NgZone
  ) {}

  ngOnInit(): void {}
  open(editElement = null) {
    const modal = this.modalService
      .open(CategoryDishModalComponent, { ariaLabelledBy: 'modal-basic-title' });
      if (editElement) {
        modal.componentInstance.editElement = editElement;
      }
      modal.result.then(
        (result) => {
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
