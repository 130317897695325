<nav class="navbar bg-dark flex-md-nowrap p-0 font background">
    <a class="navbar-brand col-sm-3 col-md-2 mr-0 navbarcolor-brand navbrand" href="http://fuddie.es">
        <img class="brand-logo logo" src="assets/Logo_Blanc_SP.png" alt="Fuudie">
    </a>
    <div class="menuicons">
        <fa-icon class="fas fa-reply iconsnorm" (click)="back()"></fa-icon>
        <!-- <fa-icon class="fas fa-language iconsnorm"></fa-icon> -->
    </div>
</nav>
<div class="fomrnorm">
    <p style="text-align: center;"><strong>Política de Cookies</strong></p>
    <p>Nuestro sitio web www.fuudie.es&nbsp;(el &ldquo;Sitio Web&rdquo;) utiliza una tecnolog&iacute;a denominada &ldquo;cookies&rdquo; con la finalidad de poder recabar informaci&oacute;n acerca del uso del Sitio Web.<br />Le informamos de que podemos utilizar
        cookies con la finalidad de facilitar su navegaci&oacute;n a trav&eacute;s del Sitio Web, distinguirle de otros usuarios, proporcionarle una mejor experiencia en el uso, o identificar problemas para mejorar nuestro Sitio Web. Asimismo, en caso
        de que preste su consentimiento, utilizaremos cookies que nos permitan obtener m&aacute;s informaci&oacute;n acerca de sus preferencias y personalizar nuestro Sitio Web de conformidad con sus intereses individuales.<br />La presente pol&iacute;tica
        de cookies tiene por finalidad informarle de manera clara y precisa sobre las cookies que se utilizan en nuestro Sitio Web (la &ldquo;Pol&iacute;tica de Cookies&rdquo;). Le informamos que el presente documento se complementa con el Aviso Legal,
        la Pol&iacute;tica de Privacidad y las Condiciones Generales de Contrataci&oacute;n ; los cuales se configuran en su conjunto como los textos legales rectores del Sitio Web.<br />En caso de que quiera recabar m&aacute;s informaci&oacute;n sobre
        las cookies que utilizamos en el Sitio Web, podr&aacute; remitir un correo electr&oacute;nico a la siguiente direcci&oacute;n: <a href="mailto:fuudie@fuudie.es">fuudie@fuudie.es</a></p>
    <p><br /><strong>&iquest;QU&Eacute; SON LAS COOKIES?</strong></p>
    <p><br />Una cookie es un archivo que se descarga en su equipo con la finalidad de almacenar datos que podr&aacute;n ser actualizados y recuperados por la entidad responsable de su instalaci&oacute;n.<br />La informaci&oacute;n recabada a trav&eacute;s
        de las cookies puede incluir la fecha y hora de visitas al Sitio Web, los productos consultados, las p&aacute;ginas visionadas, el tiempo que ha estado en nuestro Sitio Web y los sitios visitados justo antes y despu&eacute;s del mismo. Con esta
        informaci&oacute;n podemos enfocar y ajustar los servicios de forma m&aacute;s efectiva para darle una mejor experiencia como usuario.</p>
    <p><br /><strong>TIPOS Y FINALIDADES DE LAS COOKIES</strong></p>
    <p><br />Las Cookies pueden clasificarse, en virtud de sus caracter&iacute;sticas propias, dentro de diversas categor&iacute;as. Nuestro Sitio Web utiliza las cookies que se describen a continuaci&oacute;n:</p>
    <ul>
        <li>Cookies propias<br />Son aquellas cookies que son enviadas a su ordenador y gestionadas exclusivamente por nosotros para el mejor funcionamiento del Sitio Web. La informaci&oacute;n que recabamos se emplea para mejorar la calidad de nuestro servicio
            y su experiencia como usuario.</li>
        <li>Cookies de terceros<br />Si interact&uacute;a con el contenido de nuestro Sitio Web tambi&eacute;n pueden establecerse cookies de terceros. Las cookies de terceros, son aquellas establecidas por un dominio diferente de nuestro Sitio Web. No podemos
            acceder a los datos almacenados en las cookies de otros sitios web cuando navegue en los citados sitios web.<br />En nuestro Sitio Web tambi&eacute;n utilizamos el sistema de medici&oacute;n de audiencia Google Analytics, una herramienta de
            an&aacute;lisis web de Google que nos permite conocer c&oacute;mo interact&uacute;an los usuarios de nuestro Sitio Web.</li>
        <li>Cookies &ldquo;anal&iacute;ticas&rdquo;<br />Son aquellas que, junto con nuestros archivos de registro del servidor, nos permiten conocer el n&uacute;mero total de usuarios que visitan nuestro Sitio Web y aquellas partes del mismo que gozan de
            mayor popularidad. Gracias a ellas obtenemos una informaci&oacute;n que puede ayudarnos a mejorar la navegaci&oacute;n y dar un mejor servicio a usuarios y clientes. Este tipo de cookies nos ayuda, en definitiva, a mejorar la usabilidad y
            la experiencia del uso de nuestro Sitio Web.</li>
        <li>Cookies de personalizaci&oacute;n<br />Son aqu&eacute;llas que le permiten al usuario acceder al servicio con algunas caracter&iacute;sticas de car&aacute;cter general predefinidas en funci&oacute;n de una serie de criterios en el terminal del
            usuario, como es el caso de configurar el idioma al momento de crear su cuenta.</li>
        <li>Cookies de sesi&oacute;n<br />Este tipo de cookies recaban y almacenan datos &uacute;nicamente mientras el Usuario accede al Sitio Web.<br />A continuaci&oacute;n se presenta una lista de las cookies que puede encontrar en nuestro Sitio Web y
            una breve descripci&oacute;n de su finalidad.<br />| Cookie | Descripci&oacute;n | Duraci&oacute;n | Fuente |<br />| ------------- | --------------------------- | ---------------- | ----------------- |<br />| LANGUE | Preferencia de idioma:
            Se utiliza para recordar qu&eacute; idioma escogi&oacute; el visitante en su sesi&oacute;n. | Cookie de sesi&oacute;n | INGY SOLUTIONS S.C.P |<br />| ASPSESSIONID | Sesi&oacute;n de registro de visitante: Utilizada para registrar su sesi&oacute;n;
            no es intrusiva ni mantiene su registro entre visitas ni en diversos sitios Web. Utilizada para cosas como &aacute;reas de inicio de sesi&oacute;n y carritos de compra. Est&aacute; integrada a nuestro software de servidor Web. | Cookie de
            sesi&oacute;n | INGY SOLUTIONS S.C.P |<br />| ns_cookietest | Verificaci&oacute;n de preferencias de navegador: Utilizada para confirmar que su navegador pueda aceptar cookies. | Cookie de sesi&oacute;n | INGY SOLUTIONS S.C.P |</li>
        <li></li>
    </ul>
    <p><strong>CONSENTIMIENTO</strong></p>
    <p><br />Al navegar y continuar en nuestro Sitio Web estar&aacute; consintiendo el uso de las cookies antes enunciadas, por los plazos se&ntilde;alados y en las condiciones contenidas en la presente Pol&iacute;tica de Cookies.</p>
    <p><br /><strong>DESHABILITAR Y BLOQUEAR COOKIES</strong></p>
    <p><br />En cualquier caso, le informamos que, dado que las cookies no son necesarias para el uso de nuestro Sitio Web, puede bloquearlas o deshabilitarlas activando la configuraci&oacute;n de su navegador que le permite rechazar la instalaci&oacute;n
        de todas las cookies o de algunas de ellas. La pr&aacute;ctica mayor&iacute;a de los navegadores permiten advertir de la presencia de cookies o rechazarlas autom&aacute;ticamente. Si las rechaza podr&aacute; seguir usando nuestro Sitio Web, aunque
        el uso de algunos de sus servicios podr&aacute; ser limitado y por tanto su experiencia en nuestro Sitio Web menos satisfactoria.</p>
    <p><br /><strong>RETIRAR MI CONSENTIMIENTO</strong></p>
    <p><br />Si quisiese retirar en cualquier momento su consentimiento relacionado con la presente Pol&iacute;tica de Cookies, deber&aacute; eliminar las cookies almacenadas en su equipo a trav&eacute;s de los ajustes y configuraciones de su navegador de
        Internet.
    </p>
    <p><strong>MODIFICACI&Oacute;N DE LA CONFIGURACI&Oacute;N Y AJUSTES SOBRE COOKIES</strong></p>
    <p><br />Salvo que haya ajustado la configuraci&oacute;n de su navegador, nuestro sistema crear&aacute; cookies en cuanto visite nuestro Sitio Web. Tenga presente que todos los navegadores de Internet permiten el cambio de dicha configuraci&oacute;n.
        Para m&aacute;s informaci&oacute;n sobre la forma de ajustar sus configuraciones de cookies en los siguientes navegadores, le remitimos al enlace pertinente:<br />Internet Explorer:<br />(http://support.microsoft.com/kb/196955); (http://support.microsoft.com/kb/283185)<br
        />(http://www.allaboutcookies.org/manage-cookies/internet-explorer7-plus.html)<br />(http://www.allaboutcookies.org/manage-cookies/internet-explorer8-plus.html)<br />(http://windows.microsoft.com/is-IS/windows7/How-to-manage-cookies-in-Internet-Explorer-9)<br
        />Firefox:<br />http://support.mozilla.org/en-US/kb/Cookies<br />Chrome<br />http://support.google.com/chrome/bin/answer.py?hl=en&amp;answer=95647<br />Safari<br />http://support.apple.com/kb/PH5042 <br />http://support.apple.com/kb/HT1677</p>
    <p><strong>CAMBIOS EN LA POL&Iacute;TICA DE COOKIES</strong></p>
    <p><br />Es posible que actualicemos la Pol&iacute;tica de Cookies de nuestro Sitio Web, por ello le recomendamos revisar esta pol&iacute;tica cada vez que acceda a nuestro Sitio Web con el objetivo de estar adecuadamente informado sobre c&oacute;mo
        y para qu&eacute; usamos las cookies. La Pol&iacute;tica de Cookies se actualiz&oacute; por &uacute;ltima vez en 1 Junio 2020.</p>
    <p><strong>CONTACTO</strong></p>
    <p><br />Si tuviera alguna duda, comentario o sugerencia sobre la Pol&iacute;tica de Cookies, por favor escriba a:&nbsp;<a href="mailto:fuudie@fuudie.es">fuudie@fuudie.es</a></p>
    <p>&nbsp;</p>
</div>