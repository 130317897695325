<ng-container *ngIf="_structure">
    <!-- <button type="button" class="btn btn-outline-primary" style="display: inline-block;" (click)="addPageInit()">
                <fa-icon [icon]="faPlus"></fa-icon>
            </button> -->
    <div cdkDropList [id]="id" [cdkDropListData]="_structure.cnt" (cdkDropListDropped)="onTaskDrop($event)" class="modal_class" cdkDropListOrientation="horizontal" cdkDragRootElement=".modal_class">
        <ng-container *ngFor="let page of _structure.cnt; let i = index">
            <button cdkDrag type="button" style="display: inline-block;" [ngClass]="{'btn': true, 'btn-outline-primary': collapseId !== i, 'btn-primary': collapseId === i}" (click)="pageClicked(i);" [attr.aria-expanded]="collapseId !== i" attr.aria-controls="{{ 'page_collapse_' + i }}">{{(i+1)}}<fa-icon class="fa fa-arrows-alt"></fa-icon></button>
        </ng-container>
    </div>
    <button type="button" class="btn btn-outline-primary" style="display: inline-block; margin-top: 5px" (click)="addPage()">
                <fa-icon [icon]="faPlus"></fa-icon>
            </button>
    <ng-container *ngFor="let page of _structure.cnt; let i = index">
        <div id="{{ 'page_collapse_' + i }}" [ngbCollapse]="collapseId !== i">
            <!-- <button type=" button" style="display: inline; margin-top: 5px; margin-bottom: 5px;" class="btn btn-light" (click)="edit.emit(page)"><fa-icon [icon]="faEdit"></fa-icon> {{ 'structure-drawer.stylepage' | translate }}</button> -->
            <app-container-structure-form [allowDrag]="false" [contents]=page.cnt [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected" (changeStructure)="changeContentPage(i, $event)"
                (edit)="edit.emit($event)" (indexChange)="updateIndex()" (delete)="deletePage(i)"></app-container-structure-form>
        </div>
    </ng-container>
</ng-container>