import { OnInit, Injectable} from '@angular/core';
import { EstablishmentService } from '../../../shared/services/establishment.service';
import { Establishment } from 'src/app/shared/models/establishment.model';
import { faPlus, faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';

@Injectable()
export abstract class StructureFormComponent<T> implements OnInit {
    public contents: T;
    faPlus = faPlus;
    faTimes = faTimes;
    faEdit = faEdit;
    public establishment: Establishment;
    public languages: string[];
    constructor(public estService: EstablishmentService) {
        this.establishment = this.estService.isEstablishmentSelected;
        this.languages = this.establishment.languages;
    }

    ngOnInit() { }

}
