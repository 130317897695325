import { Injectable, NgZone, PipeTransform } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { UnitMeasure } from '../models/unit-mesure.model';
import { map, tap, debounceTime, switchMap, delay } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../directives/sortable.directive';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { IUnitCRUDService } from '../interfaces/iUnitCRUDService';

interface SearchResult {
    units: UnitMeasure[];
    total: number;
}

interface State {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: SortColumn;
    sortDirection: SortDirection;
    selectedLang: string;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(units: UnitMeasure[], column: SortColumn, direction: string, selectedLang: string = null): UnitMeasure[] {
    if (direction === '' || column === '') {
        return units;
    } else {
        if (selectedLang && column === "n") {
          return [...units].sort((a, b) => {
            const an = a.name_traductions[selectedLang] ? a.name_traductions[selectedLang] : a.name;
            const bn = b.name_traductions[selectedLang] ? b.name_traductions[selectedLang] : b.name;
            const res = compare(`${an}`, `${bn}`);
            return direction === "asc" ? res : -res;
          });
        }
        return [...units].sort((a, b) => {
            const res = compare(`${a[column]}`, `${b[column]}`);
            return direction === 'asc' ? res : -res;
        });
    }
}

function matches(unit: UnitMeasure, term: string, selectedLang: string = null, pipe: PipeTransform = null) {
    if (selectedLang && unit.name_traductions[selectedLang]) {
      return unit.name_traductions[selectedLang].toLowerCase().includes(term.toLowerCase());
    }
    return unit.name.toLowerCase().includes(term.toLowerCase());
}

@Injectable({
  providedIn: "root",
})
export class UnitMeasureUserService implements IUnitCRUDService {
  protected database = "UnitMeasures";
  protected databaseTrash = "UnitMeasuresTrash";
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _units$ = new BehaviorSubject<UnitMeasure[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private units: UnitMeasure[] = [];
  private _state: State = {
    page: 1,
    pageSize: 15,
    searchTerm: "",
    sortColumn: "name",
    sortDirection: "asc",
    selectedLang: localStorage.getItem("cLang"),
  };
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {}
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
  get ref() {
    const user = JSON.parse(localStorage.getItem("user"));
    const establishment = JSON.parse(localStorage.getItem("establishment"));
    return firebase
      .firestore()
      .collection(
        `users/${user.uid}/Establishments/${establishment.id}/${this.database}`
      )
      .doc("Units");
  }
  get refObs() {
    const user = JSON.parse(localStorage.getItem("user"));
    const establishment = JSON.parse(localStorage.getItem("establishment"));
    return this.afs
      .collection<UnitMeasure>(
        `users/${user.uid}/Establishments/${establishment.id}/${this.database}`
      )
      .doc("Units");
  }

  create(unit: UnitMeasure) {
    unit.id = this.afs.createId();
    const value = {};
    value[unit.id] = unit;
    this.ref.set(value, { merge: true });
    return unit;
  }
  edit(unit) {
    const value = {};
    value[unit.id] = unit;
    return this.ref.set(value, { merge: true });
  }
  remove(unit: UnitMeasure) {
    const user = JSON.parse(localStorage.getItem("user"));
    const establishment = JSON.parse(localStorage.getItem("establishment"));
    const value = {};
    value[unit.id] = unit;
    firebase
      .firestore()
      .doc(
        `users/${user.uid}/EstablishmentsTrash/${establishment.id}/${this.databaseTrash}/Units`
      )
      .set(value, { merge: true });
    const value2 = {};
    value2[unit.id] = firebase.firestore.FieldValue.delete();
    return this.ref.update(value2);
  }
  addDish(uid: string, id: string) {
    const dishes = {};
    dishes[id] = true;
    const value = {};
    value[uid] = { dishes: dishes };
    return this.ref.set(value, { merge: true });
  }
  removeDish(uid: string, id: string) {
    const dishes = {};
    dishes[id] = false;
    const value = {};
    value[uid] = { dishes: dishes };
    return this.ref.set(value, { merge: true });
  }
  getAll() {
    return this.refObs.snapshotChanges().pipe(
      map((actions) => {
        const data = actions.payload.data();
        return Object.keys(data || {}).map((key) => {
          return data[key] as UnitMeasure;
        });
      })
    );
  }
  getAllDataTable() {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this._units$.next(result.units);
        this._total$.next(result.total);
      });
    return this.refObs
      .snapshotChanges()
      .pipe(
        map((actions) => {
          const data = actions.payload.data();
          this.units = Object.keys(data || {}).map((key) => {
            return data[key] as UnitMeasure;
          });
          this._search$.next();
        })
      )
      .subscribe((data) => {});
  }
  get data$() {
    return this._units$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }
  get selectedLang() {
    return this._state.selectedLang;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }
  set selectedLang(selectedLang: string) {
    this._set({ selectedLang });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const {
      sortColumn,
      sortDirection,
      pageSize,
      page,
      searchTerm,
      selectedLang
    } = this._state;

    // 1. sort
    let units = sort(this.units, sortColumn, sortDirection, selectedLang);

    // 2. filter
    units = units.filter((unit) =>
      matches(unit, searchTerm, selectedLang)
    );
    const total = units.length;

    // 3. paginate
    units = units.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
    return of({ units, total });
  }
}
