import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { faSearch, faPlus, faEye, faTrash, faEdit, faSpinner, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { CategoryDish } from 'src/app/shared/models/category-dish.model';
import { CategoryDishService } from '../../shared/services/category-dish.service';
import { NgbdSortableHeader, SortEvent, SortColumn, SortDirection } from '../../shared/directives/sortable.directive';
import { CategoryDishModalComponent } from '../category-dish-modal/category-dish-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../shared/services/helper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category-dishes',
  templateUrl: './category-dishes.component.html',
  styleUrls: ['./category-dishes.component.css'],
})
export class CategoryDishesComponent implements OnInit {
  faPlus = faPlus;
  faSearch = faSearch;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faTrash = faTrash;
  faEdit = faEdit;
  faSpinner = faSpinner;
  categoriesDishes$: Observable<CategoryDish[]>;
  total$: Observable<number>;
  selectedLang: string;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(public service: CategoryDishService, public modalService: NgbModal, public tService: TranslateService,
    private helper: HelperService, private _router: Router) {
    this.service.isAdminData = this._router.url.includes('admin');
    this.service.instance.getAllDataTable();
    this.categoriesDishes$ = service.instance.data$;
    this.total$ = service.instance.total$;
    this.tService.onLangChange.subscribe((data) => {
      this.selectedLang = data.lang.toUpperCase();
      this.service.instance.selectedLang = data.lang.toUpperCase();
    });
  }

  ngOnInit(): void {}

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.instance.sortColumn = column;
    this.service.instance.sortDirection = direction;
  }
  getLanguages(cat: CategoryDish) {
    return Object.keys(cat.name_traductions).filter(key => cat.name_traductions[key].name !== '').join(', ');
  }
  add() {
    const modal = this.modalService
      .open(CategoryDishModalComponent, { ariaLabelledBy: 'modal-basic-title' });
  }
  show(cat: CategoryDish) {
    cat.a = !cat.a;
    this.service.instance.setAvailable(cat.id, cat.a);
  }
  edit(cat) {
    const modal = this.modalService
      .open(CategoryDishModalComponent, { ariaLabelledBy: 'modal-basic-title' });
    if (cat) {
      modal.componentInstance.editElement = cat;
    }
    modal.result.then(
      (result) => {
      },
      (reason) => {
      }
    );
  }
  delete(cat) {
    this.helper.openConfirmationDeleteData().then(result => {
      if (result) {
        this.service.instance.remove(cat);
      }
    });
  }
  getNumberDishes(cd: any) {
    return (cd.dishes) ? Object.keys(cd.dishes).filter(key => cd.dishes[key]).length : 0;
  }
}
