import { Component, Input, ContentChildren } from '@angular/core';
import { StructureTemplateComponent } from '../../shared/structure-template.component';
import { Page, Menu } from '../../../../shared/models/menu.model';
import { CategoryDish } from '../../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../../shared/models/unit-mesure.model';
import { Dish } from '../../../../shared/models/dish.model';
import { Allergen } from '../../../../shared/models/allergen.model';
import { StyleDrawerComponent } from '../../style-drawer/style-drawer.component';
@Component({
  selector: 'app-page-standard-template',
  template: `
          <app-container-template container-host
              [menu]="menu"
              [contents]="contents.cnt"
              [categories]="categories"
              [units]="units"
              [allergens]="allergens"
              [dishes]="dishes"
              [languages]="languages"
              [language_selected]="language_selected"></app-container-template>
    `,
    styles: ['']
})
export class PageStandardTemplateComponent implements StructureTemplateComponent<Page> {
  @Input() menu: Menu;
  @Input() type: string;
  @Input() contents: Page;
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() dishes: Dish[];
  @Input() allergens: Allergen[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @ContentChildren('styleDrawer') styleDrawer: StyleDrawerComponent;
  constructor() { }
}
