import { Injectable } from '@angular/core';
import { CollectionMenuTemplateUserService } from './collection-menu-template-user.service';
import { CollectionMenuTemplateAdminService } from './collection-menu-template-admin.service';
import { ITemplateCollectionCRUDService } from '../interfaces/iTemplateCollectionCRUDService';

@Injectable({
  providedIn: "root",
})
export class CollectionMenuTemplateService {

  public isAdminData = false;

  constructor (
    private _collectionMenutemplateUserService: CollectionMenuTemplateUserService,
    private _ccollectionMenutemplateAdminService: CollectionMenuTemplateAdminService
  ) {}

  public get instance(): ITemplateCollectionCRUDService {
    return this.isAdminData ? this._ccollectionMenutemplateAdminService : this._collectionMenutemplateUserService;
  }
}
