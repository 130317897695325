<form class="container font" [formGroup]="mediaGalleryForm" #form>
    <div class="row">
        <div class="col-sm column1">
            <div class="form-group">
                <div class="formGroup">
                    <div class="input-group mb-3">
                        <input formControlName="name" type="text" class="formControl form-control newform" placeholder="{{ 'collection-menu-template.collectionname' | translate}}">
                        <div class="input-group-append">
                            <button type="button" class="btn btn-outline-primary btntr" (click)="(collapseId === 1) ? collapseId = 0 : collapseId = 1" [attr.aria-expanded]="collapseId !== 1" aria-controls="nameTraductions">
                        <fa-icon class="fas fa-language"></fa-icon>
                    </button>
                        </div>
                    </div>
                </div>
                <div id="nameTraductions" [ngbCollapse]="collapseId !== 1">
                    <h6>{{ 'common.nametraduction' | translate}}</h6>
                    <div class="formGroup" *ngFor="let lan of LANGUAGES">
                        <input formControlName="{{'name_' + lan }}" type="text" class="formControl newform" placeholder="{{ ('common.namein' | translate) + lan }}">
                    </div>
                </div>
            </div>
            <h6 class="font">{{'config-form.background' | translate}}</h6>
            <div class="form-group">
                <label class="form-check-label" style="width: 50%; margin: auto;">
                    <app-media-add [location]="locationFiles" (valueChange)="mediaFile = $event" [src]="mediaFile"></app-media-add>
                </label>
            </div>
        </div>
        <div class="col-sm">
            <div class="formGroup">
                <div class="input-group mb-3">
                    <input formControlName="words" type="text" (keypress)="keyPressWord($event)" class="formControl form-control newform" placeholder="{{ 'menu-template-config-form.keywords' | translate}}">
                </div>
            </div>
            <span class="btn btn-primary savebutton mr-3 mb-3" *ngFor="let m of words">
                {{ m }}
            <button type="button" class="btn btn-outline-primary btntr" (click)="removeWord(m)"><fa-icon class="fas fa-times"></fa-icon></button>
            </span>
        </div>
    </div>
</form>