import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Text, Structure } from '../../../../../shared/models/menu.model';
import { EstablishmentService } from '../../../../../shared/services/establishment.service';
import { StructureFormComponent } from '../../../shared/structure-form.component';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CategoryDish } from '../../../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../../../shared/models/unit-mesure.model';
import { Dish } from '../../../../../shared/models/dish.model';
import { Allergen } from '../../../../../shared/models/allergen.model';

@Component({
  selector: "app-text-structure-form",
  templateUrl: "./text-structure-form.component.html",
  styleUrls: ["./text-structure-form.component.css"],
})
export class TextStructureFormComponent extends StructureFormComponent<Text> {
  collapsed = true;
  @Input() contents: Text;
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() dishes: Dish[];
  @Input() allergens: Allergen[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @Output() delete = new EventEmitter();
  @Output() edit = new EventEmitter<Structure>();
  @Output() indexChange = new EventEmitter();
  constructor(public estService: EstablishmentService) {
    super(estService);
  }
  setText($event) {
    this.contents.tx = $event.target.value;
  }
  setTextTrad(lang, $event) {
    this.contents.txt[lang] = $event.target.value;
  }
  del() {
    this.delete.emit();
  }
  changeIndex(checked) {
    this.contents.i = checked;
    if (checked) {
      this.contents.id = 'id_' + Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8);
    } else {
      // this.contents.id = '';
    }
    this.indexChange.emit();
  }
}
