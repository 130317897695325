import { Component, Input, OnInit, ViewChild, ComponentFactoryResolver, SimpleChanges, OnChanges, ContentChildren } from '@angular/core';
import { StructureTemplateComponent } from '../shared/structure-template.component';
import { CategoryDish } from '../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../shared/models/unit-mesure.model';
import { Allergen } from '../../../shared/models/allergen.model';
import { Dish } from '../../../shared/models/dish.model';
import { ImageDirective } from '../directives/image.directive';
import { Photo, Menu } from '../../../shared/models/menu.model';
import { REGISTRY } from '../shared/register.component';
import { StyleDrawerComponent } from '../style-drawer/style-drawer.component';
import { DataService } from '../../../shared/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: "app-image-template",
  template: `
  <div [ngClass]="{'template-container': showUtils}">
    <div class="editing" *ngIf="showUtils">
      <!-- <button type="button" class="btn btn-light enlinia" (click)="del()"><fa-icon class="fa fa-times"></fa-icon></button> -->
      <button type="button" class="btn btn-light enlinia" (click)="code()"><fa-icon class="fa fa-code"></fa-icon></button>
      <button type="button" class="btn btn-light enlinia" (click)="edit()"><fa-icon class="fa fa-bold"></fa-icon></button>
    </div><ng-template image-host></ng-template></div> `
})
export class ImageTemplateComponent
  implements OnInit, OnChanges, StructureTemplateComponent<Photo> {
  @Input() menu: Menu;
  @Input() contents: Photo;
  @Input() type: string;
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() allergens: Allergen[];
  @Input() dishes: Dish[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @Input() showUtils: boolean;
  @ContentChildren('styleDrawer') styleDrawer: StyleDrawerComponent;
  @ViewChild(ImageDirective, { static: true }) imageHost: ImageDirective;
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private data: DataService, private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadComponent();
  }
  ngOnInit() {
    this.loadComponent();
    this.showUtils = this.router.url.endsWith('menus');
  }

  loadComponent() {
    if (this.contents && this.languages && this.imageHost) {
      if (!this.type) {
        this.contents.dt = "Standard";
        this.type = "Standard";
      }
      let t = `Image${this.contents.dt}TemplateComponent`;
      let componentFactory;
      try {
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(REGISTRY.get(t));
      } catch {
        t = `ImageStandardTemplateComponent`;
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(REGISTRY.get(t));
      }

      const viewContainerRef = this.imageHost.viewContainerRef;
      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent(componentFactory);
      (<StructureTemplateComponent<Photo>>(
        componentRef.instance
      )).contents = this.contents;
      (<StructureTemplateComponent<Photo>>(
        componentRef.instance
      )).languages = this.languages;
      (<StructureTemplateComponent<Photo>>componentRef.instance).language_selected = this.language_selected;
    }
  }
  edit() {
    this.data.changeStructureStyle(this.contents);
  }
  del() {

  }
  code() {
    this.data.changeStructureCode(this.contents);
  }
}
