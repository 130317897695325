<form class="container font" [formGroup]="menuTemplateConfigForm" #form>
    <div class="row">
        <div class="col-sm column1">
            <div class="formGroup">
                <div class="input-group mb-3">
                    <div class="form-check">
                        <label class="form-check-label">
                        <input type="checkbox" class="larger" formControlName="home" [checked]="editingMenuTemplateConfig.c.h" (change)="editingMenuTemplateConfig.c.h = $event.target.checked"> {{'menu-template-config-form.showhome' | translate}}
                    </label>
                    </div>
                </div>
            </div>
            <div class="formGroup">
                <div class="input-group mb-3">
                    <div class="form-check">
                        <label class="form-check-label">
                        <input type="checkbox" class="larger" formControlName="template" [checked]="editingMenuTemplateConfig.c.t" (change)="editingMenuTemplateConfig.c.t = $event.target.checked"> {{'menu-template-config-form.showtemplate' | translate}}
                    </label>
                    </div>
                </div>
            </div>
            <div class="formGroup">
                <div class="input-group mb-3">
                    <ng-select appendTo="body" class="custom" [items]="collections$ | async" bindLabel="name" bindValue="id" formControlName="collections" placeholder="{{'menu-template-config-form.collections' | translate}}" [multiple]="true"></ng-select>
                    <div class="input-group-append">
                        <button type="button" class="btn btn-outline-primary btntr1" (click)="openFormCollections()">
                        <fa-icon class="fas fa-plus"></fa-icon>
                    </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm">
            <div class="formGroup">
                <div class="input-group mb-3">
                    <input formControlName="words" type="text" (keypress)="keyPressWord($event)" class="formControl form-control newform" placeholder="{{ 'menu-template-config-form.keywords' | translate}}">
                </div>
            </div>
            <span class="btn btn-primary savebutton mr-3 mb-3" *ngFor="let m of words">
                {{ m }}
            <button type="button" class="btn btn-outline-primary btntr" (click)="removeWord(m)"><fa-icon class="fas fa-times"></fa-icon></button>
            </span>
        </div>
    </div>
</form>