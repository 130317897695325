import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { faSearch, faPlus, faEye, faTrash, faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../shared/directives/sortable.directive';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Establishment } from '../../shared/models/establishment.model';
import { EstablishmentService } from '../../shared/services/establishment.service';
import { EstablishmentModalComponent } from '../establishment-modal/establishment-modal.component';

@Component({
  selector: 'app-establishments',
  templateUrl: './establishments.component.html',
  styleUrls: ['./establishments.component.css']
})
export class EstablishmentsComponent implements OnInit {
  faPlus = faPlus;
  faSearch = faSearch;
  faEye = faEye;
  faTrash = faTrash;
  faEdit = faEdit;
  faSpinner = faSpinner;
  establishments$: Observable<Establishment[]>;
  total$: Observable<number>;
  selectedLang: string;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(public service: EstablishmentService, public modalService: NgbModal, private activeModal: NgbActiveModal, private tService: TranslateService) {
    this.tService.onLangChange.subscribe((data) => {
      this.selectedLang = data.lang.toUpperCase();
      this.service.selectedLang = data.lang.toUpperCase();
    });
    this.service.getAllDataTable();
    this.establishments$ = service.establishments$;
    this.total$ = service.total$;
  }

  ngOnInit(): void { }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  getLanguages(est: Establishment) {
    return est.languages.join(', ');
  }
  add() {
    const modal = this.modalService
      .open(EstablishmentModalComponent, { ariaLabelledBy: 'modal-basic-title' });
  }
  show(est) {

  }
  edit(est) {
    const modal = this.modalService
      .open(EstablishmentModalComponent, { ariaLabelledBy: 'modal-basic-title' });
    if (est) {
      modal.componentInstance.editElement = est;
    }
    modal.result.then(
      (result) => {
      },
      (reason) => {
      }
    );
  }
  delete(est) {
    this.service.remove(est);
  }
  // getNumberDishes(ud: any) {
  //   return (ud.dishes) ? Object.keys(ud.dishes).filter(key => ud.dishes[key]).length : 0;
  // }
}
