import { Component, Input, ContentChildren } from '@angular/core';
import { StructureTemplateComponent } from '../../shared/structure-template.component';
import { Photo, Menu } from '../../../../shared/models/menu.model';
import { CategoryDish } from '../../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../../shared/models/unit-mesure.model';
import { Dish } from '../../../../shared/models/dish.model';
import { Allergen } from '../../../../shared/models/allergen.model';
import { StyleDrawerComponent } from '../../style-drawer/style-drawer.component';
@Component({
  selector: "app-image-standard-template",
  template: `
    <div
      *ngIf="_contents?.s"
      id="{{ _contents.id }}"
      style="width: 100%"
      [style.height]="_contents.s.h"
      [style.text-align]="_contents.s.hp"
      [style.padding]="_contents.s.p"
      [style.margin-bottom]="_contents.s.m"
      [style.margin-top]="_contents.s.m"
      [style.background-color]="_contents.s.bc"
      [style.border-radius]="_contents.s.br"
    >
      <img
        class="border-solid"
        [src]="_contents.src"
        [style.width]="_contents.s.w"
        [style.height]="_contents.s.h"
        [style.border-color]="_contents.s.boc"
        [style.border-width]="_contents.s.bs"
        [style.border-radius]="_contents.s.br"
        [style.margin-left]="(_contents.s.hp === 'center') ? 'auto' : (_contents.s.hp === 'justify')? 'auto': (_contents.s.hp === 'left')? '0px' : 'auto'"
        [style.margin-right]="(_contents.s.hp === 'center') ? 'auto' : (_contents.s.hp === 'justify')? 'auto': (_contents.s.hp === 'left')? 'auto' : '0px'"
      />
    </div>
  `,
  styles: [
    `
      .border-solid {
        border-style: solid;
      }
    `,
  ],
})
export class ImageStandardTemplateComponent
  implements StructureTemplateComponent<Photo> {
  _contents: Photo;
  @Input() menu: Menu;
  @Input() type: string;
  @Input() set contents(v: Photo) {
    this._contents = v;
    if (!this._contents.id) {
      this._contents.id = 'id_' + Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8);
    }
    this._contents.s = {
      hp: v.s?.hp ? v.s.hp : "center",
      p: v.s?.p ? v.s.p : "5px",
      m: v.s?.m ? v.s.m : "10px",
      bc: v.s?.bc ? v.s.bc : "transparent",
      boc: v.s?.boc ? v.s.boc : "black",
      bs: v.s?.bs ? v.s.bs : "0px",
      br: v.s?.br ? v.s.br : "5px",
      w: v.s?.w ? v.s.w : "100%",
      h: v.s?.h ? v.s.h : "auto",
    };
  }
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() dishes: Dish[];
  @Input() allergens: Allergen[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @ContentChildren('styleDrawer') styleDrawer: StyleDrawerComponent;
  constructor() {}
}
