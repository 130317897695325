<div class="modal-header font">
    <h4 class="modal-title" id="modal-basic-title">
        {{ (menu && menu.nt && menu.nt[selectedLanguage])? menu.nt[selectedLanguage] : (menu && menu.n) ? menu.n : 'menu-template-modal.newmenutemplate' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <app-menu-view-form #form [menu]="menu"></app-menu-view-form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary savebutton" (click)="activeModal.dismiss('ok click')">{{'common.back' | translate}}</button>
</div>