<div cdkDrag *ngIf="contents" [id]="'edit_'+contents.id" class="formGroup" style="border: #bebebe solid 1px; border-radius: 5px; padding: 5px;  margin-bottom: 2px!important">
    <div>
        <button type=" button " style="display: inline;" class="btn btn-light " (click)="del()"><fa-icon [icon]="faTimes"></fa-icon></button>
        <h6 style="display: inline;">{{'structure-drawer.dish' | translate}}</h6>
        <button type=" button" style="display: inline;" class="btn btn-light" (click)="edit.emit(contents)"><fa-icon [icon]="faEdit"></fa-icon></button>
        <button type=" button" style="display: inline;" class="btn btn-light"><fa-icon class="fa fa-arrows-alt"></fa-icon></button>
    </div>
    <div class="input-group">
        <ng-select appendTo="body" style="width: 100%" (change)="setDish($event)" class="custom3" [items]="dishes" bindLabel="n" bindValue="id" placeholder="{{'structure-drawer.dish' | translate}}*" [multiple]="false" [(ngModel)]="contents.id"></ng-select>
    </div>
</div>