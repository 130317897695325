import { Component, Input, OnInit, ViewChild, ComponentFactoryResolver, SimpleChanges, OnChanges, ContentChildren } from '@angular/core';
import { StructureTemplateComponent } from '../shared/structure-template.component';
import { CategoryDish } from '../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../shared/models/unit-mesure.model';
import { Allergen } from '../../../shared/models/allergen.model';
import { Dish } from '../../../shared/models/dish.model';
import { Menu } from '../../../shared/models/menu.model';
import { MenuDirective } from '../directives/menu.directive';
import { REGISTRY } from '../shared/register.component';
import { StyleDrawerComponent } from '../style-drawer/style-drawer.component';

@Component({
  selector: 'app-menu-template',
  template: `
                <ng-template menu-host [showUtils]="showUtils"></ng-template>
            `
})
export class MenuTemplateComponent implements OnInit, OnChanges, StructureTemplateComponent<Menu> {
  @Input() menu: Menu;
  @Input() contents: Menu;
  @Input() type: string;
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() allergens: Allergen[];
  @Input() dishes: Dish[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @Input() showUtils: boolean;
  @Input() indexes: { id: string, name: string, trds: any }[];
  @ContentChildren('styleDrawer') styleDrawer: StyleDrawerComponent;
  @ViewChild(MenuDirective, {static: true}) menuHost: MenuDirective;
  @Input() showIndexBtn: boolean;
  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnChanges(changes: SimpleChanges): void {
      this.loadComponent();
  }
  ngOnInit() {
    this.loadComponent();
    // this.showUtils = this.menuHost.showUtils;
  }

  loadComponent() {
    if (this.contents && this.languages && this.menuHost) {
        if (!this.type) {
            this.contents.dt = 'Standard';
            this.type = "Standard";
        }
        let t = `Menu${this.type}TemplateComponent`;
        let componentFactory;
        try {
          componentFactory = this.componentFactoryResolver.resolveComponentFactory(REGISTRY.get(t));
        } catch {
          t = `MenuStandardTemplateComponent`;
          componentFactory = this.componentFactoryResolver.resolveComponentFactory(REGISTRY.get(t));
        }
        const viewMenuRef = this.menuHost.viewContainerRef;
        viewMenuRef.clear();

        const componentRef = viewMenuRef.createComponent(componentFactory);
        (<StructureTemplateComponent<Menu>>componentRef.instance).menu = this.menu;
        (<StructureTemplateComponent<Menu>>componentRef.instance).indexes = this.indexes;
        (<StructureTemplateComponent<Menu>>componentRef.instance).contents = this.contents;
        (<StructureTemplateComponent<Menu>>componentRef.instance).categories = this.categories;
        (<StructureTemplateComponent<Menu>>componentRef.instance).units = this.units;
        (<StructureTemplateComponent<Menu>>componentRef.instance).allergens = this.allergens;
        (<StructureTemplateComponent<Menu>>componentRef.instance).dishes = this.dishes;
        (<StructureTemplateComponent<Menu>>componentRef.instance).languages = this.languages;
        (<StructureTemplateComponent<Menu>>componentRef.instance).language_selected = this.language_selected;
        (<StructureTemplateComponent<Menu>>componentRef.instance).showIndexBtn = this.showIndexBtn;
        (<StructureTemplateComponent<Menu>>componentRef.instance).styleDrawer = this.styleDrawer;
    }
  }
}
