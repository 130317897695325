import { Component, OnInit, NgZone } from '@angular/core';
import { Menu } from '../../shared/models/menu.model';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-template-config-modal',
  templateUrl: './menu-template-config-modal.component.html',
  styleUrls: ['./menu-template-config-modal.component.css']
})
export class MenuTemplateConfigModalComponent implements OnInit {
  editElement: Menu;
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public router: Router,
    public ngZone: NgZone
  ) { }
  ngOnInit(): void {
  }

}
