import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AllergenService } from '../../shared/services/allergen.service';
import { Allergen } from '../../shared/models/allergen.model';
import { LANGUAGES } from '../../shared/data/data';

@Component({
  selector: 'app-allergen-form',
  templateUrl: './allergen-form.component.html',
  styleUrls: ['./allergen-form.component.css']
})
export class AllergenFormComponent implements OnInit {
  public collapseId = 0;
  faPlus = faPlus;
  faTrash = faTrash;
  allergenForm: FormGroup;
  editingAllergen: Allergen;
  _elementToEdit: Allergen;
  languages: {code: string, name: string}[];
  @ViewChild('form') dishRefForm: ElementRef;
  @Input() set elementToEdit(value: Allergen) {
    this._elementToEdit = value;
    if (this._elementToEdit) {
      this.editElemet(this._elementToEdit);
    }
    this.languages = LANGUAGES.slice(0);
  }
  @Output() resetForm = new EventEmitter<Allergen>();
  constructor(
    private fb: FormBuilder,
    private allergenService: AllergenService
  ) {
    this.allergenForm = this.fb.group({
      icon: ['', Validators.required],
      name: ['', Validators.required],
      description: ['', Validators.required],
    });
    LANGUAGES.forEach((li) => {
      this.allergenForm.addControl('name_' + li.code, new FormControl('', null));
    });
    LANGUAGES.forEach((li) => {
      this.allergenForm.addControl('description_' + li.code, new FormControl('', null));
    });
  }
  submit() {
    this.dishRefForm.nativeElement.submit();
  }
  ngOnInit(): void { }
  saveAllergen() {
    let cdish = null;
    if (this.allergenForm.valid) {
      cdish = this.getAllergenFromForm();
      if (this.editingAllergen) {
        cdish.id = this.editingAllergen.id;
        this.allergenService.edit(cdish);
        this.editingAllergen = null;
      } else {
        cdish = this.allergenService.create(cdish);
      }
      this.reset(cdish);
    }
  }
  reset(cdish: Allergen) {
    this.editingAllergen = null;
    this.allergenForm.reset();
    this.resetForm.emit(cdish);
  }
  editElemet(element) {
    this.editingAllergen = element;
    this.allergenForm.controls['icon'].setValue(this.editingAllergen.icon);
    this.allergenForm.controls['name'].setValue(this.editingAllergen.name);
    LANGUAGES.forEach((li) => {
      this.allergenForm.controls['name_' + li.code].setValue(
        (this.editingAllergen.name_traductions[li.code]) ? this.editingAllergen.name_traductions[li.code] : '');
    });
    this.allergenForm.controls['description'].setValue(this.editingAllergen.description);
    LANGUAGES.forEach((li) => {
      this.allergenForm.controls['description_' + li.code].setValue(
        (this.editingAllergen.description_traductions[li.code]) ? this.editingAllergen.description_traductions[li.code] : '');
    });
  }
  getAllergenFromForm() {
    const obj: Allergen = {
      id: '',
      icon: this.allergenForm.get('icon').value,
      name: this.allergenForm.get('name').value,
      name_traductions: {},
      description: this.allergenForm.get('description').value,
      description_traductions: {}
    };
    LANGUAGES.forEach((li) => {
      obj.name_traductions[li.code] = this.allergenForm.get('name_' + li.code).value;
    });
    LANGUAGES.forEach((li) => {
      obj.description_traductions[li.code] = this.allergenForm.get('description_' + li.code).value;
    });
    return obj;
  }
}
