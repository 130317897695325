import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { faSearch, faPlus, faEye, faTrash, faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../shared/directives/sortable.directive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Allergen } from '../../shared/models/allergen.model';
import { AllergenService } from '../../shared/services/allergen.service';
import { AuthService } from '../../shared/services/auth.service';
import { AllergenModalComponent } from '../allergen-modal/allergen-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../shared/services/helper.service';

@Component({
  selector: 'app-allergens',
  templateUrl: './allergens.component.html',
  styleUrls: ['./allergens.component.css']
})
export class AllergensComponent implements OnInit {
  faPlus = faPlus;
  faSearch = faSearch;
  faEye = faEye;
  faTrash = faTrash;
  faEdit = faEdit;
  faSpinner = faSpinner;
  allergens$: Observable<Allergen[]>;
  total$: Observable<number>;
  selectedLang: string;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(public service: AllergenService,
    public modalService: NgbModal,
    public authService: AuthService,
    private tService: TranslateService,
    private helper: HelperService) {
    this.service.getAllDataTable();
    this.allergens$ = service.allergens$;
    this.total$ = service.total$;
    this.tService.onLangChange.subscribe((data) => {
      this.selectedLang = data.lang.toUpperCase();
      this.service.selectedLang = data.lang.toUpperCase();
    });
  }

  ngOnInit(): void { }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  getLanguages(allergen: Allergen) {
    return Object.keys(allergen.name_traductions).filter(key => allergen.name_traductions[key].name !== '').join(', ');
  }
  add() {
    const modal = this.modalService
      .open(AllergenModalComponent, { ariaLabelledBy: 'modal-basic-title' });
  }
  show(al) {

  }
  edit(al) {
    const modal = this.modalService
      .open(AllergenModalComponent, { ariaLabelledBy: 'modal-basic-title' });
    if (al) {
      modal.componentInstance.editElement = al;
    }
    modal.result.then(
      (result) => {
      },
      (reason) => {
      }
    );
  }
  delete(al) {
    this.helper.openConfirmationDeleteData().then(result => {
      if (result) {
        this.service.remove(al);
      }
    });
  }
}
