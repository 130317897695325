import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Style, Structure, Menu, Page, Container, Table } from '../../../shared/models/menu.model';
import { HttpClient } from '@angular/common/http';
import { FONTS } from '../../../shared/data/data';
@Component({
  selector: 'app-style-drawer',
  templateUrl: './style-drawer.component.html',
  styleUrls: ['./style-drawer.component.css']
})
export class StyleDrawerComponent implements OnInit, OnChanges {
  @Input() menu: Menu;
  @Input() contents: Structure;
  copyStyle: Structure;
  fonts: { code, name }[] = [];
  templates = [{ code: 'Standard', name: 'Standard'}, {code: 'Classic', name: 'Classic'}];
  @Output() templateChange = new EventEmitter();
  constructor(private http: HttpClient) {
    this.fonts = FONTS;
    // this.http.get('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBwIX97bVWr3-6AIUvGkcNnmFgirefZ6Sw').subscribe((data: any) => {
    //   console.log(data);
    //   this.fonts = data.items.map(it => it.family);
    // });
   }
  ngOnChanges(changes: SimpleChanges): void {
    this.copyStyle = Object.assign({}, this.contents);
    this.drawForm();
  }

  ngOnInit(): void {
  }
  drawForm() {
    // console.log(this.contents);
    if (this.contents && this.contents.s) {
    }
  }
  changeTemplate(code) {
    this.contents.dt = code;
    // if (this.menu) {
    //   const p = this.getParent(this.contents.id);
    //   if (p !== null) {
    //     const a = Object.assign([], p.c.cnt);
    //     a[p.index] = this.contents;
    //     p.c.cnt = a;
    //     console.log(a);
    //   }
    //   console.log(p.c.cnt[p.index]);
    // }
    // this.templateChange.emit();
    // this.contents.s.f2 = 'pr';
    // console.log(this.contents);
  }
  getParent(id): {c: Container, index: number} {
    const p = null;
    let i = 0;
    while (i < this.menu.cnt.length) {
      const parent = this.getParentContainer(this.menu.cnt[i].cnt, id);
      // console.log(parent);
      if (parent) {
        return parent;
      }
      ++i;
    }
    return null;
  }
  getParentContainer(c: Container, id): {c: Container, index: number} {
    let i = 0;
    while (i < c.cnt.length) {
      if (c.cnt[i].t === 'container') {
        const p = this.getParentContainer(c.cnt[i] as Container, id);
        if (p !== null) {
          return p;
        }
      } else if (c.cnt[i].t === 'table') {
        let j = 0;
        const t = c.cnt[i] as Table;
        while (j < t.cnt.length) {
          let k = 0;
          while (k < t.cnt[j].length) {
            const p = this.getParentContainer(t.cnt[j][k], id);
            if (p !== null) {
              return p;
            }
            ++k;
          }
          ++j;
        }
      } else if (c.cnt[i].id === id) {
        return {c: c, index: i};
      }
      ++i;
    }
    return null;
  }
  discardChanges() {
    if (this.contents.dt) { this.contents.dt = this.copyStyle.dt; }
    if (this.contents.s.h) { this.contents.s.h = this.copyStyle.s.h; }
    if (this.contents.s.w) { this.contents.s.w = this.copyStyle.s.w; }
    if (this.contents.s.hp) { this.contents.s.hp = this.copyStyle.s.hp; }
    if (this.contents.s.f) { this.contents.s.f = this.copyStyle.s.f; }
    if (this.contents.s.s) { this.contents.s.s = this.copyStyle.s.s; }
    if (this.contents.s.c) { this.contents.s.c = this.copyStyle.s.c; }
    if (this.contents.s.bc) { this.contents.s.bc = this.copyStyle.s.bc; }
    if (this.contents.s.p) { this.contents.s.p = this.copyStyle.s.p; }
    if (this.contents.s.m) { this.contents.s.m = this.copyStyle.s.m; }
    if (this.contents.s.lw) { this.contents.s.lw = this.copyStyle.s.lw; }
    if (this.contents.s.bs) { this.contents.s.bs = this.copyStyle.s.bs; }
    if (this.contents.s.br) { this.contents.s.br = this.copyStyle.s.br; }
    if (this.contents.s.boc) { this.contents.s.boc = this.copyStyle.s.boc; }
  }
}
