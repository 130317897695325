import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MailService } from '../../shared/services/mail.service';
import { User } from '../../shared/services/user';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})

export class HelpComponent implements OnInit {
  name: string;
  email: string;
  restaurant: string;
  phone: string;
  message: string;
  currentUser: User = null;

  constructor(private authService: AuthService, private mailService: MailService) {}

  ngOnInit() {
    this.authService.GetUser().then((u) => {
      this.currentUser = u;
    });
  }

  /**
   * Process the form we have. Send to whatever backend
   * Only alerting for now
   */
  processForm() {
  }
  enviar() {
    if (this.email && this.message && this.name && this.restaurant && this.phone) {
      this.mailService.SendEmailbyUser(this.currentUser, {
        id: '',
        e: this.email,
        m: this.message,
        n: this.name,
        r: this.restaurant,
        p: this.phone
      }).then(() => {
        this.email = ''; this.message = ''; this.name = ''; this.restaurant = ''; this.phone = '';
        alert('OK');
      }, (reason) => {
        alert(reason);
      });
    }
  }
}
