import { Component, OnInit, Input } from '@angular/core';
import { Menu, Structure, Text, Container, Table, Page } from '../../../shared/models/menu.model';
import { Establishment } from 'src/app/shared/models/establishment.model';
import { EstablishmentService } from '../../../shared/services/establishment.service';
import { CategoryDish } from '../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../shared/models/unit-mesure.model';
import { Dish } from '../../../shared/models/dish.model';
import { Allergen } from '../../../shared/models/allergen.model';

@Component({
  selector: "app-result-drawer",
  templateUrl: "./result-drawer.component.html",
  styleUrls: ["./result-drawer.component.css"],
})
export class ResultDrawerComponent implements OnInit {
  establishment: Establishment;
  _languages: string[] = [];
  _structure: Menu;
  _categories: CategoryDish[];
  _units: UnitMeasure[];
  _dishes: Dish[];
  _allergens: Allergen[];
  _language_selected: string;
  _indexes: { id: string, name: string, trds: any}[];
  _showIndexBtn: boolean;
  _showUtils = false;
  @Input() set showUtils (v: boolean) {
    this._showUtils = v;
  }
  @Input() set showIndexBtn (v: boolean) {
    this._showIndexBtn = v;
  }
  @Input() set languages(languages: string[]) {
    this._languages = languages;
  }
  @Input() set structure(menu: Menu) {
    this._structure = menu;
    if (menu) {
      this._languages = menu.l;
      if (menu.hi) {
        this.calculateIndex();
      }
    }
  }
  @Input() set categories(categories: CategoryDish[]) {
    this._categories = categories;
  }
  @Input() set units(units: UnitMeasure[]) {
    this._units = units;
  }
  @Input() set dishes(dishes: Dish[]) {
    this._dishes = dishes;
  }
  @Input() set allergens(allergens: Allergen[]) {
    this._allergens = allergens;
  }
  @Input() set language_selected(language: string) {
    if (language === "auto") {
      if (this._structure) {
        language = this._structure.l[0];
      }
      try {
        const userLang = navigator.language.split("-")[0].toUpperCase() || "CA";
        if (
          this._structure &&
          this._structure.l.findIndex((li) => li === userLang) < 0
        ) {
          language = this._structure.l[0];
        } else {
          language = userLang;
        }
      } catch (error) {}
    }
    this._language_selected = language;
  }
 @Input() set indexes(v: { id: string, name: string, trds: any}[]) {
   this._indexes = v;
 }
  constructor(public estService: EstablishmentService) {
    this.establishment = this.estService.isEstablishmentSelected;
  }

  ngOnInit(): void {
    if (this._structure && this._structure.hi) {
      this.calculateIndex();
    }
  }

  calculateIndex() {
      const idxs = [];
      this._structure.cnt.forEach(pi => {
          const idx = this.getIndex(pi, []);
          idx.filter(i => i.id !== '').forEach(i => idxs.push(i));
      });
      this._indexes = idxs;
  }
  getIndex(s: Structure, indexes: string[]): {id: string, name: string, trds: any}[] {
      const idxs = [];
      if (s.t === 'text') {
          if ((s as Text).i) {
              const n = ((s as Text).txt[this.language_selected]) ? (s as Text).txt[this.language_selected] : (s as Text).tx ;
              return [{ id: (s as Text).id, name: (s as Text).tx, trds: (s as Text).txt}];
          }
      } else if (s.t === 'container') {
          (s as Container).cnt.forEach(ci => {
              const idx = this.getIndex(ci, []);
              idx.filter(i => i.id !== '').forEach(i => idxs.push(i));
          });
          return idxs;
      } else if (s.t === 'table') {
          (s as Table).cnt.forEach(cis => {
              cis.forEach(ci => {
                  const idx = this.getIndex(ci, []);
                  idx.filter(i => i.id !== '').forEach(i => idxs.push(i));
              });
          });
          return idxs;
      } else if (s.t === 'page') {
          const idx = this.getIndex((s as Page).cnt, []);
          idx.filter(i => i.id !== '').forEach(i => idxs.push(i));
          return idxs;
      }
      return [{ id: '', name: '', trds: {}}];
  }
}
