import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { faEdit, faTimes, faEye } from "@fortawesome/free-solid-svg-icons";
import { User, BillingInfo, CreditCardInfo } from '../../shared/services/user';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { countryListAllIsoData } from '../../shared/data/data';
import * as Stripe from 'stripe';
import { CreditCardValidators } from 'angular-cc-library';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountType } from '../../shared/models/account-type.model';
import { AccountTypeModalComponent } from '../account-type-modal/account-type-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDeleteAccountComponent } from '../confirmation-delete-account/confirmation-delete-account.component';
import { HelperService } from '../../shared/services/helper.service';
import { InvoiceService } from '../../shared/services/invoices.service';
import { Observable } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from '../../shared/directives/sortable.directive';
import { PdfMakeWrapper, Img, Txt, Cell, Table, Polyline, Canvas, Line, Rect, Columns } from 'pdfmake-wrapper';
import pdfFonts from 'pdfmake/build/vfs_fonts'; // fonts provided for pdfmake
import { DatePipe } from '@angular/common';

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  uInfoForm: FormGroup;
  uPassForm: FormGroup;
  uBillForm: FormGroup;
  uCardForm: FormGroup;
  faEdit = faEdit;
  faTimes = faTimes;
  faEye = faEye;
  editUserInfo = false;
  editUserPass = false;
  editBillingInfo = false;
  editCreditCard = false;
  editPhotoInfo = false;
  currentUser: User = null;
  currentBillingInfo: BillingInfo = null;
  currentCreditCard: CreditCardInfo = null;
  countries = countryListAllIsoData;
  currentPlan: AccountType = null;
  photoUrlNew: string = null;
  selectedLang: string;
  invoices$: Observable<any[]>;
  total$: Observable<number>;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    public modalService: NgbModal,
    private tService: TranslateService,
    private helper: HelperService,
    public iService: InvoiceService,
    private datePipe: DatePipe
  ) {
    this.tService.onLangChange.subscribe((data) => {
      this.selectedLang = data.lang.toUpperCase();
      // this.service.selectedLang = data.lang.toUpperCase();
    });
    this.uInfoForm = this.fb.group({
      firstName: ["", Validators.required],
      emailOther: ["", [Validators.email]],
    });
    this.uPassForm = this.fb.group(
      {
        oldPass: ["", [Validators.required]],
        newPass: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/
            ),
          ],
        ],
        confirmNewPass: ["", Validators.required],
      },
      { validators: checkPasswords }
    );
    this.uBillForm = this.fb.group(
      {
        name: ["", [Validators.required]],
        line1: ["", [Validators.required]],
        city: ["", [Validators.required]],
        postal_code: ["", [Validators.required]],
        country: ["", [Validators.required]],
        state: ["", [Validators.required]],
        email: ["", [Validators.required, Validators.email]],
        phone: ["", [Validators.required]],
        cif: ["", [Validators.required]],
      },
      { validators: ValidateSpanishID }
    );
    this.uCardForm = this.fb.group({
      ccnumber: ["", [CreditCardValidators.validateCCNumber]],
      name: ["", [Validators.required]],
      expirationDate: ["", [CreditCardValidators.validateExpDate]],
      cvc: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(4)],
      ],
    });
    this.authService.GetAccountType().then((acc) => {
      this.currentPlan = acc;
    });
  }
  get f() {
    return this.uInfoForm.controls;
  }
  get p() {
    return this.uPassForm.controls;
  }
  get b() {
    return this.uBillForm.controls;
  }
  get c() {
    return this.uCardForm.controls;
  }

  ngOnInit(): void {
    this.uPassForm.disable();
    this.uInfoForm.disable();
    this.uBillForm.disable();
    this.uCardForm.disable();
    this.authService.GetUser().then((u) => {
      this.currentUser = u;
      this.authService.updateInvoiceRefreshDate(u);
      this.uInfoForm.controls["firstName"].setValue(
        this.currentUser.displayName
      );
      this.uInfoForm.controls["emailOther"].setValue(
        this.currentUser.emailOther
      );
    });
    this.authService.GetBillingInfo().then((b) => {
      this.currentBillingInfo = b;
      if (this.currentBillingInfo) {
        this.uBillForm.controls["name"].setValue(this.currentBillingInfo.name);
        this.uBillForm.controls["line1"].setValue(
          this.currentBillingInfo.address.line1
        );
        this.uBillForm.controls["city"].setValue(
          this.currentBillingInfo.address.city
        );
        this.uBillForm.controls["country"].setValue(
          this.currentBillingInfo.address.country
        );
        this.uBillForm.controls["postal_code"].setValue(
          this.currentBillingInfo.address.postal_code
        );
        this.uBillForm.controls["state"].setValue(
          this.currentBillingInfo.address.state
        );
        this.uBillForm.controls["email"].setValue(
          this.currentBillingInfo.email
        );
        this.uBillForm.controls["phone"].setValue(
          this.currentBillingInfo.phone
        );
        this.uBillForm.controls["cif"].setValue(
          this.currentBillingInfo.tax_id_data[0].value
        );
      } else {
        this.currentBillingInfo = {
          name: "",
          address: {
            line1: "",
            city: "",
            country: "",
            postal_code: "",
            state: "",
          },
          email: "",
          payment_method: "",
          phone: "",
          tax_id_data: [
            {
              type: "es_cif",
              value: "",
            },
          ],
        };
      }
    });
    this.authService.GetCreditCard().then((c) => {
      this.currentCreditCard = c;
      if (this.currentCreditCard) {
        this.uCardForm.controls["ccnumber"].setValue(
          this.currentCreditCard?.number?.length === 4
            ? "************" + this.currentCreditCard?.number
            : this.currentCreditCard.number
        );
        this.uCardForm.controls["name"].setValue(this.currentCreditCard.name);
        const expirationDate =
          this.currentCreditCard.exp_month && this.currentCreditCard.exp_year
            ? (this.currentCreditCard.exp_month < 10
                ? "0" + this.currentCreditCard.exp_month
                : this.currentCreditCard.exp_month) +
              "/" +
              this.currentCreditCard.exp_year
            : "";
        this.uCardForm.controls["expirationDate"].setValue(expirationDate);
        this.uCardForm.controls["cvc"].setValue(this.currentCreditCard.cvc);
      } else {
        this.currentCreditCard = {
          object: "card",
          name: "",
          number: "",
          cvc: "",
          exp_month: null,
          exp_year: null,
        };
      }
    });
    this.iService.getAllDataTable();
    this.invoices$ = this.iService.invoices$;
    this.total$ = this.iService.total$;
    this.invoices$.subscribe((ii) => {
      console.log(ii);
    });
  }
  editPersontalInformation() {
    this.editUserInfo = true;
    this.uInfoForm.enable();
  }
  changePersonalInformation() {
    if (this.uInfoForm.valid) {
      this.authService
        .SetMainData(
          this.currentUser,
          this.uInfoForm.controls["firstName"].value,
          this.uInfoForm.controls["emailOther"].value
        )
        .then(() => {
          this.editUserInfo = false;
          this.uInfoForm.disable();
        });
    }
  }
  cancelPersontalInformation() {
    this.editUserInfo = false;
    this.uInfoForm.disable();
    this.uInfoForm.controls["firstName"].setValue(this.currentUser.displayName);
    this.uInfoForm.controls["emailOther"].setValue(this.currentUser.emailOther);
  }

  editUserPassword() {
    this.editUserPass = true;
    this.uPassForm.enable();
  }
  changeUserPassword() {
    if (this.uPassForm.valid) {
      this.authService.changePassword(
        this.uPassForm.controls["oldPass"].value,
        this.uPassForm.controls["newPass"].value,
        () => {
          this.editUserPass = false;
          this.uPassForm.disable();
          this.uPassForm.controls["oldPass"].setValue("");
          this.uPassForm.controls["newPass"].setValue("");
          this.uPassForm.controls["confirmNewPass"].setValue("");
          alert("Password changed correctly");
        },
        (reason) => {
          alert(reason);
        }
      );
    }
  }
  cancelUserPassword() {
    this.editUserPass = false;
    this.uPassForm.disable();
    this.uPassForm.controls["oldPass"].setValue("");
    this.uPassForm.controls["newPass"].setValue("");
    this.uPassForm.controls["confirmNewPass"].setValue("");
  }
  editPhotoUrl() {
    this.editPhotoInfo = true;
    this.photoUrlNew = null;
  }
  cancelPhotoUrl() {
    this.editPhotoInfo = false;
    this.photoUrlNew = null;
  }
  changePhotoUrl() {
    if (this.photoUrlNew) {
      this.authService.SetPhotoURL(this.currentUser, this.photoUrlNew);
      // this.authService.userData.photoURL = this.photoUrlNew;
    }
    this.editPhotoInfo = false;
    this.photoUrlNew = null;
  }
  photoUrlChanged($event) {
    this.photoUrlNew = $event;
  }
  editCard() {
    this.editCreditCard = true;
    this.uCardForm.reset();
    this.uCardForm.enable();
  }
  cancelCard() {
    this.editCreditCard = false;
    this.uCardForm.disable();
    this.uCardForm.controls["ccnumber"].setValue(
      this.currentCreditCard?.number?.length === 4
        ? "************" + this.currentCreditCard?.number
        : this.currentCreditCard.number
    );
    this.uCardForm.controls["name"].setValue(this.currentCreditCard.name);
    this.uCardForm.controls["expirationDate"].setValue(
      this.currentCreditCard.exp_month + "/" + this.currentCreditCard.exp_year
    );
    this.uCardForm.controls["cvc"].setValue(this.currentCreditCard.cvc);
  }
  changeCard() {
    if (this.uCardForm.valid) {
      this.authService
        .SetCreditCard(this.currentUser, {
          object: "card",
          name: this.uCardForm.controls["name"].value,
          cvc: this.uCardForm.controls["cvc"].value,
          number: this.uCardForm.controls["ccnumber"].value,
          exp_month: +this.uCardForm.controls["expirationDate"].value
            .split("/")[0]
            .trim(),
          exp_year: +this.uCardForm.controls["expirationDate"].value
            .split("/")[1]
            .trim(),
        })
        .then(
          () => {
            this.editCreditCard = false;
            this.uCardForm.disable();
            this.currentCreditCard.object = "card";
            this.currentCreditCard.name = this.uCardForm.get("name").value;
            this.currentCreditCard.number = this.uCardForm.get(
              "ccnumber"
            ).value;
            this.currentCreditCard.cvc = this.uCardForm.get("cvc").value;
            this.currentCreditCard.exp_month = +this.uCardForm
              .get("expirationDate")
              .value.split("/")[0]
              .trim();
            this.currentCreditCard.exp_year = +this.uCardForm
              .get("expirationDate")
              .value.split("/")[1]
              .trim();
          },
          (reason) => {
            alert(reason);
          }
        );
    }
  }
  editBillingDetails() {
    this.editBillingInfo = true;
    this.uBillForm.enable();
  }
  changeBillingInfor() {
    if (this.uBillForm.valid) {
      this.authService
        .SetBillingInfo(this.currentUser, {
          name: this.uBillForm.controls["name"].value,
          address: {
            line1: this.uBillForm.controls["line1"].value,
            city: this.uBillForm.controls["city"].value,
            postal_code: this.uBillForm.controls["postal_code"].value,
            country: this.uBillForm.controls["country"].value,
            state: this.uBillForm.controls["state"].value,
          },
          email: this.uBillForm.controls["email"].value,
          phone: this.uBillForm.controls["phone"].value,
          tax_id_data: [
            {
              type: "es_cif",
              value: this.uBillForm.controls["cif"].value,
            },
          ],
          payment_method: this.currentBillingInfo.payment_method,
        })
        .then(
          () => {
            this.editBillingInfo = false;
            this.uBillForm.disable();
            this.currentBillingInfo.name = this.uBillForm.get("name").value;
            this.currentBillingInfo.address.line1 = this.uBillForm.get(
              "line1"
            ).value;
            this.currentBillingInfo.address.city = this.uBillForm.get(
              "city"
            ).value;
            this.currentBillingInfo.address.postal_code = this.uBillForm.get(
              "postal_code"
            ).value;
            this.currentBillingInfo.address.country = this.uBillForm.get(
              "country"
            ).value;
            this.currentBillingInfo.address.state = this.uBillForm.get(
              "state"
            ).value;
            this.currentBillingInfo.phone = this.uBillForm.get("phone").value;
            this.currentBillingInfo.email = this.uBillForm.get("email").value;
            this.currentBillingInfo.tax_id_data[0].type = "es_cif";
            this.currentBillingInfo.tax_id_data[0].value = this.uBillForm.get(
              "cif"
            ).value;
          },
          (reason) => {
            alert(reason);
          }
        );
    }
  }
  cancelBillingInfo() {
    this.editBillingInfo = false;
    this.uBillForm.disable();
    this.uBillForm.controls["name"].setValue(this.currentBillingInfo.name);
    this.uBillForm.controls["line1"].setValue(
      this.currentBillingInfo.address.line1
    );
    this.uBillForm.controls["city"].setValue(
      this.currentBillingInfo.address.city
    );
    this.uBillForm.controls["country"].setValue(
      this.currentBillingInfo.address.country
    );
    this.uBillForm.controls["postal_code"].setValue(
      this.currentBillingInfo.address.postal_code
    );
    this.uBillForm.controls["state"].setValue(
      this.currentBillingInfo.address.state
    );
    this.uBillForm.controls["email"].setValue(this.currentBillingInfo.email);
    this.uBillForm.controls["phone"].setValue(this.currentBillingInfo.phone);
    this.uBillForm.controls["cif"].setValue(
      this.currentBillingInfo.tax_id_data[0].value
    );
  }
  changePlan() {
    const modal = this.modalService.open(AccountTypeModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });
    if (this.currentPlan) {
      modal.componentInstance.editElement = this.currentPlan;
    }
    modal.result.then(
      (result) => {
        if (result) {
          this.currentPlan = result;
        }
      },
      (reason) => {}
    );
  }
  removeBillingDetails() {
    this.helper.openConfirmationDeleteAccount().then((result) => {
      if (result) {
        this.authService.deleteBillingDetails(this.currentUser).then(
          () => {
            // alert('OK');
            window.location.reload();
          },
          (reason) => {
            alert(reason);
          }
        );
      }
    });
  }
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.iService.sortColumn = column;
    this.iService.sortDirection = direction;
  }
  async show(invoice) {
    const logo_url = '../../../assets/images/logo_fons.jpg';
    // this.getImgFromUrl(logo_url, async (img) => {
      PdfMakeWrapper.setFonts(pdfFonts);
      const pdf = new PdfMakeWrapper();
      pdf.pageOrientation("portrait");
      pdf.pageSize("A4");
      pdf.pageMargins([60, 60, 60, 60]);
      pdf.info({
        title: `Fuudie ${invoice.number}`,
        author: "Fuudie.es",
        subject: `Fuudie ${invoice.number}`,
      });
      const dataInf = this.datePipe.transform(invoice.created, "yyyy_MM_dd");
      // pdf.add(new Txt("FULLA DE TASQUES").bold().fontSize(16).end);
      await this.addHeader(pdf, invoice);
      this.addDetails(pdf, invoice);
      // this.addMaterial(pdf);
      // this.addOperators(pdf);
      // this.addFotos(pdf);
      // this.addObs(pdf);
      const nameFile = `fuudie_invoice_${invoice.number}.pdf`;
      await pdf.create().download(nameFile);
    // });
  }
  async addHeader(pdf: PdfMakeWrapper, invoice) {
    const back = [
      new Table([[
        new Cell(new Txt(`Fuudie`).fontSize(20).alignment('left').bold().end).border([false]).end,
        new Cell(new Txt(`Factura Nº ${invoice.number}`).fontSize(20).alignment('right').bold().end).border([false]).end]]).widths(['auto', '*']).margin([60, 40, 60, 40]).end,
          // await new Img('https://firebasestorage.googleapis.com/v0/b/fuudie-81c95.appspot.com/o/home%2FFuudies_VerdBlanc.jpg?alt=media&token=c472885c-a625-4902-b11c-8162abd6c4e7')
          //   .absolutePosition(20, 20).width(90).build()
      ];
      pdf.background(back);
      const body = [];
      body.push([]);
      body.push([]);
      body.push([]);
      body.push([]);
      body.push([]);
      body.push([]);
      body.push([]);
      body[0].push(new Cell(new Txt('MIQUEL VERGÉS VILARRUBIA').end).border([false]).end);
      body[0].push(new Cell(new Txt("Facturado a:").alignment('right').end).border([false]).end);
      body[0].push(new Cell(new Txt(invoice.customer_name).end).border([false]).end);
      body[1].push(new Cell(new Txt('Av/ Pius XII, 22').end).border([false]).end);
      body[1].push(new Cell(new Txt('').end).border([false]).end);
      body[1].push(new Cell(new Txt(`${invoice.customer_address.line1} ${invoice.customer_address.line2 ? invoice.customer_address.line2 : ''}`).end).border([false]).end);
      body[2].push(new Cell(new Txt('Vic (08500)').end).border([false]).end);
      body[2].push(new Cell(new Txt('').end).border([false]).end);
      body[2].push(new Cell(new Txt(`${invoice.customer_address.city} (${invoice.customer_address.postal_code})`).end).border([false]).end);
      body[3].push(new Cell(new Txt('Barcelona (ES)').end).border([false]).end);
      body[3].push(new Cell(new Txt('').end).border([false]).end);
      body[3].push(new Cell(new Txt(`${invoice.customer_address.state} (${invoice.customer_address.country})`).end).border([false]).end);
      body[4].push(new Cell(new Txt("47851672B").end).border([false]).end);
      body[4].push(new Cell(new Txt('').end).border([false]).end);
      body[4].push(new Cell(new Txt(`${invoice.customer_tax_ids[0].value}`).end).border([false]).end);
      body[5].push(new Cell(new Txt('fuudie@fuudie.es').end).border([false]).end);
      body[5].push(new Cell(new Txt('').end).border([false]).end);
      body[5].push(new Cell(new Txt(`${invoice.customer_email}`).end).border([false]).end);
      body[6].push(new Cell(new Txt('').end).border([false]).end);
      body[6].push(new Cell(new Txt('').end).border([false]).end);
      body[6].push(new Cell(new Txt(`${invoice.customer_phone}`).end).border([false]).end);
    const t = new Table(body);
    pdf.add(
       t.widths(['auto', '*', 'auto']).margin([0, 20, 0, 0]).end
    );
  }
  addDetails(pdf: PdfMakeWrapper, invoice) {
    pdf.add(new Txt(`Fecha facturación: ${this.datePipe.transform(new Date(invoice.created * 1000), 'dd/MM/yyyy')}`).fontSize(16).alignment("left").margin([0, 20, 0, 0]).end);
    pdf.add(new Txt(`Detalles`).fontSize(16).alignment("left").bold().margin([0, 20, 0, 0]).end);
    const body = [];
    let i = 0;
    (invoice.lines.data as any[]).forEach(di => {
      body.push([]);
      body[i].push(new Cell(new Txt(di.description).end).border([false, false, false, true]).end);
      body[i].push(new Cell(new Txt(`${di.amount / 100} ${di.currency.toUpperCase()}`).alignment('right').end).border([false, false, false, true]).end);
      i++;
    });
    const subtotal = Math.round(invoice.total / 1.21);
    const iva = invoice.total - subtotal;
    body.push([new Cell(new Txt('Incluye el 21% de IVA').alignment('right').margin([0, 40, 0, 0]).end).border([false]).end, new Cell(new Txt('').end).border([false]).end]);
    body.push([new Cell(new Txt('SUBTOTAL').alignment('right').end).border([false]).margin([0, 40, 0, 0]).end, new Cell(new Txt(`${subtotal / 100} ${invoice.currency.toUpperCase()}`).alignment('right').end).border([false]).margin([0, 40, 0, 0]).end]);
    body.push([new Cell(new Txt('IVA (21%)').alignment('right').end).border([false]).end, new Cell(new Txt(`${iva / 100} ${invoice.currency.toUpperCase()}`).alignment('right').end).border([false]).end]);
    body.push([new Cell(new Txt('TOTAL').alignment('right').end).border([false]).end, new Cell(new Txt(`${invoice.total / 100} ${invoice.currency.toUpperCase()}`).alignment('right').end).border([false]).end]);

    const t = new Table(body);
    pdf.add(
       t.widths(['*', 'auto']).margin([0, 20, 0, 0]).end
    );

  }
  /*addTasks(pdf: PdfMakeWrapper) {
    pdf.add(new Txt("TASQUES A REALITZAR").fontSize(14).bold().end);
    pdf.add(new Canvas([new Line(0, [490, 0]).end]).end);
    this.worksheet.taskpoints.forEach((ti) => {
      pdf.add(
        new Txt("-  " + ti.taskpoint.name.toUpperCase())
          .margin([0, 5, 0, 0])
          .fontSize(10).end
      );
    });
    this.worksheet.customTaskpoints.forEach((ti) => {
      pdf.add(
        new Txt("-  " + ti.taskpoint.name.toUpperCase())
          .margin([0, 5, 0, 0])
          .fontSize(10).end
      );
    });
  }
  addMaterial(pdf: PdfMakeWrapper) {
    pdf.add(
      new Txt("MATERIAL NECESSARI").fontSize(14).margin([0, 10, 0, 0]).bold()
        .end
    );
    pdf.add(new Canvas([new Line(0, [490, 0]).end]).end);
    this.worksheet.materials.forEach((mi) => {
      pdf.add(
        new Txt("-  " + mi.name.toUpperCase()).margin([0, 5, 0, 0]).fontSize(10)
          .end
      );
    });
    this.worksheet.customMaterials.forEach((mi) => {
      pdf.add(
        new Txt("-  " + mi.name.toUpperCase()).margin([0, 5, 0, 0]).fontSize(10)
          .end
      );
    });
  }
  addOperators(pdf: PdfMakeWrapper) {
    pdf.add(new Txt("OPERARIS").fontSize(14).margin([0, 10, 0, 0]).bold().end);
    pdf.add(new Canvas([new Line(0, [490, 0]).end]).end);
    pdf.add(
      new Txt(this.operatives.toUpperCase()).margin([0, 5, 0, 0]).fontSize(10)
        .end
    );
  }
  addFotos(pdf: PdfMakeWrapper) {
    pdf.add(new Txt("FOTOS").fontSize(14).margin([0, 10, 0, 0]).bold().end);
    pdf.add(new Canvas([new Line(0, [490, 0]).end]).end);
    if (this.worksheet.doImages) {
      pdf.add(new Txt("SI").margin([0, 5, 0, 0]).fontSize(10).end);
    } else {
      pdf.add(new Txt("NO").margin([0, 5, 0, 0]).fontSize(10).end);
    }
  }
  addObs(pdf: PdfMakeWrapper) {
    pdf.add(
      new Txt("OBSERVACIONS").fontSize(14).margin([0, 10, 0, 0]).bold().end
    );
    pdf.add(new Canvas([new Line(0, [490, 0]).end]).end);
    pdf.add(
      new Txt(this.worksheet.observations.toUpperCase())
        .margin([0, 5, 0, 0])
        .fontSize(10).end
    );
  }*/
}
//#region Validators

  const DNI_REGEX = /^(\d{8})([A-Z])$/;
  const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
  const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

  function checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const pass = group.get("newPass").value;
    const confirmPass = group.get("confirmNewPass").value;

    return pass === confirmPass ? null : { notSame: true };
  }
  function ValidateSpanishID(group: FormGroup) {
    // Ensure upcase and remove whitespace
    let str = group.get("cif").value;
    str = str.toUpperCase().replace(/\s/, "");

    let valid = false;
    const type = spainIdType(str);
    switch (type) {
      case "dni":
        valid = validDNI(str);
        break;
      case "nie":
        valid = validNIE(str);
        break;
      case "cif":
        valid = validCIF(str);
        break;
    }
    return (valid) ? null : { invalid_Vat: !valid };
    // return {
    //   type: type,
    //   valid: valid,
    // };
  }
  function spainIdType(str) {
    if (str.match(DNI_REGEX)) {
      return "dni";
    }
    if (str.match(CIF_REGEX)) {
      return "cif";
    }
    if (str.match(NIE_REGEX)) {
      return "nie";
    }
    return '';
  }

  function validDNI(dni) {
    const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
    const letter = dni_letters.charAt(parseInt(dni, 10) % 23);
    return letter === dni.charAt(8);
  }

  function validNIE(nie) {
    // Change the initial letter for the corresponding number and validate as DNI
    let nie_prefix = nie.charAt(0);

    switch (nie_prefix) {
      case "X":
        nie_prefix = 0;
        break;
      case "Y":
        nie_prefix = 1;
        break;
      case "Z":
        nie_prefix = 2;
        break;
    }

    return this.validDNI(nie_prefix + nie.substr(1));
  }

  function validCIF(cif) {
    const match = cif.match(CIF_REGEX);
    const letter = match[1],
      number = match[2],
      control = match[3];

    let even_sum = 0;
    let odd_sum = 0;
    let n;

    for (let i = 0; i < number.length; i++) {
      n = parseInt(number[i], 10);

      // Odd positions (Even index equals to odd position. i=0 equals first position)
      if (i % 2 === 0) {
        // Odd positions are multiplied first.
        n *= 2;

        // If the multiplication is bigger than 10 we need to adjust
        odd_sum += n < 10 ? n : n - 9;

        // Even positions
        // Just sum them
      } else {
        even_sum += n;
      }
    }

    const control_digit = 10 - +(even_sum + odd_sum).toString().substr(-1);
    const control_letter = "JABCDEFGHI".substr(control_digit, 1);

    // Control must be a digit
    if (letter.match(/[ABEH]/)) {
      return control === control_digit;

      // Control must be a letter
    } else if (letter.match(/[KPQS]/)) {
      return control === control_letter;

      // Can be either
    } else {
      return control === control_digit.toString() || control === control_letter;
    }
  }
  //#endregion
