// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyB9VQ9YkYx5ZvrVbrp7YO3Ocatrg0WaseE',
    authDomain: 'fuudie-81c95.firebaseapp.com',
    databaseURL: 'https://fuudie-81c95.firebaseio.com',
    projectId: 'fuudie-81c95',
    storageBucket: 'fuudie-81c95.appspot.com',
    messagingSenderId: '966719125520',
    appId: '1:966719125520:web:27e3d3ca3b6656231cb295',
    measurementId: 'G-G4GYZBDMHE'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
