import { Injectable, NgZone } from '@angular/core';
import { User } from '../services/user';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { EstablishmentService } from './establishment.service';
import firebase from 'firebase/app';
import { BillingInfo, CreditCardInfo } from './user';
import { AccountType } from '../models/account-type.model';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  userData: any; // Save logged in user data
  user: Observable<User | null>;
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public estService: EstablishmentService
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
      // tap(user => localStorage.setItem('user', JSON.stringify(user))),
      // startWith(JSON.parse(localStorage.getItem('user')))
    );
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem("user", JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem("user"));
      } else {
        localStorage.setItem("user", null);
        localStorage.setItem("establishment", null);
        JSON.parse(localStorage.getItem("user"));
      }
    });
  }

  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        if (result.user.emailVerified === false) {
          window.alert("Please verify your email!");
          return false;
        }
        if (result.user) {
          this.userData = result.user;
          localStorage.setItem("user", JSON.stringify(this.userData));
          this.GetUserData(result.user).then(() => {
            this.ngZone.run(() => {
              this.router.navigate(["intranet", "dashboard"]);
            });
          });
        }
        this.SetUserData(result.user);
        return true;
      })
      .catch((error) => {
        window.alert(error.message);
        return false;
      });
  }

  // Sign up with email/password
  SignUp(email, password, displayName) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        return result.user
          .updateProfile({
            displayName: displayName,
          })
          .then(
            () => {
              // this.SendVerificationMail();
              this.SetUserData(result.user);
              return true;
            },
            (error) => {
              window.alert(error.message);
              return false;
            }
          );
      })
      .catch((error) => {
        window.alert(error.message);
        return false;
      });
  }

  // Send email verfificaiton when new user sign up
  async SendVerificationMail() {
    return (await this.afAuth.currentUser).sendEmailVerification();
    // .then(() => {
    //   this.router.navigate(["verify-email-address"]);
    // });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert("Password reset email sent, check your inbox.");
        return true;
      })
      .catch((error) => {
        window.alert(error);
        return false;
      });
  }

  get currentUser(): User {
    const user = JSON.parse(localStorage.getItem("user"));
    return user !== null ? user : null;
  }
  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user !== null && user.emailVerified !== false ? true : false;
  }
  get isAdmin(): boolean {
    const user = JSON.parse(localStorage.getItem("userData"));
    return user && user["isAdmin"] ? user["isAdmin"] : false;
  }
  get isSuperAdmin(): boolean {
    const user = JSON.parse(localStorage.getItem("userData"));
    return user && user["isSuperAdmin"] ? user["isSuperAdmin"] : false;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.userData = result.user;
        localStorage.setItem("user", JSON.stringify(this.userData));
        this.GetUserData(result.user).then(() => {
          this.ngZone.run(() => {
            this.router.navigate(["intranet", "dashboard"]);
          });
        });
        this.SetUserData(result.user);
        return true;
      })
      .catch((error) => {
        window.alert(error);
        return false;
      });
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }
  SetPhotoURL(user, photoUrl) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const obj = {
      photoURL: photoUrl,
    };
    return userRef
      .set(obj, {
        merge: true,
      })
      .then(() => {
        firebase.auth().currentUser.updateProfile({ photoURL: photoUrl });
      });
  }
  SetMainData(user, displayName, email) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const obj = {
      displayName: displayName,
      emailOther: email,
    };
    return userRef
      .set(obj, {
        merge: true,
      })
      .then(() => {
        firebase.auth().currentUser.updateProfile({ displayName: displayName });
      });
  }
  GetUserData(user) {
    return this.afs
      .doc(`users/${user.uid}`)
      .get()
      .toPromise()
      .then((us) => {
        const u = us.data() as User;
        localStorage.setItem("userData", JSON.stringify(u));
        if (u.selectedEstablishment) {
          localStorage.setItem(
            "establishment",
            JSON.stringify(u.selectedEstablishment)
          );
        }
      });
  }
  GetUser() {
    const uid = this.currentUser.uid;
    return this.afs
      .doc(`users/${uid}`)
      .get()
      .toPromise()
      .then((us) => {
        const u = us.data() as User;
        return u;
      });
  }
  GetBillingInfo() {
    const uid = this.currentUser.uid;
    return this.afs
      .doc(`users/${uid}/BI/BI`)
      .get()
      .toPromise()
      .then((us) => {
        const u = us.data() as BillingInfo;
        return u;
      });
  }
  GetCreditCard() {
    const uid = this.currentUser.uid;
    return this.afs
      .doc(`users/${uid}/CC/CC`)
      .get()
      .toPromise()
      .then((us) => {
        const u = us.data() as CreditCardInfo;
        return u;
      });
  }
  GetAccountType() {
    const uid = this.currentUser.uid;
    return this.afs
      .doc(`users/${uid}/AT/AT`)
      .get()
      .toPromise()
      .then((us) => {
        const u = us.data() as AccountType;
        return u;
      });
  }
  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem("user");
      this.router.navigate(["home"]);
    });
  }
  changePassword(oldPass, newPass, success, error) {
    const cuser = firebase.auth().currentUser;
    const credentials = firebase.auth.EmailAuthProvider.credential(
      cuser.email,
      oldPass
    );
    cuser.reauthenticateWithCredential(credentials).then(
      (ok) => {
        cuser.updatePassword(newPass).then((okchange) => {
          success();
        });
      },
      (reason) => {
        error(reason);
      }
    );
  }
  SetBillingInfo(user, billing: BillingInfo) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}/BI/BI`
    );
    return userRef.set(billing, {
      merge: true,
    });
  }
  SetCreditCard(user, card: CreditCardInfo) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}/CC/CC`
    );
    return userRef.set(card, {
      merge: true,
    });
  }
  SetAccountType(user, account: AccountType) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}/AT/AT`
    );
    return userRef.set(account, {
      merge: true,
    });
  }
  deleteBillingDetails(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}/BI/BI`
    );
    return userRef.delete();
  }
  updateInvoiceRefreshDate(user) {
    this.afs.doc(`users/${user.uid}`).set({refresh_date: new Date()}, {merge: true});
  }
}
