
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DishService } from '../../shared/services/dish.service';
import { Dish } from '../../shared/models/dish.model';
import { EstablishmentService } from '../../shared/services/establishment.service';
import { Establishment } from 'src/app/shared/models/establishment.model';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { CategoryDish } from 'src/app/shared/models/category-dish.model';
import { CategoryDishService } from '../../shared/services/category-dish.service';
import { UnitMeasureService } from '../../shared/services/unit-measure.service';
import { AllergenService } from '../../shared/services/allergen.service';
import { UnitMeasure } from 'src/app/shared/models/unit-mesure.model';
import { Allergen } from '../../shared/models/allergen.model';
import { CategoryDishModalComponent } from '../category-dish-modal/category-dish-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UnitsMeasuresModalComponent } from '../units-measures-modal/units-measures-modal.component';
import { Router } from '@angular/router';
import { LANGUAGES } from '../../shared/data/data';
import firebase from 'firebase/app';

@Component({
  selector: 'app-dish-form',
  templateUrl: './dish-form.component.html',
  styleUrls: ['./dish-form.component.css'],
})
export class DishFormComponent implements OnInit {
  public collapseId = 0;
  faPlus = faPlus;
  faTrash = faTrash;
  dishForm: FormGroup;
  editingDish: Dish;
  establishment: Establishment;
  categories$: Observable<CategoryDish[]>;
  units$: Observable<UnitMeasure[]>;
  allergens$: Observable<Allergen[]>;
  prices: { id: number }[] = [];
  pluses: { id: number }[] = [];
  _elementToEdit: Dish;
  @ViewChild('form') dishRefForm: ElementRef;
  @Input() set elementToEdit(value: Dish) {
    this._elementToEdit = value;
    if (this._elementToEdit) {
      this.editElemet(this._elementToEdit);
    }
  }
  @Output() resetForm = new EventEmitter<Dish>();
  currentLanguages: string[] = [];
  constructor(
    private fb: FormBuilder,
    private dishService: DishService,
    private estService: EstablishmentService,
    private cService: CategoryDishService,
    private uService: UnitMeasureService,
    private aService: AllergenService,
    private modalService: NgbModal,
    private _router: Router) {
    this.dishService.isAdminData = this._router.url.includes('admin');
    this.cService.isAdminData = this._router.url.includes('admin');
    this.uService.isAdminData = this._router.url.includes('admin');
    this.establishment = this.estService.isEstablishmentSelected;
    this.dishForm = this.fb.group({
      ref: ['', null],
      name: ['', Validators.required],
      description: ['', null],
      observations: ['', null],
      categories: ['', null],
      allergens: ['', null]
    });
    if (this.dishService.isAdminData) {
      this.currentLanguages = LANGUAGES.map(li => li.code);
       this.currentLanguages.forEach((li) => {
        this.dishForm.addControl('name_' + li, new FormControl('', null));
        this.dishForm.addControl('description_' + li, new FormControl('', null));
        this.dishForm.addControl('observations_' + li, new FormControl('', null));
      });
    } else {
      this.currentLanguages = this.establishment.languages as string[];
      this.currentLanguages.forEach((li) => {
        this.dishForm.addControl('name_' + li, new FormControl('', null));
        this.dishForm.addControl('description_' + li, new FormControl('', null));
        this.dishForm.addControl('observations_' + li, new FormControl('', null));
      });
    }
    this.categories$ = this.cService.instance.getAll();
    this.units$ = this.uService.instance.getAll();
    this.allergens$ = this.aService.getAll();
    this.addPrice();
  }
  submit() {
    this.dishRefForm.nativeElement.submit();
  }
  ngOnInit(): void {}
  saveDish() {
    let dish = null;
    if (this.dishForm.valid) {
      dish = this.getDishFromForm();
      if (this.editingDish) {
        dish.id = this.editingDish.id;
        if (this.editingDish.ab === true || this.editingDish.ab === false) {
          dish.ab = this.editingDish.ab;
        }
        this.dishService.instance.edit(dish, this.editingDish.c, Object.keys(this.editingDish.pr));
        this.editingDish = null;
      } else {
        dish = this.dishService.instance.create(dish);
      }
      this.reset(dish);
    }
  }
  reset(dish: Dish) {
    this.editingDish = null;
    this.dishForm.reset();
    this.resetForm.emit(dish);
  }
  editElemet(element) {
    this.editingDish = element;
    this.dishForm.controls['ref'].setValue(this.editingDish.r);
    this.dishForm.controls['name'].setValue(this.editingDish.n);
    this.dishForm.controls['description'].setValue(this.editingDish.d);
    this.dishForm.controls['observations'].setValue(this.editingDish.o);
    this.dishForm.controls['categories'].setValue(this.editingDish.c);
    this.dishForm.controls['allergens'].setValue(this.editingDish.a);
    this.currentLanguages.forEach((li) => {
      this.dishForm.controls['name_' + li].setValue(
        (this.editingDish.nt[li]) ? this.editingDish.nt[li] : '');
      this.dishForm.controls['description_' + li].setValue(
        (this.editingDish.dt[li]) ? this.editingDish.dt[li] : '');
      this.dishForm.controls['observations_' + li].setValue(
        (this.editingDish.ot[li]) ? this.editingDish.ot[li] : '');
    });
    let id = 1;
    this.prices = [];
    Object.keys(this.editingDish.pr).forEach(key => {
      this.dishForm.addControl('unit_' + id, new FormControl('', null));
      this.dishForm.controls['unit_' + id].setValue(key);
      this.dishForm.addControl('price_' + id, new FormControl('', null));
      this.dishForm.controls['price_' + id].setValue(this.editingDish.pr[key]['EUR']);
      this.prices.push({id: id});
      id++;
    });
    id = 4;
    this.editingDish.pl.forEach(pl => {
      this.dishForm.addControl('plusc_' + id, new FormControl('', null));
      this.dishForm.controls['plusc_' + id].setValue((pl.n) ? pl.n : '');
      this.dishForm.addControl('plusp_' + id, new FormControl('', null));
      this.dishForm.controls['plusp_' + id].setValue((pl.p['EUR']) ? pl.p['EUR'] : '');
      this.currentLanguages.forEach((li) => {
        this.dishForm.addControl('plus_traductions_' + li + '_' + id, new FormControl('', null));
        this.dishForm.controls['plus_traductions_' + li + '_' + id].setValue(
          (pl.nt[li]) ? pl.nt[li] : '');
      });
      this.pluses.push({ id: id });
      id++;
    });
  }
  getDishFromForm() {
    const obj = {
      id: '',
      r: this.dishForm.get('ref').value,
      n: this.dishForm.get('name').value,
      nt: {},
      d: this.dishForm.get('description').value,
      dt: {},
      c: (this.dishForm.get('categories').value === '') ? [] : this.dishForm.get('categories').value,
      a: (this.dishForm.get('allergens').value === '') ? [] : this.dishForm.get('allergens').value,
      pr: {},
      pl: [],
      o: this.dishForm.get('observations').value,
      ot: {},
      ab: true
    };
    this.currentLanguages.forEach((li) => {
      obj.nt[li] = this.dishForm.get('name_' + li).value;
      obj.dt[li] = this.dishForm.get('description_' + li).value;
      obj.ot[li] = this.dishForm.get('observations_' + li).value;
    });
    this.prices.forEach(pli => {
      let pri = +(this.dishForm.get('price_' + pli.id).value);
      try {
        pri = +(this.dishForm.get('price_' + pli.id).value?.replace(',', '.'));
      } catch {}
      const uti = this.dishForm.get('unit_' + pli.id).value;
      obj.pr[uti] = {'EUR': pri};
    });
    Object.keys(this.editingDish.pr).forEach(ki => {
      if (!this.prices.map(pli => this.dishForm.get('unit_' + pli.id).value).includes(ki)) {
        obj.pr[ki] = firebase.firestore.FieldValue.delete();
      }
    });
    this.pluses.forEach(pli => {
      let pri = +(this.dishForm.get('plusp_' + pli.id).value);
      try {
        pri = +(this.dishForm.get('plusp_' + pli.id).value?.replace(',', '.'));
      } catch {}
      const uti = this.dishForm.get('plusc_' + pli.id).value;
      const plus = {};
      plus['price'] = { 'EUR': pri };
      plus['name'] = uti;
      const traductions = {};
      this.currentLanguages.forEach((li) => {
        traductions[li] = this.dishForm.get('plus_traductions_' + li + '_' + pli.id).value;
      });
      plus['name_traductions'] = traductions;
      if (pri && uti) {
        obj.pl.push(plus);
      }
    });
    return obj;
  }
  openFormCategory() {
    const modal = this.modalService
      .open(CategoryDishModalComponent, { ariaLabelledBy: 'modal-basic-title' });
  }
  openFormUnits() {
    const modal = this.modalService
      .open(UnitsMeasuresModalComponent, { ariaLabelledBy: 'modal-basic-title' });
  }
  addPrice() {
    let id = 1;
    if (this.prices.length > 0) {
      id = this.prices[this.prices.length - 1].id + 1;
    }
    this.dishForm.addControl('price_' + id, new FormControl('', null));
    this.dishForm.addControl('unit_' + id, new FormControl('', null));
    this.prices.push({id: id});
  }
  removePrice(pi) {
    const index = this.prices.findIndex(pii => pii.id === pi.id);
    if (index > -1) {
      this.dishForm.removeControl('price_' + pi.id);
      this.dishForm.removeControl('unit_' + pi.id);
      this.prices.splice(index, 1);
    }
  }
  addPlus() {
    let id = 4;
    if (this.pluses.length > 0) {
      id = this.pluses[this.pluses.length - 1].id + 1;
    }
    this.dishForm.addControl('plusp_' + id, new FormControl('', null));
    this.dishForm.addControl('plusc_' + id, new FormControl('', null));
    this.currentLanguages.forEach((li) => {
      this.dishForm.addControl('plus_traductions_' + li + '_' + id, new FormControl('', null));
    });
    this.pluses.push({ id: id });
  }
  removePlus(pi) {
    const index = this.pluses.findIndex(pii => pii.id === pi.id);
    if (index > -1) {
      this.dishForm.removeControl('plusp_' + pi.id);
      this.dishForm.removeControl('plusc_' + pi.id);
      this.currentLanguages.forEach((li) => {
        this.dishForm.removeControl('plus_traductions_' + li + '_' + pi.id);
      });
      this.pluses.splice(index, 1);
    }
  }
  openAllergenInfo() {

  }
}
