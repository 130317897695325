import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { Menu } from '../../shared/models/menu.model';
import { switchMap } from 'rxjs/operators';
import { MenuUserService } from '../../shared/services/menu-user.service';
import { CategoryDish } from '../../shared/models/category-dish.model';
import { UnitMeasure } from '../../shared/models/unit-mesure.model';
import { Allergen } from '../../shared/models/allergen.model';
import { Dish } from '../../shared/models/dish.model';
import { MenuUser } from '../../shared/models/menu-user.model';
import { AllergenService } from '../../shared/services/allergen.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnDestroy  {

  private code1: string;
  private code2: string;
  private code3: string;
  _categories: CategoryDish[] = [];
  _units: UnitMeasure[] = [];
  _allergens: Allergen[] = [];
  _dishes: Dish[] = [];
  private sub: Subscription;
  private suba: Subscription;
  public _value: Menu;
  constructor(
    private router: ActivatedRoute,
    private mSerice: MenuUserService, private aService: AllergenService) {
    this.suba = this.aService.getAll().subscribe(als => {
      this._allergens = als;
    });
    this.sub = this.router.paramMap.pipe(
      switchMap(params => {
        this.code1 = params.get('id');
        this.code2 = params.get('id2');
        this.code3 = params.get('id3');
        return this.mSerice.getMenuUser(this.code1, this.code2);
      })
    ).subscribe((data) => {
      const dataMenu = data.payload.data() as MenuUser;
      this.calculateBytes(dataMenu);
      if (dataMenu) {
        this._categories = Object.keys(dataMenu.categories).map(key => dataMenu.categories[key]);
        this._units = Object.keys(dataMenu.units).map(key => dataMenu.units[key]);
        this._dishes = Object.keys(dataMenu.dishes).map(key => dataMenu.dishes[key]);
        this._value = dataMenu.menus[this.code3];
      }
    });
  }

  ngOnInit(): void {
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.suba.unsubscribe();
  }
  calculateBytes(dataMenu) {
    let total = this.code1.length + 1 + this.code2.length + 1 + this.code3.length + 1;
    total += this.calculaterec(dataMenu);
    total += 32;
    console.log(total, 'bytes');
  }
  calculaterec(obj) {
    return Object.keys(obj).map(key => {
      const total = this.calculateOfType(key, obj[key], 0);
      console.log(key, total);
      return total;
    }).reduce((acc, cur) => acc + cur, 0);
  }
  calculateOfType(key: string, value, current) {
    let sizeOfValue = 0;
    switch (typeof(value)) {
      case 'boolean':
        sizeOfValue = 1;
        break;
      case 'object':
        try {
          sizeOfValue = Object.keys(value).map(ki => this.calculateOfType(ki, value[ki], 0)).reduce((acc, cur) => acc + cur, 0);
        } catch {
          console.log(value);
        }
        break;
      case 'string':
        sizeOfValue = value.length;
        break;
      case 'number':
        sizeOfValue = 8;
        break;
      default:
        console.log('no contemplate', typeof (value));
        break;
    }
    return current + sizeOfValue + key.length + 1;
  }
}

