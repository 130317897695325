import { Component, Input, OnInit, ViewChild, ComponentFactoryResolver, SimpleChanges, OnChanges, ContentChildren } from '@angular/core';
import { StructureTemplateComponent } from '../shared/structure-template.component';
import { CategoryDish } from '../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../shared/models/unit-mesure.model';
import { Allergen } from '../../../shared/models/allergen.model';
import { Dish } from '../../../shared/models/dish.model';
import { DishCollection, DishStructure, Menu } from '../../../shared/models/menu.model';
import { ContainerDirective } from '../directives/container.directive';
import { DishDirective } from '../directives/dish.directive';
import { REGISTRY } from '../shared/register.component';
import { StyleDrawerComponent } from '../style-drawer/style-drawer.component';
import { DataService } from '../../../shared/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dish-template',
  template: `
  <div [ngClass]="{'template-container': showUtils}">
    <div class="editing" *ngIf="showUtils">
      <!-- <button type="button" class="btn btn-light enlinia" (click)="del()"><fa-icon class="fa fa-times"></fa-icon></button> -->
      <button type="button" class="btn btn-light enlinia" (click)="code()"><fa-icon class="fa fa-code"></fa-icon></button>
      <button type="button" class="btn btn-light enlinia" (click)="edit()"><fa-icon class="fa fa-bold"></fa-icon></button>
    </div><ng-template dish-host></ng-template></div>
            `
})
export class DishTemplateComponent implements OnInit, OnChanges, StructureTemplateComponent<DishStructure> {
  @Input() menu: Menu;
  @Input() contents: DishStructure;
  @Input() type: string;
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() allergens: Allergen[];
  @Input() dishes: Dish[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @Input() showUtils: boolean;
  @ContentChildren('styleDrawer') styleDrawer: StyleDrawerComponent;
  @ViewChild(DishDirective, {static: true}) dishHost: DishDirective;
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private data: DataService, private router: Router) { }

  ngOnChanges(changes: SimpleChanges): void {
      this.loadComponent();
  }
  ngOnInit() {
    this.loadComponent();
    // this.showUtils = this.dishHost.showUtils;
  }

  loadComponent() {
    if (this.contents && this.languages && this.dishHost) {
      if (this.contents.t === 'dish') {
        this.showUtils = this.router.url.endsWith('menus');
      }
        if (!this.type) {
            this.contents.dt = 'Standard';
            this.type = "Standard";
        }
        let t = `Dish${this.type}TemplateComponent`;
        let componentFactory;
        try {
          componentFactory = this.componentFactoryResolver.resolveComponentFactory(REGISTRY.get(t));
        } catch {
          t = `DishStandardTemplateComponent`;
          componentFactory = this.componentFactoryResolver.resolveComponentFactory(REGISTRY.get(t));
        }

        const viewContainerRef = this.dishHost.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        (<StructureTemplateComponent<DishStructure>>componentRef.instance).menu = this.menu;
        (<StructureTemplateComponent<DishStructure>>componentRef.instance).contents = this.contents;
        (<StructureTemplateComponent<DishStructure>>componentRef.instance).categories = this.categories;
        (<StructureTemplateComponent<DishStructure>>componentRef.instance).units = this.units;
        (<StructureTemplateComponent<DishStructure>>componentRef.instance).allergens = this.allergens;
        (<StructureTemplateComponent<DishStructure>>componentRef.instance).dishes = this.dishes;
        (<StructureTemplateComponent<DishStructure>>componentRef.instance).languages = this.languages;
        (<StructureTemplateComponent<DishStructure>>componentRef.instance).language_selected = this.language_selected;
        (<StructureTemplateComponent<DishStructure>>componentRef.instance).styleDrawer = this.styleDrawer;
    }
  }
  edit() {
    this.data.changeStructureStyle(this.contents);
  }
  del() {

  }
  code() {
    this.data.changeStructureCode(this.contents);
  }
}
