import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { Menu, Structure, Index } from '../../../shared/models/menu.model';
import { CategoryDish } from '../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../shared/models/unit-mesure.model';
import { Dish } from '../../../shared/models/dish.model';
import { Allergen } from '../../../shared/models/allergen.model';
import { StructureUtils } from '../../../shared/utils/htmlGenerator.util';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { FileFirebaseUpload } from '../../../shared/models/fileFirebaseUpload.model';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';
import { FileService } from '../../../shared/services/file.service';
import { LANGUAGES } from '../../../shared/data/data';

@Component({
  selector: 'app-config-form',
  templateUrl: './config-form.component.html',
  styleUrls: ['./config-form.component.css']
})
export class ConfigFormComponent implements OnInit, OnChanges {
  @Input() structure: Menu;
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() dishes: Dish[];
  @Input() allergens: Allergen[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @Input() screenshot: ElementRef;
  indexSelected: string;
  backSelected: string;
  loadingScreenshot: any = {};
  // @Output() changeStructure = new EventEmitter<Menu>();
  @Output() edit = new EventEmitter<Structure>();
  @Output() changeLanguage = new EventEmitter<string>();
  @ViewChild('canvas') canvas: ElementRef;

  templateTypes = [
    { id: '', name: 'No index'},
    { id: 'Standard', name: 'Standard'}];
    backs = [
      { id: 'c', name: 'Color'},
      {id: 'i', name: 'image'}];
  namecollapsed = true;
  screenshotcollapsed = true;
  isAdminData = false;
  constructor(private router: Router, private fService: FileService) {
    this.isAdminData = router.url.includes('admin');
    LANGUAGES.forEach(li => {
      this.loadingScreenshot[li.code] = false;
    });
    this.loadingScreenshot['default'] = false;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.structure && !this.structure.i) {
      this.structure.i = StructureUtils.getIndex();
    }
    this.indexSelected = this.structure.i.dt;
    this.backSelected = this.structure.tbg;
  }

  ngOnInit(): void {
  }
  deleteElement() {
    this.structure.h = StructureUtils.getContainer();
  }
  changeHeader($event) {
  }
  backImageChange($event) {
    this.structure.bgi = $event;
  }
  changeIndex(tindex) {
    if (tindex !== '') {
      if (this.structure.i === undefined || typeof(this.structure.i)  !== 'object') {
        this.structure.i = StructureUtils.getIndex();
        this.structure.i.dt = tindex;
        this.structure.hi = true;
      } else {
        this.structure.i.dt = tindex;
        this.structure.hi = true;
      }
    } else {
      this.structure.hi = false;
    }
    // console.log(this.structure);
  }
  setText($event) {
    this.structure.n = $event.target.value;
  }
  setTextTrad(lang, $event) {
    this.structure.nt[lang] = $event.target.value;
  }
  setScreenshotTrad(lang, $event) {
    this.structure.st[lang] = $event.target.value;
  }
  takeScreenshot(lang?: string) {
    if (lang) {
      this.changeLanguage.emit(lang);
      this.loadingScreenshot[lang] = true;
      setTimeout(() => {
        this.takeScreenshotCurrentView(lang);
      }, 1000);
    } else {
      this.loadingScreenshot['default'] = true;
      this.loadingScreenshot[this.language_selected] = true;
        this.takeScreenshotCurrentView(this.language_selected, true);
    }
  }
  takeScreenshotCurrentView(lang: string, setdefault = false) {
    html2canvas(this.screenshot.nativeElement).then(canvas => {
      const file = new FileFirebaseUpload();
      file.type = 'image/png';
      const path = `/templates/admin/${this.structure.id}/${lang}.png`;
      file.task = this.fService.uploadString(canvas.toDataURL('image/png'), path, 'image/png');
      file.task.then(
        (snapshot: UploadTaskSnapshot) => {
          snapshot.ref.getDownloadURL().then(data => {
            if (!this.structure.st) { this.structure.st = {}; }
            if (setdefault) {
              this.structure.ss = data;
              this.loadingScreenshot['default'] = false;
            }
            this.structure.st[lang] = data;
            this.loadingScreenshot[lang] = false;
          });
        },
        (error) => {
          alert("Error uploading image: " + error);
        }
      );
    });
  }
}
