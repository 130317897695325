<div class="modal-header font">
    <h4 class="modal-title" id="modal-basic-title">{{'category-dish-modal.newcategorydish' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body mybody">
    <app-category-dish-form #form [elementToEdit]="editElement" (resetForm)="activeModal.close($event)"></app-category-dish-form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary savebutton" (click)="form.saveCategoryDish()">{{'common.save' | translate}}</button>
    <button type="button" class="btn btn-outline-dark cancelbutton" (click)="activeModal.dismiss('Cancel click')">{{'common.cancel' | translate}}</button>
</div>