import { Injectable, NgZone, PipeTransform } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { Allergen } from '../models/allergen.model';
import { map, tap, debounceTime, switchMap, delay } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../directives/sortable.directive';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';

interface SearchResult {
    allergens: Allergen[];
    total: number;
}

interface State {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: SortColumn;
    sortDirection: SortDirection;
    selectedLang: string;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(allergens: Allergen[], column: SortColumn, direction: string, selectedLang: string = null): Allergen[] {
    if (direction === '' || column === '') {
        return allergens;
    } else {
        if (selectedLang && column === "name") {
          return [...allergens].sort((a, b) => {
            const an = a.name_traductions[selectedLang]
              ? a.name_traductions[selectedLang]
              : a.name;
            const bn = b.name_traductions[selectedLang]
              ? b.name_traductions[selectedLang]
              : b.name;
            const res = compare(`${an}`, `${bn}`);
            return direction === "asc" ? res : -res;
          });
        }
        return [...allergens].sort((a, b) => {
            const res = compare(`${a[column]}`, `${b[column]}`);
            return direction === 'asc' ? res : -res;
        });
    }
}

function matches(allergen: Allergen, term: string, selectedLang: string = null, pipe: PipeTransform = null) {
    let match_name = false;
    let match_desc = false;
    if (selectedLang && allergen.name_traductions[selectedLang]) {
        match_name =  allergen.name_traductions[selectedLang].toLowerCase().includes(term.toLowerCase());
    } else {
        match_name = allergen.name.toLowerCase().includes(term.toLowerCase());
    }
    if (selectedLang && allergen.description_traductions[selectedLang]) {
        match_desc =  allergen.description_traductions[selectedLang].toLowerCase().includes(term.toLowerCase());
    } else {
        match_desc = allergen.description.toLowerCase().includes(term.toLowerCase());
    }
    return match_name || match_desc;
    // || allergen.quantities.map(qi => qi.name.toLowerCase()).join(', ').includes(term.toLowerCase());
    // || pipe.transform(country.area).includes(term)
    // || pipe.transform(country.population).includes(term);
}

@Injectable({
  providedIn: "root",
})
export class AllergenService {
  database = "Allergens";
  databaseTrash = "AllergensTrash";
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _allergens$ = new BehaviorSubject<Allergen[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private allergens: Allergen[] = [];
  private _state: State = {
    page: 1,
    pageSize: 15,
    searchTerm: "",
    sortColumn: "name",
    sortDirection: "asc",
    selectedLang: localStorage.getItem("cLang"),
  };
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {}
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
  get ref() {
    return firebase.firestore().collection(this.database).doc(this.database);
  }
  get refObs() {
    return this.afs.collection(this.database).doc(this.database);
  }

  create(al: Allergen) {
    al.id = this.afs.createId();
    const value = {};
    value[al.id] = al;
    this.ref.set(value, { merge: true });
    return al;
  }
  edit(al) {
    const value = {};
    value[al.id] = al;
    return this.ref.set(value, { merge: true });
  }
  remove(al: Allergen) {
    const value = {};
    value[al.id] = al;
    firebase
      .firestore()
      .collection(this.databaseTrash)
      .doc(this.databaseTrash)
      .set(value, { merge: true });
    const value2 = {};
    value2[al.id] = firebase.firestore.FieldValue.delete();
    return this.ref.update(value2);
  }
  getAll() {
    return this.refObs.snapshotChanges().pipe(
      map((actions) => {
        const data = actions.payload.data();
        return Object.keys(data || {}).map((key) => {
          return data[key] as Allergen;
        });
      })
    );
  }
  getAllDataTable() {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this._allergens$.next(result.allergens);
        this._total$.next(result.total);
      });
    return this.refObs
      .snapshotChanges()
      .pipe(
        map((actions) => {
          const data = actions.payload.data();
          this.allergens = Object.keys(data || {}).map((key) => {
            return data[key] as Allergen;
          });
          this._search$.next();
        })
      )
      .subscribe((data) => {});
  }
  get allergens$() {
    return this._allergens$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }
  get selectedLang() {
    return this._state.selectedLang;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }
  set selectedLang(selectedLang: string) {
    this._set({ selectedLang });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const {
      sortColumn,
      sortDirection,
      pageSize,
      page,
      searchTerm,
      selectedLang
    } = this._state;

    // 1. sort
    let allergens = sort(this.allergens, sortColumn, sortDirection, selectedLang);

    // 2. filter
    allergens = allergens.filter((country) => matches(country, searchTerm, selectedLang));
    const total = allergens.length;

    // 3. paginate
    allergens = allergens.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
    return of({ allergens, total });
  }
}
