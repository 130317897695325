<form class="container font" [formGroup]="categoryDishForm" #form>
    <div class="form-group">
        <div class="formGroup">
            <div class="input-group mb-3">
                <input formControlName="name" type="text" class="formControl form-control newform" placeholder="{{ 'category-dish-form.categorysname' | translate}}">
                <div class="input-group-append">
                    <button type="button" class="btn btn-outline-primary btntr" (click)="(collapseId === 1) ? collapseId = 0 : collapseId = 1" [attr.aria-expanded]="collapseId !== 1" aria-controls="nameTraductions">
                        <fa-icon class="fas fa-language"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
        <div id="nameTraductions" [ngbCollapse]="collapseId !== 1">
            <h6>{{ 'common.nametraduction' | translate}}</h6>
            <div class="formGroup" *ngFor="let lan of establishment.languages">
                <input formControlName="{{'name_' + lan }}" type="text" class="formControl newform" placeholder="{{ ('common.namein' | translate) + lan }}">
            </div>
        </div>
    </div>
</form>