<div class="displayTable backgroundsigin font">
    <div class="displayTableCell displaytablesignin">

        <!-- <div class="px-logo logo">
            <a href="https://fuudie-81c95.web.app/sign-in" target="_blank">
                <img src="assets/Fuudies_Blanc.png" alt="Fuudie">
            </a>
        </div> -->

        <div class="menusignin authBlock">
            <h3 class="signintext">{{'sign-in.signin' | translate}}</h3>
            <div class="formGroup">
                <input type="text" class="formControl roundedBtn backgroundID" placeholder="{{'sign-in.emailaddress' | translate}}" #userName required>
            </div>

            <div class="formGroup">
                <input type="password" class="formControl roundedBtn backgroundpass" placeholder="{{'sign-in.password' | translate}}" #userPassword required>
            </div>

            <div class="forgotPassword forgotPasswordstyle">
                <span (click)="forgot()">{{'sign-in.forgotpass' | translate}}</span>
            </div>

            <!-- Calling SignIn Api from AuthService -->
            <div class="formGroup">
                <input type="button" class="btn btnPrimary roundedBtn btnsignin" value="{{'sign-in.login' | translate}}" (click)="login(userName.value, userPassword.value)">
            </div>

            <!-- Calling GoogleAuth Api from AuthService -->
            <div class="formGroup">
                <button type="button" class="btn googleBtn roundedBtn googlebtnstyle" (click)="signInGoogle()">
          <i class="fab fa-google-plus-g"></i>
          {{'sign-in.logingoogle' | translate}}
        </button>
            </div>

            <div class="redirectToLogin redirectstyle">
                <span>{{'sign-in.notmember' | translate}}<span class="redirect redirectstyle " (click)="signup()"> {{'sign-in.signup' | translate}}</span></span>
            </div>
        </div>
    </div>
</div>