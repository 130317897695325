import { Injectable, NgZone, PipeTransform } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { map, tap, debounceTime, switchMap, delay } from 'rxjs/operators';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { SortColumn, SortDirection } from '../directives/sortable.directive';
import { UnitMeasureService } from './unit-measure.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from './user';

interface SearchResult {
  users: User[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
  selectedLang: string;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(users: User[], column: SortColumn, direction: string, selectedLang: string = null): User[] {
  if (direction === '' || column === '') {
    return users;
  } else {
    return [...users].sort((a, b) => {
      const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(user: User, term: string, selectedLang: string = null, pipe: PipeTransform = null) {
  return user.displayName.toLowerCase().includes(term.toLowerCase()) || user.email.toLowerCase().includes(term.toLowerCase());
}

@Injectable({
  providedIn: "root",
})
export class UserService {
  database = "users";
  databaseTrash = "usersTrash";
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _users$ = new BehaviorSubject<User[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private users: User[] = [];
  private _state: State = {
    page: 1,
    pageSize: 15,
    searchTerm: "",
    sortColumn: "n",
    sortDirection: "asc",
    selectedLang: localStorage.getItem("cLang")
  };
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public uService: UnitMeasureService,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {}
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
  get ref() {
    const user = JSON.parse(localStorage.getItem("user"));
    const establishment = JSON.parse(localStorage.getItem("establishment"));
    return firebase
      .firestore()
      .collection(
        `users`
      );
  }
  get refObs() {
    // const user = JSON.parse(localStorage.getItem("user"));
    // const establishment = JSON.parse(localStorage.getItem("establishment"));
    return this.afs
      .collection(
        `users`
      );
  }
//   create(user: User) {
//     user.id = this.afs.createId();
//     const value = {};
//     value[user.id] = user;
//     this.ref.set(value, { merge: true }).then(() => {
//       user.c.forEach((ci) => {
//         this.cService.addUser(ci, user.id);
//       });
//       Object.keys(user.pr).forEach((key) => {
//         this.uService.addUser(key, user.id);
//       });
//     });
//     return user;
//   }
//   edit(user, lastCategories: string[], lastUnits: string[]) {
//     const cToRemove = lastCategories.filter(
//       (ci) => user.c.findIndex((cii) => cii === ci) === -1
//     );
//     const uToRemove = lastUnits.filter(
//       (ui) => Object.keys(user.pr).findIndex((uii) => uii === ui) === -1
//     );
//     const cToAdd = user.c.filter(
//       (ci) => lastCategories.findIndex((cii) => cii === ci) === -1
//     );
//     const uToAdd = Object.keys(user.pr).filter(
//       (ui) => lastUnits.findIndex((uii) => uii === ui) === -1
//     );
//     const value = {};
//     value[user.id] = user;
//     return this.ref.set(value, { merge: true }).then(() => {
//       cToRemove.forEach((cti) => this.cService.removeUser(cti, user.id));
//       uToRemove.forEach((uti) => this.uService.removeUser(uti, user.id));
//       cToAdd.forEach((cti) => this.cService.addUser(cti, user.id));
//       uToAdd.forEach((uti) => this.uService.addUser(uti, user.id));
//     });
//   }
  setAvailable(id: string, ab: boolean) {
    return this.ref.doc(id).set({ ab: ab }, { merge: true });
  }
  setAdministrator(id: string, isAdmin: boolean) {
    return this.ref.doc(id).set({ 'isAdmin': isAdmin }, { merge: true });
  }
  // migrate() {
  //   this.getAll().subscribe(users => {
  //     const new_users = {};
  //     const old_users = {};
  //     users.forEach(di => {
  //       old_users[di.id] = di;
  //       new_users[di.id] = this.toNewUser(di);
  //     });
  //     this.ref2.set(old_users).then(() => {
  //       this.ref.set(new_users);
  //     });
  //   });
  // }
  // toNewUser(d: User) {
  //   return {
  //     id: d.id,
  //     r: (d.ref) ? d.ref : '',
  //     n: d.name,
  //     nt: d.name_traductions,
  //     c: d.categories,
  //     d: d.description,
  //     dt: d.description_traductions,
  //     p: (d.photos) ? d.photos : [],
  //     a: d.allergens,
  //     pr: d.prices,
  //     o: d.observations,
  //     ot: d.observations_traductions,
  //     pl: []
  //   };
  // }
  //   export interface User {
  //   id: string;
  //   ref: string;
  //   name: string;
  //   name_traductions: any;
  //   categories: string[];
  //   description: string;
  //   description_traductions: any;
  //   photos?: string[];
  //   allergens: string[];
  //   prices: any;
  //   observations: string;
  //   observations_traductions: string;
  //   pluses: PlusProduct[];
  //   available: boolean;
  // }

  // export interface PlusProduct {
  //   name: string;
  //   name_traductions: any;
  //   price: any;
  // }

  // export interface NewUser {
  //   id: string;
  //   r: string; // ref
  //   n: string; // name
  //   mt: any; // name_traductions
  //   c: string[]; // categories
  //   d: string; // description
  //   dt: any; // descriptions_traductions
  //   p?: string[]; // photos
  //   a: string[]; // allergens
  //   pr: any; // prices
  //   o: string; // observations
  //   ot: string; // observations_traductions
  //   pl: PlusProduct[]; // pluses
  //   ab: boolean; // available
  // }

  // export interface NewPlusProduct {
  //   n: string; // name
  //   nt: any; // name_traductions
  //   p: any; // prices
  // }
//   remove(user: User) {
//     const user = JSON.parse(localStorage.getItem("user"));
//     const establishment = JSON.parse(localStorage.getItem("establishment"));
//     const value = {};
//     value[user.id] = user;
//     firebase
//       .firestore()
//       .doc(
//         `users/${user.uid}/EstablishmentsTrash/${establishment.id}/${this.databaseTrash}/Useres`
//       )
//       .set(value, { merge: true });
//     const value2 = {};
//     value2[user.id] = firebase.firestore.FieldValue.delete();
//     return this.ref.update(value2).then(() => {
//       user.c.forEach((ci) => {
//         this.cService.removeUser(ci, user.id);
//       });
//       Object.keys(user.pr).forEach((key) => {
//         this.uService.removeUser(key, user.id);
//       });
//     });
//   }
  getAll() {
    return this.refObs.snapshotChanges().pipe(
      map((actions) => {
        return actions.map(ai => ai.payload.doc.data() as User);
      })
    );
  }
  getAllDataTable() {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this._users$.next(result.users);
        this._total$.next(result.total);
      });
    return this.refObs
      .snapshotChanges()
      .pipe(
        map((actions) => {
          this.users = actions.map(ai => ai.payload.doc.data() as User);
          this._search$.next();
        })
      )
      .subscribe((data) => {});
  }
  get users$() {
    return this._users$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }
  get selectedLang() {
    return this._state.selectedLang;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }
  set selectedLang(selectedLang: string) {
    this._set({ selectedLang });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const {
      sortColumn,
      sortDirection,
      pageSize,
      page,
      searchTerm,
      selectedLang,
    } = this._state;

    // console.log(this.users);
    // 1. sort
    let users = sort(this.users, sortColumn, sortDirection, selectedLang);

    // 2. filter
    // console.log(this._state);
    // console.log(users);
    users = users.filter((user) => matches(user, searchTerm, selectedLang));
    const total = users.length;

    // 3. paginate
    users = users.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
    return of({ users, total });
  }
}
