import { Component, Input, OnChanges, ContentChildren } from '@angular/core';
import { StructureTemplateComponent } from '../../shared/structure-template.component';
import { DishCollection, Menu } from '../../../../shared/models/menu.model';
import { CategoryDish } from '../../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../../shared/models/unit-mesure.model';
import { Dish } from '../../../../shared/models/dish.model';
import { Allergen } from '../../../../shared/models/allergen.model';
import { StyleDrawerComponent } from '../../style-drawer/style-drawer.component';
@Component({
  selector: "app-dish-collection-standard-template",
  template: `
    <ul
      *ngIf="_contents?.s"
      id="{{ _contents.id }}"
      class="list-group"
      [style.margin-bottom]="_contents.s.m"
      [style.margin-top]="_contents.s.m"
      [style.padding]="_contents.s.p"
      [style.font-size]="_contents.s.s"
      [style.background-color]="_contents.s.bc"
      [style.color]="_contents.s.c"
      [style.font-family]="_contents.s.f"
      [style.text-align]="_contents.s.hp"
    >
      <ng-container *ngFor="let dish of getDishes()">
        <app-dish-template
          dish-host
          [type]="dish.dt"
          [contents]="dish"
          [categories]="categories"
          [units]="units"
          [allergens]="allergens"
          [dishes]="dishes"
          [languages]="languages"
          [language_selected]="language_selected"
        ></app-dish-template>
      </ng-container>
    </ul>
  `,
  styles: [""],
})
export class DishCollectionStandardTemplateComponent
  implements StructureTemplateComponent<DishCollection>, OnChanges {
  _contents: DishCollection;
  @Input() menu: Menu;
  @Input() type: string;
  @Input() set contents(v: DishCollection) {
    this._contents = v;
    if (!this._contents.id) {
      this._contents.id = 'id_' + Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8);
    }
    this._contents.s = {
      hp: v.s?.hp ? v.s.hp : "left",
      p: v.s?.p ? v.s.p : "5px",
      m: v.s?.m ? v.s.m : "10px",
      bc: v.s?.bc ? v.s.bc : "transparent",
      c: v.s?.c ? v.s.c : "black",
      f: v.s?.f ? v.s.f : "inherit",
      s: v.s?.s ? v.s.s : "16px",
      b: v.s?.b !== undefined ? v.s.b : false,
      cu: v.s?.cu !== undefined ? v.s.cu : false,
      u: v.s?.u !== undefined ? v.s.u : false,
      boc: v.s?.boc ? v.s.boc : "black",
      bs: v.s?.bs ? v.s.bs : "0px",
      br: v.s?.br ? v.s.br : "5px",
      w: v.s?.w ? v.s.w : "100%",
    };
  }
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() dishes: Dish[];
  @Input() allergens: Allergen[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @ContentChildren('styleDrawer') styleDrawer: StyleDrawerComponent;
  constructor() {}
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (this.type && this.dishes && this.contents && this.allergens && this.languages && this.units && this.categories) {
      this.getDishes();
    }
  }
  getDishes() {
    if (this.units && this.categories && this.dishes && this.allergens) {
      if (
        this.units.length > 0 &&
        this.categories.length >= 0 &&
        this.dishes.length > 0
      ) {
        if (this._contents.m === "dish_group") {
          const dishes = this._contents.d
            .filter((id) => {
              const d = this.dishes.find((di) => di.id === id);
              return d.ab === true;
            })
            .map((di) => {
              return { id: di, dt: this._contents.dt, s: this._contents.s };
            });
            if (this.menu && this.menu.obn) {
                return dishes.sort((d1, d2) => {
                    const dh1 = this.dishes.find(di => di.id === d1.id) as Dish;
                    const dh2 = this.dishes.find(di => di.id === d2.id) as Dish;
                    return dh1 && dh2 && dh1.nt[this.language_selected] > dh2.nt[this.language_selected] ? 1 : -1;
                });
            }
            return dishes;
        } else if (this._contents.m === "category_group") {
                 let ids = [];
                 this.categories
                   .filter(
                     (ci) =>
                       this._contents.c.findIndex((cii) => cii === ci.id) > -1
                   )
                   .map((ci) =>
                     ci.dishes
                       ? Object.keys(ci.dishes).filter(
                           (cii) => ci.dishes[cii] === true
                         )
                       : []
                   )
                   .forEach((cids) => (ids = ids.concat(cids)));
                 return ids
                   .filter(
                     (id) => this.dishes.find((di) => di.id === id).ab === true
                   )
                   .map((di) => {
                     return {
                       id: di,
                       dt: this._contents.dt,
                       s: this._contents.s,
                     };
                   })
                   .sort((di1, di2) => {
                     if (this.menu && this.menu.obn) {
                        const dh1 = this.dishes.find(di => di.id === di1.id) as Dish;
                        const dh2 = this.dishes.find(di => di.id === di2.id) as Dish;
                        return dh1 && dh2 && dh1.nt[this.language_selected] > dh2.nt[this.language_selected] ? 1 : -1;
                     } else if (di1.id > di2.id) {
                       return -1;
                     } else {
                       return 1;
                     }
                   });
               }
      }
    }
  }
}
