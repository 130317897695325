import { Injectable } from '@angular/core';
import { MenuTemplateUserService } from './menu-template-user.service';
import { MenuTemplateAdminService } from './menu-template-admin.service';
import { IMenuTemplateCRUDService } from '../interfaces/iMenuTemplateCRUDService';

@Injectable({
  providedIn: "root",
})
export class MenuTemplateService {

  public isAdminData = false;

  constructor (
    private _menuTemplateUserService: MenuTemplateUserService,
    private _menuTemplateAdminService: MenuTemplateAdminService
  ) {}

  public get instance(): IMenuTemplateCRUDService {
    return this.isAdminData ? this._menuTemplateAdminService : this._menuTemplateUserService;
  }
}
