import { Directive, ViewContainerRef, Output, EventEmitter, Input } from '@angular/core';
import { Structure } from '../../../shared/models/menu.model';

@Directive({
    selector: '[index-host]',
})
export class IndexDirective {
    @Output() edit = new EventEmitter<Structure>();
    @Output() indexChange = new EventEmitter();
    @Input() showUtils: boolean;
    constructor(public viewContainerRef: ViewContainerRef) { }
}
