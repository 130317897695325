<!-- Top navigation -->
<nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow newnavbar font">
    <a class="navbar-brand col-sm-3 col-md-2 mr-0 navbarcolor-brand margin0">
        <img class="brand-logo logo" src="assets/Logo_Blanc_SP.png" alt="Fuudie">
    </a>
    <ng-container *ngIf="establishments">
        <ng-container *ngIf="establishments.length === 0">
            <button class="btn btn-primary mr-2 Btnrest" id="establishmentDropDown" data-toggle="modal" data-target="#exampleModal" (click)="addEstablishment()"><fa-icon [icon]="faPlus"></fa-icon> {{'top-nav.addestablishment' | translate}}</button>
        </ng-container>
        <ng-container *ngIf="establishments.length > 0">
            <div class="d-inline-block" ngbDropdown>
                <button class="btn btn-primary mr-2 Btnrest" id="establishmentDropDown" ngbDropdownToggle>
                    <ng-container *ngIf="establishmentSelected">{{ establishmentSelected.name }}</ng-container> 
                    <ng-container *ngIf="!establishmentSelected"><fa-icon [icon]="faPlus"></fa-icon> {{'top-nav.addestablishment' | translate}}</ng-container></button>
                <div ngbDropdownMenu aria-labelledby="establishmentDropDown" class="menudropdown">
                    <button ngbDropdownItem (click)="addEstablishment()"><fa-icon [icon]="faPlus"></fa-icon> {{'top-nav.addestablishment' | translate}}</button>
                    <button ngbDropdownItem *ngFor="let est of establishments" (click)="selectEstablishment(est)" class="establiments">{{ est.name }}</button>
                    <button ngbDropdownItem routerLink="./myrestaurants" class="establiments">{{'top-nav.myestablishments' | translate}}</button>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <img *ngIf="authService.userData as user" src="{{(user.photoURL) ? user.photoURL : '/assets/dummy-user.png'}}" alt="..." class="img-rounded profileimg">
    <ng-container>
        <div class="d-inline-block profilebtn" ngbDropdown>
            <button class="btn mr-2 accountbtn" id="userDropDown" ngbDropdownToggle>
                <ng-container *ngIf="authService.userData as user">{{ user.displayName }}</ng-container> 
            </button>
            <div ngbDropdownMenu aria-labelledby="userDropDown" class="menudropdownuser">
                <button ngbDropdownItem routerLink="./profile">{{'top-nav.accountsettings' | translate}}</button>
                <button ngbDropdownItem (click)="authService.SignOut()">{{'top-nav.logout' | translate}}</button>
            </div>
        </div>
    </ng-container>
    <ng-container>
        <div class="d-inline-block profilebtn" ngbDropdown>
            <button class="btn mr-2 accountbtn" id="langDropDown" ngbDropdownToggle>
                <ng-container *ngIf="authService.userData as user">{{ selectedLang }}</ng-container> 
            </button>
            <button class="btn mr-2 accountbtn" routerLink="./help"><span class="fa fa-life-ring"></span></button>
            <div ngbDropdownMenu aria-labelledby="langDropDown" class="menudropdown">
                <button ngbDropdownItem *ngFor="let l of languages" (click)="tService.use(l.code.toLowerCase())">{{l.code}}</button>
            </div>
        </div>
    </ng-container>
</nav>