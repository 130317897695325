import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Menu, Structure, DishStructure, DishCollection, Container, Table, Page } from '../../shared/models/menu.model';
import { MenuTemplateAdminService } from '../../shared/services/menu-template-admin.service';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { MenuViewModalComponent } from '../menu-view-modal/menu-view-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-menu-template-selector-form',
  templateUrl: './menu-template-selector-form.component.html',
  styleUrls: ['./menu-template-selector-form.component.css']
})
export class MenuTemplateSelectorFormComponent implements OnInit {
  faEye = faEye;
  selectedLang: string;
  menus$: Observable<Menu[]>;
  @Output() templateSel: EventEmitter<Menu> = new EventEmitter<Menu>();
  constructor(public service: MenuTemplateAdminService, private tService: TranslateService, public modalService: NgbModal) {
    this.selectedLang = this.tService.currentLang.toUpperCase();
    this.service.selectedLang = this.tService.currentLang.toUpperCase();
    this.tService.onLangChange.subscribe((data) => {
      this.selectedLang = data.lang.toUpperCase();
      this.service.selectedLang = data.lang.toUpperCase();
    });
    this.service.getAllDataTable();
    this.menus$ = service.data$;
  }

  ngOnInit(): void {
  }
  viewMenu(menu: Menu) {
    const modal = this.modalService
        .open(MenuViewModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'modal_class' });
    modal.componentInstance.menu = menu;
    modal.componentInstance.selectedLanguage = this.selectedLang;
  }
  templateSelected(menu: Menu) {
    let copy_menu: any = this.deepMerge({}, menu);
    copy_menu = this.cleanMenu(copy_menu);
    this.templateSel.emit(copy_menu);
  }

  private cleanMenu(menu: Menu) {
    function cleanMenuRec(st: Structure) {
      switch (st.t) {
        case 'dish':
          (st as DishStructure).id = '';
          return;
        case 'dishcollection':
          (st as DishCollection).m = '';
          (st as DishCollection).c = [];
          (st as DishCollection).d = [];
          return;
        case 'container':
          (st as Container).cnt.forEach(ci => {
            cleanMenuRec(ci);
          });
          return;
        case 'table':
          (st as Table).cnt.forEach(ri => {
            ri.forEach(ci => cleanMenuRec(ci));
          });
          return;
        case 'page':
          cleanMenuRec((st as Page).cnt);
          return;
        default:
            return;
      }
    }
    delete menu.ss;
    delete menu.st;
    delete menu.c;
    if (menu.h) {
      cleanMenuRec(menu.h);
    }
    menu.cnt.forEach(ci => cleanMenuRec(ci));
    return menu;
  }


  private deepMerge(...objects: object[]) {
    const isObject = (obj: any) => obj && typeof obj === 'object';

    function deepMergeInner(targetInner: object, sourceInner: object) {
      Object.keys(sourceInner).forEach((key: string) => {
        const targetValue = targetInner[key];
        const sourceValue = sourceInner[key];

        if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
          targetInner[key] = targetValue.concat(sourceValue);
        } else if (isObject(targetValue) && isObject(sourceValue)) {
          targetInner[key] = deepMergeInner(Object.assign({}, targetValue), sourceValue);
        } else {
          targetInner[key] = sourceValue;
        }
      });
      return targetInner;
    }

    if (objects.length < 2) {
      throw new Error('deepMerge: this function expects at least 2 objects to be provided');
    }
    if (objects.some(object => !isObject(object))) {
      throw new Error('deepMerge: all values should be of type "object"');
    }
    const targetOut = objects.shift();
    let sourceOut: object;
    while (sourceOut = objects.shift()) {
      deepMergeInner(targetOut, sourceOut);
    }
    return targetOut;
  }
}
