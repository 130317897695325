import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: "app-qr-viewer",
  templateUrl: "./qr-viewer.component.html",
  styleUrls: ["./qr-viewer.component.css"],
})
export class QrViewerComponent implements OnInit {
  _qrText = '';
  @Input() set elementToEdit(value: string) {
    this._qrText = value;
  }

  constructor() {}

  ngOnInit(): void {}

  download() {
    const a = document.createElement("a");
    a.href = document.getElementsByTagName("canvas").item(0).toDataURL();
    // console.log(a.href);
    a.setAttribute("download", 'qr.png');
    a.click();
  }
}
