<div class="inner-adjust font">
    <form>
        <div class="pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2 inlineComp font newtitle">{{'media-gallery.mediagalleries' | translate }}</h1>
            <button type="button" (click)="add();" class="btn btn-primary bmd-btn-fab rightBtn inlineComp plusbtn">
            <fa-icon [icon]="faPlus"></fa-icon>
            <h1 class="h2 inlineComp font Addtext">{{'media-gallery.addmediagalleries' | translate }}</h1>
        </button>
            <div class="form-group form-inline rightBtn">
                <fa-icon *ngIf="!(service.instance.loading$ | async)" [icon]="faSearch"></fa-icon>
                <fa-icon class="rotateInfinite" *ngIf=" service.instance.loading$ | async" [icon]="faSpinner"></fa-icon><input class="form-control ml-2 searchitem" type="text" name="searchTerm" [(ngModel)]="service.instance.searchTerm" />

            </div>
        </div>
        <table class="table table-striped newtable">
            <thead class="headline">
                <tr>
                    <th scope="col" sortable="name" direction="asc" (sort)="onSort($event)" class="newborderleft newcolor">{{'media-gallery.name' | translate }}</th>
                    <th scope="col" class="newcolor">{{'media-gallery.image' | translate }}</th>
                    <th scope="col" class="newcolor">{{'media-gallery.languages' | translate }}</th>
                    <th class="fit newborderright newcolor" scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let med of medias$ | async " class="contentline">
                    <td class="formatocelda">
                        <ngb-highlight [result]="(med.nt[selectedLang])? med.nt[selectedLang] : med.n" [term]="service.instance.searchTerm"></ngb-highlight>
                    </td>
                    <td class="formatocelda">
                        <img [src]="med.url">
                        <!-- <ngb-highlight [result]="getLanguages(cat)" [term]="service.searchTerm"></ngb-highlight> -->
                    </td>
                    <td class="formatocelda">
                        {{ getLanguages(med) }}
                    </td>
                    <td class="fit" scope="row">
                        <button type="button" (click)="show(med)" class="btn btn-primary bmd-btn-fab inlineComp btnTaleRow rightbtn"><fa-icon [icon]="(med.a)? faEye : faEyeSlash"></fa-icon></button>
                        <button type="button" (click)="edit(med)" class="btn btn-success bmd-btn-fab inlineComp btnTaleRow rightbtn"><fa-icon [icon]="faEdit"></fa-icon></button>
                        <button type="button" [disabled]="getNumberMenus(med) > 0" (click)="delete(med)" class="btn btn-danger bmd-btn-fab inlineComp btnTaleRow rightbtn"><fa-icon [icon]="faTrash"></fa-icon></button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-between p-2 selectpag">
            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.instance.page" [pageSize]="service.instance.pageSize">
            </ngb-pagination>

            <select class="custom-select paginas" style="width: auto" name="pageSize" [(ngModel)]="service.instance.pageSize">
                <option [ngValue]="15">15 {{'common.itemsperpage' | translate}}</option>
                <option [ngValue]="30">30 {{'common.itemsperpage' | translate}}</option>
                <option [ngValue]="50">50 {{'common.itemsperpage' | translate}}</option>
            </select>
        </div>
    </form>
</div>