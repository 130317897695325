<nav class="navbar bg-dark flex-md-nowrap p-0 font background">
    <a class="navbar-brand col-sm-3 col-md-2 mr-0 navbarcolor-brand navbrand" href="http://fuddie.es">
        <img class="brand-logo logo" src="assets/Logo_Blanc_SP.png" alt="Fuudie">
    </a>
    <div class="menuicons">
        <fa-icon class="fas fa-reply iconsnorm" (click)="back()"></fa-icon>
        <!-- <fa-icon class="fas fa-language iconsnorm"></fa-icon> -->
    </div>
</nav>
<div class="fomrnorm">
    <p style="text-align: center;"><strong>Pol&iacute;tica de privacidad</strong></p>
    <p>Versi&oacute;n&nbsp;1 junio 2020<br />INGY SOLUTIONS S.C.P (en adelante, FUUDIE) desea poner en conocimiento de los usuarios de nuestra web www.fuudie.es (en adelante, Sitio web) y de nuestros clientes y suscriptores su Pol&iacute;tica de Privacidad,
        en la que proporciona a clientes, suscriptores y usuarios del Sitio Web informaci&oacute;n de manera transparente de todos los trata mientos que realizamos con sus datos, las finalidades para las cuales los tratamos y los derechos que pueden ejercer
        sobre sus datos personales.&nbsp;La presente Pol&iacute;tica de Privacidad debe leerse conjuntamente con los&nbsp;t&eacute;rminos y condiciones de contrataci&oacute;n que regulan el uso del Sitio Web y el contrato de compraventa de productos,
        la suscripci&oacute;n servicio de carta virtual y consiguientemente, la relaci&oacute;n entre FUUDIE y los usuarios, suscriptores y clientes (en adelante, las &ldquo;T&eacute;rminos y Condiciones&rdquo;). Podr&aacute;s encontrar la presente pol&iacute;tica
        de privacidad siempre a tu disposici&oacute;n en nuestra web www.fuudie.es<br />&nbsp;<br /><strong>&nbsp;1. &iquest;QUI&Eacute;NES SOMOS?</strong></p>
    <p><br />La compa&ntilde;&iacute;a responsable del tratamiento de tus datos personales es INGY SOLUTIONS S.C.P (en adelante, FUUDIE), con CIF (MIQUEL VERGÉS VILARRUBIA 47851672B) y con domicilio social en Av/ Pius XII 22, 08500 Vic (Barcelona). Puedes
        contactar con nosotros a trav&eacute;s del correo electr&oacute;nico fuudie@fuudie.es.&nbsp;<br />2. &iquest;QU&Eacute; SON UN DATO DE CAR&Aacute;CTER PERSONAL Y UN TRATAMIENTO?<br />Dato de car&aacute;cter personal es toda informaci&oacute;n
        sobre una persona f&iacute;sica identificada o identificable. Se considerar&aacute; persona f&iacute;sica identificable toda persona cuya identidad pueda determinarse, directa o indirectamente, en particular mediante un identificador, como por
        ejemplo un nombre, un n&uacute;mero de identificaci&oacute;n, datos de localizaci&oacute;n, un identificador en l&iacute;nea o uno o varios elementos propios de la identidad f&iacute;sica, fisiol&oacute;gica, gen&eacute;tica, ps&iacute;quica,
        econ&oacute;mica, cultural o social de dicha persona Un tratamiento de car&aacute;cter personal es toda operaci&oacute;n o conjunto de operaciones que realizamos sobre tus datos personales, como, por ejemplo, la recogida, registro, conservaci&oacute;n,
        utilizaci&oacute;n y comunicaci&oacute;n de tus datos.<br />&nbsp;<br /><strong>3. &iquest;QU&Eacute; DATOS PERSONALES RECOPILAMOS Y A TRAV&Eacute;S DE QU&Eacute; CANALES LO HACEMOS?</strong></p>
    <p><br />Para poder ejecutar realizar los tratamientos de datos especificados en la presente Pol&iacute;tica de Privacidad, necesitamos determinada informaci&oacute;n sobre ti. Por ello recopilamos la informaci&oacute;n que nos facilitas a trav&eacute;s
        de los distintos canales de interacci&oacute;n y comunicaci&oacute;n con FUUDIE como son, el formulario de registro en la web para dar de alta una cuenta de usuario, los formularios de solicitud de informaci&oacute;n y contacto, el formulario
        de proceso de compra o de suscripci&oacute;n servicios, las encuestas de valoraci&oacute;n del servicio, los distintos canales de servicio de atenci&oacute;n al cliente (tel&eacute;fono de contacto, centro de ayuda y formulario online de contacto),
        los perfiles corporativos de FUUDIE en redes sociales, la aplicaci&oacute;n para m&oacute;viles de FUUDIE, etc. Los datos que podemos recabar a trav&eacute;s de los anteriores canales son tu nombre y apellidos, fecha de nacimiento, g&eacute;nero,
        nacionalidad, n&uacute;mero de D.N.I, domicilio completo, n&uacute;mero de m&oacute;vil, direcci&oacute;n de correo electr&oacute;nico, tus datos bancarios para realizar el pago, tu historial de compra de productos y/o suscripci&oacute;n, las
        respuestas que nos facilites a encuestas de satisfacci&oacute;n o valoraci&oacute;n de productos, preferencias, gustos y temas que son de tu inter&eacute;s, detalles de las compras y/o suscripciones de servicios que realices, tus perfiles de redes
        sociales (en los casos en que consientas en compartir esta informaci&oacute;n personal con nosotros), informaci&oacute;n sobre tu uso y navegaci&oacute;n por el Sitio Web (p&aacute;gina web desde la que llegas al Sitio Web, capas del mismo visitadas,
        procesos iniciados y abandonados, direcci&oacute;n IP, tipo de dispositivo desde el que acceder, navegador de Internet empleado, tiempo que has permanecido en el Sitio Web, etc.).</p>
    <p><br /><strong>4. A TRAV&Eacute;S DE QU&Eacute; V&Iacute;AS CAPTAMOS TUS DATOS PERSONALES?</strong></p>
    <p><br />Podemos captar informaci&oacute;n sobre ti directamente de ti a trav&eacute;s de las siguientes v&iacute;as:</p>
    <ul>
        <li>Formulario de registro de cuenta: en el que nos comunicas datos personales para poder dar de alta tu cuenta de usuario en el Sitio Web.</li>
        <li>Formulario de compra o suscripci&oacute;n: en el que nos comunicas datos personales para poder ejecutar las Condiciones de uso y contrataci&oacute;n.</li>
        <li>Formularios de solicitud de informaci&oacute;n: en el que nos comunicas datos personales para solicitar informaci&oacute;n de nuestros productos, condiciones de pago, m&eacute;todos de cancelaci&oacute;n de suscripciones, etc.</li>
        <li>Respuestas a valoraciones de productos y/o servicios y encuestas de valoraciones de productos, servicios, experiencia como cliente, nivel de satisfacci&oacute;n etc.</li>
        <li>Interacci&oacute;n con FUDDIE al usar nuestro servicio de atenci&oacute;n al cliente: a trav&eacute;s de los distintos canales que ponemos a tu disposici&oacute;n como son nuestro centro de ayuda</li>
    </ul>
    <p><strong>5. &iquest;QU&Eacute; DERECHOS PUEDES EJERCER?</strong></p>
    <ul>
        <li>Derecho de acceso<br />Tienes derecho a que FUUDIE te confirme si est&aacute; tratando o no tus datos personales, y en tal caso, derecho a conocer qu&eacute; datos trata.</li>
        <li>Derecho de rectificaci&oacute;n<br />Consiste en tu derecho a modificar aquellos datos tuyos que sean inexactos o incompletos. Siempre que nos solicites rectificar alg&uacute;n dato personal tuyo, deber&aacute;s indicar qu&eacute; datos deseas
            que se modifiquen.</li>
        <li>Derecho de oposici&oacute;n<br />Puedes oponerte en cualquier momento a que tratemos tus datos. Sin embargo, existen determinados supuestos en los que legalmente se establece que no puedes ejercer este derecho (como por ejemplo, cuando la base
            legitimadora de un tratamiento de tus datos personales sea la ejecuci&oacute;n de un contrato o el cumplimiento de una obligaci&oacute;n legal por nuestra parte). Exceptuando estos supuestos, puedes oponerte en cualquier momento, por motivos
            relacionados con tu situaci&oacute;n particular, a que datos personales que te conciernan sean objeto de tratamiento por nuestra parte. Este es un derecho que claramente puedes ejercer cuando los tratamientos de datos est&eacute;n basados
            en nuestro inter&eacute;s leg&iacute;timo como empresa. En caso de que ejerzas este derecho, FUUDIE dejar&aacute; de tratar los datos personales, pudiendo anonimizarlos o eliminarlos, salvo que acreditemos motivos leg&iacute;timos para el
            tratamiento que prevalezcan sobre tus intereses, derechos y libertades, como por ejemplo su conservaci&oacute;n durante un plazo prudencial para la formulaci&oacute;n, el ejercicio o la defensa de reclamaciones.</li>
        <li>Derecho de supresi&oacute;n<br />Este derecho permite la cancelaci&oacute;n de tus datos personales. Esto no significa que tus datos sean totalmente eliminados, sino que tus datos se conservar&aacute;n bloqueados de manera que impida su tratamiento,
            sin perjuicio de su puesta a disposici&oacute;n de las administraciones p&uacute;blicas, jueces y tribunales, para la atenci&oacute;n de posibles responsabilidades que hayan surgido del tratamiento durante su plazo de prescripci&oacute;n.
            Una vez superado ese plazo, tus datos ser&aacute;n debidamente anonimizados o eliminados.</li>
        <li>Derecho a la portabilidad de datos<br />Tienes derecho a transferir aquellos de tus datos que te incumban y que nos hayas facilitado a otro responsable del tratamiento diferente de FUUDIE. Solo es posible ejercer este derecho cuando el tratamiento
            est&eacute; basado en la ejecuci&oacute;n de un contrato o en tu consentimiento y el tratamiento se realice por medios automatizados.</li>
        <li>Derecho a la limitaci&oacute;n en el tratamiento<br />Este derecho te permite solicitarnos que suspendamos el tratamiento de tus datos cuando:
            <ul>
                <li>Hayas impugnado la exactitud de los datos, mientras FUUDIE verifica dicha exactitud.</li>
                <li>Hayas ejercido tu derecho de oposici&oacute;n al tratamiento de tus datos, mientras se verifica si los motivos leg&iacute;timos de FUUDIE prevalecen sobre los tuyos como interesado.</li>
            </ul>
        </li>
    </ul>
    <p>Igualmente, este derecho te permite solicitar a FUUDIE que conserve tus datos personales cuando:</p>
    <ul>
        <li>El tratamiento de datos sea il&iacute;cito y como interesado te opongas a la supresi&oacute;n de tus datos y solicites en su lugar una limitaci&oacute;n de su uso.</li>
        <li>FUUDIE ya no necesite tus datos personales para los fines del tratamiento, pero los necesite para la formulaci&oacute;n, ejercicio, o defensa de reclamaciones.<br />Podr&aacute;s ejercer tus derechos, mand&aacute;ndonos tu petici&oacute;n a la
            siguiente direcci&oacute;n de correo electr&oacute;nico fuudie@fuudie.es o bien por correo postal, acreditando en ambos casos tu identidad, dirigido a: INGY SOLUTIONS S.C.P, Av/Pius XII 22, 2&ordf;, 1&ordf;, 08500, Vic (Barcelona).</li>
    </ul>
    <p><br />Asimismo, te informamos de que, en el caso de que consideres que FUUDIE no ha satisfecho correctamente el ejercicio de tus derechos, podr&aacute;s presentar una reclamaci&oacute;n ante la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos
        (AEPD), domiciliada en calle Jorge Juan, n&ordm; 6, 28001, Madrid (Madrid). Si quieres conocer m&aacute;s informaci&oacute;n sobre c&oacute;mo solicitar la protecci&oacute;n de la AEPD, puedes dirigirte a su p&aacute;gina web, http://www.agpd.es
        , o solicitar informaci&oacute;n en los n&uacute;mero de tel&eacute;fono 901 100 099 y 91.266.35.17.<br />&nbsp;<br /><strong>6. &iquest;QU&Eacute; TRATAMIENTOS REALIZAMOS SOBRE TUS DATOS PERSONALES?</strong></p>
    <p><br />Para poder informarte de forma transparente y con detalle sobre las finalidades para las cuales tratamos tus datos hemos procedido a separar la informaci&oacute;n relativa a cada tratamiento en cuadros independientes. As&iacute;, podr&aacute;s
        encontrar toda la informaci&oacute;n espec&iacute;fica de cada uno de los tratamientos que realizamos de tus datos en su correspondiente cuadro.<br />Cada cuadro descriptivo de los correspondientes tratamientos recoge la siguiente informaci&oacute;n:</p>
    <p><br /><strong>1. &iquest;Para qu&eacute; finalidades tratamos tus datos?</strong></p>
    <p><br />En esta columna explicamos para qu&eacute; y con qu&eacute; finalidad tratamos tus datos personales.</p>
    <p><br /><strong>2. &iquest; Sobre qu&eacute; base legal tratamos tus datos?</strong></p>
    <p><br />Esta columna explica el fundamento legal que nos permite tratar tus datos personales. La normativa de protecci&oacute;n de datos requiere que para el tratamiento de tus datos tengamos una base que legitime dicho tratamiento. As&iacute;, para
        tratar tu informaci&oacute;n personal nos basamos en 4 bases legales dependiendo de la actividad o tratamiento que llevemos a cabo de tus datos. Las bases legales para el tratamiento de tus datos personales pueden ser:</p>
    <ul>
        <li>Inter&eacute;s leg&iacute;timo: Como empresa, FUUDIE tiene un inter&eacute;s leg&iacute;timo en recopilar y tratar tus datos de car&aacute;cter personal con el fin de estudiar y analizar la adecuaci&oacute;n de nuestro Sitio Web, productos y servicios,
            si se adaptan a las necesidades de nuestros clientes o, por el contrario, deben ser modificado en alg&uacute;n aspecto concreto o pueden ser mejorados para adecuarse mejor a dichas necesidades. Tambi&eacute;n tiene un inter&eacute;s leg&iacute;timo
            en mantener un contacto regular contigo para mantenerte informado sobre cuestiones promocionales o publicitarias sobre sus productos y servicios. En todo caso, este inter&eacute;s leg&iacute;timo se basa en un an&aacute;lisis sobre el adecuado
            equilibrio de nuestros intereses en tratar tus datos de car&aacute;cter personal y tus derechos y libertades. El resultado de dicho an&aacute;lisis determinar&aacute; el que podamos utilizar o no tu informaci&oacute;n personal para los tratamientos
            descritos en la presente Pol&iacute;tica de Privacidad (salvo en lo relativo al tratamiento de segmentaci&oacute;n y perfilado para el env&iacute;o de comunicaciones comerciales, respecto del cual requerimos tu consentimiento expreso).</li>
        <li>La ejecuci&oacute;n del contrato: En el momento en que aceptas expresamente los T&eacute;rminos y Condiciones, te conviertes en parte del contrato de compraventa de producto o suscripci&oacute;n de los servicios de FUUDIE suscrito con nosotros.
            Con el fin de que podamos cumplir las obligaciones recogidas en dichos T&eacute;rminos y Condiciones necesitamos recopilar y tratar tus datos de car&aacute;cter personal. As&iacute;, tu negativa a proporcionarnos aquellos datos personales
            que resulten necesarios para ejecutar los T&eacute;rminos y Condiciones implicar&aacute; la imposibilidad de que puedas contratar productos o servicios con FUUDIE, ya que no ser&iacute;a posible en tal caso ejecutar dicho contrato y que pudieras
            beneficiarte del mismo.</li>
        <li>El cumplimiento de una obligaci&oacute;n legal: FUUDIE como empresa, act&uacute;a como responsable de tus datos y es por ello por lo que debemos cumplir con una serie de obligaciones impuestas por normativa contable, fiscal, consumo, etc. En aquellos
            casos en que la justificaci&oacute;n legal de alguno de nuestros tratamientos sea el cumplimiento de una obligaci&oacute;n legal, tu negativa a proporcionarnos aquellos datos personales que resulten legalmente obligatorios implicar&aacute;
            la imposibilidad de que puedas contratar productos o servicios con FUUDIE, pues hacerlo conllevar&iacute;a un incumplimiento de la normativa a la que estamos sujetos.</li>
        <li>Tu consentimiento: En el caso de que FUUDIE desee realizar alg&uacute;n tratamiento de tus datos cuya justificaci&oacute;n legal no pueda basarse en ninguno de los motivos anteriormente explicados, solicitaremos previamente tu consentimiento para
            dicho tratamiento. En todo caso, te informamos, que en tales casos siempre podr&aacute;s retirar tu consentimiento.</li>
    </ul>
    <p><strong>3. &iquest;Durante cu&aacute;nto tiempo conservamos tus datos personales?</strong><br /> <br />En esta columna se informa de manera orientativa durante cu&aacute;nto tiempo se conservar&aacute;n tus datos. El tiempo de conservaci&oacute;n
        depender&aacute; en todo caso del tratamiento que se lleve a cabo sobre tu informaci&oacute;n personal. Debes tener en cuenta que determinada normativa contable, fiscal, de consumo, protecci&oacute;n de datos y reguladora de los contratos nos
        puede obligar a conservar determinados datos de los clientes y usuarios durante un tiempo determinado.</p>
    <p><strong>4. &iquest;Cu&aacute;les son tus derechos?</strong></p>
    <p><br />En esta columna se informa de manera orientativa durante cu&aacute;nto tiempo se conservar&aacute;n tus datos. El tiempo de conservaci&oacute;n depender&aacute; en todo caso del tratamiento que se lleve a cabo sobre tu informaci&oacute;n personal.
        Debes tener en cuenta que determinada normativa contable, fiscal, de consumo, protecci&oacute;n de datos y reguladora de los contratos nos puede obligar a conservar determinados datos de los clientes y usuarios durante un tiempo determinado.</p>
    <p><br /><strong>4.1&iquest;Qu&eacute; es la segmentaci&oacute;n y perfilado?</strong></p>
    <p><br />Con el fin de dise&ntilde;ar y enviarte promociones y comunicaciones comerciales y publicitarias lo m&aacute;s personalizadas posibles seg&uacute;n tus intereses, gustos, caracter&iacute;sticas y h&aacute;bitos de compra o aprovechamiento del
        propio programa utilizamos t&eacute;cnicas de segmentaci&oacute;n, anal&iacute;tica y perfilado para conocer mejor cu&aacute;les son tus gustos, intereses y preferencias.<br />La segmentaci&oacute;n y el perfilado son t&eacute;cnicas y procesos
        automatizados cuya finalidad es mejorar la calidad de los datos de los clientes, suscriptores y usuarios de que disponemos. La aplicaci&oacute;n de t&eacute;cnicas de perfilado sobre los datos de los clientes y usuarios no permite descubrir y
        definir requisitos y reglas de calidad sobre dichos datos y las t&eacute;cnicas de segmentaci&oacute;n nos permiten segmentar los clientes y usuarios en base a los distintos requisitos y reglas obtenidos con las t&eacute;cnicas de perfilado. As&iacute;,
        podemos identificar unos perfiles comunes de clientes, suscriptores y usuarios a los que podemos ofrecer promociones, productos, servicios e informaci&oacute;n mucho m&aacute;s personalizados seg&uacute;n sus gustos, intereses y preferencias.</p>
    <p><br /><strong>4.2 &iquest;Qu&eacute; informaci&oacute;n captamos para llevar a cabo el perfilado?</strong></p>
    <p><br />Para llevar a cabo el perfilado necesitamos captar y tratar la informaci&oacute;n relacionada en el punto 3 de la presente pol&iacute;tica de privacidad, entre ella, tus datos b&aacute;sicos (domicilio, edad, nombre y direcci&oacute;n completos),
        temas de inter&eacute;s, detalles de los servicios o compras de productos que realices, tus perfiles de redes sociales (en los casos en que consientas en compartir esta informaci&oacute;n personal con nosotros), informaci&oacute;n sobre tu historial
        de compra, patr&oacute;n de uso del Sitio Web, opiniones sobre productos, servicios, eventos, etc.</p>
    <p><strong>4.3 &iquest;C&oacute;mo afecta el perfilado al env&iacute;o de comunicaciones comerciales y promociones por v&iacute;a electr&oacute;nica?</strong></p>
    <p><br />Los perfiles obtenidos son utilizados para ofrecerte promociones y comunicaciones comerciales personalizadas, acordes con tus gustos, preferencias, intereses e historial de compra. As&iacute;, evitamos enviarte promociones o comunicaciones comerciales
        que no sean de tu inter&eacute;s. El perfilado del conjunto de tus datos personales nos permite conocer mejor tus gustos, de manera que las promociones y las comunicaciones comerciales que recibas ser&aacute;n m&aacute;s personalizadas y encajar&aacute;n
        en mayor medida con tus preferencias e intereses.<br />Como resultado, mediante la segmentaci&oacute;n y el perfilado podemos agrupar a los clientes y usuarios seg&uacute;n coincidan o se diferencien en distintas reglas como, por ejemplo, los
        productos que les interesan, las promociones a las que m&aacute;s se acogen, sus h&aacute;bitos de compra o su respuesta a las comunicaciones comerciales enviadas por FUUDIE.<br />Es decir, aplicando t&eacute;cnicas y herramientas de segmentaci&oacute;n
        y perfilado sobre los datos de los clientes y usuarios, FUUDIE puede obtener distintos perfiles de clientes y usuarios con el fin de agruparlos y ofrecerles de forma diferenciada productos y servicios, promociones, experiencias de compra y comunicaciones
        comerciales mucho m&aacute;s acordes y afines a sus caracter&iacute;sticas particulares.</p>
    <p><br /><strong>5. &iquest;A QUI&Eacute;N COMUNICAMOS TUS DATOS?</strong></p>
    <p>1. FUUDIE comunicar&aacute; tus datos de car&aacute;cter personal que sean estrictamente necesarios a terceros con el fin de poder cumplir las Condiciones de uso y contrataci&oacute;n suscrito con el cliente o usuario, como, por ejemplo:<br />- Comunicaci&oacute;n
        a plataformas de pago, bancos y empresas respecto de las cuales resulte necesaria dicha comunicaci&oacute;n para que puedan procesarse las transacciones que resulten necesarias en cada momento.<br />- Marcas colaboradoras con las que organicemos
        acciones promocionales conjuntamente y solicitemos tu consentimiento para ello.<br />- Otros posibles terceros a los que podemos comunicar tus datos, previo requerimiento legal o base que legitime la comunicaci&oacute;n:</p>
    <ul>
        <li>Asesores legales</li>
        <li>Tribunales</li>
        <li>Administraci&oacute;n Tributaria</li>
        <li>Organismos de gobierno y administraci&oacute;n p&uacute;blica</li>
        <li>Fuerzas y cuerpos de seguridad.</li>
        <li>Encargados de tratamiento que, para prestar los servicios necesarios deben acceder a cierta informaci&oacute;n y/o datos personales. Son encargados del tratamiento, por ejemplo, las compa&ntilde;&iacute;as de transporte y mensajer&iacute;a encargadas
            de llevar tu pedido a la direcci&oacute;n solicitada. Los datos podr&aacute;n ser cedidos a proveedores con acceso a datos con quienes se formaliza las obligaciones y responsabilidades que asumen en el tratamiento de los datos, en calidad
            de Encargados de Tratamiento. FUUDIE suscribe los correspondientes contratos de encargo de tratamiento que recogen las debidas garant&iacute;as en cuanto al tratamiento de los datos de car&aacute;cter personal, la confidencialidad y la cancelaci&oacute;n,
            destrucci&oacute;n o devoluci&oacute;n.</li>
    </ul>
    <p>Asimismo, podremos comunicar tu informaci&oacute;n personal en los siguientes casos:</p>
    <ul>
        <li>Si nosotros o sustancialmente todos nuestros activos, son adquiridos por un tercero en cuyo caso, los datos de car&aacute;cter personal que tenemos de nuestros clientes y usuarios ser&aacute;n uno de los activos transferidos. Cualquier operaci&oacute;n
            de este tipo ser&aacute; previamente informada y comunicada.</li>
        <li>Para proteger la seguridad y los derechos de nuestro personal, nuestros clientes y usuarios y nuestros sistemas de informaci&oacute;n. Ello incluye intercambiar informaci&oacute;n con fuerzas y cuerpos de seguridad o Tribunales en los casos de
            detecci&oacute;n de fraude, usurpaci&oacute;n de identidad o cualquier otro uso, tratamiento o hecho il&iacute;cito.</li>
    </ul>
    <p><strong>6. &iquest;QUI&Eacute;N PUEDE ACCEDER A TUS DATOS?</strong></p>
    <p>FUUDIE te informa que trabajamos con terceros proveedores necesarios para la correcta presentaci&oacute;n y usabilidad de del Sitio Web y ejecuci&oacute;n del contrato de compraventa de producto o contrataci&oacute;n del servicio. &Eacute;stos pueden
        en el ejercicio de su actividad, tener acceso a tus datos, como son, por ejemplo, los proveedores que nos ayudan a mejorar y optimizar nuestro Sitio Web, los proveedores de servicios tecnol&oacute;gicos o de gesti&oacute;n de cobros, las compa&ntilde;&iacute;as
        de transporte y mensajer&iacute;a encargadas de llevar tu pedido a la direcci&oacute;n solicitada y otros terceros, o aquellos que nos ofrecen servicios de mantenimiento de nuestras bases de datos.<br /><strong>&nbsp;</strong><br /><strong>7. &iquest;EST&Aacute;N TUS DATOS SEGUROS?</strong></p>
    <p><br />Con el fin de asegurar un procesamiento justo y transparente de tu informaci&oacute;n personal, adoptaremos los procedimientos adecuados que incluir&aacute;n la implementaci&oacute;n de medidas t&eacute;cnicas y organizativas que tengan en cuenta
        el posible riesgo y corrijan cualquier imprecisi&oacute;n identificada en los datos personales tratados, de modo que el riesgo de cualquier error se minimice, tratando tus datos de manera justa y segura.</p>
    <p>Toda la informaci&oacute;n que nos proporcionas se almacena en servidores seguros. Lamentablemente, la transmisi&oacute;n o comunicaci&oacute;n de informaci&oacute;n a trav&eacute;s de internet no es complemente segura. As&iacute;, una vez hayamos
        recibido toda tu informaci&oacute;n utilizaremos procedimientos estrictos de seguridad para tratar de evitar cualquier acceso no autorizado.</p>
    <p>Igualmente, nos aseguramos de que, nuestros proveedores de servicios tambi&eacute;n gozan de est&aacute;ndares de seguridad adecuados para la protecci&oacute;n de los datos de car&aacute;cter personal respecto de los cuales tengan o puedan llegar
        a tener acceso, en atenci&oacute;n con la legislaci&oacute;n de protecci&oacute;n de datos aplicable.</p>
    <p><br /><strong>8. CAMBIOS EN LA PRESENTE POL&Iacute;TICA DE PRIVACIDAD</strong></p>
    <p>La presente Pol&iacute;tica de Privacidad podr&aacute; variar con el tiempo debido a los posibles cambios de criterio seguidos por la autoridad de control competente en materia de protecci&oacute;n de datos en cada momento. FUUDIE se reserva por tanto
        el derecho a modificar la presente Pol&iacute;tica de Privacidad para poder adaptarla a dichos criterios, as&iacute; como a novedades jurisprudenciales o legislativas. &Uacute;ltima versi&oacute;n: Versi&oacute;n&nbsp;1 junio 2020</p>
    <p><strong>10. CONTACTO CON BIRCHBOX</strong></p>
    <p><br />Si tienes alguna duda acerca de los tratamientos que realizamos sobre tus datos personales, ponte en contacto con nosotros a trav&eacute;s del correo electr&oacute;nico: fuudie@fuudie.es.</p>
</div>