import { Directive, ViewContainerRef, Output, EventEmitter, Input } from '@angular/core';
import { Structure } from '../../../shared/models/menu.model';

@Directive({
  selector: '[container-host]',
})
export class ContainerDirective {
  @Output() edit = new EventEmitter<Structure>();
  @Output() indexChange = new EventEmitter();
  @Input() showUtils: boolean;
  constructor(public viewContainerRef: ViewContainerRef) { }
}
