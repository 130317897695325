import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Stepper from 'bs-stepper';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { countryListAllIsoData } from '../../shared/data/data';
import { CreditCardValidators } from 'angular-cc-library';
import { AccountType } from '../../shared/models/account-type.model';
import { AuthService } from '../../shared/services/auth.service';
import { User } from '../../shared/services/user';

@Component({
  selector: 'app-stepper-billing-info-modal',
  templateUrl: './stepper-billing-info-modal.component.html',
  styleUrls: ['./stepper-billing-info-modal.component.css']
})
export class StepperBillingInfoModalComponent implements OnInit {
  countries = countryListAllIsoData;
  private stepper: Stepper;
  uBillForm: FormGroup;
  uCardForm: FormGroup;
  currentUser: User = null;
  selectedAccount: AccountType = null;
  constructor(public activeModal: NgbActiveModal,
    private fb: FormBuilder, private authService: AuthService) {
    this.uBillForm = this.fb.group(
      {
        name: ["", [Validators.required]],
        line1: ["", [Validators.required]],
        city: ["", [Validators.required]],
        postal_code: ["", [Validators.required]],
        country: ["", [Validators.required]],
        state: ["", [Validators.required]],
        email: ["", [Validators.required, Validators.email]],
        phone: ["", [Validators.required]],
        cif: ["", [Validators.required]],
      },
      { validators: ValidateSpanishID }
    );
    this.uCardForm = this.fb.group({
      ccnumber: ["", [CreditCardValidators.validateCCNumber]],
      name: ["", [Validators.required]],
      expirationDate: ["", [CreditCardValidators.validateExpDate]],
      cvc: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(4)],
      ],
    });
   }
  //  get f() {
  //   return this.uInfoForm.controls;
  // }
  // get p() {
  //   return this.uPassForm.controls;
  // }
  get b() {
    return this.uBillForm.controls;
  }
  get c() {
    return this.uCardForm.controls;
  }
  ngOnInit(): void {
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    });
    this.authService.GetUser().then((u) => {
      this.currentUser = u;
    });
  }
  next() {
    this.stepper.next();
  }
  back() {

  }
  planSelected(plan) {
    this.selectedAccount = plan;
    this.stepper.next();
  }
  register() {
    this.authService
    .SetAccountType(
      this.currentUser,
      this.selectedAccount
    )
    .then(
      () => {
    if (this.uCardForm.valid) {
      this.authService
        .SetCreditCard(this.currentUser, {
          object: "card",
          name: this.uCardForm.controls["name"].value,
          cvc: this.uCardForm.controls["cvc"].value,
          number: this.uCardForm.controls["ccnumber"].value,
          exp_month: +this.uCardForm.controls["expirationDate"].value
            .split("/")[0]
            .trim(),
          exp_year: +this.uCardForm.controls["expirationDate"].value
            .split("/")[1]
            .trim(),
        })
        .then(
          () => {
            if (this.uBillForm.valid) {
              this.authService
                .SetBillingInfo(this.currentUser, {
                  name: this.uBillForm.controls["name"].value,
                  address: {
                    line1: this.uBillForm.controls["line1"].value,
                    city: this.uBillForm.controls["city"].value,
                    postal_code: this.uBillForm.controls["postal_code"].value,
                    country: this.uBillForm.controls["country"].value,
                    state: this.uBillForm.controls["state"].value,
                  },
                  email: this.uBillForm.controls["email"].value,
                  phone: this.uBillForm.controls["phone"].value,
                  tax_id_data: [
                    {
                      type: "es_cif",
                      value: this.uBillForm.controls["cif"].value,
                    },
                  ],
                  payment_method: '',
                })
                .then(
                  () => {
                    this.activeModal.close();
                  },
                  (reason) => {
                    alert(reason);
                  }
                );
            }
          },
          (reason) => {
            alert(reason);
          }
        );
    }
  }, (reason) => {
    alert('Error: ' + reason);
  });
  }
}
//#region Validators

const DNI_REGEX = /^(\d{8})([A-Z])$/;
const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

function checkPasswords(group: FormGroup) {
  // here we have the 'passwords' group
  const pass = group.get("newPass").value;
  const confirmPass = group.get("confirmNewPass").value;

  return pass === confirmPass ? null : { notSame: true };
}
function ValidateSpanishID(group: FormGroup) {
  // Ensure upcase and remove whitespace
  let str = group.get("cif").value;
  str = str.toUpperCase().replace(/\s/, "");

  let valid = false;
  const type = spainIdType(str);
  console.log('TYPE: ', type);
  switch (type) {
    case "dni":
      valid = validDNI(str);
      break;
    case "nie":
      valid = validNIE(str);
      break;
    case "cif":
      valid = validCIF(str);
      break;
  }
  console.log('Valid code:', valid);
  return (valid) ? null : { invalid_Vat: !valid };
  // return {
  //   type: type,
  //   valid: valid,
  // };
}
function spainIdType(str) {
  if (str.match(DNI_REGEX)) {
    return "dni";
  }
  if (str.match(CIF_REGEX)) {
    return "cif";
  }
  if (str.match(NIE_REGEX)) {
    return "nie";
  }
  return '';
}

function validDNI(dni) {
  const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
  const letter = dni_letters.charAt(parseInt(dni, 10) % 23);
  return letter === dni.charAt(8);
}

function validNIE(nie) {
  // Change the initial letter for the corresponding number and validate as DNI
  let nie_prefix = nie.charAt(0);

  switch (nie_prefix) {
    case "X":
      nie_prefix = 0;
      break;
    case "Y":
      nie_prefix = 1;
      break;
    case "Z":
      nie_prefix = 2;
      break;
  }

  return this.validDNI(nie_prefix + nie.substr(1));
}

function validCIF(cif) {
  const match = cif.match(CIF_REGEX);
  const letter = match[1],
    number = match[2],
    control = match[3];

  let even_sum = 0;
  let odd_sum = 0;
  let n;

  for (let i = 0; i < number.length; i++) {
    n = parseInt(number[i], 10);

    // Odd positions (Even index equals to odd position. i=0 equals first position)
    if (i % 2 === 0) {
      // Odd positions are multiplied first.
      n *= 2;

      // If the multiplication is bigger than 10 we need to adjust
      odd_sum += n < 10 ? n : n - 9;

      // Even positions
      // Just sum them
    } else {
      even_sum += n;
    }
  }

  const control_digit = 10 - +(even_sum + odd_sum).toString().substr(-1);
  const control_letter = "JABCDEFGHI".substr(control_digit, 1);
  // Control must be a digit
  if (letter.match(/[ABEH]/)) {
    return control === control_digit;

    // Control must be a letter
  } else if (letter.match(/[KPQS]/)) {
    return control === control_letter;

    // Can be either
  } else {
    return control === control_digit.toString() || control === control_letter;
  }
}
//#endregion
