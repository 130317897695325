<nav class="col-md-2 d-md-block bg-light sidebar newmain-nav font">
    <div class="sidebar-sticky">
        <ul class="nav flex-column newflexcolumn">
            <li class="nav-item" *ngIf="estService.isEstablishmentSelected">
                <a class="nav-link newitem" routerLink="./menus">
                    <i class="fas fa-book-open"></i>{{'main-nav.menusandcards' | translate}}
                </a>
            </li>
            <li class="nav-item" *ngIf="estService.isEstablishmentSelected">
                <a class="nav-link newitem" routerLink="./dishes">
                    <i class="fas fa-concierge-bell"></i>{{'main-nav.dishes' | translate}}
                </a>
            </li>
            <li class="nav-item" *ngIf="estService.isEstablishmentSelected">
                <a class="nav-link newitem" routerLink="./categorydishes">
                    <i class="fas fa-th-large"></i>{{'main-nav.categories' | translate}}
                </a>
            </li>
            <li class="nav-item" *ngIf="estService.isEstablishmentSelected">
                <a class="nav-link newitem" routerLink="./unitsmeasure">
                    <i class="fas fa-chart-pie"></i>{{'main-nav.units' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link newitem" routerLink="./allergens">
                    <i class="fas fa-exclamation-triangle"></i>{{'main-nav.allergens' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link newitem" routerLink="./dashboard">
                    <i class="fas fa-chart-line"></i>{{'main-nav.dashboard' | translate}}
                </a>
            </li>
        </ul>
        <ul *ngIf="authService.isAdmin" class="nav flex-column newflexcolumn">
            <h3>{{ 'main-nav.administration' | translate }}</h3>
            <li class="nav-item" *ngIf="authService.isSuperAdmin">
                <a class="nav-link newitem" routerLink="./admin/users">
                    <i class="fas fa-users"></i>{{'main-nav.users' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link newitem" routerLink="./admin/invoices">
                    <i class="fas fa-users"></i>{{'main-nav.invoices' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link newitem" routerLink="./admin/menus">
                    <i class="fas fa-book-open"></i>{{'main-nav.menusandcards' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link newitem" routerLink="./admin/menuscollections">
                    <i class="fas fa-th-large"></i>{{'main-nav.menuscollections' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link newitem" routerLink="./admin/dishes">
                    <i class="fas fa-concierge-bell"></i>{{'main-nav.dishes' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link newitem" routerLink="./admin/categorydishes">
                    <i class="fas fa-th-large"></i>{{'main-nav.categories' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link newitem" routerLink="./admin/unitsmeasure">
                    <i class="fas fa-chart-pie"></i>{{'main-nav.units' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link newitem" routerLink="./admin/gallery">
                    <i class="fas fa-image"></i>{{'main-nav.gallery' | translate}}
                </a>
            </li>
        </ul>
    </div>
</nav>