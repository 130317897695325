import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Establishment } from '../../shared/models/establishment.model';
import { EstablishmentService } from '../../shared/services/establishment.service';
import { EstablishmentModalComponent } from '../establishment-modal/establishment-modal.component';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from '../../shared/data/data';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StepperBillingInfoModalComponent } from '../stepper-billing-info-modal/stepper-billing-info-modal.component';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})
export class TopNavComponent implements OnInit {

  faPlus = faPlus;
  establishments: Establishment[];
  establishmentSelected: any;
  selectedLang: string;
  languages = LANGUAGES;
  // @ViewChild('establishmentModal') establishmentModal: EstablishmentModalComponent;
  constructor(
    private estService: EstablishmentService,
    private router: Router,
    public ngZone: NgZone,
    public authService: AuthService,
    public tService: TranslateService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal
    ) {
      this.tService.onLangChange.subscribe(data => {
        this.selectedLang = data.lang.toUpperCase();
        localStorage.setItem("clang", data.lang);
      });
      this.selectedLang = localStorage.getItem("clang").toUpperCase();
    this.estService.getAll().subscribe((ests) => {
      this.establishments = ests.docs.map((di) => di.data() as Establishment);
      if (this.establishments.length === 1) {
        this.estService.selectEstablishment(this.establishments[0]);
      }
      this.establishmentSelected = this.estService.isEstablishmentSelected;
    });
  }

  ngOnInit(): void {
  }

  async selectEstablishment(est: Establishment) {
      this.estService.selectEstablishment(est).then(() => {
        this.ngZone.run(() => {
          this.router.navigate(['intranet', 'dashboard']).then(() => {
            window.location.reload();
          });
        });
      });
  }
  addEstablishment() {
    const modal = this.modalService
      .open(EstablishmentModalComponent, { ariaLabelledBy: 'modal-basic-title' });
      modal.result.then((value) => {
        console.log(value);
        this.estService.selectEstablishment(value).then(() => {
          this.ngZone.run(() => {
            this.router.navigate(['intranet', 'dashboard']).then(() => {
              window.location.reload();
            });
          });
        });
      });
  }
}
