import { Component, OnInit, NgZone } from '@angular/core';
import { TemplateCollection } from '../../shared/models/template-collection.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-collection-menu-template-modal',
  templateUrl: './collection-menu-template-modal.component.html',
  styleUrls: ['./collection-menu-template-modal.component.css']
})
export class CollectionMenuTemplateModalComponent implements OnInit {
  closeResult = '';
  editElement: TemplateCollection;
  constructor(
    public activeModal: NgbActiveModal,
    public router: Router,
    public ngZone: NgZone
  ) {}

  ngOnInit(): void {}

}
