import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StructureFormComponent } from '../../../shared/structure-form.component';
import { EstablishmentService } from '../../../../../shared/services/establishment.service';
import { DishCollection, Structure } from '../../../../../shared/models/menu.model';
import { CategoryDish } from 'src/app/shared/models/category-dish.model';
import { UnitMeasure } from '../../../../../shared/models/unit-mesure.model';
import { Dish } from '../../../../../shared/models/dish.model';
import { Allergen } from '../../../../../shared/models/allergen.model';

@Component({
  selector: 'app-dish-collection-structure-form',
  templateUrl: './dish-collection-structure-form.component.html',
  styleUrls: ['./dish-collection-structure-form.component.css']
})
export class DishCollectionStructureFormComponent extends StructureFormComponent<DishCollection> {
  @Input() contents: DishCollection;
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() dishes: Dish[];
  @Input() allergens: Allergen[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @Output() delete = new EventEmitter();
  @Output() edit = new EventEmitter<Structure>();
  constructor(public estService: EstablishmentService) {
    super(estService);
  }
  del() {
    this.delete.emit();
  }
  createData() {

  }
  setCategories($event) {
    this.contents.c = $event.map(ci => ci.id);
  }
  setDishes($event) {
    this.contents.d = $event.map(di => di.id);
  }
  setMode($event) {
    this.contents.m = $event.target.value;
  }
}
