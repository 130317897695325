import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import firebase from 'firebase/app';
import { FileUpload } from '../models/fileFirebaseUpload.model';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
    providedIn: "root",
})
export class FileService {
    database = 'Gallery';
    get ref() {
        const user = JSON.parse(localStorage.getItem('user'));
        const establishment = JSON.parse(localStorage.getItem('establishment'));
        return firebase
            .firestore()
            .collection(
                `users/${user.uid}/Establishments/${establishment.id}/${this.database}`
            ).doc('Gallery');
    }
    constructor(
        public afs: AngularFirestore, // Inject Firestore service
        private storage: AngularFireStorage
    ) { }

    addFile(f: FileUpload) {
        const obj = {};
        obj[f.f] = f;
        return this.ref.set(obj, {merge: true});
    }
    uploadFile(file: any, path: string) {
        // Totally optional metadata
        const customMetadata = { origin: 'Web', docType: file.type };
        return this.storage.upload(path, file, { customMetadata });
    }
    uploadString(file: string, path: string, type: string) {
        const storageRef = this.storage.ref(path);
         const customMetadata = { origin: 'Web', docType: type };
        return storageRef.putString(file, 'data_url', { customMetadata });
    }
    getDownloadURL(path: string) {
        return this.storage.ref(path).getDownloadURL();
    }
}
