import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EstablishmentService } from '../../shared/services/establishment.service';
import { Establishment } from '../../shared/models/establishment.model';
import { LANGUAGES } from '../../shared/data/data';

@Component({
  selector: 'app-establishment-form',
  templateUrl: './establishment-form.component.html',
  styleUrls: ['./establishment-form.component.css']
})
export class EstablishmentFormComponent implements OnInit {

  establishmentForm: FormGroup;
  editingEstablishment: Establishment;
  @ViewChild('form') establishForm: ElementRef;
  @Output() resetForm = new EventEmitter<Establishment>();
  @Input() set elementToEdit(value: Establishment) {
    this.editingEstablishment = value;
    if (this.editingEstablishment) {
      this.editElemet(this.editingEstablishment);
    }
  }
  languages: any;
  constructor(private fb: FormBuilder, private estService: EstablishmentService) {
    this.establishmentForm = this.fb.group({
      name: ['', Validators.required],
      languages: ['', Validators.required],
    });
    this.languages = LANGUAGES;
  }
  submit() {
    this.establishForm.nativeElement.submit();
  }
  ngOnInit(): void {
  }
  saveEstablishment() {
    let establishment = null;
    if (this.establishmentForm.valid) {
      establishment = this.getEstablishmentFromForm();
      if (this.editingEstablishment) {
        establishment.id = this.editingEstablishment.id;
        this.estService.edit(establishment);
        this.editingEstablishment = null;
      } else {
        establishment = this.estService.create(establishment);
      }
    this.reset(establishment);
    }
  }
  reset(est: Establishment) {
    this.editingEstablishment = null;
    this.establishmentForm.reset();
    this.resetForm.emit(est);
  }
  editElemet(element) {
    this.editingEstablishment = element;
    this.establishmentForm.controls['name'].setValue(this.editingEstablishment.name);
    this.establishmentForm.controls['languages'].setValue(this.editingEstablishment.languages);
  }
  getEstablishmentFromForm() {
    return {
      id: '',
      name: this.establishmentForm.get('name').value,
      languages: this.establishmentForm.get('languages').value,
      dishes: []
    };
  }
}
