import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StructureFormComponent } from '../../../shared/structure-form.component';
import { EstablishmentService } from '../../../../../shared/services/establishment.service';
import { Table, Text, Container, Structure } from '../../../../../shared/models/menu.model';
import { StructureUtils } from '../../../../../shared/utils/htmlGenerator.util';
import { CategoryDish } from '../../../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../../../shared/models/unit-mesure.model';
import { Dish } from '../../../../../shared/models/dish.model';
import { Allergen } from '../../../../../shared/models/allergen.model';

@Component({
  selector: "app-table-structure-form",
  templateUrl: "./table-structure-form.component.html",
  styleUrls: ["./table-structure-form.component.css"],
})
export class TableStructureFormComponent extends StructureFormComponent<Table> {
  @Input() contents: Table;
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() dishes: Dish[];
  @Input() allergens: Allergen[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @Output() delete = new EventEmitter();
  @Output() edit = new EventEmitter<Structure>();
  @Output() indexChange = new EventEmitter();
  constructor(public estService: EstablishmentService) {
    super(estService);
  }
  del() {
    this.delete.emit();
  }
  indexed() {
    this.indexChange.emit();
  }
  changeCols($event) {
    const widths = [];
    const width =
      $event.target.value < 7 ? Math.floor(12 / $event.target.value) : 1;
    let i = 0;
    while (i < $event.target.value) {
      widths.push(width);
      i++;
    }
    this.contents.w = widths;
    this.contents.cn = $event.target.value;
    this.contents.cnt = this.getContent($event.target.value, this.contents.rn);
  }
  changeRows($event) {
    this.contents.cnt = this.getContent(this.contents.cn, $event.target.value);
    this.contents.rn = $event.target.value;
  }
  getContent(cols, rows) {
    const content: Container[][] = [];
    let i = 0;
    let j = 0;
    while (i < rows) {
      content.push([]);
      j = 0;
      while (j < cols) {
        content[i].push(StructureUtils.getContainer());
        if (this.contents.cnt[i] && this.contents.cnt[i][j]) {
          content[i][j] = this.contents.cnt[i][j];
        }
        j++;
      }
      i++;
    }
    return content;
  }
  deleteElement(i, j) {
    this.contents.cnt[i][j] = StructureUtils.getContainer();
  }
  // changeElement(i, j, $event) {
  //   this.contents.content[i][j] = $event;
  // }
}
