import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignInComponent } from '../sign-in/sign-in.component';

@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.css"],
})
export class VerifyEmailComponent implements OnInit {
  constructor(public authService: AuthService, public modal: NgbActiveModal, public modalService: NgbModal) {}

  ngOnInit() {}
  send() {
    this.authService.SendVerificationMail();
  }
  signIn() {
    this.modal.close();
    const modal = this.modalService.open(SignInComponent, {
      windowClass: "white-content",
      centered: true,
    });
    modal.result.then(
      (result) => {},
      (reason) => {}
    );
  }
}
