<div class="modal-header font">
    <h4 class="modal-title" id="modal-basic-title">{{ 'stepper.register' | translate}}</h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button> -->
</div>
<div class="modal-body mybody">
    <div class="container">
        <div id="stepper1" class="bs-stepper">
            <div class="bs-stepper-header">
                <div class="step" data-target="#test-l-1">
                    <button class="step-trigger">
                <span class="bs-stepper-circle">1</span>
                <span class="bs-stepper-label">{{ 'stepper.plan' | translate}}</span>
              </button>
                </div>
                <div class="line"></div>
                <div class="step" data-target="#test-l-2">
                    <button class="step-trigger" [disabled]="selectedAccount === null">
                    <span class="bs-stepper-circle">2</span>
                    <span class="bs-stepper-label">{{ 'stepper.billingdetails' | translate}}</span>
                </button>
                </div>
                <div class="line"></div>
                <div class="step" data-target="#test-l-3">
                    <button class="step-trigger" (click)="next()" [disabled]="!(selectedAccount !== null && uBillForm.valid)">
                <span class="bs-stepper-circle">3</span>
                <span class="bs-stepper-label">{{ 'stepper.creditcard' | translate}}</span>
              </button>
                </div>
            </div>
            <div class="bs-stepper-content">
                <form>
                    <div id="test-l-1" class="content text-center">
                        <app-account-type-form (selectedPlan)="planSelected($event)"></app-account-type-form>
                    </div>
                    <div id="test-l-2" class="content">
                        <form [formGroup]="uBillForm">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group row">
                                        <label for="name" class="col-sm-4 col-form-label">{{'profile.bussinessname' | translate}}*: </label>
                                        <div class="col-sm-8">
                                            <input formControlName="name" type="text" id="name" class="form-control newform" placeholder="{{'profile.bussinessname' | translate}}">
                                            <div *ngIf="b.name.errors" class="invalid-feedback">
                                                <div *ngIf="b.name.errors.required">{{'profile.bussinessnamereq' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="cif" class="col-sm-4 col-form-label">{{'profile.cif' | translate}}*: </label>
                                        <div class="col-sm-8">
                                            <input formControlName="cif" type="text" id="cif" class="form-control newform" placeholder="{{'profile.cif' | translate}}">
                                            <div *ngIf="(b.cif.errors || uBillForm.errors )" class="invalid-feedback">
                                                <div *ngIf="b.cif.errors && b.cif.errors.required">{{'profile.cifreq' | translate}}</div>
                                                <div *ngIf="uBillForm.errors && uBillForm.errors.invalid_Vat">{{'profile.cifvalid' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="line1" class="col-sm-4 col-form-label">{{'profile.address' | translate}}*: </label>
                                        <div class="col-sm-8">
                                            <input formControlName="line1" type="text" id="line1" class="form-control newform" placeholder="{{'profile.address' | translate}}">
                                            <div *ngIf="b.line1.errors" class="invalid-feedback">
                                                <div *ngIf="b.line1.errors.required">{{'profile.addressreq' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="city" class="col-sm-4 col-form-label">{{'profile.city' | translate}}*: </label>
                                        <div class="col-sm-8">
                                            <input formControlName="city" type="text" id="city" class="form-control newform" placeholder="{{'profile.city' | translate}}">
                                            <div *ngIf="b.city.errors" class="invalid-feedback">
                                                <div *ngIf="b.city.errors.required">{{'profile.cityreq' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="state" class="col-sm-4 col-form-label">{{'profile.state' | translate}}*: </label>
                                        <div class="col-sm-8">
                                            <input formControlName="state" type="text" id="state" class="form-control newform" placeholder="{{'profile.state' | translate}}">
                                            <div *ngIf="b.state.errors" class="invalid-feedback">
                                                <div *ngIf="b.state.errors.required">{{'profile.statereq' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group row">
                                        <label for="country" class="col-sm-4 col-form-label">{{'profile.country' | translate}}*: </label>
                                        <div class="col-sm-8">
                                            <div class="input-group mb-3">
                                                <ng-select formControlName="country" appendTo="body" class="custom" [items]="countries" bindLabel="name" bindValue="code" placeholder="{{'profile.country' | translate}}" [multiple]="false"></ng-select>
                                                <div *ngIf="b.country.errors" class="invalid-feedback">
                                                    <div *ngIf="b.country.errors.required">{{'profile.countryreq' | translate}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="postal_code" class="col-sm-4 col-form-label">{{'profile.postalcode' | translate}}*: </label>
                                        <div class="col-sm-8">
                                            <input formControlName="postal_code" type="text" id="postal_code" class="form-control newform" placeholder="{{'profile.postalcode' | translate}}">
                                            <div *ngIf="b.postal_code.errors" class="invalid-feedback">
                                                <div *ngIf="b.postal_code.errors.required">{{'profile.postalcodereq' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="phone" class="col-sm-4 col-form-label">{{'profile.phone' | translate}}*: </label>
                                        <div class="col-sm-8">
                                            <input formControlName="phone" type="number" id="phone" class="form-control newform" placeholder="{{'profile.phone' | translate}}">
                                            <div *ngIf="b.phone.errors" class="invalid-feedback">
                                                <div *ngIf="b.phone.errors.required">{{'profile.phonereq' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="email" class="col-sm-4 col-form-label">{{'profile.email' | translate}}: </label>
                                        <div class="col-sm-8">
                                            <input formControlName="email" type="email" id="email" class="form-control newform" placeholder="{{'profile.email' | translate}}">
                                            <div *ngIf="b.email.errors" class="invalid-feedback">
                                                <div *ngIf="b.email.errors.required">{{'profile.emailreq' | translate}}</div>
                                                <div *ngIf="b.email.errors.email">{{'profile.emailvalid' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <button *ngIf="editBillingInfo" (click)="changeBillingInfor()" type="submit" class="btn btn-primary savebutton">{{'profile.change' | translate}}</button> -->
                        </form>
                        <div style="width: 100%; text-align: right;">
                            <button (click)="next()" class="btn btn-primary" [disabled]="!(selectedAccount !== null && uBillForm.valid)">{{'stepper.next' | translate}}</button>
                        </div>
                    </div>
                    <div id="test-l-3" class="content">
                        <form [formGroup]="uCardForm" novalidate>
                            <div class="form-group row">
                                <label for="ccnumber" class="col-sm-4 col-form-label">{{'profile.number' | translate}}*: </label>
                                <div class="col-sm-8">
                                    <input id="ccnumber" class="form-control newform" placeholder="{{'profile.number' | translate}}" formControlName="ccnumber" type="tel" autocomplete="cc-number" ccNumber>
                                    <div *ngIf="c.ccnumber.errors" class="invalid-feedback">
                                        <div *ngIf="c.ccnumber.errors.required">{{'profile.numberreq' | translate}}</div>
                                        <div *ngIf="c.ccnumber.errors">{{'profile.numbervalid' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">{{'profile.titular' | translate}}*: </label>
                                <div class="col-sm-8">
                                    <input id="name" class="form-control newform" placeholder="{{'profile.titular' | translate}}" formControlName="name" type="text" autocomplete="off">
                                    <div *ngIf="c.name.errors" class="invalid-feedback">
                                        <div *ngIf="c.name.errors.required">{{'profile.titularreq' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="expirationDate" class="col-sm-4 col-form-label">{{'profile.expirationdate' | translate}}*: </label>
                                <div class="col-sm-8">
                                    <input id="expirationDate" class="form-control newform" placeholder="{{'profile.expirationdate' | translate}}" formControlName="expirationDate" type="tel" autocomplete="cc-exp" ccExp>
                                    <div *ngIf="c.expirationDate.errors" class="invalid-feedback">
                                        <div *ngIf="c.expirationDate.errors.required">{{'profile.expirationdatereq' | translate}}</div>
                                        <div *ngIf="c.expirationDate.errors">{{'profile.expirationdatevalid' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="cvc" class="col-sm-4 col-form-label">{{'profile.cvc' | translate}}*: </label>
                                <div class="col-sm-8">
                                    <input id="cvc" class="form-control newform" placeholder="{{'profile.cvc' | translate}}" formControlName="cvc" type="tel" autocomplete="off" ccCvc>
                                    <div *ngIf="c.cvc.errors" class="invalid-feedback">
                                        <div *ngIf="c.cvc.errors.required">{{'profile.cvcreq' | translate}}</div>
                                        <div *ngIf="c.cvc.errors">{{'profile.cvcvalid' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- <button type="submit" [disabled]="!(selectedAccount != null && uBillForm.valid && uCardForm.valid)" class="btn btn-primary mt-5">Submit</button> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- <app-allergen-form #form [elementToEdit]="editElement" (resetForm)="activeModal.close($event)"></app-allergen-form> -->
</div>
<div class="modal-footer">
    <!-- <button type="button" class="btn btn-primary savebutton" (click)="next()">{{ 'common.save' | translate}}</button> -->
    <button type="button" class="btn btn-primary savebutton" (click)="register()" [disabled]="!(selectedAccount !== null && uBillForm.valid && uCardForm.valid)">{{ 'common.completeregister' | translate}}</button>
</div>