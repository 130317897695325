import { Component, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-template-selector-modal',
  templateUrl: './menu-template-selector-modal.component.html',
  styleUrls: ['./menu-template-selector-modal.component.css']
})
export class MenuTemplateSelectorModalComponent implements OnInit {
  closeResult = '';
  @ViewChild('content') content: ElementRef;
  constructor(
    public activeModal: NgbActiveModal,
    public router: Router,
    public ngZone: NgZone
  ) { }

  ngOnInit(): void { }
}
