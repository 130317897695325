<div ngbDropdown class="d-inline-block">
    <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle><fa-icon [icon]="faPlus"></fa-icon></button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <!-- <button ngbDropdownItem (click)="addComponent('page')">Page</button> -->
        <button ngbDropdownItem (click)="addComponent('container')">{{'structure-drawer.container' | translate}}</button>
        <button ngbDropdownItem (click)="addComponent('dishcollection')">{{'structure-drawer.dishcollection' | translate}}</button>
        <button ngbDropdownItem (click)="addComponent('table')">{{'structure-drawer.table' | translate}}</button>
        <button ngbDropdownItem (click)="addComponent('image')">{{'structure-drawer.image' | translate}}</button>
        <button ngbDropdownItem (click)="addComponent('text')">{{'structure-drawer.text' | translate}}</button>
        <button ngbDropdownItem (click)="addComponent('dish')">{{'structure-drawer.dish' | translate}}</button>
    </div>
</div>