<form>
    <div class="form-group">
        <fieldset class="form-group">

            {{imageError}}
            <div class="custom-file fileInputProfileWrap">
                <input type="file" (change)="fileChangeEvent($event)" class="fileInputProfile">
                <div class="img-space">

                    <ng-container *ngIf="isImageSaved; else elseTemplate">
                        <img [ngClass]="{'img-thumbnail': rounded, 'rounded-circle': rounded}" [src]="cardImageBase64" />
                    </ng-container>
                    <ng-template #elseTemplate>

                        <img [ngClass]="{'img-thumbnail': rounded, 'rounded-circle': rounded}" [src]="(src) ? src : './../../../../../assets/placeholder.png'" class="img-responsive">
                    </ng-template>

                </div>

            </div>
        </fieldset>
    </div>
    <a class="btn btn-danger" *ngIf="showRemove && isImageSaved" (click)="removeImage()">Remove</a>
</form>