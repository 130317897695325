<div class="inner-adjust font">
    <form>
        <div class="pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2 inlineComp font newtitle">{{ 'allergens.allergens' | translate }}</h1>
            <button *ngIf="authService.isAdmin" type="button" (click)="add();" class="btn btn-primary bmd-btn-fab rightBtn inlineComp plusbtn">
            <fa-icon [icon]="faPlus"></fa-icon>
            <h1 class="h2 inlineComp font Addtext">{{ 'allergens.addallergens' | translate }}</h1>
        </button>
            <div class="form-group form-inline rightBtn rightbtn">
                <fa-icon *ngIf="!(service.loading$ | async)" [icon]="faSearch"></fa-icon>
                <fa-icon class="rotateInfinite" *ngIf=" service.loading$ | async" [icon]="faSpinner"></fa-icon><input class="form-control ml-2 searchitem" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
            </div>
        </div>
        <table class="table table-striped newtable">
            <thead class="headline">
                <tr>
                    <th scope="col" class="newborderleft newcolor">{{ 'allergens.icon' | translate }}</th>
                    <th scope="col" sortable="name" direction="asc" (sort)="onSort($event)" class="newcolor widthname">{{ 'allergens.name' | translate }}</th>
                    <th scope="col" sortable="description" (sort)="onSort($event)" class="newcolor">{{ 'allergens.description' | translate }}</th>
                    <th class="fit newborderright newcolor" scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let al of allergens$ | async" class="contentline">
                    <td class="formatocelda">
                        <img style="height: 50px; width: 50px;" [src]="'../../assets/alergens/' + al.icon + '.png'" />
                    </td>
                    <td class="formatocelda">
                        <ngb-highlight [result]="(al.name_traductions[selectedLang])? al.name_traductions[selectedLang] : al.name" [term]="service.searchTerm"></ngb-highlight>
                    </td>
                    <td class="formatocelda">
                        <ngb-highlight [result]="(al.description_traductions[selectedLang])? al.description_traductions[selectedLang] : al.description" [term]="service.searchTerm"></ngb-highlight>
                    </td>
                    <td class="fit" scope="row">
                        <!-- <button type="button" (click)="show(al)" class="btn btn-primary bmd-btn-fab inlineComp btnTaleRow rightbtn"><fa-icon [icon]="faEye"></fa-icon></button> -->
                        <button *ngIf="authService.isAdmin" type="button" (click)="edit(al)" class="btn btn-success bmd-btn-fab inlineComp btnTaleRow rightbtn"><fa-icon [icon]="faEdit"></fa-icon></button>
                        <button *ngIf="authService.isAdmin" type="button" (click)="delete(al)" class="btn btn-danger bmd-btn-fab inlineComp btnTaleRow rightbtn"><fa-icon [icon]="faTrash"></fa-icon></button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-between p-2 selectpag">
            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
            </ngb-pagination>

            <select class="custom-select paginas" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                <option [ngValue]="15">15 {{ 'common.itemsperpage' | translate }}</option>
                <option [ngValue]="30">30 {{ 'common.itemsperpage' | translate }}</option>
                <option [ngValue]="50">50 {{ 'common.itemsperpage' | translate }}</option>
            </select>
        </div>
    </form>
</div>