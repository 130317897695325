import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SignInComponent } from '../../components/sign-in/sign-in.component';
import { SignUpComponent } from '../../components/sign-up/sign-up.component';
import { DashboardComponent } from '../../components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from '../../components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from '../../components/verify-email/verify-email.component';

import { AuthGuard } from '../../shared/guard/auth.guard';
import { ProfileComponent } from '../../components/profile/profile.component';
import { DishesComponent } from '../../components/dishes/dishes.component';
import { CategoryDishesComponent } from '../../components/category-dishes/category-dishes.component';
import { UnitsMeasuresComponent } from '../../components/units-measures/units-measures.component';
import { AllergensComponent } from '../../components/allergens/allergens.component';
import { MenuTemplatesComponent } from '../../components/menu-templates/menu-templates.component';
import { MenuComponent } from '../../components/menu/menu.component';
import { HomeComponent } from '../../components/home/home.component';
import { CookiePolicyComponent } from 'src/app/components/cookie-policy/cookie-policy.component';
import { LegalAdviceComponent } from 'src/app/components/legal-advice/legal-advice.component';
import { PrivacyPolicyComponent } from 'src/app/components/privacy-policy/privacy-policy.component';
import { HelpComponent } from '../../components/help/help.component';
import { EstablishmentsComponent } from '../../components/establishments/establishments.component';
import { IntranetComponent } from '../../components/intranet/intranet.component';
import { UsersComponent } from '../../components/users/users.component';
import { InvoicesComponent } from '../../components/invoices/invoices.component';
import { AdminIntranetComponent } from '../../components/admin-intranet/admin-intranet.component';
import { CollectionMenuTemplatesComponent } from '../../components/collection-menu-templates/collection-menu-templates.component';
import { MediaGalleryComponent } from '../../components/media-gallery/media-gallery.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: 'intranet', component: IntranetComponent, canActivate: [AuthGuard],
  children: [
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
    { path: 'categorydishes', component: CategoryDishesComponent, canActivate: [AuthGuard] },
    { path: 'unitsmeasure', component: UnitsMeasuresComponent, canActivate: [AuthGuard] },
    { path: 'allergens', component: AllergensComponent, canActivate: [AuthGuard] },
    { path: 'dishes', component: DishesComponent, canActivate: [AuthGuard] },
    { path: 'menus', component: MenuTemplatesComponent, canActivate: [AuthGuard] },
    { path: 'help', component: HelpComponent, canActivate: [AuthGuard] },
    { path: 'myrestaurants', component: EstablishmentsComponent, canActivate: [AuthGuard] },
    { path: 'admin', component: AdminIntranetComponent, canActivate: [AuthGuard],
      children: [
        { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
        { path: 'invoices', component: InvoicesComponent, canActivate: [AuthGuard] },
        { path: 'categorydishes', component: CategoryDishesComponent, canActivate: [AuthGuard] },
        { path: 'unitsmeasure', component: UnitsMeasuresComponent, canActivate: [AuthGuard] },
        { path: 'dishes', component: DishesComponent, canActivate: [AuthGuard] },
        { path: 'menus', component: MenuTemplatesComponent, canActivate: [AuthGuard] },
        { path: 'menuscollections', component: CollectionMenuTemplatesComponent, canActivate: [AuthGuard] },
        { path: 'gallery', component: MediaGalleryComponent, canActivate: [AuthGuard] }]
    }]
  },
  { path: 'menu/:id/:id2/:id3', component: MenuComponent },
  // { path: 'forgot-password', component: ForgotPasswordComponent },
  // { path: 'verify-email-address', component: VerifyEmailComponent },
  { path: 'cookiepolicy', component: CookiePolicyComponent },
  { path: 'legaladvice', component: LegalAdviceComponent },
  { path: 'privacypolicy', component: PrivacyPolicyComponent },
  { path: 'home', component: HomeComponent },
  { path: '**', redirectTo: '/home', pathMatch: 'full'}
  // { path: 'sign-in', component: SignInComponent},
  // { path: 'register-user', component: SignUpComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
