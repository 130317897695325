import { Injectable } from '@angular/core';
import { IUnitCRUDService } from '../interfaces/iUnitCRUDService';
import { UnitMeasureAdminService } from './unit-measure-admin.service';
import { UnitMeasureUserService } from './unit-measure-user.service';

@Injectable({
  providedIn: "root",
})
export class UnitMeasureService {

  public isAdminData = false;

  constructor (
    private _unitMeasureUserService: UnitMeasureUserService,
    private _unitMeasureAdminService: UnitMeasureAdminService
  ) {}

  public get instance(): IUnitCRUDService {
    return this.isAdminData ? this._unitMeasureAdminService : this._unitMeasureUserService;
  }
}
