import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreditCardValidators } from "angular-cc-library";

@Component({
  selector: "app-credit-card-form",
  templateUrl: "./credit-card-form.component.html",
  styleUrls: ["./credit-card-form.component.css"],
})
export class CreditCardFormComponent implements OnInit {
  form: FormGroup;
  submitted = false;

  constructor(private _fb: FormBuilder) {}

  ngOnInit() {
    this.form = this._fb.group({
      creditCard: ["", [CreditCardValidators.validateCCNumber]],
      titular: ["", [Validators.required]],
      expirationDate: ["", [CreditCardValidators.validateExpDate]],
      cvc: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(4)],
      ],
    });
  }

  onSubmit(form) {
    this.submitted = true;
    // console.log(form);
  }
}
