import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Reactive Form
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// App routing modules
import { AppRoutingModule } from './shared/routing/app-routing.module';

// App components
import { AppComponent } from './app.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { ProfileComponent } from './components/profile/profile.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { DishesComponent } from './components/dishes/dishes.component';
import { EstablishmentFormComponent } from './components/establishment-form/establishment-form.component';
import { EstablishmentModalComponent } from './components/establishment-modal/establishment-modal.component';
import { DishFormComponent } from './components/dish-form/dish-form.component';
import { DishModalComponent } from './components/dish-modal/dish-modal.component';
import { CategoryDishModalComponent } from './components/category-dish-modal/category-dish-modal.component';
import { CategoryDishFormComponent } from './components/category-dish-form/category-dish-form.component';
import { CategoryDishesComponent } from './components/category-dishes/category-dishes.component';
import { NgbdSortableHeader } from './shared/directives/sortable.directive';
import { UnitsMeasuresComponent } from './components/units-measures/units-measures.component';
import { UnitsMeasuresModalComponent } from './components/units-measures-modal/units-measures-modal.component';
import { UnitsMeasuresFormComponent } from './components/units-measures-form/units-measures-form.component';
import { AllergensComponent } from './components/allergens/allergens.component';
import { AllergenFormComponent } from './components/allergen-form/allergen-form.component';
import { AllergenModalComponent } from './components/allergen-modal/allergen-modal.component';
import { MenuTemplatesComponent } from './components/menu-templates/menu-templates.component';
import { MenuTemplateModalComponent } from './components/menu-template-modal/menu-template-modal.component';
import { MenuTemplateFormComponent } from './components/menu-template-form/menu-template-form.component';
import { StructureDrawerComponent } from './components/menu/forms/structure-drawer/structure-drawer.component';
import { StyleDrawerComponent } from './components/menu/style-drawer/style-drawer.component';
import { TextStructureFormComponent } from './components/menu/forms/structure/text-structure-form/text-structure-form.component';
import { ImageStructureFormComponent } from './components/menu/forms/structure/image-structure-form/image-structure-form.component';
import { TableStructureFormComponent } from './components/menu/forms/structure/table-structure-form/table-structure-form.component';
import { ContainerStructureFormComponent } from './components/menu/forms/structure/container-structure-form/container-structure-form.component';
import { ButtonAddComponent } from './components/menu/shared/button-add/button-add.component';
import { DishCollectionStructureFormComponent } from './components/menu/forms/structure/dish-collection-structure-form/dish-collection-structure-form.component';
import { MediaAddComponent } from './components/menu/shared/media-add/media-add.component';
import { ResultDrawerComponent } from './components/menu/result-drawer/result-drawer.component';
import { QrViewerComponent } from './components/qr-viewer/qr-viewer.component';
import { QrViewerModalComponent } from './components/qr-viewer-modal/qr-viewer-modal.component';
import { MenuTemplateComponent } from './components/menu/templates/menu-template.component';
import { PageTemplateComponent } from './components/menu/templates/page-template.component';
import { ContainerTemplateComponent } from './components/menu/templates/container-template.component';
import { TableTemplateComponent } from './components/menu/templates/table-template.component';
import { DishCollectionTemplateComponent } from './components/menu/templates/dish-collection-template.component';
import { DishTemplateComponent } from './components/menu/templates/dish-template.component';
import { TextTemplateComponent } from './components/menu/templates/text-template.component';
import { ImageTemplateComponent } from './components/menu/templates/image-template.component';
import { MenuStandardTemplateComponent } from "./components/menu/templates/standard/menu-standard-template.component";
import { DishStandardTemplateComponent } from "./components/menu/templates/standard/dish-standard-template.component";
import { PageStandardTemplateComponent } from "./components/menu/templates/standard/page-standard-template.component";
import { ContainerStandardTemplateComponent } from "./components/menu/templates/standard/container-standard-template.component";
import { TableStandardTemplateComponent } from "./components/menu/templates/standard/table-standard-template.component";
import { DishCollectionStandardTemplateComponent } from "./components/menu/templates/standard/dish-collection-standard-template.component";
import { TextStandardTemplateComponent } from "./components/menu/templates/standard/text-standard-template.component";
import { ImageStandardTemplateComponent } from "./components/menu/templates/standard/image-standard-template.component";
import { DishStructureFormComponent } from "./components/menu/forms/structure/dish-structure-form/dish-structure-form.component";
import { IndexStandardTemplateComponent } from "./components/menu/templates/standard/index-standard-template.component";
import { IndexTemplateComponent } from "./components/menu/templates/index-template.component";
import { ConfigFormComponent } from "./components/menu/config-form/config-form.component";
import { UsersComponent } from './components/users/users.component';
import { MenuTemplateGeneratorComponent } from './components/menu-template-generator/menu-template-generator.component';
import { AdminIntranetComponent } from './components/admin-intranet/admin-intranet.component';
import { MenuTemplateSelectorModalComponent } from './components/menu-template-selector-modal/menu-template-selector-modal.component';
import { MenuTemplateSelectorFormComponent } from './components/menu-template-selector-form/menu-template-selector-form.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { ConfirmationChangePlanComponent } from './components/confirmation-change-plan/confirmation-change-plan.component';

// Firebase services + enviorment module
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorage } from "@angular/fire/storage";
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { environment } from '../environments/environment';

// Auth service
import { AuthService } from './shared/services/auth.service';

// Font Awesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Bootstrap for Angular
import { NgbModule, NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

// NgSelect
import { NgSelectModule } from '@ng-select/ng-select';
import { MenuComponent } from './components/menu/menu.component';

// Qr Code Generator
import { QRCodeModule } from 'angularx-qrcode';

// Color picker
import { ColorPickerModule } from "ngx-color-picker";

// Directives
import { PageDirective } from './components/menu/directives/page.directive';
import { ContainerDirective } from './components/menu/directives/container.directive';
import { TableDirective } from './components/menu/directives/table.directive';
import { DishCollectionDirective } from './components/menu/directives/dish-container.directive';
import { DishDirective } from './components/menu/directives/dish.directive';
import { TextDirective } from './components/menu/directives/text.directive';
import { MenuDirective } from './components/menu/directives/menu.directive';
import { ImageDirective } from './components/menu/directives/image.directive';
import { IndexDirective } from "./components/menu/directives/index.directive";
import { EditabletDirective } from './shared/directives/editable.directive';

// Http Module
import { HttpClient, HttpClientModule } from '@angular/common/http';

// Pipes
import { DecimalPipe, DatePipe } from '@angular/common';

// Credit card library
import { CreditCardDirectivesModule } from "angular-cc-library";
import { CreditCardFormComponent } from './components/credit-card-form/credit-card-form.component';
import { AccountTypeFormComponent } from './components/account-type-form/account-type-form.component';
import { AccountTypeModalComponent } from './components/account-type-modal/account-type-modal.component';
import { HomeComponent } from './components/home/home.component';


// Translation
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { LegalAdviceComponent } from './components/legal-advice/legal-advice.component';
import { StepperBillingInfoModalComponent } from './components/stepper-billing-info-modal/stepper-billing-info-modal.component';
import { ContactComponent } from './components/contact/contact.component';
import { DishClassicTemplateComponent } from './components/menu/templates/classic/dish-classic-collection-template.component';
import { DishCollectionClassicTemplateComponent } from './components/menu/templates/classic/dish-collection-classic-template.component';

// Drag and drop
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HelpComponent } from './components/help/help.component';
import { EstablishmentsComponent } from './components/establishments/establishments.component';
import { IntranetComponent } from './components/intranet/intranet.component';
import { ConfirmationDeleteAccountComponent } from './components/confirmation-delete-account/confirmation-delete-account.component';
import { ConfirmationDeleteDataComponent } from './components/confirmation-delete-data/confirmation-delete-data.component';

// Cookie consent
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MenuViewModalComponent } from './components/menu-view-modal/menu-view-modal.component';
import { MenuViewFormComponent } from './components/menu-view-form/menu-view-form.component';
import { MenuTemplateConfigFormComponent } from './components/menu-template-config-form/menu-template-config-form.component';
import { MenuTemplateConfigModalComponent } from './components/menu-template-config-modal/menu-template-config-modal.component';
import { CollectionMenuTemplatesComponent } from './components/collection-menu-templates/collection-menu-templates.component';
import { CollectionMenuTemplateModalComponent } from './components/collection-menu-template-modal/collection-menu-template-modal.component';
import { CollectionMenuTemplateFormComponent } from './components/collection-menu-template-form/collection-menu-template-form.component';
import { MediaGalleryComponent } from './components/media-gallery/media-gallery.component';
import { MediaGalleryModalComponent } from './components/media-gallery-modal/media-gallery-modal.component';
import { MediaGalleryFormComponent } from './components/media-gallery-form/media-gallery-form.component';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'fuudie.es' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#87af99'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'info'
};
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    MainNavComponent,
    ProfileComponent,
    TopNavComponent,
    DishesComponent,
    EstablishmentFormComponent,
    EstablishmentModalComponent,
    DishFormComponent,
    DishModalComponent,
    CategoryDishModalComponent,
    CategoryDishFormComponent,
    CategoryDishesComponent,
    NgbdSortableHeader,
    EditabletDirective,
    UnitsMeasuresComponent,
    UnitsMeasuresModalComponent,
    UnitsMeasuresFormComponent,
    AllergensComponent,
    AllergenFormComponent,
    AllergenModalComponent,
    MenuTemplatesComponent,
    MenuTemplateModalComponent,
    MenuTemplateFormComponent,
    StructureDrawerComponent,
    StyleDrawerComponent,
    TextStructureFormComponent,
    ImageStructureFormComponent,
    TableStructureFormComponent,
    ContainerStructureFormComponent,
    ButtonAddComponent,
    DishCollectionStructureFormComponent,
    MediaAddComponent,
    ResultDrawerComponent,
    MenuComponent,
    QrViewerComponent,
    QrViewerModalComponent,
    MenuTemplateComponent,
    PageTemplateComponent,
    IndexTemplateComponent,
    ContainerTemplateComponent,
    TableTemplateComponent,
    DishCollectionTemplateComponent,
    DishTemplateComponent,
    TextTemplateComponent,
    ImageTemplateComponent,
    MenuDirective,
    PageDirective,
    IndexDirective,
    ContainerDirective,
    TableDirective,
    DishCollectionDirective,
    DishDirective,
    TextDirective,
    ImageDirective,
    MenuStandardTemplateComponent,
    PageStandardTemplateComponent,
    IndexStandardTemplateComponent,
    ContainerStandardTemplateComponent,
    TableStandardTemplateComponent,
    DishCollectionStandardTemplateComponent,
    DishStandardTemplateComponent,
    TextStandardTemplateComponent,
    ImageStandardTemplateComponent,
    DishStructureFormComponent,
    ConfigFormComponent,
    CreditCardFormComponent,
    AccountTypeFormComponent,
    AccountTypeModalComponent,
    HomeComponent,
    CookiePolicyComponent,
    PrivacyPolicyComponent,
    LegalAdviceComponent,
    StepperBillingInfoModalComponent,
    ContactComponent,
    DishClassicTemplateComponent,
    DishCollectionClassicTemplateComponent,
    HelpComponent,
    EstablishmentsComponent,
    IntranetComponent,
    ConfirmationDeleteAccountComponent,
    ConfirmationDeleteDataComponent,
    ConfirmationChangePlanComponent,
    UsersComponent,
    InvoicesComponent,
    MenuTemplateGeneratorComponent,
    AdminIntranetComponent,
    MenuTemplateSelectorModalComponent,
    MenuTemplateSelectorFormComponent,
    MenuViewModalComponent,
    MenuViewFormComponent,
    MenuTemplateConfigFormComponent,
    MenuTemplateConfigModalComponent,
    CollectionMenuTemplatesComponent,
    CollectionMenuTemplateModalComponent,
    CollectionMenuTemplateFormComponent,
    MediaGalleryComponent,
    MediaGalleryModalComponent,
    MediaGalleryFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    QRCodeModule,
    HttpClientModule,
    ColorPickerModule,
    CreditCardDirectivesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
    }),
    DragDropModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    FlexLayoutModule
  ],
  providers: [AuthService, AngularFireStorage, DecimalPipe, NgbActiveModal, NgbDropdown, DatePipe,
  { provide: REGION, useValue: 'us-central1' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
