import { Injectable } from '@angular/core';
import { DishAdminService } from './dish-admin.service';
import { DishUserService } from './dish-user.service';
import { IDishCRUDService } from '../interfaces/iDishCRUDService';

@Injectable({
  providedIn: "root",
})
export class DishService {

  public isAdminData = false;

  constructor (
    private _DishUserService: DishUserService,
    private _DishAdminService: DishAdminService
  ) {}

  public get instance(): IDishCRUDService {
    return this.isAdminData ? this._DishAdminService : this._DishUserService;
  }
}
