
import { PdfMakeWrapper, Img, Txt, Cell, Table, Polyline, Canvas, Line, Rect, Columns } from 'pdfmake-wrapper';
import pdfFonts from 'pdfmake/build/vfs_fonts'; // fonts provided for pdfmake
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: "root",
})
export class InvoiceHelper {
    constructor(private datePipe: DatePipe) {
    }
    async show(invoice) {
    const logo_url = '../../../assets/images/logo_fons.jpg';
    // this.getImgFromUrl(logo_url, async (img) => {
      PdfMakeWrapper.setFonts(pdfFonts);
      const pdf = new PdfMakeWrapper();
      pdf.pageOrientation("portrait");
      pdf.pageSize("A4");
      pdf.pageMargins([60, 60, 60, 60]);
      pdf.info({
        title: `Fuudie ${invoice.number}`,
        author: "Fuudie.es",
        subject: `Fuudie ${invoice.number}`,
      });
      const dataInf = this.datePipe.transform(invoice.created, "yyyy_MM_dd");
      // pdf.add(new Txt("FULLA DE TASQUES").bold().fontSize(16).end);
      await this.addHeader(pdf, invoice);
      this.addDetails(pdf, invoice);
      // this.addMaterial(pdf);
      // this.addOperators(pdf);
      // this.addFotos(pdf);
      // this.addObs(pdf);
      const nameFile = `fuudie_invoice_${invoice.number}.pdf`;
      await pdf.create().download(nameFile);
    // });
  }
  async addHeader(pdf: PdfMakeWrapper, invoice) {
    const back = [
      new Table([[
        new Cell(new Txt(`Fuudie`).fontSize(20).alignment('left').bold().end).border([false]).end,
        new Cell(new Txt(`Factura Nº ${invoice.number}`).fontSize(20).alignment('right').bold().end).border([false]).end]]).widths(['auto', '*']).margin([60, 40, 60, 40]).end,
          // await new Img('https://firebasestorage.googleapis.com/v0/b/fuudie-81c95.appspot.com/o/home%2FFuudies_VerdBlanc.jpg?alt=media&token=c472885c-a625-4902-b11c-8162abd6c4e7')
          //   .absolutePosition(20, 20).width(90).build()
      ];
      pdf.background(back);
      const body = [];
      body.push([]);
      body.push([]);
      body.push([]);
      body.push([]);
      body.push([]);
      body.push([]);
      body.push([]);
      body[0].push(new Cell(new Txt('MIQUEL VERGÉS VILARRUBIA').end).border([false]).end);
      body[0].push(new Cell(new Txt("Facturado a:").alignment('right').end).border([false]).end);
      body[0].push(new Cell(new Txt(invoice.customer_name).end).border([false]).end);
      body[1].push(new Cell(new Txt('Av/ Pius XII, 22').end).border([false]).end);
      body[1].push(new Cell(new Txt('').end).border([false]).end);
      body[1].push(new Cell(new Txt(`${invoice.customer_address.line1} ${invoice.customer_address.line2 ? invoice.customer_address.line2 : ''}`).end).border([false]).end);
      body[2].push(new Cell(new Txt('Vic (08500)').end).border([false]).end);
      body[2].push(new Cell(new Txt('').end).border([false]).end);
      body[2].push(new Cell(new Txt(`${invoice.customer_address.city} (${invoice.customer_address.postal_code})`).end).border([false]).end);
      body[3].push(new Cell(new Txt('Barcelona (ES)').end).border([false]).end);
      body[3].push(new Cell(new Txt('').end).border([false]).end);
      body[3].push(new Cell(new Txt(`${invoice.customer_address.state} (${invoice.customer_address.country})`).end).border([false]).end);
      body[4].push(new Cell(new Txt("47851672B").end).border([false]).end);
      body[4].push(new Cell(new Txt('').end).border([false]).end);
      body[4].push(new Cell(new Txt(`${invoice.customer_tax_ids[0].value}`).end).border([false]).end);
      body[5].push(new Cell(new Txt('fuudie@fuudie.es').end).border([false]).end);
      body[5].push(new Cell(new Txt('').end).border([false]).end);
      body[5].push(new Cell(new Txt(`${invoice.customer_email}`).end).border([false]).end);
      body[6].push(new Cell(new Txt('').end).border([false]).end);
      body[6].push(new Cell(new Txt('').end).border([false]).end);
      body[6].push(new Cell(new Txt(`${invoice.customer_phone}`).end).border([false]).end);
    const t = new Table(body);
    pdf.add(
       t.widths(['auto', '*', 'auto']).margin([0, 20, 0, 0]).end
    );
  }
  addDetails(pdf: PdfMakeWrapper, invoice) {
    pdf.add(new Txt(`Fecha facturación: ${this.datePipe.transform(new Date(invoice.created * 1000), 'dd/MM/yyyy')}`).fontSize(16).alignment("left").margin([0, 20, 0, 0]).end);
    pdf.add(new Txt(`Detalles`).fontSize(16).alignment("left").bold().margin([0, 20, 0, 0]).end);
    const body = [];
    let i = 0;
    (invoice.lines.data as any[]).forEach(di => {
      body.push([]);
      body[i].push(new Cell(new Txt(di.description).end).border([false, false, false, true]).end);
      body[i].push(new Cell(new Txt(`${di.amount / 100} ${di.currency.toUpperCase()}`).alignment('right').end).border([false, false, false, true]).end);
      i++;
    });
    const subtotal = Math.round(invoice.total / 1.21);
    const iva = invoice.total - subtotal;
    body.push([new Cell(new Txt('Incluye el 21% de IVA').alignment('right').margin([0, 40, 0, 0]).end).border([false]).end, new Cell(new Txt('').end).border([false]).end]);
    body.push([new Cell(new Txt('SUBTOTAL').alignment('right').end).border([false]).margin([0, 40, 0, 0]).end, new Cell(new Txt(`${subtotal / 100} ${invoice.currency.toUpperCase()}`).alignment('right').end).border([false]).margin([0, 40, 0, 0]).end]);
    body.push([new Cell(new Txt('IVA (21%)').alignment('right').end).border([false]).end, new Cell(new Txt(`${iva / 100} ${invoice.currency.toUpperCase()}`).alignment('right').end).border([false]).end]);
    body.push([new Cell(new Txt('TOTAL').alignment('right').end).border([false]).end, new Cell(new Txt(`${invoice.total / 100} ${invoice.currency.toUpperCase()}`).alignment('right').end).border([false]).end]);

    const t = new Table(body);
    pdf.add(
       t.widths(['*', 'auto']).margin([0, 20, 0, 0]).end
    );

  }
}
