import { Container, Table, Text, Photo, DishCollection, Page, DishStructure, Index } from '../models/menu.model';
export class StructureUtils {
    static getContainer() {
        const c: Container = {
            t: 'container',
            s: {
                bc: 'transparent',
                boc: 'transparent',
                br: '0',
                bs: '0',
                c: 'black',
                f: 'inherit',
                m: '0',
                p: '0',
                s: '16px',
            },
            cnt: []
        };
        return c;
    }
    static getTable() {
        const table: Table = {
            t: 'table',
            cn: 2,
            rn: 1,
            cnt: [[
                StructureUtils.getContainer(),
                StructureUtils.getContainer()]
            ],
            hl: false,
            h: ['auto'],
            w: [6, 6],
            s: { lw: '0' }
        };
        return table;
    }
    static getText(languages) {
        const trd = {};
        languages.forEach(li => {
            trd[li] = '';
        });
        const text: Text = {
            t: 'text',
            s: {
                hp: 'center',
                bc: 'transparent',
                c: 'black',
                f: 'inherit',
                m: '0',
                p: '0',
                s: '16px',
            },
            tx: '',
            txt: trd
        };
        return text;
    }
    static getImage() {
        const image: Photo = {
            t: 'image',
            src: null,
            s: {
                w: '100%',
                h: '100%'
            }
        };
        return image;
    }
    static getDishCollection() {
        const dcoll: DishCollection = {
            t: 'dishcollection',
            m: 'dish_group',
            c: [],
            d: [],
            s: {
                p: '0px',
                m: '0px',
                bc: 'transparent',
                f: 'inherit',
                s: '12px',
                c: 'black',
                lw: '0px',
            }
        };
        return dcoll;
    }
    static getDish() {
        const d: DishStructure = {
            t: 'dish',
            id: '',
            s: {}
        };
        return d;
    }
    static getPage() {
        const p: Page = {
            t: 'page', cnt: {
                t: 'container',
                s: {
                    bc: 'transparent',
                    boc: 'transparent',
                    br: '0px',
                    bs: '0px',
                },
                cnt: []
            }
        };
        return p;
    }
    static getIndex() {
        const i: Index = {
            dt: 'Standard',
            s: {},
            t: 'index',
            ref: [],
        };
        return i;
    }
}
