<div class="form-group" *ngIf="contents">
    <div class="formGroup" *ngIf="contents.dt">
        <label>{{'style-drawer.template' | translate}}</label>
        <ng-select [disabled]="!contents.dt" appendTo="body" (change)="changeTemplate($event.code)" class="custom3" [items]="templates" bindLabel="name" bindValue="code" placeholder="{{'style-drawer.templates' | translate}}" [multiple]="false" [(ngModel)]="contents.dt"></ng-select>
        <!-- <select class="formControl" (change)="contents.dt = $event.target.value" [value]="contents.dt" aria-placeholder="Template">
                <option selected value="Standard">Standard</option>
            </select> -->
    </div>
    <ng-container *ngIf="contents.s">
        <div class="row">
            <div class="col-3">
                <div class="input-group mb-3">
                    <label>{{'style-drawer.height' | translate}}</label>
                    <div class="input-group-prepend">
                        <input type="number" [attr.disabled]="contents.s.h ? (contents.s.h !== 'auto' ? null : 'disabled') : 'disabled'" class="formControl form-control" (change)="contents.s.h = ((heighttype.value !== 'auto') ? (+$event.target.value + heighttype.value) : 'auto')"
                            [value]="contents.s.h?.replace('px', '').replace('%', '').replace('auto', '')" placeholder="{{'style-drawer.height' | translate}}" #heigthvalue>
                    </div>
                    <select [attr.disabled]="contents.s.h ? null : 'disabled'" class="formControl form-control" (change)="contents.s.h = (($event.target.value !== 'auto') ? (+heigthvalue.value + $event.target.value) : 'auto')" [value]="contents.s.h?.endsWith('px') ? 'px' : (contents.s.h?.endsWith('%') ? '%' : 'auto')"
                        #heighttype>
                        <option value="%">%</option>
                        <option value="px">Pixel</option>
                        <option value="auto">Auto</option>
                    </select>
                </div>
            </div>
            <div class="col-3">
                <div class="input-group mb-3">
                    <label>{{'style-drawer.width' | translate}}</label>
                    <div class="input-group-prepend">
                        <input type="number" [attr.disabled]="contents.s.w ? null : 'disabled'" class="formControl form-control" (change)="contents.s.w = +$event.target.value + widthtype.value" [value]="contents.s.w?.replace('px', '').replace('%', '')" placeholder="{{'style-drawer.width' | translate}}"
                            #widthvalue>
                    </div>
                    <select [attr.disabled]="contents.s.w ? null : 'disabled'" class="formControl form-control" (change)="contents.s.w = +widthvalue.value + $event.target.value" [value]="contents.s.w?.endsWith('px') ? 'px' : '%'" #widthtype>
                        <option value="%">%</option>
                        <option value="px">Pixel</option>
                    </select>
                </div>
            </div>
            <div class="col-3">
                <div class="input-group mb-3">
                    <label>{{'style-drawer.margin' | translate}}</label>
                    <div class="input-group-prepend">
                        <input type="number" [attr.disabled]="contents.s.m ? null : 'disabled'" class="formControl form-control" (change)="contents.s.m = +$event.target.value + margintype.value" [value]="contents.s.m?.replace('px', '').replace('%', '')" placeholder="{{'style-drawer.margin' | translate}}"
                            #marginvalue>
                    </div>
                    <select [attr.disabled]="contents.s.m ? null : 'disabled'" class="formControl form-control" (change)="contents.s.m = +marginvalue.value + $event.target.value" [value]="contents.s.m?.endsWith('px') ? 'px' : '%'" #margintype>
                        <option value="%">%</option>
                        <option value="px">Pixel</option>
                    </select>
                </div>
            </div>
            <div class="col-3">
                <div class="input-group mb-3">
                    <label>{{'style-drawer.padding' | translate}}</label>
                    <div class="input-group-prepend">
                        <input type="number" [attr.disabled]="contents.s.p ? null : 'disabled'" class="formControl form-control" (change)="contents.s.p = +$event.target.value + paddingtype.value" [value]="contents.s.p?.replace('px', '').replace('%', '')" placeholder="{{'style-drawer.padding' | translate}}"
                            #paddingvalue>
                    </div>
                    <select [attr.disabled]="contents.s.p ? null : 'disabled'" class="formControl form-control" (change)="contents.s.p = +paddingvalue.value + $event.target.value" [value]="contents.s.p?.endsWith('px') ? 'px' : '%'" #paddingtype>
                        <option selected value="%">%</option>
                        <option value="px">Pixel</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label>{{'style-drawer.font' | translate}}</label>
                <ng-select [disabled]="contents.s.f === null || contents.s.f === undefined" appendTo="body" (change)="contents.s.f = ($event)? $event.code : ''" class="custom3" [items]="fonts" bindLabel="name" bindValue="code" placeholder="{{'style-drawer.fonts' | translate}}"
                    [multiple]="false" [(ngModel)]="contents.s.f"></ng-select>
            </div>
            <div class="col-2">
                <div class="formGroup">
                    <label>{{'style-drawer.size' | translate}}</label>
                    <input [disabled]="!contents.s.s" type="number" style="width: 75px" class="formControl form-control" (change)="contents.s.s = +$event.target.value + 'px'" [value]="contents.s.s ? contents.s.s.replace('px', '') : ''" placeholder="{{'style-drawer.size' | translate}}">
                </div>
            </div>
            <div class="col-3">
                <label>{{'style-drawer.horizontalalign' | translate}}</label>
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="contents.s.hp">
                    <label ngbButtonLabel class="btn-primary" [ngClass]="{'active': contents.s.hp === 'left'}">
                        <input [attr.disabled]="contents.s.hp ? null : 'disabled'" ngbButton type="radio" value="left"><fa-icon class="fas fa-align-left"></fa-icon>
                        </label>
                    <label ngbButtonLabel class="btn-primary" [ngClass]="{'active': contents.s.hp === 'center'}">
                        <input [attr.disabled]="contents.s.hp ? null : 'disabled'" ngbButton type="radio" value="center"><fa-icon class="fas fa-align-center"></fa-icon>
                        </label>
                    <label ngbButtonLabel class="btn-primary" [ngClass]="{'active': contents.s.hp === 'right'}">
                        <input [attr.disabled]="contents.s.hp ? null : 'disabled'" ngbButton type="radio" value="right"><fa-icon class="fas fa-align-right"></fa-icon>
                        </label>
                    <label ngbButtonLabel class="btn-primary" [ngClass]="{'active': contents.s.hp === 'justify'}">
                        <input [attr.disabled]="contents.s.hp ? null : 'disabled'" ngbButton type="radio" value="justify"><fa-icon class="fas fa-align-justify"></fa-icon>
                        </label>
                </div>
            </div>
            <div class="col-2">
                <div class="formGroup">
                    <label>{{'style-drawer.textformat' | translate}}</label>
                    <div class="btn-group btn-group-toggle">
                        <label class="btn-primary" ngbButtonLabel [ngClass]="{'active': contents.s.b}">
                        <input type="checkbox" ngbButton [(ngModel)]="contents.s.b"><fa-icon class="fas fa-bold"></fa-icon>
                        </label>
                        <label class="btn-primary" ngbButtonLabel [ngClass]="{'active': contents.s.cu}">
                        <input type="checkbox" ngbButton [(ngModel)]="contents.s.cu"><fa-icon class="fas fa-italic"></fa-icon>
                        </label>
                        <label class="btn-primary" ngbButtonLabel [ngClass]="{'active': contents.s.u}">
                        <input type="checkbox" ngbButton [(ngModel)]="contents.s.u"><fa-icon class="fas fa-underline"></fa-icon>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <!-- Font extr -->
        <div class="row" *ngIf="contents.s.f2">
            <div class="col-4">
                <label>{{'style-drawer.font' | translate}}</label>
                <ng-select [disabled]="!contents.s.f2" appendTo="body" (change)="contents.s.f2 = $event.code" class="custom3" [items]="fonts" bindLabel="name" bindValue="code" placeholder="{{'style-drawer.fonts' | translate}}" [multiple]="false" [(ngModel)]="contents.s.f2"></ng-select>
            </div>
            <div class="col-2">
                <div class="formGroup">
                    <label>{{'style-drawer.size' | translate}}</label>
                    <input [disabled]="!contents.s.s2" type="number" style="width: 75px" class="formControl form-control" (change)="contents.s.s2 = +$event.target.value + 'px'" [value]="contents.s.s2 ? contents.s.s2.replace('px', '') : ''" placeholder="{{'style-drawer.size' | translate}}">
                </div>
            </div>
            <div class="col-3">
                <label>{{'style-drawer.horizontalalign' | translate}}</label>
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="contents.s.hp2">
                    <label ngbButtonLabel class="btn-primary" [ngClass]="{'active': contents.s.hp2 === 'left'}">
                        <input [attr.disabled]="contents.s.hp2 ? null : 'disabled'" ngbButton type="radio" value="left"><fa-icon class="fas fa-align-left"></fa-icon>
                        </label>
                    <label ngbButtonLabel class="btn-primary" [ngClass]="{'active':  contents.s.hp2 === 'center'}">
                        <input [attr.disabled]="contents.s.hp2 ? null : 'disabled'" ngbButton type="radio" value="center"><fa-icon class="fas fa-align-center"></fa-icon>
                        </label>
                    <label ngbButtonLabel class="btn-primary" [ngClass]="{'active': contents.s.hp2 === 'right'}">
                        <input [attr.disabled]="contents.s.hp2 ? null : 'disabled'" ngbButton type="radio" value="right"><fa-icon class="fas fa-align-right"></fa-icon>
                        </label>
                    <label ngbButtonLabel class="btn-primary" [ngClass]="{'active':  contents.s.hp2 === 'justify'}">
                        <input [attr.disabled]="contents.s.hp2 ? null : 'disabled'" ngbButton type="radio" value="justify"><fa-icon class="fas fa-align-justify"></fa-icon>
                        </label>
                </div>
            </div>
            <div class="col-2">
                <div class="formGroup">
                    <label>{{'style-drawer.textformat' | translate}}</label>
                    <div class="btn-group btn-group-toggle">
                        <label class="btn-primary" ngbButtonLabel [ngClass]="{'active': contents.s.b2}">
                        <input type="checkbox" ngbButton [(ngModel)]="contents.s.b2"><fa-icon class="fas fa-bold"></fa-icon>
                        </label>
                        <label class="btn-primary" ngbButtonLabel [ngClass]="{'active': contents.s.cu2}">
                        <input type="checkbox" ngbButton [(ngModel)]="contents.s.cu2"><fa-icon class="fas fa-italic"></fa-icon>
                        </label>
                        <label class="btn-primary" ngbButtonLabel [ngClass]="{'active': contents.s.u2}">
                        <input type="checkbox" ngbButton [(ngModel)]="contents.s.u2"><fa-icon class="fas fa-underline"></fa-icon>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <!-- FOnt 3-->
        <div class="row" *ngIf="contents.s.f3">
            <div class="col-4">
                <label>{{'style-drawer.font' | translate}}</label>
                <ng-select [disabled]="!contents.s.f3" appendTo="body" (change)="contents.s.f3 = $event.code" class="custom3" [items]="fonts" bindLabel="name" bindValue="code" placeholder="Fonts" [multiple]="false" [(ngModel)]="contents.s.f3"></ng-select>
            </div>
            <div class="col-2">
                <div class="formGroup">
                    <label>{{'style-drawer.size' | translate}}</label>
                    <input [disabled]="!contents.s.s3" type="number" style="width: 75px" class="formControl form-control" (change)="contents.s.s3 = +$event.target.value + 'px'" [value]="contents.s.s3 ? contents.s.s3.replace('px', '') : ''" placeholder="Font size">
                </div>
            </div>
            <div class="col-3">
                <label>{{'style-drawer.horizontalalign' | translate}}</label>
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="contents.s.hp3">
                    <label ngbButtonLabel class="btn-primary" [ngClass]="{'active': contents.s.hp3 === 'left'}">
                        <input [attr.disabled]="contents.s.hp3 ? null : 'disabled'" ngbButton type="radio" value="left"><fa-icon class="fas fa-align-left"></fa-icon>
                        </label>
                    <label ngbButtonLabel class="btn-primary" [ngClass]="{'active': contents.s.hp3 === 'v'}">
                        <input [attr.disabled]="contents.s.hp3 ? null : 'disabled'" ngbButton type="radio" value="center"><fa-icon class="fas fa-align-center"></fa-icon>
                        </label>
                    <label ngbButtonLabel class="btn-primary" [ngClass]="{'active': contents.s.hp3 === 'right'}">
                        <input [attr.disabled]="contents.s.hp3 ? null : 'disabled'" ngbButton type="radio" value="right"><fa-icon class="fas fa-align-right"></fa-icon>
                        </label>
                    <label ngbButtonLabel class="btn-primary" [ngClass]="{'active': contents.s.hp3 === 'justify'}">
                        <input [attr.disabled]="contents.s.hp3 ? null : 'disabled'" ngbButton type="radio" value="justify"><fa-icon class="fas fa-align-justify"></fa-icon>
                        </label>
                </div>
            </div>
            <div class="col-2">
                <div class="formGroup">
                    <label>{{'style-drawer.textformat' | translate}}</label>
                    <div class="btn-group btn-group-toggle">
                        <label class="btn-primary" ngbButtonLabel [ngClass]="{'active': contents.s.b3}">
                        <input type="checkbox" ngbButton [(ngModel)]="contents.s.b3"><fa-icon class="fas fa-bold"></fa-icon>
                        </label>
                        <label class="btn-primary" ngbButtonLabel [ngClass]="{'active': contents.s.cu3}">
                        <input type="checkbox" ngbButton [(ngModel)]="contents.s.cu3"><fa-icon class="fas fa-italic"></fa-icon>
                        </label>
                        <label class="btn-primary" ngbButtonLabel [ngClass]="{'active': contents.s.u3}">
                        <input type="checkbox" ngbButton [(ngModel)]="contents.s.u3"><fa-icon class="fas fa-underline"></fa-icon>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row ">
            <div class="col-2 ">
                <label>{{'style-drawer.maincolor' | translate}}</label>
                <input [attr.disabled]="contents.s.c ? null : 'disabled'" [(colorPicker)]="contents.s.c" style="width: 100%;" class="formControl form-control" [style.background]="contents.s.c" />
            </div>
            <div class="col-2 ">
                <label>{{'style-drawer.backcolor' | translate}}</label>
                <input [attr.disabled]="contents.s.bc ? null : 'disabled'" [(colorPicker)]="contents.s.bc" style="width: 100%;" class="formControl form-control" [style.background]="contents.s.bc" />
            </div>
            <div class="col-2 ">
                <label>{{'style-drawer.bordercolor' | translate}}</label>
                <input [attr.disabled]="contents.s.boc ? null : 'disabled'" [(colorPicker)]="contents.s.boc" style="width: 100%;" class="formControl form-control" [style.background]="contents.s.boc" />
            </div>
            <div class="col-2 ">
                <label>{{'style-drawer.color2' | translate}}</label>
                <input [attr.disabled]="contents.s.c2 ? null : 'disabled'" [(colorPicker)]="contents.s.c2" style="width: 100%;" class="formControl form-control" [style.background]="contents.s.c2" />
            </div>
            <div class="col-2 ">
                <label>{{'style-drawer.color3' | translate}}</label>
                <input [attr.disabled]="contents.s.c3 ? null : 'disabled'" [(colorPicker)]="contents.s.c3" style="width: 100%;" class="formControl form-control" [style.background]="contents.s.c3" />
            </div>
            <div class="col-2 ">
                <label>{{'style-drawer.color4' | translate}}</label>
                <input [attr.disabled]="contents.s.c4 ? null : 'disabled'" [(colorPicker)]="contents.s.c4" style="width: 100%;" class="formControl form-control" [style.background]="contents.s.c4" />
            </div>
            <!-- <div class="col-auto ">
                <label>Color 5</label>
                <input [attr.disabled]="contents.s.c5 ? null : 'disabled'" [(colorPicker)]="contents.s.c5" style="width: 100%;" class="formControl form-control" [style.background]="contents.s.c5" />
            </div> -->
        </div>
        <div class="row ">
            <div class="col-4 ">
                <label>{{'style-drawer.linewidth' | translate}}</label>
                <input [attr.disabled]="contents.s.lw ? null : 'disabled'" type="number " class="formControl form-control " (change)="contents.s.lw=+ $event.target.value + 'px'" [value]="contents.s.lw?.replace( 'px', '') " placeholder="{{
                            'style-drawer.linewidth' | translate}} ">
            </div>
            <div class="col-4 ">
                <label>{{'style-drawer.bordersize' | translate}}</label>
                <input [attr.disabled]="contents.s.bs ? null : 'disabled'" type="number " class="formControl form-control " (change)="contents.s.bs=+ $event.target.value + 'px'" [value]="contents.s.bs?.replace( 'px', '') " placeholder="{{
                            'style-drawer.borderwidth' | translate}} ">
            </div>
            <div class="col-4 ">
                <label>{{'style-drawer.borderradius' | translate}}</label>
                <input [attr.disabled]="contents.s.br ? null : 'disabled'" type="number " class="formControl form-control " (change)="contents.s.br=+ $event.target.value + 'px'" [value]="contents.s.br?.replace( 'px', '') " placeholder="{{
                            'style-drawer.borderradius' | translate}} ">
            </div>
        </div>
    </ng-container>
</div>