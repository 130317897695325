<div class="displayTable backgroundresetpass font">
    <div class="displayTableCell displaytableresetpass">
        <!-- <div class="px-logo logo">
            <a href="https://fuudie-81c95.web.app/sign-in" target="_blank">
                <img src="assets/Fuudies_Blanc.png" alt="Fuudie">
            </a>
        </div> -->
        <div class="authBlock menuresetpass">
            <h3>{{'forgot-password.resetpassword' | translate}}</h3>

            <p class="text-center">{{'forgot-password.pleaseenter' | translate}}</p>

            <div class="formGroup">
                <input type="email" class="formControl roundedBtn backgroundID" placeholder="{{'forgot-password.emailaddress' | translate}}" #passwordResetEmail required>
            </div>

            <!-- Calling ForgotPassword from AuthService Api -->
            <div class="formGroup">
                <input type="submit" class="btn btnPrimary roundedBtn btnresetpass" value="{{'forgot-password.resetpassword' | translate}}" (click)="reset(passwordResetEmail.value)">
            </div>
            <div class="redirectToLogin redirectstyle">
                <span>{{'forgot-password.gobackto' | translate}}<span class="redirect redirectstyle" (click)="signIn()">{{'forgot-password.login' | translate}}</span></span>
            </div>
        </div>



    </div>
</div>