<div *ngIf="currentUser" class="inner-adjust font">

    <div class="pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">{{'profile.accountsettings' | translate}}</h1>
    </div>
    <!-- Show user data when logged in -->
    <div *ngIf="authService.userData as user">
        <div ngbNav #nav="ngbNav" class="nav-tabs">
            <div ngbNavItem>
                <a ngbNavLink class="selectpage">{{'profile.general' | translate}}</a>
                <!-- <ng-template class="selectpage" ngbTabTitle>General</ng-template> -->
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-2">
                            <h1 class="h5 inlineComp">{{'profile.photo' | translate}}</h1>
                            <button type="button" (click)="editPhotoUrl()" *ngIf="!editPhotoInfo" class="btn btn-primary bmd-btn-fab rightBtn inlineComp btntr2"><fa-icon class="fas fa-edit"></fa-icon></button>
                            <button type="button" (click)="cancelPhotoUrl()" *ngIf="editPhotoInfo" class="btn btn-primary bmd-btn-fab rightBtn inlineComp btntr2"><fa-icon class="fas fa-times"></fa-icon></button>
                            <!-- <button type="button" (click)="editPhotoUrl()" class="btn btn-primary bmd-btn-fab rightBtn inlineComp btntr2"><fa-icon class="fas fa-edit"></fa-icon></button> -->
                            <img *ngIf="!editPhotoInfo" class="align-self-start mr-5 img-thumbnail rounded-circle marginTop" src="{{(user.photoURL) ? user.photoURL : '/assets/dummy-user.png'}}" alt="{{user.displayName}}">
                            <app-media-add *ngIf="editPhotoInfo" class="marginTop" uploadPath="profile" src="{{(user.photoURL) ? user.photoURL : '/assets/dummy-user.png'}}" [rounded]="true" [showRemove]="false" (valueChange)="photoUrlChanged($event)"></app-media-add>
                            <button *ngIf="editPhotoInfo" (click)="changePhotoUrl()" class="btn btn-primary savebutton">{{'profile.change' | translate}}</button>
                        </div>
                        <div class="col-md-5">
                            <form [formGroup]="uInfoForm">
                                <div class="pb-2 mb-3">
                                    <h1 class="h5 inlineComp">{{'profile.personalinf' | translate}}</h1>
                                    <button type="button" (click)="editPersontalInformation()" *ngIf="!editUserInfo" class="btn btn-primary bmd-btn-fab rightBtn inlineComp btntr2"><fa-icon class="fas fa-edit"></fa-icon></button>
                                    <button type="button" (click)="cancelPersontalInformation()" *ngIf="editUserInfo" class="btn btn-primary bmd-btn-fab rightBtn inlineComp btntr2"><fa-icon class="fas fa-times"></fa-icon></button>
                                </div>
                                <div class="form-group row">
                                    <label for="username" class="col-sm-4 col-form-label">{{'profile.username' | translate}}: </label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control newform" id="username" placeholder="User name" [value]="currentUser.email" disabled>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="firstname" class="col-sm-4 col-form-label">{{'profile.nameandsur' | translate}}: </label>
                                    <div class="col-sm-8">
                                        <input formControlName="firstName" type="text" id="firstname" class="form-control newform" placeholder="{{'profile.nameandsur' | translate}}">
                                        <div *ngIf="f.firstName.errors" class="invalid-feedback">
                                            <div *ngIf="f.firstName.errors.required">{{'profile.nameandsurrequired' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="emailOther" class="col-sm-4 col-form-label">{{'profile.otheremail' | translate}}: </label>
                                    <div class="col-sm-8">
                                        <input formControlName="emailOther" type="email" id="emailOther" class="form-control newform" placeholder="{{'profile.otheremail' | translate}}">
                                        <div *ngIf="f.emailOther.errors" class="invalid-feedback">
                                            <div *ngIf="f.emailOther.errors.email">{{'profile.emailvalid' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <button *ngIf="editUserInfo" type="submit" (click)="changePersonalInformation()" class="btn btn-primary savebutton">{{'profile.change' | translate}}</button>
                            </form>
                            <form [formGroup]="uPassForm">
                                <div class="pb-2 mb-3">
                                    <h1 class="h5 inlineComp">{{'profile.changepass' | translate}}</h1>
                                    <button type="button" (click)="editUserPassword()" *ngIf="!editUserPass" class="btn btn-primary bmd-btn-fab rightBtn inlineComp btntr2"><fa-icon class="fas fa-edit"></fa-icon></button>
                                    <button type="button" (click)="cancelUserPassword()" *ngIf="editUserPass" class="btn btn-primary bmd-btn-fab rightBtn inlineComp btntr2"><fa-icon class="fas fa-times"></fa-icon></button>
                                </div>
                                <div class="form-group row">
                                    <label for="oldPass" class="col-sm-4 col-form-label">{{'profile.currentpass' | translate}}: </label>
                                    <div class="col-sm-8">
                                        <input type="password" formControlName="oldPass" class="form-control newform" id="oldPass" placeholder="{{'profile.currentpass' | translate}}">
                                        <div *ngIf="p.oldPass.errors" class="invalid-feedback">
                                            <div *ngIf="p.oldPass.errors.required">{{'profile.currentpassrequired' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="newPass" class="col-sm-4 col-form-label">{{'profile.newpass' | translate}}: </label>
                                    <div class="col-sm-8">
                                        <input type="password" formControlName="newPass" class="form-control newform" id="newPass" placeholder="{{'profile.newpass' | translate}}">
                                        <div *ngIf="p.newPass.errors" class="invalid-feedback">
                                            <div *ngIf="p.newPass.errors.required">{{'profile.newpassrequired' | translate}}</div>
                                            <div *ngIf="p.newPass.errors.pattern">{{'profile.newpassvalid' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="confirmNewPass" class="col-sm-4 col-form-label">{{'profile.confirmpass' | translate}}: </label>
                                    <div class="col-sm-8">
                                        <input type="password" formControlName="confirmNewPass" class="form-control newform" id="confirmNewPass" placeholder="{{'profile.confirmpass' | translate}}">
                                        <div *ngIf="(p.confirmNewPass.errors || uPassForm.errors )" class="invalid-feedback">
                                            <div *ngIf="p.confirmNewPass.errors && p.confirmNewPass.errors.required">{{'profile.confpassrequired' | translate}}</div>
                                            <div *ngIf="uPassForm.errors && uPassForm.errors.notSame">{{'profile.confpassequal' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <button *ngIf="editUserPass" (click)="changeUserPassword()" type="submit" class="btn btn-primary savebutton">{{'profile.change' | translate}}</button>
                            </form>
                        </div>
                        <div class="col-md-5">
                            <h3>{{'profile.accounttype' | translate}}</h3>
                            <div class="form-group row">
                                <label for="plan" class="col-sm-4 col-form-label">{{'profile.plan' | translate}}: </label>
                                <div class="col-sm-8" *ngIf="currentPlan && currentPlan.id">
                                    <label>{{ currentPlan.n }}</label>
                                </div>
                                <div class="col-sm-8" *ngIf="!currentPlan || !currentPlan.id">
                                    <label>FREE</label>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary savebutton" (click)="changePlan()">{{'profile.change' | translate}}</button>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div ngbNavItem>
                <a ngbNavLink class="selectpage">{{'profile.billinginf' | translate}}</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-6">
                            <form [formGroup]="uBillForm">
                                <div class="pb-2 mb-3">
                                    <h1 class="h5 inlineComp">{{'profile.billingdet' | translate}}</h1>
                                    <button type="button" (click)="editBillingDetails()" *ngIf="!editBillingInfo" class="btn btn-primary bmd-btn-fab rightBtn inlineComp btntr2"><fa-icon class="fas fa-edit"></fa-icon></button>
                                    <button type="button" (click)="cancelBillingInfo()" *ngIf="editBillingInfo" class="btn btn-primary bmd-btn-fab rightBtn inlineComp btntr2"><fa-icon class="fas fa-times"></fa-icon></button>
                                </div>
                                <div class="form-group row">
                                    <label for="name" class="col-sm-4 col-form-label">{{'profile.bussinessname' | translate}}*: </label>
                                    <div class="col-sm-8">
                                        <input formControlName="name" type="text" id="name" class="form-control newform" placeholder="{{'profile.bussinessname' | translate}}">
                                        <div *ngIf="b.name.errors" class="invalid-feedback">
                                            <div *ngIf="b.name.errors.required">{{'profile.bussinessnamereq' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="cif" class="col-sm-4 col-form-label">{{'profile.cif' | translate}}*: </label>
                                    <div class="col-sm-8">
                                        <input formControlName="cif" type="text" id="cif" class="form-control newform" placeholder="{{'profile.cif' | translate}}">
                                        <div *ngIf="(b.cif.errors || uBillForm.errors )" class="invalid-feedback">
                                            <div *ngIf="b.cif.errors && b.cif.errors.required">{{'profile.cifreq' | translate}}</div>
                                            <div *ngIf="uBillForm.errors && uBillForm.errors.invalid_Vat">{{'profile.cifvalid' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="line1" class="col-sm-4 col-form-label">{{'profile.address' | translate}}*: </label>
                                    <div class="col-sm-8">
                                        <input formControlName="line1" type="text" id="line1" class="form-control newform" placeholder="{{'profile.address' | translate}}">
                                        <div *ngIf="b.line1.errors" class="invalid-feedback">
                                            <div *ngIf="b.line1.errors.required">{{'profile.addressreq' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="city" class="col-sm-4 col-form-label">{{'profile.city' | translate}}*: </label>
                                    <div class="col-sm-8">
                                        <input formControlName="city" type="text" id="city" class="form-control newform" placeholder="{{'profile.city' | translate}}">
                                        <div *ngIf="b.city.errors" class="invalid-feedback">
                                            <div *ngIf="b.city.errors.required">{{'profile.cityreq' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="state" class="col-sm-4 col-form-label">{{'profile.state' | translate}}*: </label>
                                    <div class="col-sm-8">
                                        <input formControlName="state" type="text" id="state" class="form-control newform" placeholder="{{'profile.state' | translate}}">
                                        <div *ngIf="b.state.errors" class="invalid-feedback">
                                            <div *ngIf="b.state.errors.required">{{'profile.statereq' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="country" class="col-sm-4 col-form-label">{{'profile.country' | translate}}*: </label>
                                    <div class="col-sm-8">
                                        <div class="input-group mb-3">
                                            <ng-select formControlName="country" appendTo="body" class="custom" [items]="countries" bindLabel="name" bindValue="code" placeholder="{{'profile.country' | translate}}*" [multiple]="false"></ng-select>
                                            <div *ngIf="b.country.errors" class="invalid-feedback">
                                                <div *ngIf="b.country.errors.required">{{'profile.countryreq' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="postal_code" class="col-sm-4 col-form-label">{{'profile.postalcode' | translate}}*: </label>
                                    <div class="col-sm-8">
                                        <input formControlName="postal_code" type="text" id="postal_code" class="form-control newform" placeholder="{{'profile.postalcode' | translate}}">
                                        <div *ngIf="b.postal_code.errors" class="invalid-feedback">
                                            <div *ngIf="b.postal_code.errors.required">{{'profile.postalcodereq' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="phone" class="col-sm-4 col-form-label">{{'profile.phone' | translate}}*: </label>
                                    <div class="col-sm-8">
                                        <input formControlName="phone" type="number" id="phone" class="form-control newform" placeholder="{{'profile.phone' | translate}}">
                                        <div *ngIf="b.phone.errors" class="invalid-feedback">
                                            <div *ngIf="b.phone.errors.required">{{'profile.phonereq' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="email" class="col-sm-4 col-form-label">{{'profile.email' | translate}}: </label>
                                    <div class="col-sm-8">
                                        <input formControlName="email" type="email" id="email" class="form-control newform" placeholder="{{'profile.email' | translate}}">
                                        <div *ngIf="b.email.errors" class="invalid-feedback">
                                            <div *ngIf="b.email.errors.required">{{'profile.emailreq' | translate}}</div>
                                            <div *ngIf="b.email.errors.email">{{'profile.emailvalid' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <button *ngIf="editBillingInfo" (click)="changeBillingInfor()" type="submit" class="btn btn-primary savebutton">{{'profile.change' | translate}}</button>
                            </form>
                        </div>
                        <div class="col-md-6">
                            <div class="pb-2 mb-3">
                                <h1 class="h5 inlineComp"> {{ 'profile.creditcard' | translate}}</h1>
                                <button type="button" (click)="editCard()" *ngIf="!editCreditCard" class="btn btn-primary bmd-btn-fab rightBtn inlineComp btntr2"><fa-icon class="fas fa-edit"></fa-icon></button>
                                <button type="button" (click)="cancelCard()" *ngIf="editCreditCard" class="btn btn-primary bmd-btn-fab rightBtn inlineComp btntr2"><fa-icon class="fas fa-times"></fa-icon></button>
                            </div>
                            <form [formGroup]="uCardForm" novalidate>
                                <div class="form-group row">
                                    <label for="ccnumber" class="col-sm-4 col-form-label">{{'profile.number' | translate}}*: </label>
                                    <div class="col-sm-8">
                                        <input id="ccnumber" class="form-control" placeholder="{{'profile.number' | translate}}" formControlName="ccnumber" type="tel" autocomplete="cc-number" ccNumber>
                                        <div *ngIf="c.ccnumber.errors" class="invalid-feedback">
                                            <div *ngIf="c.ccnumber.errors.required">{{'profile.numberreq' | translate}}</div>
                                            <div *ngIf="c.ccnumber.errors">{{'profile.numbervalid' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="name" class="col-sm-4 col-form-label">{{'profile.titular' | translate}}*: </label>
                                    <div class="col-sm-8">
                                        <input id="name" class="form-control" placeholder="{{'profile.titular' | translate}}" formControlName="name" type="text" autocomplete="off">
                                        <div *ngIf="c.name.errors" class="invalid-feedback">
                                            <div *ngIf="c.name.errors.required">{{'profile.titularreq' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="expirationDate" class="col-sm-4 col-form-label">{{'profile.expirationdate' | translate}}*: </label>
                                    <div class="col-sm-8">
                                        <input id="expirationDate" class="form-control" placeholder="{{'profile.expirationdate' | translate}}" formControlName="expirationDate" type="tel" autocomplete="cc-exp" ccExp>
                                        <div *ngIf="c.expirationDate.errors" class="invalid-feedback">
                                            <div *ngIf="c.expirationDate.errors.required">{{'profile.expirationdatereq' | translate}}</div>
                                            <div *ngIf="c.expirationDate.errors">{{'profile.expirationdatevalid' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="cvc" class="col-sm-4 col-form-label">{{'profile.cvc' | translate}}*: </label>
                                    <div class="col-sm-8">
                                        <input id="cvc" class="form-control" placeholder="{{'profile.cvc' | translate}}" formControlName="cvc" type="tel" autocomplete="off" ccCvc>
                                        <div *ngIf="c.cvc.errors" class="invalid-feedback">
                                            <div *ngIf="c.cvc.errors.required">{{'profile.cvcreq' | translate}}</div>
                                            <div *ngIf="c.cvc.errors">{{'profile.cvcvalid' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <button *ngIf="editCreditCard" (click)="changeCard()" type="submit" class="btn btn-primary savebutton">{{'profile.change' | translate}}</button>
                            <!-- <app-credit-card-form></app-credit-card-form> -->
                        </div>
                    </div>
                </ng-template>
            </div>
            <div ngbNavItem>
                <a ngbNavLink class="selectpage">{{'profile.invoices' | translate}}</a>
                <ng-template ngbNavContent>
                    <div class="inner-adjust font">
                        <form>
                            <table class="table table-striped newtable">
                                <thead class="headline">
                                    <tr>
                                        <th scope="col" sortable="customer_name" direction="asc" (sort)="onSort($event)" class="newborderleft newcolor">{{'profile.customer_name' | translate}}</th>
                                        <th scope="col" sortable="created" direction="desc" (sort)="onSort($event)" class="newcolor">{{'profile.date' | translate}}</th>
                                        <th scope="col" class="newcolor">{{'profile.total' | translate}}</th>
                                        <th class="fit newborderright newcolor" scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let invoice of invoices$ | async " class="contentline">
                                        <td class="formatocelda">
                                            <ngb-highlight [result]="invoice.customer_name" [term]="iService.searchTerm"></ngb-highlight>
                                        </td>
                                        <td class="formatocelda">
                                            {{ invoice.created * 1000 | date }}
                                        </td>
                                        <td class="formatocelda">
                                            {{ ((invoice.total / 100) | number:'1.2-2') + ' €' }}
                                        </td>
                                        <td class="fit" scope="row">
                                            <button type="button" (click)="show(invoice)" class="btn btn-primary bmd-btn-fab inlineComp btnTaleRow rightbtn"><fa-icon [icon]="faEye"></fa-icon></button>
                                            <!-- <button type="button" (click)="edit(invoice)" class="btn btn-success bmd-btn-fab inlineComp btnTaleRow rightbtn"><fa-icon [icon]="faEdit"></fa-icon></button>
                                            <button type="button" [disabled]="getNumberDishes(unit) > 0" (click)="delete(unit)" class="btn btn-danger bmd-btn-fab inlineComp btnTaleRow rightbtn"><fa-icon [icon]="faTrash"></fa-icon></button> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="d-flex justify-content-between p-2 selectpag">
                                <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="iService.page" [pageSize]="iService.pageSize">
                                </ngb-pagination>

                                <select class="custom-select paginas" style="width: auto" name="pageSize" [(ngModel)]="iService.pageSize">
                                    <option [ngValue]="5">5 {{'common.itemsperpage' | translate}}</option>
                                    <option [ngValue]="10">10 {{'common.itemsperpage' | translate}}</option>
                                    <option [ngValue]="15">15 {{'common.itemsperpage' | translate}}</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </div>
            <button class="trashicon" style="float: right" (click)="removeBillingDetails()"><span class="fa fa-trash-alt"></span></button>
        </div>
        <div [ngbNavOutlet]="nav"></div>
    </div>

</div>