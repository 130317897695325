import { Injectable, NgZone, PipeTransform } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import firebase from 'firebase/app';
import { map, tap, debounceTime, switchMap, delay } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../directives/sortable.directive';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';

interface SearchResult {
    invoices: any[];
    total: number;
}

interface State {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: SortColumn;
    sortDirection: SortDirection;
    selectedLang: string;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(invoices: any[], column: SortColumn, direction: string, selectedLang: string = null): any[] {
    if (direction === '' || column === '') {
        return invoices;
    } else {
        if (selectedLang && column === "n") {
          return [...invoices].sort((a, b) => {
            const an = a.name_traductions[selectedLang] ? a.name_traductions[selectedLang] : a.name;
            const bn = b.name_traductions[selectedLang] ? b.name_traductions[selectedLang] : b.name;
            const res = compare(`${an}`, `${bn}`);
            return direction === "asc" ? res : -res;
          });
        }
        return [...invoices].sort((a, b) => {
            const res = compare(`${a[column]}`, `${b[column]}`);
            return direction === 'asc' ? res : -res;
        });
    }
}

function matches(invoice: any, term: string, selectedLang: string = null, pipe: PipeTransform = null) {
    return invoice.customer_name.toLowerCase().includes(term.toLowerCase());
    // if (selectedLang && invoice.name_traductions[selectedLang]) {
    //   return invoice.name_traductions[selectedLang].toLowerCase().includes(term.toLowerCase());
    // }
    // return invoice.name.toLowerCase().includes(term.toLowerCase());
}

@Injectable({
  providedIn: "root",
})
export class InvoiceAdminService {
  database = "IN";
  databaseTrash = "INTrash";
  getInvoices: any;
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _invoices$ = new BehaviorSubject<any[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private _money$ = new BehaviorSubject<number>(0);
  private invoices: any[] = [];
  private _state: State = {
    page: 1,
    pageSize: 15,
    searchTerm: "",
    sortColumn: "created",
    sortDirection: "desc",
    selectedLang: localStorage.getItem("cLang"),
  };
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private functions: AngularFireFunctions
  ) {
      this.getInvoices = this.functions.httpsCallable('API_GET_INVOICES');
  }
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
  get ref() {
    const user = JSON.parse(localStorage.getItem("user"));
    const establishment = JSON.parse(localStorage.getItem("establishment"));
    return firebase
      .firestore()
      .collection(
        `users/${user.uid}/${this.database}`
      )
      .doc("Units");
  }
  get refObs() {
    const user = JSON.parse(localStorage.getItem("user"));
    const establishment = JSON.parse(localStorage.getItem("establishment"));
    return this.afs
      .collection<any>(
        `users/${user.uid}/IN`
      );
  }
  async getAll() {
    return this.getInvoices()
      .pipe(
        map((actions) => {
          this.invoices = (actions as any).data;
          this._search$.next();
        })
      )
      .subscribe((data) => {});
  }
  async getAllDataTable() {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this._invoices$.next(result.invoices);
        this._total$.next(result.total);
        this._money$.next(result.invoices.map(ii => ii.total).reduce((a, b) => a + b, 0));
      });
    return this.getInvoices()
      .pipe(
        map((actions) => {
          this.invoices = (actions as any).data;
          this._search$.next();
        })
      )
      .subscribe((data) => {});
  }
  get invoices$() {
    return this._invoices$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get money$() {
    return this._money$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }
  get selectedLang() {
    return this._state.selectedLang;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }
  set selectedLang(selectedLang: string) {
    this._set({ selectedLang });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const {
      sortColumn,
      sortDirection,
      pageSize,
      page,
      searchTerm,
      selectedLang
    } = this._state;

    // 1. sort
    let invoices = sort(this.invoices, sortColumn, sortDirection, selectedLang);

    // 2. filter
    invoices = invoices.filter((invoice) =>
      matches(invoice, searchTerm, selectedLang)
    );
    const total = invoices.length;

    // 3. paginate
    invoices = invoices.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
    return of({ invoices, total });
  }
}
