import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormGroupDirective, NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignInComponent } from '../sign-in/sign-in.component';
import { VerifyEmailComponent } from '../verify-email/verify-email.component';

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.css"],
})
export class SignUpComponent implements OnInit {
  signUpForm: FormGroup;
  submitted = false;
  constructor(public authService: AuthService, private fb: FormBuilder, private modal: NgbActiveModal, private modalService: NgbModal) {
    this.signUpForm = this.fb.group(
      {
        firstName: ["", Validators.required],
        email: ["", [Validators.email, Validators.required]],
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/
            ),
          ],
        ],
        confirmPassword: ["", Validators.required],
      },
      { validators: this.checkPasswords }
    );
  }
  get f() {
    return this.signUpForm.controls;
  }
  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const pass = group.get("password").value;
    const confirmPass = group.get("confirmPassword").value;

    return pass === confirmPass ? null : { notSame: true };
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.signUpForm.invalid) {
      return;
    }
    this.authService.SignUp(
      this.signUpForm.get("email").value,
      this.signUpForm.get("password").value,
      this.signUpForm.get("firstName").value
    ).then(result => {
      if (result) {
        this.authService.SendVerificationMail().then(() => {
          this.modal.close();
          this.modalService.open(VerifyEmailComponent, {
            windowClass: "white-content",
            centered: true,
          });
        });
      }
    });
  }
  ngOnInit() {}
  signIn() {
    this.modal.close();
    const modal = this.modalService.open(SignInComponent, {
      windowClass: "white-content",
      centered: true,
    });
    modal.result.then(
      (result) => {},
      (reason) => {}
    );
  }
  signInGoogle() {
    this.authService.GoogleAuth().then((result) => {
      if (result) {
        this.modal.close();
      }
    });
  }
}
