import { Component, Input, ContentChildren } from '@angular/core';
import { StructureTemplateComponent } from '../../shared/structure-template.component';
import { Container, Menu } from '../../../../shared/models/menu.model';
import { CategoryDish } from '../../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../../shared/models/unit-mesure.model';
import { Dish } from '../../../../shared/models/dish.model';
import { Allergen } from '../../../../shared/models/allergen.model';
import { StyleDrawerComponent } from '../../style-drawer/style-drawer.component';
@Component({
  selector: 'app-container-standard-template',
  template: `
          <ng-container *ngIf="_contents?.s">
                <div
                  id="{{ _contents.id }}"
                  [style.width]="_contents.s.w"
                  [style.margin-bottom]="_contents.s.m"
                  [style.margin-top]="_contents.s.m"
                  [style.padding]="_contents.s.p"
                  [style.font-size]="_contents.s.s"
                  [style.background-color]="_contents.s.bc"
                  [style.color]="_contents.s.c"
                  [style.font-family]="_contents.s.f"
                  [style.text-align]="_contents.s.hp"
                  [style.font-weight]="(_contents.s.b ? 'bold' : 'normal')"
                  [style.font-style]="(_contents.s.cu ? 'italic' : 'normal')"
                  [style.text-decoration]="(_contents.s.u ? 'underline' : 'none')"
                  [style.border-color]="_contents.s.boc"
                  [style.border-width]="_contents.s.bs"
                  [style.border-radius]="_contents.s.br">
            <ng-container *ngFor=" let ci of _contents.cnt">
                <div *ngIf="ci.t === 'text'" style="display: block;">
                    <app-text-template text-host [menu]="menu" [contents]="ci" [type]="ci.dt"  [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected"></app-text-template>
                </div>
                <div *ngIf="ci.t === 'container'" style="display: block;">
                    <app-container-template container-host [menu]="menu" [contents]="ci" [type]="ci.dt" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected"></app-container-template>
                </div>
                <div *ngIf="ci.t === 'dishcollection'" style="display: block;">
                    <app-dish-collection-template dish-collection-host [menu]="menu" [contents]="ci" [type]="ci.dt"  [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected"></app-dish-collection-template>
                </div>
                <div *ngIf="ci.t === 'image'" style="display: block;">
                    <app-image-template image-host [menu]="menu" [contents]="ci" [type]="ci.dt"  [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected"></app-image-template>
                </div>
                <div *ngIf="ci.t === 'table'" style="display: block;">
                    <app-table-template table-host [menu]="menu" [contents]="ci" [type]="ci.dt"  [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected"></app-table-template>
                </div>
                <div *ngIf="ci.t === 'dish'" style="display: block;">
                    <app-dish-template dish-host [menu]="menu" [contents]="ci" [type]="ci.dt"  [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected"></app-dish-template>
                </div>
            </ng-container>
            </div>
</ng-container>
    `,
    styles: ['']
})
export class ContainerStandardTemplateComponent implements StructureTemplateComponent<Container> {
  _contents: Container;
  @Input() menu: Menu;
  @Input() type: string;
  @Input() set contents(v: Container) {
    this._contents = v;
    if (!this._contents.id) {
      this._contents.id = 'id_' + Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8);
    }
    this._contents.s = {
      hp: v.s?.hp ? v.s.hp : "left",
      p: v.s?.p ? v.s.p : "5px",
      m: v.s?.m ? v.s.m : "10px",
      bc: v.s?.bc ? v.s.bc : "transparent",
      c: v.s?.c ? v.s.c : "black",
      f: v.s?.f ? v.s.f : "inherit",
      s: v.s?.s ? v.s.s : "16px",
      b: v.s?.b !== undefined ? v.s.b : false,
      cu: v.s?.cu !== undefined ? v.s.cu : false,
      u: v.s?.u !== undefined ? v.s.u : false,
      boc: v.s?.boc ? v.s.boc : "black",
      bs: v.s?.bs ? v.s.bs : "0px",
      br: v.s?.br ? v.s.br : "5px",
      w: v.s?.w ? v.s.w : "100%",
    };
  }
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() dishes: Dish[];
  @Input() allergens: Allergen[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @ContentChildren('styleDrawer') styleDrawer: StyleDrawerComponent;
  constructor() { }
}
