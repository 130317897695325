
import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { faSearch, faPlus, faEye, faTrash, faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../shared/directives/sortable.directive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UnitMeasure } from '../../shared/models/unit-mesure.model';
import { UnitMeasureService } from '../../shared/services/unit-measure.service';
import { UnitsMeasuresModalComponent } from '../units-measures-modal/units-measures-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../shared/services/helper.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-units-measures',
  templateUrl: './units-measures.component.html',
  styleUrls: ['./units-measures.component.css']
})
export class UnitsMeasuresComponent implements OnInit {
  faPlus = faPlus;
  faSearch = faSearch;
  faEye = faEye;
  faTrash = faTrash;
  faEdit = faEdit;
  faSpinner = faSpinner;
  unitsMeasures$: Observable<UnitMeasure[]>;
  total$: Observable<number>;
  selectedLang: string;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(public service: UnitMeasureService, public modalService: NgbModal, private tService: TranslateService,
    private helper: HelperService, private _router: Router) {
    this.service.isAdminData = this._router.url.includes('admin');
    this.tService.onLangChange.subscribe((data) => {
      this.selectedLang = data.lang.toUpperCase();
      this.service.instance.selectedLang = data.lang.toUpperCase();
    });
    this.service.instance.getAllDataTable();
    this.unitsMeasures$ = service.instance.data$;
    this.total$ = service.instance.total$;
  }

  ngOnInit(): void { }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.instance.sortColumn = column;
    this.service.instance.sortDirection = direction;
  }
  getLanguages(unit: UnitMeasure) {
    return Object.keys(unit.name_traductions).filter(key => unit.name_traductions[key].name !== '').join(', ');
  }
  add() {
    const modal = this.modalService
      .open(UnitsMeasuresModalComponent, { ariaLabelledBy: 'modal-basic-title' });
  }
  show(unit) {

  }
  edit(unit) {
    const modal = this.modalService
      .open(UnitsMeasuresModalComponent, { ariaLabelledBy: 'modal-basic-title' });
    if (unit) {
      modal.componentInstance.editElement = unit;
    }
    modal.result.then(
      (result) => {
      },
      (reason) => {
      }
    );
  }
  delete(unit) {
    this.helper.openConfirmationDeleteData().then(result => {
      if (result) {
        this.service.instance.remove(unit);
      }
    });
  }
  getNumberDishes(ud: any) {
    return (ud.dishes) ? Object.keys(ud.dishes).filter(key => ud.dishes[key]).length : 0;
  }
}
