import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../shared/services/user';
import { MailService } from '../../shared/services/mail.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  name: string;
  email: string;
  restaurant: string;
  phone: string;
  message: string;
  @Input() currentUser: User;

  constructor(private emailService: MailService) {}

  ngOnInit() {}

  /**
   * Process the form we have. Send to whatever backend
   * Only alerting for now
   */
  processForm() {
  }
  enviar() {
    if (this.email && this.message && this.name && this.restaurant && this.phone) {
      this.emailService.SendEmail({
        id: '',
        e: this.email,
        m: this.message,
        n: this.name,
        r: this.restaurant,
        p: this.phone
      }).then(() => {
        this.email = ''; this.message = ''; this.name = ''; this.restaurant = ''; this.phone = '';
        alert('OK');
      }, (reason) => {
        alert(reason);
      });
    }
  }
}
