import { Component, Input, ContentChildren } from '@angular/core';
import { StructureTemplateComponent } from '../../shared/structure-template.component';
import { Container, DishStructure, Menu } from '../../../../shared/models/menu.model';
import { CategoryDish } from '../../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../../shared/models/unit-mesure.model';
import { Dish } from '../../../../shared/models/dish.model';
import { Allergen } from '../../../../shared/models/allergen.model';
import { DecimalPipe } from '@angular/common';
import { StyleDrawerComponent } from '../../style-drawer/style-drawer.component';
@Component({
  selector: 'app-dish-standard-template',
  template: `
    <li
      *ngIf="_contents?.s"
      id="{{ _contents.id }}"
      class="list-group-item d-flex justify-content-between align-items-center"
      [style.background-color]="_contents.s.bc"
      [style.font-family]="_contents.s.f"
      [style.font-size]="_contents.s.s"
      [style.margin-bottom]="_contents.s.m"
      [style.width]="_contents.s.w"
      [style.font-weight]="(_contents.s.b ? 'bold' : 'normal')"
      [style.font-style]="(_contents.s.cu ? 'italic' : 'normal')"
      [style.text-decoration]="(_contents.s.u ? 'underline' : 'none')"
      [style.border-color]="_contents.s.boc"
      [style.border-width]="_contents.s.bs"
      [style.border-radius]="_contents.s.br"
    style="display: block!important; width:100%">
      <div *ngIf="getDish() as d">
        <div style="display: block!important; width: 100%; text-align: left;" >{{ d.nt[language_selected] ? d.nt[language_selected] : d.n}}</div>
        <div style="display: block!importnat; width: 100%;">
          <span class="nice_price" [style.border-color]="_contents.s.boc" [style.font-size]="_contents.s.s" style="display: block!important; padding-right: 5px; width: auto">
        <span style="display: inline!important; text-align: left; padding-right: 5px; width: 100% padding-left: 5px;">
          <img *ngFor="let ali of getAllergens()" style="width: 23px; height: 23px; margin-left: 5px; margin-bottom: 3px" [src]="'../../../../assets/alergens/' + ali + '.png'"/>
  </span>
          <span style="display: inline!important; text-align: right; float: right; padding-right: 5px;">{{ getPrices() }}</span></span>
      </div>
      </div>
    </li>
    `,
    styles: [`
    .nice_price {
      border-left: 2px solid #702D2D;
      border-bottom: 2px solid #702D2D;
      border-radius: 40px 40px 40px 40px;
      outline-offset: 15px;
    }
    `]
})
export class DishStandardTemplateComponent implements StructureTemplateComponent<DishStructure> {
   _contents: DishStructure;
  @Input() menu: Menu;
  @Input() type: string;
  @Input() set contents(v: DishStructure) {
    this._contents = v;
    if (!this._contents.id) {
      this._contents.id = 'id_' + Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8);
    }
    this._contents.s = {
      hp: v.s?.hp ? v.s.hp : "left",
      p: v.s?.p ? v.s.p : "5px",
      m: v.s?.m ? v.s.m : "10px",
      bc: v.s?.bc ? v.s.bc : "transparent",
      c: v.s?.c ? v.s.c : "black",
      f: v.s?.f ? v.s.f : "inherit",
      s: v.s?.s ? v.s.s : "16px",
      b: v.s?.b !== undefined ? v.s.b : false,
      cu: v.s?.cu !== undefined ? v.s.cu : false,
      u: v.s?.u !== undefined ? v.s.u : false,
      boc: v.s?.boc ? v.s.boc : "#702D2D",
      bs: v.s?.bs ? v.s.bs : "0px",
      br: v.s?.br ? v.s.br : "5px",
      w: v.s?.w ? v.s.w : "100%",
    };
  }
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() dishes: Dish[];
  @Input() allergens: Allergen[];
  @Input() languages: string[];
  @Input() language_selected: string;
  lblPreus = {
    'CA': 'Segons mercat',
    'ES': 'Según mercado',
    'EN': 'According to market'
  };
  @ContentChildren('styleDrawer') styleDrawer: StyleDrawerComponent;
  constructor(private numberPipe: DecimalPipe) { }
  getDish() {
      if (this._contents && this.dishes && this.dishes.length > 0) {
        return this.dishes.find(di => di.id === this._contents.id);
      }
  }
  getPrices() {
    const dish = this.getDish();
    if (this.units.length > 0) {
      return Object.keys(dish.pr).sort((k1, k2) => {
        if (k1 > k2) {
          return 1;
        } else {
          return -1;
        }
      }).map(pi => {
        let price = this.numberPipe.transform(dish.pr[pi]['EUR'], '1.2-2');
        if (!price) {
          price = this.lblPreus[this.language_selected] + ' ';
        } else {
          price = price + ' € ';
        }
        return price + ((Object.keys(dish.pr).length > 1) ? this.units.find(ui => ui.id === pi).name_traductions[this.language_selected] : '');
      }).join(' / ');
    }
    return '';
  }
  getAllergens() {
    const dish = this.getDish();
    if (this.allergens.length > 0) {
      return this.allergens.filter(ai => dish.a.findIndex(ali => ali === ai.id) > -1).map(ali => ali.icon).sort((a1, a2) => {
      if (a1 > a2) {
        return 1;
      } else {
        return -1;
      }
      });
    }
  }
}
