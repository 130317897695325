<div class="row">
    <div class="col-sm-2">
          
    </div>
    <div class="col-sm-4">
      <div class="planform" (click)="selectAccount(data_plans[0].t)" [ngClass]="{'selected_plan': selectedElement === 1}">
        <p class="plantitle font">Bàsic</p>
        <p class="pricetext font">12,99 €/mes</p>
        <ul class="conditionslist">
          <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>Carta Digital</li>
          <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>Accés mitjançant QR</li>
          <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>Panell fàcil edició de carta</li>
          <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>Gestió 1 restaurant</li>
          <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>Fins a 200 plats</li>
          <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>Fins a 5 menús/cartes</li>
        </ul>
    </div>         
    </div>
    <div class="col-sm-4">
      <div class="planform" (click)="selectAccount(data_plans[1].t)" [ngClass]="{'selected_plan': selectedElement === 2}">
        <p class="plantitle font">Pro</p>
        <p class="pricetext font">24,99 €/mes</p>
        <ul class="conditionslist">
          <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>Carta Digital</li>
          <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>Accés mitjançant QR</li>
          <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>Panell fàcil edició de carta</li>
          <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>Gestió múltiples restaurants</li>
          <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>Fins a 400 plats</li>
          <li class="planlist"><i class="fas fa-check-circle" style="color:#87af99"></i>Fins a 10 menús/cartes</li>
        </ul>
    </div>           
    </div>
    <div class="col-sm-2">
     
    </div>
</div>