import { ContainerStandardTemplateComponent } from '../templates/standard/container-standard-template.component';
import { MenuStandardTemplateComponent } from '../templates/standard/menu-standard-template.component';
import { PageStandardTemplateComponent } from '../templates/standard/page-standard-template.component';
import { TableStandardTemplateComponent } from '../templates/standard/table-standard-template.component';
import { DishCollectionStandardTemplateComponent } from '../templates/standard/dish-collection-standard-template.component';
import { DishStandardTemplateComponent } from '../templates/standard/dish-standard-template.component';
import { TextStandardTemplateComponent } from '../templates/standard/text-standard-template.component';
import { ImageStandardTemplateComponent } from '../templates/standard/image-standard-template.component';
import { IndexStandardTemplateComponent } from '../templates/standard/index-standard-template.component';
import { DishClassicTemplateComponent } from '../templates/classic/dish-classic-collection-template.component';
import { DishCollectionClassicTemplateComponent } from '../templates/classic/dish-collection-classic-template.component';
// // tslint:disable-next-line:no-empty-interface
// interface ComponentR { }

// // tslint:disable-next-line:interface-over-type-literal
// type ComponentClass = { new (): ComponentR };

export const REGISTRY = new Map<string, any>();
REGISTRY.set('MenuStandardTemplateComponent', MenuStandardTemplateComponent);
REGISTRY.set('PageStandardTemplateComponent', PageStandardTemplateComponent);
REGISTRY.set('ContainerStandardTemplateComponent', ContainerStandardTemplateComponent);
REGISTRY.set('TableStandardTemplateComponent', TableStandardTemplateComponent);
REGISTRY.set('DishCollectionStandardTemplateComponent', DishCollectionStandardTemplateComponent);
REGISTRY.set('DishStandardTemplateComponent', DishStandardTemplateComponent);
REGISTRY.set('TextStandardTemplateComponent', TextStandardTemplateComponent);
REGISTRY.set('ImageStandardTemplateComponent', ImageStandardTemplateComponent);
REGISTRY.set("IndexStandardTemplateComponent", IndexStandardTemplateComponent);
REGISTRY.set("DishClassicTemplateComponent", DishClassicTemplateComponent);
REGISTRY.set("DishCollectionClassicTemplateComponent", DishCollectionClassicTemplateComponent);
// function register(cls: ComponentClass): void {
//     REGISTRY.set(cls.name, cls);
// }
// function getTypeFor(name: string): ComponentClass {
//     return REGISTRY.get(name);
// }
