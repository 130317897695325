import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Menu, Structure } from '../../../../shared/models/menu.model';
import { Establishment } from '../../../../shared/models/establishment.model';
import { EstablishmentService } from '../../../../shared/services/establishment.service';
import { StructureUtils } from '../../../../shared/utils/htmlGenerator.util';
import { CategoryDish } from '../../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../../shared/models/unit-mesure.model';
import { Dish } from '../../../../shared/models/dish.model';
import { Allergen } from '../../../../shared/models/allergen.model';

@Component({
  selector: "app-structure-drawer",
  templateUrl: "./structure-drawer.component.html",
  styleUrls: ["./structure-drawer.component.css"],
})
export class StructureDrawerComponent implements OnInit {
  faPlus = faPlus;
  faEdit = faEdit;
  _structure: Menu;
  establishment: Establishment;
  _languages: string[] = [];
  collapseId = 0;
  @Input() set structure(menu: Menu) {
    this._structure = menu;
    this._languages = menu.l;
  }
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() dishes: Dish[];
  @Input() allergens: Allergen[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @Output() changeStructure = new EventEmitter<Menu>();
  @Output() edit = new EventEmitter<Structure>();
  @Output() indexChange = new EventEmitter();
  id: string;
  constructor(public estService: EstablishmentService) {
    this.id = 'id_' + Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8);
  }

  ngOnInit(): void {}
  changeContentPage(index, $event) {
    if (this.structure) {
      this.structure.cnt[index] = $event;
    }
    this.emitChange();
  }
  emitChange() {
    this.changeStructure.emit(this._structure);
  }
  addPageInit() {
    this._structure.cnt.unshift(StructureUtils.getPage());
    this.emitChange();
  }
  addPage() {
    this._structure.cnt.push(StructureUtils.getPage());
    this.emitChange();
  }
  deletePage(index) {
    this._structure.cnt.splice(index, 1);
  }
  updateIndex() {
    this.indexChange.emit();
  }
  onTaskDrop(event) {
    this.array_move(this._structure.cnt, event.previousIndex, event.currentIndex);
  }
  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }
  public pageClicked(i) {
    this.collapseId = i;
    document.querySelector('#' + this._structure.cnt[i]?.cnt?.id).scrollIntoView({behavior: 'smooth'});
  }
}
