import { Component, Input, ContentChildren } from '@angular/core';
import { StructureTemplateComponent } from '../../shared/structure-template.component';
import { Menu } from '../../../../shared/models/menu.model';
import { CategoryDish } from '../../../../shared/models/category-dish.model';
import { UnitMeasure } from '../../../../shared/models/unit-mesure.model';
import { Dish } from '../../../../shared/models/dish.model';
import { Allergen } from '../../../../shared/models/allergen.model';
import { StyleDrawerComponent } from '../../style-drawer/style-drawer.component';
@Component({
  selector: 'app-menu-standard-template',
  template: `<style>
      .fixBack {
        background-repeat: no-repeat!important;
        background-position: center center!important;
        background-size: cover!important;
        background-attachment: fixed!important;
      }
      .btn-group-fab {
  position: fixed;
  width: 50px;
  height: auto;
  right: 20px; bottom: 20px;
}
.btn-group-fab div {
  position: relative; width: 100%;
  height: auto;
}
.btn-group-fab .btn {
  position: absolute;
  bottom: 0;
  border-radius: 50%;
  display: block;
  margin-bottom: 4px;
  width: 40px; height: 40px;
  margin: 4px auto;
}
.btn-group-fab .btn-main {
  width: 50px; height: 50px;
  right: 50%; margin-right: -25px;
  z-index: 9;
}
.btn-group-fab .btn-sub {
  bottom: 0; z-index: 8;
  right: 50%;
  margin-right: -20px;
  -webkit-transition: all 2s;
  transition: all 0.5s;
}
.btn-group-fab.active .btn-sub:nth-child(2) {
  bottom: 60px;
}
.btn-group-fab.active .btn-sub:nth-child(3) {
  bottom: 110px;
}
.btn-group-fab.active .btn-sub:nth-child(4) {
  bottom: 160px;
}
.btn-group-fab .btn-sub:nth-child(5) {
  bottom: 210px;
}
    </style>
          <ng-container *ngIf="contents">
  <div class="fixBack" id="top"
    [style.background-image]="contents.bgi"
    [style.background]="contents.tbg === 'c' ? ((contents.bg) ? contents.bg : '') : ((contents.bgi) ? 'url('+contents.bgi+')': '')" style.background-attachment="fixed">
            <app-container-template *ngIf="contents.hh === true && contents.hf === false && contents.eh === false"  style="display: block; width: 100%;"
              container-host [menu]="menu"  [contents]="contents.h" [categories]="categories" [type]="contents.h.dt"  [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected"></app-container-template>
            <div *ngIf="contents.hi === true && contents.i">
            <div class="d-inline-block sticky" ngbDropdown>
              <button *ngIf="contents.ls && showIndexBtn" type="button" class="btn btn-go-top transparent btn-circle btn-md toTopButton" id="langDropDown" ngbDropdownToggle
            [style.background-color]="'white'" (click)="changeLanguage()">{{ contents.dl }}</button>
              <div ngbDropdownMenu aria-labelledby="langDropDown" class="menudropdownuser menuinvisible">
                <button ngbDropdownItem *ngFor="let li of contents.l" (click)="contents.dl = li" class="btn btn-go-top transparent btn-circle btn-sm toTopButton" [style.background-color]="'white'">{{ li }}</button>
              </div>
            </div>
           <app-container-template *ngIf="contents.hh === true && contents.hf === false && contents.eh === true"  style="display: block; width: 100%;"
              container-host [menu]="menu"  [contents]="contents.h" [type]="contents.h.dt"  [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected"></app-container-template>
              <app-index-template
                index-host [menu]="contents" [indexes]="indexes"  [type]="contents.i.dt"
                  [contents]="contents.i" [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected" [showIndexBtn]="showIndexBtn"></app-index-template>
            </div>
            <ng-container *ngFor="let page of contents.cnt; let i = index">
                <div id="{{ 'page_' + i }}" style="display: block; width: 100%;">
                     <app-container-template *ngIf="contents.hh === true && contents.hf === false && contents.eh === true"  style="display: block; width: 100%;"
              container-host [menu]="menu" [contents]="contents.h" [type]="contents.h.dt"  [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected"></app-container-template>
                    <app-page-template style="height: 100%" page-host [menu]="menu"  [contents]="page" [type]="page.dt"  [categories]="categories" [units]="units" [allergens]="allergens" [dishes]="dishes" [languages]="languages" [language_selected]="language_selected"></app-page-template>
                </div>
            </ng-container>
</div>
          </ng-container>
    `,
    styles: [`
    .dropdown-toggle::after {
    display:none;
}
    .menuinvisible {
      background-color: transparent;
      border: 0;
    }
    .btn-go-top {
        background-color: 'white';
        border-color: #87af99 !important;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
        border-radius: 0.5rem;
    }
    .btn-go-top.focus,
    .btn-go-top:focus {
        box-shadow: none;
    }
    .btn-go-top.focus {
        background-color: #87af99 !important;
        border-color: #87af99 !important;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
        border-radius: 0.5rem;
    }
    .btn-go-top:hover {
        background-color: #ffffff !important;
        color: #87af99;
        border-color: #ffffff !important;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
        border-radius: 0.5rem;
    }
    .transparent {
        opacity: 0.7;
    }
    .sticky {
        position: fixed;
        top: 5px;
        left: 0;
        width: 100%;
        z-index: 1;
      }
    h1 {
        color:green;
    }
    .xyz {
        background-size: auto;
        text-align: center;
        padding-top: 100px;
    }
    .btn-circle.btn-sm {
        width: 32px;
        height: 32px;
        padding: 6px 0px;
        border-radius: 16px;
        font-size: 16px;
        text-align: center;
        margin-bottom: 4px;
    }
    .btn-circle.btn-md {
        width: 50px;
        height: 50px;
        padding: 7px 10px;
        border-radius: 25px;
        font-size: 20px;
        text-align: center;
    }
    .btn-circle.btn-xl {
        width: 70px;
        height: 70px;
        padding: 10px 16px;
        border-radius: 35px;
        font-size:24px;
        text-align: center;
    }
    `]
})
export class MenuStandardTemplateComponent implements StructureTemplateComponent<Menu> {
  @Input() menu: Menu;
  @Input() contents: Menu;
  @Input() type: string;
  @Input() categories: CategoryDish[];
  @Input() units: UnitMeasure[];
  @Input() dishes: Dish[];
  @Input() allergens: Allergen[];
  @Input() languages: string[];
  @Input() language_selected: string;
  @Input() indexes: { id: string, name: string, trds: any}[];
  @Input() showIndexBtn: boolean;
  @ContentChildren('styleDrawer') styleDrawer: StyleDrawerComponent;
  active = false;
  constructor() { }
  changeLanguage() {

  }
}
