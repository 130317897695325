import { Injectable, NgZone, PipeTransform } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { map, tap, debounceTime, switchMap, delay } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../directives/sortable.directive';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { MediaGallery } from '../models/mediaGallery.model';
import { IMediaGalleryCRUDService } from '../interfaces/iMediaGalleryCRUDService';

interface SearchResult {
    mediaGalleries: MediaGallery[];
    total: number;
}

interface State {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: SortColumn;
    sortDirection: SortDirection;
    selectedLang: string;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(mediaGalleries: MediaGallery[], column: SortColumn, direction: string, selectedLang: string = null): MediaGallery[] {
    if (direction === '' || column === '') {
        return mediaGalleries;
    } else {
        if (selectedLang && column === "n") {
          return [...mediaGalleries].sort((a, b) => {
            const an = a.nt[selectedLang] ? a.nt[selectedLang] : a.n;
            const bn = b.nt[selectedLang] ? b.nt[selectedLang] : b.n;
            const res = compare(`${an}`, `${bn}`);
            return direction === "asc" ? res : -res;
          });
        }
        return [...mediaGalleries].sort((a, b) => {
            const res = compare(`${a[column]}`, `${b[column]}`);
            return direction === 'asc' ? res : -res;
        });
    }
}

function matches(mediaGallery: MediaGallery, term: string, selectedLang: string = null, pipe: PipeTransform = null) {
    if (selectedLang && mediaGallery.nt[selectedLang]) {
        return mediaGallery.nt[selectedLang].toLowerCase().includes(term.toLowerCase());
    }
    return mediaGallery.n.toLowerCase().includes(term.toLowerCase());
}

@Injectable({
    providedIn: 'root',
})
export class MediaGalleryUserService implements IMediaGalleryCRUDService {
    database = 'MediaGalleries';
    databaseTrash = 'MediaGalleriesTrash';
    protected _loading$ = new BehaviorSubject<boolean>(true);
    protected _search$ = new Subject<void>();
    protected _mediaGalleries$ = new BehaviorSubject<MediaGallery[]>([]);
    protected _total$ = new BehaviorSubject<number>(0);
    protected mediaGalleries: MediaGallery[] = [];
    protected _state: State = {
        page: 1,
        pageSize: 15,
        searchTerm: '',
        sortColumn: '',
        sortDirection: '',
        selectedLang: localStorage.getItem("cLang")
    };
    constructor(
        public afs: AngularFirestore, // Inject Firestore service
        public afAuth: AngularFireAuth, // Inject Firebase auth service
        public ngZone: NgZone // NgZone service to remove outside scope warning
    ) { }
    get timestamp() {
        return firebase.firestore.FieldValue.serverTimestamp();
    }
    get ref() {
        const user = JSON.parse(localStorage.getItem('user'));
        const establishment = JSON.parse(localStorage.getItem('establishment'));
        return firebase
            .firestore()
            .collection(
                `users/${user.uid}/Establishments/${establishment.id}/${this.database}`
            ).doc('MediaGalleries');
    }
    get refObs() {
        const user = JSON.parse(localStorage.getItem('user'));
        const establishment = JSON.parse(localStorage.getItem('establishment'));
        return this.afs.collection<MediaGallery>(
            `users/${user.uid}/Establishments/${establishment.id}/${this.database}`
        ).doc('MediaGalleries');
    }
    createId() {
        return this.afs.createId();
    }
    create(mediaGallery: MediaGallery) {
        mediaGallery.id = mediaGallery.id ? mediaGallery.id : this.afs.createId();
        const value = {};
        value[mediaGallery.id] = mediaGallery;
        this.ref.set(value, { merge: true });
        return mediaGallery;
    }
    edit(mediaGallery) {
        const value = {};
        value[mediaGallery.id] = mediaGallery;
        return this.ref
            .set(value, { merge: true });
    }
    remove(mediaGallery: MediaGallery) {
        const user = JSON.parse(localStorage.getItem('user'));
        const establishment = JSON.parse(localStorage.getItem('establishment'));
        const value = {};
        value[mediaGallery.id] = mediaGallery;
        firebase
            .firestore()
            .doc(
                `users/${user.uid}/EstablishmentsTrash/${establishment.id}/${this.databaseTrash}/MediaGallerys`
            )
            .set(value, { merge: true });
        const value2 = {};
        value2[mediaGallery.id] = firebase.firestore.FieldValue.delete();
        return this.ref.update(value2);
    }
    setAvailable(id: string, a: boolean) {
        const value = {};
        value[id] = { a: a };
        return this.ref.set(value, { merge: true });
    }
    getAll() {
        return this.refObs.snapshotChanges().pipe(
            map((actions) => {
                const data = actions.payload.data();
                return Object.keys(data || {}).map((key) => {
                    return data[key] as MediaGallery;
                });
            })
        );
    }
    getAllDataTable() {
        this._search$
            .pipe(
                tap(() => this._loading$.next(true)),
                debounceTime(200),
                switchMap(() => this._search()),
                delay(200),
                tap(() => this._loading$.next(false))
            )
            .subscribe((result) => {
                this._mediaGalleries$.next(result.mediaGalleries);
                this._total$.next(result.total);
            });
        return this.refObs.snapshotChanges().pipe(
            map((actions) => {
                const data = actions.payload.data();
                this.mediaGalleries = Object.keys(data || {}).map((key) => {
                    return data[key] as MediaGallery;
                });
                this._search$.next();
            })
        ).subscribe(data => {
        });
    }
    get data$() { return this._mediaGalleries$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._state.page; }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }
    get selectedLang() {
      return this._state.selectedLang;
    }

    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize }); }
    set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
    set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }
    set selectedLang(selectedLang: string) {
      this._set({ selectedLang });
    }

    protected _set(patch: Partial<State>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    protected _search(): Observable<SearchResult> {
        const { sortColumn, sortDirection, pageSize, page, searchTerm, selectedLang } = this._state;

        // 1. sort
        let mediaGalleries = sort(this.mediaGalleries, sortColumn, sortDirection, selectedLang);

        // 2. filter
        mediaGalleries = mediaGalleries.filter((country) => matches(country, searchTerm, selectedLang));
        const total = mediaGalleries.length;

        // 3. paginate
        mediaGalleries = mediaGalleries.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        return of({ mediaGalleries, total });
    }
}
