<form class="container font" style="min-height: 640px;" #form *ngIf="_value && _categories && _units &&_allergens &&_dishes && languages">
    <div class="row">
        <div class="col-sm-4" style="text-align: center;">
            <div *ngIf="_value" class="btn-group mr-2" role="group" aria-label="First group" style="text-align: center; margin-top: 5px; margin-bottom: 5px; margin-left: auto; margin-right: auto;">
                <button *ngFor="let lang of _value.l" type="button" class="btn btn-fuddie" [ngClass]="{'active': drawer._language_selected === lang}" (click)="drawer._language_selected = lang && configForm.language_selected = lang && languageChanged(lang)">{{lang}}</button>
            </div>
            <div class="smartphone">
                <!-- <div *ngIf="_value"> -->
                <!-- <app-container-standard-template *ngIf="_value.hh === true && _value.hf === true" class="fixHeader" container-host [contents]="_value.h" [categories]="_value" [units]="_units" [allergens]="_allergens" [dishes]="_dishes" [languages]="languages" [language_selected]="_value? _value.l[0] : 'CA'"></app-container-standard-template> -->
                <div #screenshot id="screenshot" class="content" style="overflow-y: scroll">
                    <app-result-drawer #drawer [showUtils]="true" [structure]="_value" [indexes]="indexes" [categories]="_categories" [units]="_units" [allergens]="_allergens" [dishes]="_dishes" [language_selected]="_value? _value.l[0] : 'CA'" [showIndexBtn]="false"></app-result-drawer>
                </div>
                <!-- </div> -->
            </div>
        </div>
        <div class="col-sm-8">
            <div class="form-group">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" [destroyOnHide]="false">
                    <li [ngbNavItem]="3">
                        <a ngbNavLink class="selectpage">
                            <fa-icon class="fa fa-cog"></fa-icon>
                        </a>
                        <ng-template ngbNavContent>
                            <app-config-form [structure]="_value" [categories]="_categories" [units]="_units" [allergens]="_allergens" [dishes]="_dishes" [languages]="languages" [language_selected]="languages[0]" [languages]="languages" [screenshot]="screenshotRef" (edit)="edit($event)"
                                (changeLanguage)="drawer._language_selected = $event"></app-config-form>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="1">
                        <a ngbNavLink class="selectpage">
                            <fa-icon class="fa fa-code"></fa-icon>
                        </a>
                        <ng-template ngbNavContent>
                            <app-structure-drawer [structure]="_value" [categories]="_categories" [units]="_units" [allergens]="_allergens" [dishes]="_dishes" [languages]="languages" [language_selected]="languages[0]" (edit)="edit($event)" (indexChange)="changeIndex()"></app-structure-drawer>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink class="selectpage">
                            <fa-icon class="fa fa-bold"></fa-icon>
                        </a>
                        <ng-template ngbNavContent>
                            <app-style-drawer (templateChange)="templateChanged()" [menu]="_value" #styleFormDrawer></app-style-drawer>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </div>
    </div>
</form>